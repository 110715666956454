import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
// Customizable Area Start
import { deleteIcon } from "./assets";
import { runEngine } from "../../../framework/src/RunEngine";
import { dateFormateToDDMMYY } from "../../../framework/src/Utilities";
export const configJSON = require("./config.js");

export interface Props {
    open: boolean;
    handleClose: (type: string, action: boolean) => void;
    classes: any;
    handleSubmit: (formData: object) => void;
    accountTypeList: [];
    parentAccountList: [];
    handleChange: () => void;
    accountCreateerrorMessage: string;
    accountCreateNameerrorMessage: string;
    accountDetailserrorMessage: string;
    accountDetails: accountData | null;
    parentListByAccount :(id: string) => void;
}

interface accountAttribute {
    id: number;
    name: string;
    account_type_id: string;
    code: string;
    description: string;
    custom_id: string;
    is_sub_account: boolean;
    parent_academic_account_id: string;
    parent_account_name: string;
  }
  
  interface accountData {
    id: number
    attributes: accountAttribute;
  }

  interface roleMenuSubmenu {
    id: number;
    title: string;
    permission_category_id: number,
    created_at: Date;
  }

  interface InitialField {
    account_type_id: string,
    name: string,
    code: string,
    description: string,
    parent_academic_account_id: string,
    is_sub_account: boolean,
  }

interface S {
    closeCopyModal: boolean;
    data: any;
    noteHeader: string;
    disabledbutton: boolean;
    loadingSpinner: boolean;
    accountFormstate: InitialField;
    accNameError: string;
    accTypeError: string;
    subAccountStatus: boolean;
    parentAccError: string;
}
interface SS {
    id: any;
}

const accountInitialField: InitialField =
{ account_type_id: "",name: "",code: "",description: "",parent_academic_account_id: "",
    is_sub_account: false
};

// Customizable Area End
export default class CopyAccountController extends BlockComponent<Props, S, SS> {
   
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [];

        this.state = {
            closeCopyModal: false,
            data: [],
            noteHeader: '',
            disabledbutton: true,
            loadingSpinner: false,
            accountFormstate: accountInitialField,
            accNameError: "",
            accTypeError: "",
            subAccountStatus: false,
            parentAccError: "",
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }
    // Customizable Area Start
    async componentDidMount() {
        super.componentDidMount();
    }
    
    modalhandleClose = () => {
        this.setState({ closeCopyModal: true })
        this.props.handleClose("copy", false);
    }

    componentDidUpdate(prevProps: Props) {

      if (prevProps.accountDetails !== this.props.accountDetails) {
        const { accountDetails } = this.props;
        if( accountDetails ){
            if( accountDetails ){
                this.setState({ accountFormstate: { 
                  account_type_id: accountDetails?.attributes?.account_type_id, 
                  name: accountDetails?.attributes?.name, 
                  code: accountDetails?.attributes?.code, 
                  description: accountDetails?.attributes?.description, 
                  parent_academic_account_id: accountDetails?.attributes?.parent_academic_account_id, 
                  is_sub_account: accountDetails?.attributes?.is_sub_account
                  
                },subAccountStatus: accountDetails?.attributes?.is_sub_account });
                if(accountDetails?.attributes?.account_type_id){
                    this.props.parentListByAccount(accountDetails?.attributes?.account_type_id);
                }
        }}
      }
    }

    handleChange = (e: any) => {

        let value = e.target.value;
        if(e.target.name === "is_sub_account"){
            value = e.target.checked ? true : false;
            if(!e.target.checked){
                this.setState({ accountFormstate: {...this.state.accountFormstate, parent_academic_account_id: "", [e.target.name]: value } ,subAccountStatus: value});
            }else{
                this.setState({ accountFormstate: { ...this.state.accountFormstate, [e.target.name]: value}, subAccountStatus: value  });
            }
        }else{
           
            if(e.target.name === "account_type_id" || e.target.name === "parent_academic_account_id"){
                value = (e.target.value === "null") ? "" : e.target.value;
                if(e.target.name === "account_type_id"){
                    this.props.parentListByAccount(value);
                }
                this.setState({ accountFormstate: { ...this.state.accountFormstate, parent_academic_account_id: (e.target.name === "account_type_id") ? "" : this.state.accountFormstate.parent_academic_account_id,is_sub_account:(e.target.name === "account_type_id") ? false : this.state.accountFormstate.is_sub_account, [e.target.name]: value },subAccountStatus:(e.target.name === "account_type_id") ? false :this.state.subAccountStatus});
            }else{
              this.setState({ accountFormstate: { ...this.state.accountFormstate, [e.target.name]: value } });
            }
        }
    };

    handleValidation: any = (formState: InitialField) => {
        let isValid = true;
        const regex = /^[^-\s][a-zA-Z0-9_\s-]{2,40}[^\s]$/i;
        if (formState.name === "") {
            this.setState({ accNameError: ('Account name is required') });
            isValid = false;
        }else if (formState.name && regex.test(formState.name) === false) {
            this.setState({
                accNameError: ('Account name is invalid'
                )
            });
            isValid = false;
        } else {
            this.setState({ accNameError: '' });
        }
        if (formState.account_type_id === "") {
            this.setState({
                accTypeError: (
                    'Account type is required'
                )
            });
            isValid = false;
        } else {
            this.setState({ accTypeError: '' });
        }
        if (formState.is_sub_account === true) {
            if (formState.parent_academic_account_id === "") {
                this.setState({
                    parentAccError: (
                        'Parent account is required'
                    )
                });
                isValid = false;
            } else {
                this.setState({ parentAccError: '' });
            }
        } else {
            this.setState({ parentAccError: '' });
        } 
        return isValid;
    };

    handleSubmit = (e: any) => {
        e.preventDefault();
        if (this.handleValidation(this.state.accountFormstate)) {
            console.log('copy data', this.state.accountFormstate);
            this.props.handleSubmit({ accountData: this.state.accountFormstate });
        }
    };
    // Customizable Area End

}