import React from "react";
// Customizable Area Start
import {
    TextField,
    Typography
} from "@material-ui/core";
import { withStyles, } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CustomModal from '../../CommonLayout/CustomModal/src/CustomModal.web';
import OutlinedSelect from "../../../components/src/OutlinedSelect";

import CreateRolesController, {
    Props,
} from "./CreateRolesController";

interface roleMenuSubmenu {
    id: number;
    title: string;
    permission_category_id: number,
    fullAccessStatus: boolean,
    viewStatus: boolean,
    editStatus: boolean,
    createStatus: boolean,
    deleteStatus: boolean,
    approveStatus: boolean
}

interface roleMenuAttributes {
    id: number;
    title: string;
    menus: roleMenuSubmenu[];
}

interface roleMenuData {
    attributes: roleMenuAttributes;
}
// Customizable Area End
class CreateRoles extends CreateRolesController {

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    render() {
        // Customizable Area Start
        const { open, menuList, handleClose, roleMenuListerrorMessage, roleCreateerrorMessage,roleCreateNameerrorMessage } = this.props;
        const { roleFormstate, roleNameError, statusError, permissionError, rolePermissions } = this.state;
        // Customizable Area End
        return (
            // Customizable Area Start
            <CustomModal
                title={"Create Role"}
                handleSubmit={this.handleSubmit}
                buttonTitle={"Create"}
                open={open}
                handleClose={(evt: object) => handleClose("create", false)}
                isError={roleMenuListerrorMessage ? true : false}
                errormsg={roleCreateerrorMessage ? roleCreateerrorMessage : ''}

            >
                {roleMenuListerrorMessage ? <p className="errorMsg2">{roleMenuListerrorMessage}</p> :
                    <>
                        <Grid
                            container
                            spacing={3}
                            style={{
                                marginTop: "10px",
                            }}
                        >
                            <Grid item xs={6}>
                                <FormControl style={{ minWidth: "100%" }}>
                                    <FormLabel style={{ marginBottom: "8px" }}>
                                        Role Name*
                                    </FormLabel>
                                    <TextField
                                        value={roleFormstate.name}
                                        type={'text'}
                                        placeholder={`Role Name`}
                                        className={roleNameError ?"errorInput":"withoutError" }
                                        variant="outlined"
                                        name={'name'}
                                        onChange={this.handleChange}
                                    />
                                    {
                                        roleCreateNameerrorMessage && <p className="errorMsg2">{roleCreateNameerrorMessage}</p>

                                    }
                                    <p className="errorMsg2">{roleNameError}</p>
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl style={{ minWidth: "100%" }}>
                                    <FormLabel style={{ marginBottom: "8px" }}>
                                        Date Created
                                    </FormLabel>
                                    <TextField
                                        value={roleFormstate.created_date}
                                        style={{ width: "100%", backgroundColor: "#f1f1f1" }}
                                        color={'primary'}
                                        variant="outlined"
                                        type={'text'}
                                        disabled
                                        name={'created_date'}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <OutlinedSelect
                                    placeholderTitle="Please Select"
                                    val={roleFormstate.status}
                                    options={[{ id: "active", name: "Active" }, { id: "inactive", name: "Inactive" }]}
                                    label="Status*"
                                    name={'status'}
                                    handleChange={this.handleChange}
                                    className={statusError && !roleFormstate.status ?"errorSelect":"withoutErrorSelect" }                               
                                />
                                {statusError && !roleFormstate.status &&
                                    <p className="errorMsg2">{statusError}</p>
                                }
                            </Grid>
                        </Grid>
                        <p className="errorMsg2" style={{textAlign: "left", marginTop: "26px", marginBottom: "0px" }}>{permissionError}</p>
                        {menuList.map((menu: roleMenuData, index: number) => (
                            <>
                                <Grid key={index} container spacing={0} style={{
                                    backgroundColor: "#fef9e9",
                                    display: "flex",
                                    alignItems: "center",
                                    padding: 20,
                                    borderRadius: 5,
                                    height: "60px",
                                    marginTop: "0px",
                                }}>

                                    <Grid item xs={5}>
                                        <Typography variant="subtitle1" style={{ fontWeight: "bold",color: "#44556c" }}>
                                            {menu.attributes.title}
                                        </Typography>
                                    </Grid>
                                    <Grid item style={{ textAlign: "center" }} xs={2}>
                                        <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
                                            {'Full Access'}
                                        </Typography>
                                    </Grid>
                                    {["View", "Create", "Edit", "Delete", "Approve"].map((label: string) => (
                                        <Grid item xs={1} style={{ paddingLeft: 8 }}>
                                            <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
                                                {label}
                                            </Typography>
                                        </Grid>
                                    ))}
                                </Grid>
                                <div style={{
                                    maxWidth: "100%",
                                    margin: "0px auto",
                                    backgroundColor: "white",
                                    marginTop: "28px",
                                    textAlign: "center"

                                }}>
                                    {menu.attributes.menus.map((fieldData: roleMenuSubmenu, indx: number) => (
                                        <Grid
                                            container
                                            spacing={0}
                                            style={{
                                                padding: 10,
                                                backgroundColor: "#fafafa",
                                                marginBottom: "8px",
                                                borderRadius: "5px",
                                            }}
                                        >
                                            <Grid item xs={5}>
                                                <TextField
                                                    disabled
                                                    value={fieldData.title}
                                                    style={{ width: "100%", backgroundColor: "gainsboro" }}
                                                    variant="outlined"
                                                    inputProps={{ style: { padding: 12, backgroundColor: "#f1f1f1" } }}
                                                    type={'text'}
                                                    color={'primary'} />
                                            </Grid>
                                            <Grid item xs={2} style={{ alignSelf: "center", paddingLeft: 56 }}>
                                                <FormControlLabel
                                                    control={<Checkbox
                                                        onChange={(e) => this.handlePermissionChange(e, fieldData.id)}
                                                        checked={(rolePermissions[fieldData.id]) ? rolePermissions[fieldData.id].fullAccessStatus : false}
                                                        name="fullAccessStatus" />}
                                                    label=""
                                                />
                                            </Grid>
                                            <Grid item xs={1} style={{ alignSelf: "center" }}>
                                                <FormControlLabel
                                                    control={<Checkbox
                                                        onChange={(e) => this.handlePermissionChange(e, fieldData.id)}
                                                        checked={(rolePermissions[fieldData.id]) ? rolePermissions[fieldData.id].viewStatus : false}
                                                        name="viewStatus" />}
                                                    label=""
                                                />
                                            </Grid>
                                            <Grid item xs={1} style={{ alignSelf: "center" }}>
                                                <FormControlLabel
                                                    control={<Checkbox
                                                        onChange={(e) => this.handlePermissionChange(e, fieldData.id)}
                                                        checked={(rolePermissions[fieldData.id]) ? rolePermissions[fieldData.id].createStatus : false}
                                                        name="createStatus" />}
                                                    label=""
                                                />
                                            </Grid>
                                            <Grid item xs={1} style={{ alignSelf: "center" }}>
                                                <FormControlLabel
                                                    control={<Checkbox
                                                        onChange={(e) => this.handlePermissionChange(e, fieldData.id)}
                                                        name="editStatus"
                                                        checked={(rolePermissions[fieldData.id]) ? rolePermissions[fieldData.id].editStatus : false}
                                                    />}
                                                    label=""
                                                />
                                            </Grid>
                                            <Grid item xs={1} style={{ alignSelf: "center" }}>
                                                <FormControlLabel
                                                    control={<Checkbox
                                                        onChange={(e) => this.handlePermissionChange(e, fieldData.id)}
                                                        name="deleteStatus"
                                                        checked={(rolePermissions[fieldData.id]) ? rolePermissions[fieldData.id].deleteStatus : false}
                                                    />}
                                                    label=""
                                                />
                                            </Grid>
                                            <Grid item xs={1} style={{ alignSelf: "center" }}>
                                                <FormControlLabel
                                                    control={<Checkbox
                                                        onChange={(e) => this.handlePermissionChange(e, fieldData.id)}
                                                        checked={(rolePermissions[fieldData.id]) ? rolePermissions[fieldData.id].approveStatus : false}
                                                        name="approveStatus" />}
                                                    label=""
                                                />
                                            </Grid>
                                        </Grid>
                                    ))}
                                </div>
                            </>
                        ))}
                    </>
                }

            </CustomModal>
            // Customizable Area End
        );
    }
}

export const themeCreatStyle = ((theme: any) => ({

    background_color: {
        backgroundColor: theme.palette.primary.main
    },
    title_font: {
        color: theme.props.titleColor,
        fontFamily: theme.props.titleFont
    },
    subTitle_font: {
        color: theme.props.subtitleColor,
        fontFamily: theme.props.subtitleFont
    },
    heading_font: {
        color: theme.props.headingColor,
        fontFamily: theme.props.headingFont
    },
    subHeading_font: {
        color: theme.props.subheadingColor,
        fontFamily: theme.props.subheadingFont
    },
    bodyText_font: {
        color: theme.props.textColor,
        fontFamily: theme.props.textFont
    },
    button_color: {
        backgroundColor: theme.props.buttonColor,
        color: "white",
    },
    icon_color: {
        color: theme.props.iconColor
    },
    button_color_disabled: {
        color: theme.props.buttonColor,
        backgroundColor: "white",
        border: `1px solid ${theme.props.buttonColor}`,
        '&:hover': {
            background: "#fff",
        }
    }
}));

export default withStyles(themeCreatStyle)(
    CreateRoles as React.ComponentType<any>
)