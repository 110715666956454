import React from "react";
// Customizable Area Start
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { withStyles, Theme } from '@material-ui/core/styles';
import { formSubmitBtn,formCancelBtn } from '../../Layout/src/color';
import { Styles } from '@material-ui/styles';
// Customizable Area End
// Customizable Area Start
import CustomBulkUpdateModalController, {
  Props
} from "./CustomBulkUpdateModalController";

import {
  StyleSheet,
  Platform
} from "react-native";
import { ThemeContext } from "react-native-elements";


const useStyles: Styles<Theme, {}, string> = (theme: Theme) => ({
 modalTitle: {
  "backgroundColor": "#fcf3d4",
  "fontWeight": 600
 },
 dialogPaper: {
  maxWidth: "80vh",
  minHeight: "486px",
  borderRadius: "26px",
},
});
// Customizable Area End
class CustomBulkUpdateModal extends CustomBulkUpdateModalController {
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { open, handleClose, submitLoading,  cancelButtonTitle, errormsg, title, children, handleSubmit } = this.props;
    // Customizable Area End
    return (
      // Customizable Area Start
      //Merge Engine DefaultContainer
      <>
        <Dialog
          open={open}
          onClose={handleClose}
          fullWidth={true}
          maxWidth={"md"}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          classes={{ paper: this.props.classes.dialogPaper }}
        >
          {title &&
            <DialogTitle style={{ textAlign: "center" }} id="alert-dialog-title">
              <div style={{
              fontWeight: "bold",
              fontSize: "18px",
              borderBottom: "2px solid #f1f1f1",
              paddingBottom: "14px",
            }}>{title}</div>
            </DialogTitle>
          }
          <DialogContent>
            {children}
          </DialogContent>
          <DialogActions style={{
            justifyContent: "center",
            marginLeft: "18px",
            marginBottom: "26px",
          }}>
            <Button style={this.props.cancelButtonTitle ? {
              boxShadow: 'none',
              textTransform: 'none',
              fontSize: 16,
              padding: '8px 22px',
              border: '1px solid',
              lineHeight: 1.5,
              background: formSubmitBtn,//"linear-gradient(to right, #fc4a1a, #f7b733)",
              color: "white",
              marginRight: "20px",
              borderRadius: "5px",
            }:
            {
              boxShadow: 'none',
              textTransform: 'none',
              fontSize: 16,
              padding: '8px 45px',
              border: '1px solid',
              lineHeight: 1.5,
              backgroundColor: handleSubmit ? formCancelBtn : '#ea586b',
              borderColor: '#ea5c6b',
              color: handleSubmit ? "#44556c" : 'white',
              marginRight: "20px",
              borderRadius: "5px",
            }}
              onClick={handleClose}>
              {this.props.cancelButtonTitle ? this.props.cancelButtonTitle: 'Cancel'}
            </Button>
            {handleSubmit && !this.props.isError &&
              <Button 
                disabled={submitLoading? true:false}
                type="submit"
                onClick={handleSubmit}
                style={{
                  borderRadius: "5px",
                  boxShadow: 'none',
                  textTransform: 'none',
                  fontSize: 16,
                  padding: '8px 45px',
                  border: '1px solid',
                  lineHeight: 1.5,
                  background: formSubmitBtn,
                  color: "white",
                }}>
                {this.props.buttonTitle}
              </Button>
            }
            {
                errormsg && <p style={{color: "red"}}>{errormsg}</p>
            }
          </DialogActions>
          
        </Dialog>

      </>
      //Merge Engine End DefaultContainer
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 16,
    marginLeft: "auto",
    marginRight: "auto",
    width: Platform.OS === "web" ? "75%" : "100%",
    maxWidth: 650,
    backgroundColor: "#ffffffff"
  }
});

// Customizable Area End

export default withStyles(useStyles)(CustomBulkUpdateModal);
