import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
// Customizable Area Start
import { deleteIcon } from "./assets";
import { runEngine } from "../../../framework/src/RunEngine";
import { dateFormateToDDMMYY } from "../../../framework/src/Utilities";

export const configJSON = require("./config.js");

export interface Props {
    open: boolean;
    handleClose: (type: string, action: boolean) => void;
    classes: any;
    handleSubmit: (formData: object) => void;
    menuList: [];
    roleMenuListerrorMessage: string;
    roleDetailserrorMessage: string;
    roleCreateerrorMessage: string;
    roleDetails: roleData;
    DateFormateConverter: any;
    roleCreateNameerrorMessage: string;
}

interface roleAttribute {
    id: number;
    name: string;
    status: string;
    created_by: string;
    custom_id: string;
    created: Date;
    role_premissions:rolePremission;
  }
  
  interface roleData {
    id: string;
    attributes: roleAttribute
  }

  interface roleMenuSubmenu {
    id: number;
    title: string;
    permission_category_id: number,
    created_at: Date;
  }
  interface rolePremission {
    data: rolePremissionAttribute[],
  }

  interface rolePremissionAttribute {
    attributes: rolePremissionData;
  }

  interface rolePremissionData {
    id: number,
    actions: Array<string>,
    menu:roleMenuSubmenu,
  }
interface prmField {
    viewStatus: boolean, 
    editStatus: boolean, 
    createStatus: boolean, 
    deleteStatus: boolean,
    fullAccessStatus: boolean,
    approveStatus: boolean,
}
interface roleMenuAttributes {
    id: number;
    title: string;
    menus: roleMenuSubmenu[];
  }
  
  interface roleMenuData {
    attributes: roleMenuAttributes;
  }

  interface InitialField {
    name: string,
    status: string,
    created_date: string,
}

interface S {
    closeCopyModal: boolean;
    data: any;
    noteHeader: string;
    disabledbutton: boolean;
    loadingSpinner: boolean;
    deleteIcon: any;
    //permissionField: prmField[];
    rolePermissions: rolePerm;
    roleFormstate: InitialField;
    roleNameError: string;
    statusError: string;
    permissionError: string;

}
interface SS {
    id: any;
}
interface rolePerm {
    [key: number]: prmField;
}

const todayDate = new Date().toISOString().slice(0, 10);
const roleInitialField: InitialField = { name: '', status: '', created_date: dateFormateToDDMMYY(new Date(todayDate)) };

const updateRoleMappingStatus = (roleDetails: roleData | null) => {
  let permissions: rolePerm = {};
  if (roleDetails && roleDetails.attributes && roleDetails.attributes.role_premissions && roleDetails.attributes.role_premissions.data && roleDetails.attributes?.role_premissions?.data.length) {
    roleDetails?.attributes?.role_premissions?.data.map((item: rolePremissionAttribute) => {
      if (item.attributes.menu) {
        permissions[item.attributes.menu.id] = { createStatus: false, editStatus: false, viewStatus: false, approveStatus: false, fullAccessStatus: false, deleteStatus: false };
        if (item.attributes.actions.includes('Create')) {
          permissions[item.attributes.menu.id].createStatus = true;
        }
        if (item.attributes.actions.includes('Edit')) {
          permissions[item.attributes.menu.id].editStatus = true;
        }
        if (item.attributes.actions.includes('View')) {
          permissions[item.attributes.menu.id].viewStatus = true;
        }
        if (item.attributes.actions.includes('Approve')) {
          permissions[item.attributes.menu.id].approveStatus = true;
        }
        if (item.attributes.actions.includes('FullAccess')) {
          permissions[item.attributes.menu.id].fullAccessStatus = true;
        }
        if (item.attributes.actions.includes('Delete')) {
          permissions[item.attributes.menu.id].deleteStatus = true;
        }
      }
    });
    return permissions;
  }
  return null;
}
// Customizable Area End
export default class CopyRolesController extends BlockComponent<Props, S, SS> {
   
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [];

        this.state = {
            closeCopyModal: false,
            data: [],
            noteHeader: '',
            disabledbutton: true,
            loadingSpinner: false,
            deleteIcon: deleteIcon,
            //permissionField: [{...permissionInitialField}],
            rolePermissions: {},
            roleFormstate: roleInitialField,
            roleNameError: '',
            statusError: '',
            permissionError: '',
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }
    // Customizable Area Start
    async componentDidMount() {
        super.componentDidMount();
    }
    
    modalhandleClose = () => {
        this.setState({ closeCopyModal: true })
        this.props.handleClose("copy", false);
    }

    componentDidUpdate(prevProps: Props) {

      if (prevProps.roleDetails !== this.props.roleDetails) {
        this.setState({ rolePermissions: {} });
        const { roleDetails } = this.props;
        if( roleDetails ){
          this.setState({ roleFormstate: { 
            name: roleDetails?.attributes?.name, 
            status: roleDetails?.attributes?.status, 
            created_date: dateFormateToDDMMYY(new Date(todayDate)) 
          } });
        }
        const detailsResult = updateRoleMappingStatus(this.props.roleDetails);
        if (detailsResult) {
          this.setState({ rolePermissions: detailsResult });
        }
      }
    }

    handleChange = (e: any) => {
      let value = e.target.value;
      if(e.target.name === "status"){
        value = (e.target.value === "null") ? "" : e.target.value;
      }
      this.setState({ roleFormstate: { ...this.state.roleFormstate, [e.target.name]: value.trimStart() } });
      //this.handleValidation({...this.state.roleFormstate, [e.target.name]: value});
      };

    handlePermissionChange = (e: any, id: number) => {
    
        let prevState:rolePerm = this.state.rolePermissions;
        if(e.target.name === "fullAccessStatus"){
            prevState[id] = {fullAccessStatus: e.target.checked, createStatus: e.target.checked, editStatus: e.target.checked, viewStatus: e.target.checked, deleteStatus: e.target.checked,  approveStatus: e.target.checked};
        }else if(e.target.name === "viewStatus" && !e.target.checked){
            prevState[id] = {fullAccessStatus: e.target.checked, createStatus: e.target.checked, editStatus: e.target.checked, viewStatus: e.target.checked, deleteStatus: e.target.checked, approveStatus: e.target.checked};
        }else if(e.target.name !== "viewStatus" && e.target.checked){
            prevState[id] = {...prevState[id], [e.target.name]: e.target.checked, ['viewStatus']: e.target.checked};
        }else if(this.state.rolePermissions[id] && this.state.rolePermissions[id].fullAccessStatus && !e.target.checked){
          prevState[id] = {...prevState[id], [e.target.name]: e.target.checked, ['fullAccessStatus']: e.target.checked};
        }else{
            prevState[id] = {...prevState[id], [e.target.name]: e.target.checked};
        }
        this.setState({ rolePermissions: prevState}); 
       // this.handleValidation(this.state.roleFormstate);
      };

    handleValidation: any = (formState: InitialField) => {
        let isValid = true;
        // Role name validation

        const regex = /^[^-\s][a-zA-Z0-9_\s-]{2,40}[^\s]$/i;
        if (formState.name === "" ){
          this.setState({
              roleNameError: ('Role name is required'
              )
          });
          isValid = false;
        }
        else if(regex.test(formState.name) === false) {
            this.setState({
                roleNameError: ('Role name is invalid'
                )
            });
            isValid = false;
        } else {
            this.setState({ roleNameError: '' });
        }

        // status validation
        if (formState.status === "") {
            this.setState({
                statusError: (
                    'Status is required'
                )
            });
            isValid = false;
        } else {
            this.setState({ statusError: '' });
        }

        // permission validation
        let checkPermisson = false;
        for (const iterator in this.state.rolePermissions) {
            const {fullAccessStatus, viewStatus, deleteStatus, editStatus, createStatus, approveStatus} = this.state.rolePermissions[iterator];
            if((fullAccessStatus || viewStatus || deleteStatus || editStatus || createStatus || approveStatus)){
                checkPermisson = true;
                break;        
            }
        }
        if (!checkPermisson) {
            this.setState({permissionError: 'Atleast one permission is required'});
            isValid = false;
        } else {
            this.setState({ permissionError: '' });
        }
        return isValid;
    };

    handleSubmit = (e: any) => {

        e.preventDefault();

        if (this.handleValidation(this.state.roleFormstate)) {
          this.props.handleSubmit({ roleData: this.state.roleFormstate, permissionData: this.state.rolePermissions });
        }
    };
// Customizable Area End
}