import React from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { downloadCSV } from "../../../framework/src/Utilities";
import { toast } from "react-toastify";

import { FormMode, IFineForm } from "./FineFormController";
export const configJSON = require("./config");
// Customizable Area End

// Customizable Area Start
enum Method {
  GET = "GET",
  POST = "POST",
  PUT = "PUT",
  DELETE = "DELETE",
  PATCH = "PATCH"
}

interface IPageMeta {
  message: string;
  total_pages: number;
}

interface IFilterForm {
  name: string;
  start_range: number;
  end_range: number;
  tax_type: string;
}
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  anchorEl: HTMLElement | null;
  pageMeta: IPageMeta;
  selectAllCheckboxChecked: false | "indeterminate" | true;
  isLoading: boolean;
  deleteModalOpen: boolean;
  filterForm: IFilterForm;
  fineForm: IFineForm;
  formMode: FormMode;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CreateFineController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start

  public RequestMessage = {
    GetFine: this.buildRequestMessage(Method.GET),
    CreateFine: this.buildRequestMessage(Method.POST),
    EditFine: this.buildRequestMessage(Method.PUT),
    ExportTaxRates: this.buildRequestMessage(Method.GET),
    Null: undefined as any
  };

  public printReferance = React.createRef();
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      anchorEl: null,
      pageMeta: {
        message: "",
        total_pages: 0
      },
      selectAllCheckboxChecked: false,
      isLoading: true,
      deleteModalOpen: false,
      filterForm: {
        name: "",
        start_range: 0,
        end_range: 0,
        tax_type: ""
      },
      fineForm: {
        day: 0,
        duration: "week",
        fine_amount: "0",
        fine_category: "late_fine",
        mode: "amount",
        name: "",
        sub_fines_attributes: []
      },
      formMode: FormMode.View
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const callID = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const response = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      switch (callID) {
        case this.RequestMessage.GetFine.messageId:
          if (response) {
            const fineForm: IFineForm = {
              ...response.data.attributes,
              sub_fines_attributes: response.data.attributes.sub_fines
            };
            this.setState({
              fineForm,
              pageMeta: response.meta || {},
              isLoading: false
            });
          }
          break;

        case this.RequestMessage.CreateFine.messageId:
          if (response !== null) {
            this.props.navigation.navigate("Fine");
          }

          break;

        case this.RequestMessage.EditFine.messageId:
          if (response !== null) {
            this.props.navigation.navigate("Fine");
          }

          break;
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  public async componentDidMount() {
    super.componentDidMount();
    let formMode = FormMode.View;
    if (window.location.pathname === "/CreateFine") formMode = FormMode.Create;
    else if (window.location.pathname === "/ViewFine") formMode = FormMode.View;
    else if (window.location.pathname === "/EditFine") formMode = FormMode.Edit;

    this.setState({ formMode });
    if (formMode !== FormMode.Create) {
      const query = window.location.search.replace("?", "");
      const searchText = "id=";
      if (query.indexOf(searchText) >= 0) {
        const id = query.substring(
          query.indexOf(searchText) + searchText.length
        );

        this.getFineCall(id);
      }
    }
  }

  public onClickAdd() {
    this.state.fineForm.sub_fines_attributes = [
      ...this.state.fineForm.sub_fines_attributes,
      {
        name: "",
        day: 0,
        duration: "week",
        fine_amount: "0",
        fine_category: "late_fine",
        mode: "amount"
      }
    ];

    this.setState({
      fineForm: { ...this.state.fineForm }
    });
  }

  public onClickDelete(index: number) {
    this.state.fineForm.sub_fines_attributes = this.state.fineForm.sub_fines_attributes.filter(
      (el, i) => i !== index
    );
    this.setState({
      fineForm: { ...this.state.fineForm }
    });
  }

  public onCloseDeleteModal() {
    this.setState({
      deleteModalOpen: false
    });
  }

  public onCreateFine(form: IFineForm) {
    this.createFineCall(form);
  }

  public onEditFine(form: IFineForm) {
    this.createFineCall(form);
  }

  public onSubmitEditTaxRateModal(form: IFineForm) {
    this.editFineCall(form);
  }

  public onUploadCSVForImport(file: File) {
    this.importTaxRatesCall(file);
  }

  public onClickExportCSVButton() {
    this.exportTaxRatesCall();
  }

  //#region Filter Methods
  public onChangeFilterFineName(event: any) {
    this.setState({
      filterForm: {
        ...this.state.filterForm,
        name: event.target.value
      }
    });
  }

  public onChangeFineCategory(event: any, index?: number) {
    let fineForm;
    if (index !== undefined)
      fineForm = this.state.fineForm.sub_fines_attributes[index];
    else fineForm = this.state.fineForm;
    if (fineForm) fineForm.fine_category = event.target.value;
    this.setState({ fineForm: { ...this.state.fineForm } });
  }
  public onChangeFineName(event: any, index?: number) {
    let fineForm;
    if (index !== undefined)
      fineForm = this.state.fineForm.sub_fines_attributes[index];
    else fineForm = this.state.fineForm;
    if (fineForm) fineForm.name = event.target.value;
    this.setState({ fineForm: { ...this.state.fineForm } });
  }

  public onChangeFineDay(event: any, index?: number) {
    let fineForm;
    if (index !== undefined)
      fineForm = this.state.fineForm.sub_fines_attributes[index];
    else fineForm = this.state.fineForm;
    if (fineForm) fineForm.day = event.target.value;
    this.setState({ fineForm: { ...this.state.fineForm } });
  }

  public onChangeFineDuration(event: any, index?: number) {
    let fineForm;
    if (index !== undefined)
      fineForm = this.state.fineForm.sub_fines_attributes[index];
    else fineForm = this.state.fineForm;
    if (fineForm) fineForm.duration = event.target.value;
    this.setState({ fineForm: { ...this.state.fineForm } });
  }

  public onChangeFineAmount(event: any, index?: number) {
    let fineForm;
    if (index !== undefined)
      fineForm = this.state.fineForm.sub_fines_attributes[index];
    else fineForm = this.state.fineForm;
    if (fineForm) fineForm.fine_amount = event.target.value;
    this.setState({ fineForm: { ...this.state.fineForm } });
  }

  public onBlurFineAmount(event: any, index?: number) {
    let fineForm;
    if (index !== undefined)
      fineForm = this.state.fineForm.sub_fines_attributes[index];
    else fineForm = this.state.fineForm;
    if (fineForm)
      fineForm.fine_amount = parseFloat(fineForm.fine_amount).toFixed(2) || "0";

    this.setState({ fineForm: { ...this.state.fineForm } });
  }

  public onChangeFineMode(event: any, index?: number) {
    let fineForm;
    if (index !== undefined)
      fineForm = this.state.fineForm.sub_fines_attributes[index];
    else fineForm = this.state.fineForm;
    if (fineForm) fineForm.mode = event.target.value;
    this.setState({ fineForm: { ...this.state.fineForm } });
  }

  public onChangeFilterFineCategory(event: any) {
    this.setState({
      filterForm: {
        ...this.state.filterForm,
        tax_type: event.target.value
      }
    });
  }

  //#region Service Calls
  private getFineCall(id: string) {
    this.setState({ isLoading: true });

    this.RequestMessage.GetFine.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.FinesEndPoint}/${id}`
    );

    runEngine.sendMessage(
      this.RequestMessage.GetFine.id,
      this.RequestMessage.GetFine
    );
  }

  private createFineCall(form: IFineForm) {
    this.RequestMessage.CreateFine.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.FinesEndPoint
    );
    const body = { fine: form };

    this.RequestMessage.CreateFine.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    runEngine.sendMessage(
      this.RequestMessage.CreateFine.id,
      this.RequestMessage.CreateFine
    );
  }

  private editFineCall(form: IFineForm) {
    const { id, ...rest } = form;

    if (id !== undefined) {
      this.RequestMessage.EditFine.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify({ tax: rest })
      );

      this.RequestMessage.EditFine.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.FinesEndPoint}/${id}`
      );

      runEngine.sendMessage(
        this.RequestMessage.EditFine.id,
        this.RequestMessage.EditFine
      );
    }
  }

  private exportTaxRatesCall() {
    const requestOptions = {
      method: "GET",
      headers: JSON.parse(this.getHeaderMessage())
    };

    fetch(
      `${configJSON.APIBaseURL}/${configJSON.TaxesEndPoint}/export`,
      requestOptions
    )
      .then(response => {
        const file_sub_name = new Date().getTime();
        response
          .blob()
          .then(blob => downloadCSV(blob, "role_csv_" + file_sub_name));
      })
      .catch(error => {
        toast.success(error.message);
      });
  }

  private importTaxRatesCall(file: File) {
    const formData = new FormData();
    formData.append("file", file);

    const requestOptions = {
      method: "POST",
      headers: JSON.parse(this.getHeaderMessage()),
      body: formData
    };

    fetch(
      `${configJSON.APIBaseURL}/${configJSON.TaxesEndPoint}/import`,
      requestOptions
    )
      .then(response => response.json())
      .then(response => toast.success(response.message))
      .catch(response => toast.error(response.error));
  }
  //#endregion Service Calls

  private buildRequestMessage(method: Method): Message {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.TaxesEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.APIBaseURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      this.getHeaderMessage()
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method.toString()
    );

    return requestMessage;
  }

  private getHeaderMessage() {
    const header = {
      "Content-Type": configJSON.APIContentType,
      token: localStorage.getItem("token")
    };

    return JSON.stringify(header);
  }

  private getFilterQuery(form: IFilterForm) {
    return Object.entries(form)
      .filter(([key, value]) => value !== "" && value !== null)
      .map(([key, value]) => `${key}=${value}`)
      .join("&");
  }
  // Customizable Area End
}
