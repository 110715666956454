import React from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { IActionMenuItem } from "../../CommonLayout/HRMSCommons/src/actionMenu";
import { downloadCSV } from "../../../framework/src/Utilities";
import { toast } from "react-toastify";
import { debounce } from "lodash";
import moment from "moment";
// Customizable Area End

import { FormMode, Props as IFeeFormProps, IFeeForm, ITax, IAcademicAccount } from "./FeeFormController";
export const configJSON = require("./config");

// Customizable Area Start
enum Method {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
  PATCH = 'PATCH',
}

interface IFee {
  id: number;
  type: string;
  selected: boolean;
  attributes: {
    academic_account: {
      id: number;
      name: string;
    };
    amount: number;
    custom_id: string;
    id: number;
    name: string;
    tax: Array<{
      id: number;
      name: string;
      tax_percentage: number;
    }>;
    valid_until: string;
  };
}

interface IPageMeta {
  message: string;
  total_pages: number;
}

interface IFilterForm {
  name: string;
  valid_until: Date;
  start_range: number;
  end_range: number;
}
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  anchorEl: HTMLElement | null;
  actionedTableRow: IFee | null;
  actionMenuItems: Array<IActionMenuItem>;
  fees: Array<IFee>;
  feeNames: Array<IFee>;
  pageMeta: IPageMeta;
  selectAllCheckboxChecked: false | 'indeterminate' | true;
  isLoading: boolean;
  deleteMessage: "single" | "multiple"
  deleteModalOpen: boolean;
  filterForm: IFilterForm;
  isFeeFormModalOpened: boolean;
  feeForm: IFeeForm | null;
  feeFormMode: FormMode;
  importErrorMessage: string;
  currentPage: number;
  taxes: Array<ITax>;
  academicAccounts: Array<IAcademicAccount>;
  searchText: string,
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class FeeController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  public tableActionMenuItems: Array<IActionMenuItem> = [
    { label: "Edit", action: this.editFee.bind(this) },
    { label: "Delete", action: this.deleteFee.bind(this) },
    { label: "View", action: this.viewFee.bind(this) },
    { label: "Copy", action: this.copyFee.bind(this) }
  ];

  public RequestMessage = {
    GetFee: this.buildRequestMessage(Method.GET),
    GetFeeNames: this.buildRequestMessage(Method.GET),
    SearchFee: this.buildRequestMessage(Method.GET),
    GetTaxes: this.buildRequestMessage(Method.GET),
    GetAcademicAccounts: this.buildRequestMessage(Method.GET),
    FilterFee: this.buildRequestMessage(Method.GET),
    DeleteFee: this.buildRequestMessage(Method.DELETE),
    CreateFee: this.buildRequestMessage(Method.POST),
    EditFee: this.buildRequestMessage(Method.PUT),
    ImportFee: this.buildRequestMessage(Method.POST),
    Null: undefined as any
  }

  public feeFormProps: { [key: number]: IFeeFormProps } = {
    [FormMode.Create]: {
      title: "Create Fee",
      submitLabel: "Create",
      initialValues: null,
      formMode: FormMode.Create,
      isOpen: false,
      onClose: this.onCloseFeeFormModal.bind(this),
      onSubmit: this.onSubmitCreateFeeModal.bind(this),
      requestMessage: this.RequestMessage.CreateFee
    } as any,
    [FormMode.Edit]: {
      title: "Edit Fee",
      submitLabel: "Update",
      initialValues: null,
      formMode: FormMode.Edit,
      isOpen: false,
      onClose: this.onCloseFeeFormModal.bind(this),
      onSubmit: this.onSubmitEditFeeModal.bind(this),
      requestMessage: this.RequestMessage.EditFee
    } as any,
    [FormMode.View]: {
      title: "View Fee",
      submitLabel: "Back to Listing",
      initialValues: null,
      formMode: FormMode.View,
      isOpen: false,
      onClose: this.onCloseFeeFormModal.bind(this),
      onSubmit: this.onCloseFeeFormModal.bind(this),
      requestMessage: this.RequestMessage.Null
    } as any,
    [FormMode.Copy]: {
      title: "Copy Fee",
      submitLabel: "Update",
      initialValues: null,
      formMode: FormMode.Copy,
      isOpen: false,
      onClose: this.onCloseFeeFormModal.bind(this),
      onSubmit: this.onSubmitCreateFeeModal.bind(this),
      requestMessage: this.RequestMessage.Null
    } as any
  }
  tableRef: any = null;
  public printSelectorReferance: any = null;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      deleteMessage: "single",
      anchorEl: null,
      actionedTableRow: null,
      fees: [],
      feeNames: [],
      actionMenuItems: [],
      pageMeta: {
        message: "",
        total_pages: 0
      },
      selectAllCheckboxChecked: false,
      isLoading: true,
      deleteModalOpen: false,
      filterForm: {
        name: '',
        start_range: 0,
        end_range: 0,
        valid_until: null as any
      },
      isFeeFormModalOpened: false,
      feeForm: null,
      feeFormMode: FormMode.Create,
      importErrorMessage: '',
      currentPage: 1,
      taxes: [],
      academicAccounts: [],
      searchText: '',
      // Customizable Area End
    };
    
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.tableRef = React.createRef();
    this.printSelectorReferance = React.createRef();
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const callID = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const response = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage)) || null;

      switch (callID) {
        case this.RequestMessage.GetFee.messageId:
          if (response !== null) {
            this.setState({
              fees: response?.data || [],
              pageMeta: response?.meta || {},
              isLoading: false
            });
          }
          break;

        case this.RequestMessage.FilterFee.messageId:
          if (response !== null) {
            this.setState({
              fees: response?.data || [],
              pageMeta: response?.meta || {},
              isLoading: false
            });
          }
          break;

        case this.RequestMessage.GetFeeNames.messageId:
          if (response !== null) {
            this.setState({
              feeNames: response?.data || [],
            });
          }

          break;

        case this.RequestMessage.DeleteFee.messageId:
          if (response !== null) {
            this.setState({
              deleteModalOpen: false,
              actionedTableRow: null
            });

            toast.success("Fee deleted successfully");
            this.getFeeCall();
          }
          break;

        case this.RequestMessage.CreateFee.messageId:
          if (response !== null && response.errors === undefined) {
            this.setState({ isFeeFormModalOpened: false });

            toast.success("Fee created successfully");
            this.getFeeCall();
          }

          break;

        case this.RequestMessage.EditFee.messageId:
          if (response !== null) {
            this.setState({ isFeeFormModalOpened: false });

            toast.success("Fee updated successfully");
            this.getFeeCall();
          }

          break;

        case this.RequestMessage.ImportFee.messageId:
          if (response !== null) {
            if (!response.errors && !response.error) {
              toast.success("Fees import successfully");
              this.setState({ isFeeFormModalOpened: false });

              toast.success("Fee created successfully");
              this.getFeeCall();
            }
            else {
              this.setState({ importErrorMessage: response.errors ? response.errors : response.error });
            }
          }

          break;

        case this.RequestMessage.GetTaxes.messageId:
          if (response !== null) {
            this.setState({ taxes: response?.data || [] });
          }
          break;

        case this.RequestMessage.GetAcademicAccounts.messageId:
          if (response !== null) {
            this.setState({ academicAccounts: response?.data || [] });
          }
          break;

        case this.RequestMessage.SearchFee.messageId:
          if (response !== null) {
            this.setState({
              fees: response?.data || [],
              pageMeta: response?.meta || {},
              isLoading: false
            });
          }
          break;
      }
    }
    // Customizable Area End 
  }

  // Customizable Area Start
  public async componentDidMount() {
    super.componentDidMount();

    this.getFeeCall();
    this.getFeeNamesCall();

    this.getTaxesCall();
    this.getAcademicAccountsCall();
  }

  public onClickCreateFee() {
    this.setState({
      isFeeFormModalOpened: true,
      feeFormMode: FormMode.Create
    });
  }

  public onSelectAllFee(event: React.ChangeEvent<HTMLInputElement>) {
    const { fees } = this.state;
    const { checked } = event.target;

    const updatedFee = fees.map((fee) => {
      return {
        ...fee,
        selected: checked
      }
    });

    this.setState({
      fees: updatedFee,
      selectAllCheckboxChecked: this.isSelectAllCheckboxChecked(updatedFee)
    });
  }

  public onSelectFee(event: React.ChangeEvent<HTMLInputElement>, row: IFee) {
    const { fees } = this.state;
    const { checked } = event.target;

    const updatedFee = fees.map((fee) => {
      return {
        ...fee,
        selected: row.id === fee.id ? checked : fee.selected
      }
    });

    this.setState({
      fees: updatedFee,
      selectAllCheckboxChecked: this.isSelectAllCheckboxChecked(updatedFee)
    });
  }

  public onClickActionButton(event: any, item: IFee) {
    this.setState({
      anchorEl: event.currentTarget,
      actionedTableRow: item,
      actionMenuItems: this.tableActionMenuItems
    });
  }

  public onCloseActionMenu() {
    this.setState({
      anchorEl: null,
      actionMenuItems: []
    });
  }

  public onClickPagination(page: any) {
    this.tableRef.current.scrollIntoView();
    this.getFeeCall(page.selected + 1);
  }

  public onCloseDeleteModal() {

    const currentFee = this.state.actionedTableRow;
    if (!currentFee) return;

    const { fees } = this.state;
    if (!currentFee.selected) {
      const updatedFines = fees.map(el => {
        return { ...el, selected: el.id === currentFee.id ? true : false };
      });

      this.setState({
        fees: updatedFines,
        selectAllCheckboxChecked: false,
        deleteMessage: "single"
      });
    } else {
      if (fees.filter(el => el.selected).length > 1)
        this.setState({ deleteMessage: "multiple" });
      else this.setState({ deleteMessage: "single" });
    }

    this.setState({
      deleteModalOpen: false,
      actionedTableRow: null
    });
  }

  public onSubmitDeleteModal() {
    this.setState({ selectAllCheckboxChecked: false });
    this.deleteFeeCall();
  }

  public onCloseFeeFormModal() {
    this.setState({ isFeeFormModalOpened: false });
  }

  public onSubmitCreateFeeModal(form: IFeeForm) {
    this.createFeeCall(form);
  }

  public onSubmitEditFeeModal(form: IFeeForm) {
    this.editFeeCall(form);
  }

  public onUploadCSVForImport(file: File) {
    this.importFeeCall(file);
  }

  public onClickExportCSVButton(isSample: boolean) {
    this.exportFeeCall(isSample);
  }

  //#region Filter Methods
  public onChangeFilterSliderRange(event: any, value: Array<number> | number) {
    if (Array.isArray(value)) {
      this.setState({
        filterForm: {
          ...this.state.filterForm,
          start_range: value[0],
          end_range: value[1]
        }
      });
    }
  }

  public onChangeFilterFeeName(event: any) {
    this.setState({
      filterForm: {
        ...this.state.filterForm,
        name: event.target.value
      }
    });
  }

  public onChangeFilterFeeType(date: Date) {
    this.setState({
      filterForm: {
        ...this.state.filterForm,
        valid_until: date
      }
    });
  }

  public onFilterFormSubmit() {
    this.filterFeeCall(this.state.filterForm);
  }

  public onChangeSearchInput({ target: { value } }: any) {
    this.setState({ searchText: value });

    this.debouncedSearch(value);
  }

  public debouncedSearch = debounce((value) => {
    this.setState({ searchText: value });

    if (value !== "") {
      this.setState({ currentPage: 1 }, () => {
        this.getSearchTaxCall(value);
      });
    } else {
      this.getFeeCall();
    }
  }, 500);
  //#endregion Filter Methods

  private editFee() {
    this.onSelectAllFee({ target: { checked: false } } as any);

    this.openFeeModalWithInitialData(this.state.actionedTableRow, FormMode.Edit);
  }

  private viewFee() {
    this.onSelectAllFee({ target: { checked: false } } as any);

    this.openFeeModalWithInitialData(this.state.actionedTableRow, FormMode.View);
  }

  private deleteFee() {
    const { fees } = this.state;
    const selectedFees = fees.filter(el => el.selected);
    const activeRow = this.state.actionedTableRow as IFee;
    const selectOther = !selectedFees.some(el => el.id === activeRow.id);

    if (selectOther) {
      const updatedFees = fees.map(el => {
        return { ...el, selected: el.id === activeRow.id ? true : false };
      });

      this.setState({
        fees: updatedFees,
        selectAllCheckboxChecked: false,
        deleteMessage: "single",
        deleteModalOpen: true
      });
    }
    else {
      this.setState({
        deleteMessage: selectedFees.length > 1 ? "multiple" : "single",
        deleteModalOpen: true
      });
    }
  }

  private copyFee() {
    this.onSelectAllFee({ target: { checked: false } } as any);

    this.openFeeModalWithInitialData(this.state.actionedTableRow, FormMode.Copy);
  }

  private openFeeModalWithInitialData(fee: IFee | null, mode: FormMode) {
    if (fee !== null) {
      const { attributes: {
        name,
        amount,
        id,
        valid_until,
        tax,
        academic_account
      } } = fee as IFee;

      this.setState({
        isFeeFormModalOpened: true,
        feeFormMode: mode,
        feeForm: {
          id,
          name,
          amount,
          valid_until: valid_until as any,
          academic_account_id: academic_account?.id,
          tax_ids: tax.map((tax) => tax.id)
        }
      });
    }
  }

  private selectedFee(fees?: Array<IFee>): Array<IFee> {
    if (fees === undefined) {
      fees = this.state.fees;
    }

    return fees.filter((fee) => fee.selected);
  }


  private isSelectAllCheckboxChecked(fees: Array<IFee>): false | "indeterminate" | true {
    const selectedFee = this.selectedFee(fees);
    const { length } = selectedFee;

    return length !== 0 && (length === fees.length ? true : 'indeterminate');
  }

  //#region Service Calls 
  private getFeeCall(page: number = 1) {
    this.setState({ isLoading: true, currentPage: page });

    this.RequestMessage.GetFee.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.FeesEndPoint}?page=${page}`
    );

    runEngine.sendMessage(this.RequestMessage.GetFee.id, this.RequestMessage.GetFee);
  }

  private getFeeNamesCall() {
    this.RequestMessage.GetFeeNames.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.FeesEndPoint}`
    );

    runEngine.sendMessage(this.RequestMessage.GetFeeNames.id, this.RequestMessage.GetFeeNames);
  }

  private deleteFeeCall() {
    const selectedFees = this.state.fees.filter(el => el.selected);
    if (selectedFees.length === 1) {
      this.RequestMessage.DeleteFee.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.FeesEndPoint}/${selectedFees[0].id}`
      );
    }
    else if (selectedFees.length > 1) {
      const ids = selectedFees.reduce((p, c) => p + (p ? "," : "") + c.id, "");
      this.RequestMessage.DeleteFee.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.FeesEndPoint}/bulk_destroy?ids=${ids}`
      );
    } else return;

    runEngine.sendMessage(this.RequestMessage.DeleteFee.id, this.RequestMessage.DeleteFee);
  }

  private createFeeCall(form: IFeeForm) {
    const { valid_until, ...rest } = form;
    const date = moment(valid_until).format("YYYY-MM-DD");

    this.RequestMessage.CreateFee.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({ fee: { ...rest, valid_until: date } })
    );

    runEngine.sendMessage(this.RequestMessage.CreateFee.id, this.RequestMessage.CreateFee);
  }

  private editFeeCall(form: IFeeForm) {
    const { id, valid_until, ...rest } = form;
    const date = moment(valid_until).format("YYYY-MM-DD");

    if (id !== undefined) {
      this.RequestMessage.EditFee.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify({ fee: { ...rest, valid_until: date } })
      );

      this.RequestMessage.EditFee.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.FeesEndPoint}/${id}`
      );

      runEngine.sendMessage(this.RequestMessage.EditFee.id, this.RequestMessage.EditFee);
    }
  }

  private filterFeeCall(form: IFilterForm) {
    this.RequestMessage.FilterFee.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.FeesEndPoint}/filter?${this.getFilterQuery(form)}`
    );

    runEngine.sendMessage(this.RequestMessage.FilterFee.id, this.RequestMessage.FilterFee);
  }

  private getTaxesCall() {
    this.RequestMessage.GetTaxes.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.TaxesEndPoint}`
    );

    runEngine.sendMessage(this.RequestMessage.GetTaxes.id, this.RequestMessage.GetTaxes);
  }

  private getAcademicAccountsCall() {
    this.RequestMessage.GetAcademicAccounts.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.AcademicAccountEndPoint}`
    );

    runEngine.sendMessage(this.RequestMessage.GetAcademicAccounts.id, this.RequestMessage.GetAcademicAccounts);
  }

  private exportFeeCall(isSample: boolean) {
    let url = `${configJSON.APIBaseURL}/${configJSON.FeesEndPoint}/export`;
    const requestOptions = {
      method: "GET",
      headers: JSON.parse(this.getHeaderMessage()),
    };

    if (isSample) {
      url = `${configJSON.APIBaseURL}/${configJSON.FeesEndPoint}/fee_csv_sample_file`;
    }

    fetch(url, requestOptions)
      .then((response) => {
        const now = new Date().getTime();
        let fileName = `fee_rates_csv_${now}.csv`;

        if (isSample) {
          fileName = `fee_rates_csv_sample_file.csv`;
        }

        response.blob().then(blob => downloadCSV(blob, fileName))
      })
      .catch((error) => {
        toast.success(error.message);
      });
  }

  private importFeeCall(file: File) {
    debugger;
    const bodyFormData = new FormData();
    bodyFormData.append("file", file);

    this.RequestMessage.ImportFee.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.FeesEndPoint}/import`
    );

    this.RequestMessage.ImportFee.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      bodyFormData
    );

    runEngine.sendMessage(this.RequestMessage.ImportFee.id, this.RequestMessage.ImportFee);
  }

  private getSearchTaxCall(searchText: string = "") {
    if (searchText !== "") { searchText = this.state.searchText; }

    this.RequestMessage.SearchFee.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.FeesEndPoint}/search?name=${searchText}&page=${this.state.currentPage}`
    );

    runEngine.sendMessage(this.RequestMessage.SearchFee.id, this.RequestMessage.SearchFee);
  }
  //#endregion Service Calls 

  private buildRequestMessage(method: Method): Message {
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.FeesEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.APIBaseURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      this.getHeaderMessage()
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method.toString()
    );

    return requestMessage;
  }

  private getHeaderMessage() {
    const header = {
      "Content-Type": configJSON.APIContentType,
      token: localStorage.getItem("token"),
    };

    return JSON.stringify(header);
  }

  private getFilterQuery(form: IFilterForm) {
    const clone = { ...form };

    if (clone.start_range === clone.end_range) {
      clone.start_range = null as any;
      clone.end_range = null as any;
    }

    const date = moment(clone.valid_until).format("YYYY-MM-DD");

    if (moment.isDate(date)) {
      clone.valid_until = date;
    }

    return Object.entries(clone)
      .filter(([key, value]) => value !== "" && value !== null && value !== "null")
      .map(([key, value]) => `${key}=${value}`)
      .join("&");
  }
  // Customizable Area End
}
