import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";

// Customizable Area Start
import { deleteBoxIcon } from "./assets";
export interface ICustomer {
  id: string;
  type: string;
  attributes: {
    id: number | string;
    company_name: string;
    customer_name: string;
    phone: string;
  };
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  classes: any;
  open: boolean;
  handleClose: any;
  title: string;
  handleSubmit?: (id: string) => void;
  customers?: ICustomer[];
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  deleteBoxIcon: any;
  selectedKey: string;
  selectedCustomer: string;
  searchValue: string;
  submittedSearchValue: string;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class CustomDeleteModalController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [getName(MessageEnum.AccoutLoginSuccess)];

    this.state = {
      selectedCustomer: "",
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      deleteBoxIcon: deleteBoxIcon,
      selectedKey: "phone",
      searchValue: "",
      submittedSearchValue: ""
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start
  // Customizable Area End
}
