import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { configJSON } from "./UserListController";
// Customizable Area Start
interface InitialField {
    role: string,
    status: string,
}

const { baseURL } = require("../../../framework/src/config");
export interface Props {
    open: boolean;
    handleClose: (type: string, action: boolean) => void;
    classes: any;
    menuList: [];
    handleSubmit: (formData: object) => void;
    handleChange: () => void;
    checkBulkUpdateId:{};
    userbulkUpdateerrorMessage: string;
    roleList: [];
}

interface S {
    closeModal: boolean;
    data: any;
    noteHeader: string;
    disabledbutton: boolean;
    loadingSpinner: boolean;
    statusError: string;
    commonErrorMsg: string;
    status: string;
    roleError: string;
    role: string;
    bulkFormstate: InitialField,
}

interface SS {
    id: any;
}
const HeightPanel=200;

export default class BulkUpdateUsersController extends BlockComponent<Props, S, SS> {

    apiGetRoleList: string = "";
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
    
        // Customizable Area Start
        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage)
            // Customizable Area End
        ];

        this.state = {
            closeModal: false,
            data: [],
            noteHeader: '',
            disabledbutton: true,
            loadingSpinner: false,
            statusError: '',
            status: '',
            roleError: '',
            role: '',
            // userRoleList: [],
            // userRoleListMeta: {total_pages: 0, message: ''},
            bulkFormstate: {role:'', status: ''},
           // rolePage: 0,
            commonErrorMsg: "",
        };

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Recived", message);
        
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
          const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
          const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
          const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
        } 
        
    }

    async componentDidMount() {
        super.componentDidMount();
    }

    componentDidUpdate(prevProps: Props) {
        if (prevProps.open !== this.props.open) {
            this.setState({ status: '', statusError: "",role: '', roleError:'', bulkFormstate: {role:'', status: ''},
        });
        }
    }

    handleChange = (e: any) => {
        let value = e.target.value;
        value = (e.target.value === "null") ? "" : e.target.value;
        this.setState({ bulkFormstate: { ...this.state.bulkFormstate, [e.target.name]: value } });
        this.handleValidation({...this.state.bulkFormstate, [e.target.name]: value});
    };

    handleValidation: any = (formState: InitialField) => {
        let isValid = true;

        // status validation
        
        if (formState.status === "" && formState.role === "") {
            this.setState({
                commonErrorMsg: (
                    'Atleast one field is required'
                )
            });
            isValid = false;
        } else {
            this.setState({ commonErrorMsg: '' });
        } 
        return isValid;
    };

    handleSubmit = (e: any) => {
        e.preventDefault();
        if (this.handleValidation(this.state.bulkFormstate)) {
            this.props.handleSubmit({ userData: this.props.checkBulkUpdateId,status:this.state.bulkFormstate.status,role: this.state.bulkFormstate.role,type: "bulkUpdate" });
        }
    };
}
