import React from "react";
// Customizable Area Start
import { withStyles, Theme } from "@material-ui/core/styles";
import { Styles } from "@material-ui/styles";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import Layout from "../../CommonLayout/Layout/src/Layout.web";
import Pagination from "../../CommonLayout/Pagination/src/Pagination.web";
import CustomDeleteModal from "../../CommonLayout/CustomDeleteModal/src/CustomDeleteModal.web";
import { IconButton } from "../../CommonLayout/HRMSCommons/src/button/iconButton";
import { Icon } from "../../CommonLayout/HRMSCommons/src/assets";
import { dangerIcon } from "./assets";
import { ActionMenu } from "../../CommonLayout/HRMSCommons/src/actionMenu";
import PageHeader from "../../CommonLayout/HRMSCommons/src/layout/PageHeader";
import Loader from "../../../components/src/Loader.web";
import { classNames } from "../../CommonLayout/HRMSCommons/src/classNames";
import OutlinedSelect from "../../../components/src/OutlinedSelect";
import RecurringExpenseForm from "./RecurringExpenseForm.web";
import FormLabel from "@material-ui/core/FormLabel";
import TextField from "@material-ui/core/TextField";
// Customizable Area End

import RecurringExpenseListController, {
  Props
} from "./RecurringExpenseListController";

// Customizable Area Start
const useStyles: Styles<Theme, {}, string> = (theme: Theme) => ({
  container: {
    minHeight: "calc(100vh - 120px)",
    maxHeight: "calc(100vh - 120px)",
    flexDirection: "column",
    flexWrap: "nowrap"
  },
  mt20: {
    marginTop: 20
  },
  actionColumn: {
    maxWidth: "100px",
    width: "100px"
  },
  tableContainer: {
    position: "relative",
    flexGrow: 1,
    boxShadow: "0px 5px 10px 0px #d9d9d9",
    borderRadius: 10
  },
  table: {
    borderCollapse: "separate",
    borderSpacing: "0 10px",
    padding: "10px 25px 0",

    "& td": {
      backgroundColor: "#F9F9F9",
      border: "none"
    }
  },
  actionColumnButton: {
    margin: 0
  },
  checkbox: {
    padding: 0
  },
  tableHead: {
    backgroundColor: "#FCF3D4",
    height: 41
  }
});
// Customizable Area End

class RecurringExpenseList extends RecurringExpenseListController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes, navigation } = this.props;
    const {
      pageMeta,
      isLoading,
      recurringExpenses,
      anchorEl,
      actionMenuItems,
      deleteModalOpen,
      recurringExpenseFormMode,
      selectAllCheckboxChecked,
      currentPage,
      closeImportModal,
      expenseAccounts,
      stopModalOpen,
      searchKeyword
    } = this.state;

    return (
      <Layout
        searchKeyword={searchKeyword}
        handleGlobalSearch={this.handleSearchChange}
        navigation={navigation}
      >
        <Grid container className={classes.container}>
          <PageHeader
            FilterTitle="Filters"
            title="Recurring Expenses"
            createButtonTitle="Create"
            importModalTitle="Import recurring expense by CSV file"
            printReference={this.printReferance}
            filterForm={this.renderFilterForm()}
            onClickCreateButton={this.onClickCreateRecurringExpense.bind(this)}
            onUploadCSVForImport={this.onUploadCSVForImport.bind(this)}
            onClickExportCSVButton={this.onClickExportCSVButton.bind(this)}
            onFilterFormSubmit={this.onFilterFormSubmit.bind(this)}
            onClickDownloadCSVTemplate={this.onClickExportCSVButton.bind(this)}
            closeImportModal={closeImportModal}
          />
          <TableContainer
            style={{ overflow: isLoading ? "hidden" : "" }}
            component={Paper}
            className={classNames(classes.mt20, classes.tableContainer)}
            ref={(el: any) => (this.printReferance = el)}
          >
            {isLoading && <Loader loading positionType={"center"} />}

            <Table
              ref={this.tableRef}
              stickyHeader
              className={classes.table}
              aria-label="recurring expenses"
            >
              <TableHead>
                <TableRow>
                  <TableCell
                    align="center"
                    className={classNames(
                      classes.actionColumn,
                      classes.tableHead
                    )}
                  >
                    <Checkbox
                      className={classes.checkbox}
                      inputProps={{
                        "aria-label": "select all recurring expenses"
                      }}
                      onChange={this.onSelectAllRecurringExpenses.bind(this)}
                      indeterminate={
                        selectAllCheckboxChecked === "indeterminate"
                      }
                      checked={!!selectAllCheckboxChecked}
                      key={`recurring-expense-super-checkbox-${selectAllCheckboxChecked}`}
                    />
                  </TableCell>
                  <TableCell className={classes.tableHead}>
                    Expense Name
                  </TableCell>
                  <TableCell className={classes.tableHead}>
                    Expense Account
                  </TableCell>
                  <TableCell className={classes.tableHead}>
                    Vendor Name
                  </TableCell>
                  <TableCell className={classes.tableHead}>Frequency</TableCell>
                  <TableCell className={classes.tableHead}>
                    Last Expense Date
                  </TableCell>
                  <TableCell className={classes.tableHead}>
                    Next Expense Date
                  </TableCell>
                  <TableCell className={classes.tableHead}>Status</TableCell>
                  <TableCell className={classes.tableHead}>Amount</TableCell>
                  <TableCell className={classes.tableHead} align="center" />
                </TableRow>
              </TableHead>
              <TableBody>
                {recurringExpenses.map(row => {
                  const {
                    id,
                    selected,
                    attributes: {
                      expense_name,
                      expense_id,
                      vendor_id,
                      repeat_every,
                      status,
                      ammount,
                      last_expense_date,
                      next_expense_date
                    }
                  } = row;
                  return (
                    <TableRow key={`recurring-expense-${id}`}>
                      <TableCell
                        align="center"
                        className={classes.actionColumn}
                      >
                        <Checkbox
                          className={classes.checkbox}
                          value={selected}
                          checked={selected}
                          inputProps={{
                            "aria-label": "select recurring expense"
                          }}
                          key={`recurring-expense-${id}-checkbox-${selected}`}
                          onChange={event =>
                            this.onSelectRecurringExpense(event, row)
                          }
                        />
                      </TableCell>
                      <TableCell
                        style={{
                          textTransform: "capitalize",
                          color: "#eb5257",
                        }}
                      >
                        {expense_name}
                      </TableCell>
                      <TableCell style={{ textTransform: "capitalize" }}>
                        {expenseAccounts[expense_id]}
                      </TableCell>
                      <TableCell style={{ textTransform: "capitalize" }}>
                        {vendor_id}
                      </TableCell>
                      <TableCell style={{ textTransform: "capitalize" }}>
                        {repeat_every}
                      </TableCell>
                      <TableCell style={{ textTransform: "capitalize" }}>
                        {last_expense_date}
                      </TableCell>
                      <TableCell style={{ textTransform: "capitalize" }}>
                        {next_expense_date}
                      </TableCell>
                      <TableCell style={{ textTransform: "capitalize" }}>
                        {status ? "Active" : "Inactive"}
                      </TableCell>
                      <TableCell style={{ textTransform: "capitalize" }}>
                        {ammount}
                      </TableCell>
                      <TableCell
                        align="center"
                        className={classes.actionColumn}
                      >
                        <IconButton
                          icon={Icon.Action}
                          borderless
                          className={classes.actionColumnButton}
                          onClick={event =>
                            this.onClickActionButton(event, row)
                          }
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
                {!recurringExpenses.length && !isLoading && (
                  <TableRow>
                    <TableCell
                      colSpan={12}
                      style={{
                        color: "red",
                        fontWeight: 600,
                        fontSize: "18px",
                        textAlign: "center"
                      }}
                    >
                      {"No Record Found"}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Grid container justify="flex-end" alignItems="center">
            <Pagination
              page={currentPage - 1}
              totalPageCount={pageMeta}
              handlePageClick={this.onClickPagination.bind(this)}
            />
          </Grid>
        </Grid>

        <ActionMenu
          anchorEl={anchorEl}
          onClose={this.onCloseActionMenu.bind(this)}
          items={actionMenuItems}
          arrowSide="right"
          anchorOrigin={actionMenuItems.length > 1 ? { vertical: -12, horizontal: -48 } : { vertical: -12, horizontal: -35 }}
        />

        <CustomDeleteModal
          DialogueDeletetitle={"Delete Expense"}
          open={deleteModalOpen}
          handleClose={this.onCloseDeleteModal.bind(this)}
          handleSubmit={this.onSubmitDeleteModal.bind(this)}
          msgHandle={this.state.deleteMessage}
        />

        <CustomDeleteModal
          DialogueDeletetitle={"Stop Expense"}
          open={stopModalOpen}
          handleClose={this.onCloseStopModal.bind(this)}
          handleSubmit={this.onSubmitStopModal.bind(this)}
          submitActionText={"Proceed"}
          dialogText={"Proceed to stop the Expense?"}
          modalIcon={dangerIcon}
        />

        <RecurringExpenseForm
          {...this.recurringExpenseFormProps[recurringExpenseFormMode]}
          isOpen={this.state.isRecurringExpenseFormModalOpened}
          initialValues={this.state.recurringExpenseForm}
        />
      </Layout>
    );
    // Customizable Area End
  }

  // Customizable Area Start
  private renderFilterForm() {
    const { classes } = this.props;
    const {
      filterForm: { expense_type, vendor_id, status }
    } = this.state;

    return (
      <Grid container direction="column">
        <OutlinedSelect
          val={expense_type}
          options={[
            { id: "Job Costing", name: "Job Costing" },
            { id: "Labour", name: "Labour" },
            { id: "Materials", name: "Materials" }
          ]}
          label="Expense Account"
          name={"expense_type"}
          formControlStyle={{
            margin: 0
          }}
          handleChange={(e: any) =>
            this.onFilterElementChange(e, "expense_type")
          }
        />

        <div
          style={{ margin: "20px 0", display: "flex", flexDirection: "column" }}
        >
          <FormLabel style={{ marginBottom: "8px" }}>Vendor Name</FormLabel>
          <TextField
            value={vendor_id}
            color={"primary"}
            variant="outlined"
            type={"text"}
            name={"vendor_id"}
            placeholder={"Enter Value"}
            onChange={e => this.onFilterElementChange(e, "vendor_id")}
          />
        </div>

        <OutlinedSelect
          val={status}
          options={[
            { id: "true", name: "Active" },
            { id: "false", name: "Inactive" }
          ]}
          label="Status"
          name={"status"}
          formControlStyle={{
            margin: 0
          }}
          handleChange={(e: any) => this.onFilterElementChange(e, "status")}
        />
      </Grid>
    );
  }
  // Customizable Area End
}

export default withStyles(useStyles)(RecurringExpenseList);
