Object.defineProperty(exports, "__esModule", {
  value: true
});

const urlConfig = require("../../../framework/src/config");

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Account";
exports.labelBodyText = "Account Body";
exports.btnExampleTitle = "CLICK ME";

exports.GET = "GET";
exports.POST = "POST";
exports.PUT = "PUT";
exports.DELETE = "DELETE";
exports.PATCH = "PATCH";
exports.accountManagementURL = urlConfig.baseURL;
exports.getAccountEndPoint = "bx_block_academic_account/academic_account";
exports.getAccountBaseEndPoint = "bx_block_academic_account";

// Customizable Area End