import React, { FunctionComponent, ReactNode } from "react";

import { IBlock } from "../../../../../framework/src/IBlock";
import { Message } from "../../../../../framework/src/Message";
import { BlockComponent } from "../../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../../framework/src/RunEngine";

// Customizable Area Start
import { IActionMenuItem } from "../actionMenu";
// Customizable Area End

// Customizable Area Start
// Customizable Area End

export interface Props {
  // Customizable Area Start
  classes?: any;
  title: string;
  haveCreateButton?: boolean;
  createButtonTitle?: string;
  haveActionBar?: boolean;
  haveFilterButton?: boolean;
  havePrintButton?: boolean;
  haveCSVButton?: boolean;
  haveImportCSVButton?: boolean;
  haveExportCSVButton?: boolean;
  importModalTitle?: string;
  importErrorMessage?: string;
  filterForm?: ReactNode | null;
  printReference?: any;
  haveEditButton?: boolean;
  FilterTitle?: string;
  haveAddButton?: boolean;
  closeImportModal?: boolean;
  onClickCreateButton?: () => void;
  onClickAddButton?: () => void;
  onClickEditButton?: () => void;
  onFilterFormSubmit?: () => void;
  onClickPrint?: () => void;
  onAfterPrint?: () => void;
  onFilterFormCancel?: () => void;
  onUploadCSVForImport?: (file: File) => void;
  onClickDownloadCSVTemplate?: () => void;
  onCloseImportModal?: () => void;
  onClickExportCSVButton?: () => void;
  // Customikszable Area End
}

interface S {
  // Customizable Area Start
  CSVAnchorEl: HTMLElement | null;
  filterPopoverAnchorEl: HTMLElement | null;
  importCSVModal: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class TaxRatesController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  public defaults: Props = {
    title: "",
    haveActionBar: true,
    haveCreateButton: true,
    createButtonTitle: "Create",
    haveFilterButton: true,
    havePrintButton: true,
    haveCSVButton: true,
    haveImportCSVButton: true,
    haveExportCSVButton: true,
    importModalTitle: "Import CSV",
    importErrorMessage: "",
    haveEditButton: false,
    filterForm: null,
    printReference: null,
    haveAddButton: false,
    closeImportModal: false,
    onClickCreateButton: () => {},
    onClickEditButton: () => {},
    onFilterFormSubmit: () => {},
    onClickPrint: () => {},
    onAfterPrint: () => {},
    onFilterFormCancel: () => {},
    onUploadCSVForImport: () => {},
    onClickDownloadCSVTemplate: () => {},
    onCloseImportModal: () => {},
    onClickExportCSVButton: () => {},
    onClickAddButton: () => {}
  };

  public CSVMenuItems: Array<IActionMenuItem> = [];
  // Customizable Area End

  constructor(props: Props) {
    super(props);

    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      CSVAnchorEl: null,
      filterPopoverAnchorEl: null,
      importCSVModal: false
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    const {
      haveImportCSVButton,
      haveExportCSVButton,
      onClickExportCSVButton
    } = { ...this.defaults, ...props };

    if (haveImportCSVButton) {
      this.CSVMenuItems.push({
        label: "Import CSV",
        action: this.onClickImportCSVButton.bind(this)
      });
    }
    if (haveExportCSVButton && typeof onClickExportCSVButton === "function") {
      this.CSVMenuItems.push({
        label: "Export CSV",
        action: onClickExportCSVButton
      });
    }
    // Customizable Area End
  }

  public componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
    const { closeImportModal } = this.props;
    if (closeImportModal !== prevProps.closeImportModal && closeImportModal) {
      this.onCloseUploadModal();
    }
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  public onClickCSVButton(event: React.MouseEvent<HTMLButtonElement>) {
    this.setState({ CSVAnchorEl: event.currentTarget });
  }

  public onCloseCSVMenu() {
    this.setState({ CSVAnchorEl: null });
  }

  public onClickFilterButton(event: React.MouseEvent<HTMLButtonElement>) {
    this.setState({ filterPopoverAnchorEl: event.currentTarget });
  }

  public onClosePopover() {
    this.setState({ filterPopoverAnchorEl: null });
  }

  public onClickApplyButton() {
    // this.onClosePopover();
    const { onFilterFormSubmit } = this.props;

    if (typeof onFilterFormSubmit === "function") {
      onFilterFormSubmit();
      this.onClosePopover();
    }
  }

  public onClickCancelButton() {
    this.onClosePopover();
    const { onFilterFormCancel } = this.props;

    if (typeof onFilterFormCancel === "function") {
      onFilterFormCancel();
    }
  }

  public onUploadCSVFile(file: File) {
    const { onUploadCSVForImport } = this.props;

    if (file && typeof onUploadCSVForImport === "function") {
      onUploadCSVForImport(file);
    }
  }

  public onClickImportCSVButton() {
    this.setState({
      importCSVModal: true
    });
  }

  public onCloseUploadModal() {
    this.setState({ importCSVModal: false });

    const { onCloseImportModal } = this.props;

    if (typeof onCloseImportModal === "function") {
      onCloseImportModal();
    }
  }

  public downloadCSVTemplate() {
    const { onClickDownloadCSVTemplate } = this.props;

    if (typeof onClickDownloadCSVTemplate === "function") {
      onClickDownloadCSVTemplate();
    }
  }
  // Customizable Area End
}
