import React, { FunctionComponent, ReactNode } from "react";
// Customizable Area Start
import { Button as MUIButton, ButtonProps, Theme } from "@material-ui/core";
import withStyles, { Styles } from "@material-ui/core/styles/withStyles";
// Customizable Area End

// Customizable Area Start
export enum ButtonType {
  Primary = "primary",
  Secondary = "secondary",
  Cancel = "cancel",
  Bulk = "bulk",
}

export enum ButtonTheme {
  PrimaryBackground = "#F15B5B",
  PrimaryText = '#FFFFFF',

  SecondaryBackground = "#FILL_ME_IN",
  SecondaryText = 'FILL_ME_IN',

  CancelBackground = "#FFFFFF",
  CancelBorderColor = "#F15B5B",
  CancelText = '#3C5271',

  BulkBackground = "#FFFFFF",
  BulkText = 'darkblue',
  BulkActiveText = '#FFFFFF',
  BulkBorderColor = "darkblue",
}
// Customizable Area End

interface Props extends ButtonProps {
  // Customizable Area Start
  children?: ReactNode;
  title: any;
  onClick?: () => void;
  buttonType?: ButtonType;
  classes?: any;
  className?: string;

  startIcon?: ReactNode | null;
  // Customizable Area End
}

const ButtonWithOutStyles: FunctionComponent<Props> = (props) => {
  // Customizable Area Start

  const defaults: Props = {
    children: null,
    title: "",
    onClick: () => { }
  };

  props = { ...defaults, ...props };
  const { classes, buttonType, className, startIcon, title, onClick, children, ...otherProps } = props;

  return (
    <MUIButton
      {...otherProps}
      className={[classes.button, classes[buttonType || ButtonType.Primary], className].join(" ")}
      startIcon={startIcon}
      onClick={onClick}>
      {
        children ? children : title
      }
    </MUIButton>
  );
  // Customizable Area End
};

const styles: Styles<Theme, {}, string> = {
  // Customizable Area Start
  button: {
    textTransform: 'none',
    fontSize: 16,
    padding: '8px 45px',
    border: '1px solid',
    lineHeight: 1.5,
    outline: 'none',
    '&:hover': {
      boxShadow: 'none'
    }
  },
  primary: {
    backgroundColor: ButtonTheme.PrimaryBackground,
    borderColor: ButtonTheme.PrimaryBackground,
    color: ButtonTheme.PrimaryText,
    '&:hover, &:focus': {
      backgroundColor: ButtonTheme.PrimaryBackground,
      borderColor: ButtonTheme.PrimaryBackground,
      color: ButtonTheme.PrimaryText,
    }
  },
  secondary: {
    backgroundColor: ButtonTheme.SecondaryBackground,
    color: ButtonTheme.SecondaryText,
    '&:hover, &:focus': {
      backgroundColor: ButtonTheme.SecondaryBackground,
      borderColor: ButtonTheme.CancelBackground,
      color: ButtonTheme.SecondaryText,
    }
  },
  cancel: {
    backgroundColor: ButtonTheme.CancelBackground,
    borderColor: ButtonTheme.CancelBorderColor,
    color: ButtonTheme.CancelText,
    '&:hover, &:focus': {
      backgroundColor: ButtonTheme.CancelBackground,
      borderColor: ButtonTheme.CancelBorderColor,
      color: ButtonTheme.CancelText,
    }
  },
  bulk: {
    backgroundColor: ButtonTheme.BulkBackground,
    padding: '10px 15px',
    color: ButtonTheme.BulkText,
    borderColor: ButtonTheme.BulkBorderColor,
    borderRadius: '5px',
    fontSize: 13,
    transition: '.3s all ease',
    '&:hover, &:active': {
      backgroundColor: ButtonTheme.PrimaryBackground,
      borderColor: ButtonTheme.PrimaryBackground,
      boxShadow: 'none',
      color: ButtonTheme.BulkActiveText,
    },
    
  }
  // Customizable Area End
};

export const Button = withStyles(styles)(ButtonWithOutStyles);
