import React from "react";
// Customizable Area Start
import { withStyles, Theme } from '@material-ui/core/styles';
import { Styles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import Layout from '../../CommonLayout/Layout/src/Layout.web';
import Pagination from "../../CommonLayout/Pagination/src/Pagination.web"
import CustomDeleteModal from '../../CommonLayout/CustomDeleteModal/src/CustomDeleteModal.web';
import { IconButton } from "../../CommonLayout/HRMSCommons/src/button/iconButton";
import { Icon } from "../../CommonLayout/HRMSCommons/src/assets";
import { ActionMenu } from "../../CommonLayout/HRMSCommons/src/actionMenu";
import PageHeader from "../../CommonLayout/HRMSCommons/src/layout/PageHeader";
import Loader from "../../../components/src/Loader.web";
import { classNames } from "../../CommonLayout/HRMSCommons/src/classNames";
import { FormControl, FormLabel, TextField } from "@material-ui/core";
import OutlinedSelect from "../../../components/src/OutlinedSelect";
import FeeForm from "./FeeForm.web";
import CommonSlider from "../../CommonLayout/HRMSCommons/src/CommonSlider";
import { calenderIcon } from "./assets";
import DatePicker from "react-date-picker";
// Customizable Area End

import FeeController, { Props } from "./FeeListController";
import { BulkButton } from "../../CommonLayout/HRMSCommons/src/button/bulk";

// Customizable Area Start
const useStyles: Styles<Theme, {}, string> = (theme: Theme) => ({
  container: {
    minHeight: "calc(100vh - 120px)",
    maxHeight: "calc(100vh - 120px)",
    flexDirection: "column",
    flexWrap: "nowrap",
  },
  mt20: {
    marginTop: 20,
  },
  actionColumn: {
    maxWidth: "100px",
    width: "100px",
  },
  tableContainer: {
    position: "relative",
    flexGrow: 1,
    boxShadow: "0px 5px 10px 0px #d9d9d9",
    borderRadius: 10
  },
  table: {
    borderCollapse: "separate",
    borderSpacing: "0 10px",
    padding: "10px 25px 0",

    "& td": {
      backgroundColor: "#F9F9F9",
      border: "none"
    }
  },
  actionColumnButton: {
    margin: 0
  },
  checkbox: {
    padding: 0,
  },
  tableHead: {
    backgroundColor: "#FCF3D4",
    height: 41,
  }
});
// Customizable Area End

class Fee extends FeeController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes, navigation } = this.props;
    const {
      pageMeta: { total_pages },
      isLoading,
      fees,
      anchorEl,
      actionMenuItems,
      deleteModalOpen,
      feeFormMode,
      selectAllCheckboxChecked,
      importErrorMessage,
      currentPage,
      isFeeFormModalOpened,
      feeForm,
      taxes,
      academicAccounts,
      searchText
    } = this.state;

    return (
      <Layout navigation={navigation} searchKeyword={searchText} handleGlobalSearch={this.onChangeSearchInput.bind(this)}>
        <Grid container className={classes.container}>
          <PageHeader
            title="Fee"
            createButtonTitle="Create"
            importModalTitle="Import fee by CSV file"
            printReference={this.printSelectorReferance}
            filterForm={this.renderFilterForm()}
            FilterTitle={'Select Fee'}
            onClickCreateButton={this.onClickCreateFee.bind(this)}
            onUploadCSVForImport={this.onUploadCSVForImport.bind(this)}
            onClickExportCSVButton={this.onClickExportCSVButton.bind(this, false)}
            onFilterFormSubmit={this.onFilterFormSubmit.bind(this)}
            onClickDownloadCSVTemplate={this.onClickExportCSVButton.bind(this, true)}
            importErrorMessage={importErrorMessage}
          />

          <TableContainer style={{ overflow: isLoading ? 'hidden' : '' }} ref={(el: any) => this.printSelectorReferance = el} component={Paper} className={classNames(classes.mt20, classes.tableContainer)}>
            {isLoading && <Loader loading positionType={'center'} />}

            <Table ref={this.tableRef} stickyHeader className={classes.table} aria-label="fees">
              <TableHead>
                <TableRow>
                  <TableCell align="center" className={classNames(classes.actionColumn, classes.tableHead)}>
                    <Checkbox
                      className={classes.checkbox}
                      inputProps={{ 'aria-label': 'select all fees' }}
                      onChange={this.onSelectAllFee.bind(this)}
                      indeterminate={selectAllCheckboxChecked === 'indeterminate'}
                      checked={!!selectAllCheckboxChecked}
                      key={`user-super-checkbox-${selectAllCheckboxChecked}`}
                    />
                  </TableCell>
                  <TableCell className={classes.tableHead}>ID</TableCell>
                  <TableCell className={classes.tableHead}>Fee Name</TableCell>
                  <TableCell className={classes.tableHead}>Valid Until</TableCell>
                  <TableCell className={classes.tableHead}>Tax %</TableCell>
                  <TableCell className={classes.tableHead}>Amount</TableCell>
                  <TableCell className={classes.tableHead}>Account</TableCell>
                  <TableCell className={classes.tableHead} align="center"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {fees.map((row) => {
                  const { id, selected, attributes: { custom_id, name, valid_until, tax, amount, academic_account } } = row;
                  const date = valid_until.split('T')[0].split('-').reverse().join('-');

                  return (
                    <TableRow key={`user-${id}`}>
                      <TableCell align="center" className={classes.actionColumn}>
                        <Checkbox
                          className={classes.checkbox}
                          value={selected}
                          checked={selected}
                          inputProps={{ 'aria-label': 'select user' }}
                          key={`user-${id}-checkbox-${selected}`}
                          onChange={event => this.onSelectFee(event, row)}
                        />
                      </TableCell>
                      <TableCell>{custom_id}</TableCell>
                      <TableCell style={{ textTransform: 'capitalize' }}>{name}</TableCell>
                      <TableCell>{date}</TableCell>
                      <TableCell>{
                        tax && tax.map(({ name, tax_percentage }) => `${name} ${tax_percentage}%`).join(', ')
                      }</TableCell>
                      <TableCell>{amount}</TableCell>
                      <TableCell>{academic_account?.name}</TableCell>
                      <TableCell align="center" className={classes.actionColumn}>
                        <IconButton
                          icon={Icon.Action}
                          borderless
                          className={classes.actionColumnButton}
                          onClick={event => this.onClickActionButton(event, row)}
                        />
                      </TableCell>
                    </TableRow>
                  )
                })}
                {
                  fees.length === 0 && !isLoading &&
                  <TableRow>
                    <TableCell colSpan={12} style={{
                      color: "red",
                      fontWeight: 600,
                      fontSize: "18px",
                      textAlign: 'center'
                    }}>
                      {"No Record Found"}
                    </TableCell>
                  </TableRow>
                }
              </TableBody>
            </Table>
          </TableContainer>

          <Grid container justify="flex-end" alignItems="center">
            <Pagination page={currentPage - 1} totalPageCount={total_pages} handlePageClick={this.onClickPagination.bind(this)} />
          </Grid>
        </Grid>

        <ActionMenu
          anchorEl={anchorEl}
          onClose={this.onCloseActionMenu.bind(this)}
          items={actionMenuItems}
          arrowSide="right"
          anchorOrigin={{ vertical: -12, horizontal: -48 }}
        />

        <CustomDeleteModal DialogueDeletetitle={'Delete Fee'}
          open={deleteModalOpen}
          handleClose={this.onCloseDeleteModal.bind(this)}
          handleSubmit={this.onSubmitDeleteModal.bind(this)}
          msgHandle={this.state.deleteMessage}
        />

        <FeeForm
          {...this.feeFormProps[feeFormMode]}
          isOpen={isFeeFormModalOpened}
          initialValues={feeForm}
          taxes={taxes}
          academicAccounts={academicAccounts}
          requestMessage={this.RequestMessage.CreateFee}
        />
      </Layout>
    );
    // Customizable Area End
  }

  // Customizable Area Start
  private renderFilterForm() {
    const { classes } = this.props;
    const { filterForm: { name, valid_until, start_range, end_range }, feeNames } = this.state;

    return (
      <Grid container direction="column">
        <Grid item style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <OutlinedSelect
            val={name}
            options={feeNames.map(({ id, attributes: { name } }) => ({ id: name, name }))}
            label="Fee Name"
            name={'fee_name'}
            formControlStyle={{
              width: '150px',
              margin: 0
            }}
            handleChange={this.onChangeFilterFeeName.bind(this)} className={undefined} />

          <FormControl>
            <FormLabel style={{ marginBottom: "8px" }}>
              Valid Until
            </FormLabel>

            <DatePicker
              className={''}
              dayPlaceholder="DD"
              monthPlaceholder="MM"
              yearPlaceholder="YYYY"
              onChange={this.onChangeFilterFeeType.bind(this)}
              calendarClassName="Calender"
              name={"valid_until"}
              value={valid_until}
              minDate={new Date()}
              calendarIcon={<img style={{ height: "27px" }} src={calenderIcon} />}
            />
          </FormControl>
        </Grid>

        <Grid item>
          <FormControl style={{ width: "100%", marginTop: 20 }}>
            <FormLabel style={{ marginBottom: "8px" }}>
              Amount range
            </FormLabel>
            <CommonSlider
              onChange={this.onChangeFilterSliderRange.bind(this)}
              start_range={start_range}
              end_range={end_range}
              min={0}
              maxDafultValue={1000}
              max={5000}
              minDafultValue={0}
            />
          </FormControl>
        </Grid>
      </Grid>
    );
  }
  // Customizable Area End
}

export default withStyles(useStyles)(Fee);
