import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { toast } from "react-toastify";
import React from "react";
import { IActionMenuItem } from "../../CommonLayout/HRMSCommons/src/actionMenu";
import { downloadCSV } from "../../../framework/src/Utilities";
import { FormMode, IUserForm, Props as IUserFormProps } from "./UserFormController";
import { debounce } from 'lodash';
// Customizable Area End

export const configJSON = require("./config");

// Customizable Area Start
enum Method {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
  PATCH = 'PATCH',
}
export interface IUserFormErrors {
  first_name: any;
  email: any;
  activated: any;
  role: any;
  full_phone_number: any;
}
interface roleAttribute {
    id: number;
    name: string;
}
interface roleData {
    id: string;
    attributes: roleAttribute
}

interface IUser {
  id: number;
  type: string;
  selected: boolean;
  attributes: {
    activated: any;
    country_code: string;
    full_phone_number: string;
    created_at: string;
    updated_at: string;
    custom_id: string;
    role: string;
    first_name:string
    email: string;
    phone_number: string;
    last_name:string;
    role_id:string;
    // not known
    unique_auth_id: null,
    device_id: null,
    type: null,
    current_password:string,
    new_password:string,
  };
}

interface IPageMeta {
  message: string;
  total_pages: number;
}

interface IFilterForm {
  role_id: any;
  status: string;
}
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  anchorEl: HTMLElement | null;
  actionedTableRow: IUser | null;
  actionMenuItems: Array<IActionMenuItem>;
  users: Array<IUser>;
  pageMeta: IPageMeta;
  selectAllCheckboxChecked: false | 'indeterminate' | true;
  isLoading: boolean;
  deleteModalOpen: boolean;
  bulkUpdateUserModalOpen: boolean;
  filterForm: IFilterForm;
  isUserFormModalOpened: boolean;
  userForm: IUserForm | null;
  userbulkUpdateerrorMessage: string,
  userFormMode: FormMode;
  formErrorMessage: any;
  editUserstatusError:any,
  editUserNameError :any,
  editUseremailError :any,
  editUserroleError :any,
  editUsermobileError :any,
  editUserPasswordError :any,
  editUserCurrentPasswordError :any,
  searchKeyword: string;
  currentPage:number;
  importUserModalOpen: boolean;
  //userImporterrorMessage: string;
  userRoleList: [],
  userListerrorMessage: string;
  importErrorMessage: string;
  filterStatusError:string;
  filterRoleError:string;
  closeImportModal: boolean,
  deleteMessage: "single" | "multiple";
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class UsersController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  public tableActionMenuItems: Array<IActionMenuItem> = [
    { label: "Edit", action: this.editUser.bind(this) },
    { label: "Delete", action: this.deleteUser.bind(this) },
    { label: "View", action: this.viewUser.bind(this) },
  ];

  public RequestMessage = {
    GetUsers: this.buildRequestMessage(Method.GET),
    DeleteUser: this.buildRequestMessage(Method.DELETE),
    CreateUser: this.buildRequestMessage(Method.POST),
    FilterUsers: this.buildRequestMessage(Method.GET),
    EditUser: this.buildRequestMessage(Method.PUT),
    CopyUser: this.buildRequestMessage(Method.POST),
    SearchUsers: this.buildRequestMessage(Method.GET),
    GetRolesList: this.buildRequestMessage(Method.GET),
    Null: undefined as any
  }

  public userFormProps: { [key: number]: IUserFormProps } = {
    [FormMode.Create]: {
      title: "Create User",
      submitLabel: "Create",
      editUserstatusError:"",
      editUserNameError :"",
      editUseremailError :"",
      editUserroleError :"",
      editUsermobileError :"",
      editUserPasswordError :"",
      editUserCurrentPasswordError :"",
      initialValues: null,
      formMode: FormMode.Create,
      isOpen: false,
      onClose: this.onCloseUserFormModal.bind(this),
      handleSubmit: this.onSubmitCreateUserForm.bind(this),
      requestMessage: this.RequestMessage.CreateUser, // must change
      roleList: []
    },
    [FormMode.Edit]: {
      title: "Edit User",
      editUserstatusError:"",
      editUserNameError :"",
      editUseremailError :"",
      editUserroleError :"",
      editUsermobileError :"",
      editUserPasswordError :"",
      editUserCurrentPasswordError :"",
      submitLabel: "Update",
      initialValues: null,
      formMode: FormMode.Edit,
      isOpen: false,
      onClose: this.onCloseUserFormModal.bind(this),
      handleSubmit: this.onSubmitEditUserForm.bind(this),
      requestMessage: this.RequestMessage.EditUser, // must change
      roleList: []
    },
    [FormMode.View]: {
      title: "User Details",
      submitLabel: "Back to Listing",
      editUserstatusError:"",
      editUserNameError :"",
      editUseremailError :"",
      editUserroleError :"",
      editUsermobileError :"",
      editUserPasswordError :"",
      editUserCurrentPasswordError :"",
      initialValues: null,
      formMode: FormMode.View,
      isOpen: false,
      onClose: this.onCloseUserFormModal.bind(this),
      handleSubmit: this.onCloseUserFormModal.bind(this),
      requestMessage: this.RequestMessage.Null,
      roleList: []
    },
    [FormMode.Copy]: {
      title: "Copy User",
      submitLabel: "Create",
      editUserstatusError:"",
      editUserNameError :"",
      editUseremailError :"",
      editUserroleError :"",
      editUsermobileError :"",
      editUserPasswordError :"",
      editUserCurrentPasswordError :"",
      initialValues: null,
      formMode: FormMode.Copy,
      isOpen: false,
      onClose: this.onCloseUserFormModal.bind(this),
      handleSubmit: this.onSubmitCopyUserForm.bind(this),
      requestMessage: this.RequestMessage.CopyUser,
      roleList: [] 
    },
  }
  // Customizable Area End
  selectorRef: any = null;
  apiBulkUpdateUser: string = "";
  apiUpdateUser: string = "";
  apiImportUser: string = "";
  tableRef: any = null;
  
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      anchorEl: null,
      actionedTableRow: null,
      users: [],
      actionMenuItems: [],
      pageMeta: {
        message: "",
        total_pages: 0
      },
      filterRoleError:'',
      filterStatusError:'',
      userListerrorMessage: '',
      selectAllCheckboxChecked: false,
      isLoading: true,
      deleteModalOpen: false,
      bulkUpdateUserModalOpen: false,
      filterForm: {
        role_id: '',
        status: '',
      },
      isUserFormModalOpened: false,
      userForm: null,
      userbulkUpdateerrorMessage: '',
      userFormMode: FormMode.Create,
      formErrorMessage: "",
      editUserstatusError:"",
      editUserNameError :"",
      editUseremailError :"",
      editUserroleError :"",
      editUsermobileError :"",
      editUserPasswordError :"",
      editUserCurrentPasswordError :"",
      searchKeyword: "",
      currentPage: 0,
      importUserModalOpen: false,
     // userImporterrorMessage: "",
      userRoleList: [],
      importErrorMessage: "",
      closeImportModal: false,
      deleteMessage: 'single',
      // Customizable Area End
    };

    this.tableRef = React.createRef();
    this.selectorRef = React.createRef();
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const callID = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const response = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

      if (callID === this.apiBulkUpdateUser) {
        if (response != null) {
            if(!response.errors && !response.error){
              toast.success("Users updated successfully");
              this.setState({ bulkUpdateUserModalOpen: false,users: [], selectAllCheckboxChecked: false });
              this.getUsersCall();
            } else {
              if(response?.errors && response?.errors[0]?.token == "Token has Expired, Please login again."){
                localStorage.removeItem("token");
                this.props.navigation.navigate("EmailAccountLoginBlock");
              }
              this.setState({ userbulkUpdateerrorMessage: response.errors ? response.errors : response.error});
            }
        }
        this.setState({ isLoading: false })
      }
      // if (callID === this.apiImportUser) {
      //   if (response != null) {
      //       if(!response.errors && !response.error){
      //         toast.success("Users import successfully");
      //         this.setState({ importUserModalOpen: false,users: [], selectAllCheckboxChecked: false });
      //         this.getUsersCall();
      //       } else {
      //         if(response?.errors && response?.errors[0]?.token == "Token has Expired, Please login again."){
      //           localStorage.removeItem("token");
      //           this.props.navigation.navigate("EmailAccountLoginBlock");
      //         }
      //         this.setState({ importErrorMessage: response.errors ? response.errors : response.error});
      //       }
      //   }
      //   this.setState({ isLoading: false })
      // }
      
      switch (callID) {
        case this.RequestMessage.GetUsers.messageId:
          if (response !== null) {
            if(!response.errors && !response.error){
            this.setState({
              users: response?.data || [],
              pageMeta: response?.meta || {},
              searchKeyword: ""
            });
            } else {
              if(response?.errors && response?.errors[0]?.token == "Token has Expired, Please login again."){
                localStorage.removeItem("token");
                this.props.navigation.navigate("EmailAccountLoginBlock");
              }
              this.setState({ userListerrorMessage: response.error});
            }
            setTimeout(() => this.setState({ isLoading: false }),500);
          }
         

          break;
          case this.RequestMessage.FilterUsers.messageId:
            if (response !== null) {
              this.setState({
                users: response?.data || [],
                pageMeta: response?.meta || {},
                isLoading: false
              });
            }
            break;

        case this.RequestMessage.DeleteUser.messageId:
          if (response !== null) {
            if(!response.errors && !response.error){
              this.setState({
                deleteModalOpen: false,
                actionedTableRow: null
              });
              this.getUsersCall();
              } else {
                if(response?.errors && response?.errors[0]?.token == "Token has Expired, Please login again."){
                  localStorage.removeItem("token");
                  this.props.navigation.navigate("EmailAccountLoginBlock");
                }
              }
          }
          break;

          case this.RequestMessage.EditUser.messageId:
            if (response !== null) {
              if(!response.errors && !response.error){
                toast.success("User updated successfully");
                this.setState({ isUserFormModalOpened: false })
                this.setState({editUserstatusError:"",
                  editUserNameError :"",
                  editUseremailError :"",
                  editUserroleError :"",
                  editUsermobileError :"",
                  editUserPasswordError:"",
                  editUserCurrentPasswordError:''
                })
                this.getUsersCall();
              }else {
                if(response?.errors && response?.errors[0]?.token == "Token has Expired, Please login again."){
                  localStorage.removeItem("token");
                  this.props.navigation.navigate("EmailAccountLoginBlock");
                }else if(response.errors.full_phone_number){
                  this.setState({ editUsermobileError:response.errors.full_phone_number[0],
                  editUserstatusError:"",
                  editUserNameError :"",
                  editUserroleError :"",
                  editUserPasswordError:"",
                  editUserCurrentPasswordError:''
                });
                  this.setState({ editUseremailError:response.errors.email?response.errors.email[0]:'' });
                }else if(response.errors.email){
                  this.setState({ 
                  editUseremailError:response.errors.email[0],
                  editUserstatusError:"",
                  editUserNameError :"",
                  editUserroleError :"",
                  editUsermobileError :"", 
                  editUserPasswordError:"",
                  editUserCurrentPasswordError:''
                });

                }else if(response.errors.role_id){
                  this.setState({ editUserroleError:  response.errors.role_id[0],
                    editUserstatusError:"",
                    editUserNameError :"",
                    editUseremailError :"",
                    editUsermobileError :"",
                    editUserPasswordError:"",
                  editUserCurrentPasswordError:''
                  });
                }else if(response.errors.status){
                  this.setState({ editUserstatusError:  response.errors.status[0],
                    editUserNameError :"",
                    editUseremailError :"",
                    editUserroleError :"",
                    editUsermobileError :"",
                    editUserPasswordError:"",
                  editUserCurrentPasswordError:''
                  });
                }else if(response.errors.first_name){
                  this.setState({ editUserNameError:  response.errors.first_name[0],
                    editUserstatusError:"",
                    editUseremailError :"",
                    editUserroleError :"",
                    editUsermobileError :"",
                    editUserPasswordError:"",
                    editUserCurrentPasswordError:''
                  });
                }else if(response.errors.current_password){
                  this.setState({ editUserCurrentPasswordError:  response.errors.current_password[0],
                    editUserstatusError:"",
                    editUserNameError :"",
                    editUseremailError :"",
                    editUserroleError :"",
                    editUsermobileError :"",
                    editUserPasswordError:"" });
                }else if(response.errors.password){
                  this.setState({ editUserPasswordError:  response.errors.password[0],
                    editUserstatusError:"",
                    editUserNameError :"",
                    editUseremailError :"",
                    editUserroleError :"",
                    editUsermobileError :"",
                    editUserCurrentPasswordError:''
                  });
                }else{
                  this.setState({editUserstatusError:"",
                  editUserNameError :"",
                  editUseremailError :"",
                  editUserroleError :"",
                  editUsermobileError :"",
                  editUserPasswordError:"",
                  editUserCurrentPasswordError:''
                })
                }

                
               console.log("-------------------------",response.errors[0])
              }
            }
          break;
          case this.RequestMessage.SearchUsers.messageId:
            if (response != null) {
              if(!response.errors && !response.error){
                this.setState({
                  users: response?.data || [],
                  pageMeta: response?.meta || {},
                });
                } else {
                  if(response?.errors && response?.errors[0]?.token == "Token has Expired, Please login again."){
                    localStorage.removeItem("token");
                    this.props.navigation.navigate("EmailAccountLoginBlock");
                  }
                  this.setState({ userListerrorMessage: response.error});
                }
            }
            
            setTimeout(() => this.setState({ isLoading: false }),300);
            break;

          case this.RequestMessage.CreateUser.messageId:
            if (response !== null) {
              if(!response.errors){
                toast.success("User created successfully");
                this.setState({ isUserFormModalOpened: false ,})
                this.setState({editUserstatusError:"",
                  editUserNameError :"",
                  editUseremailError :"",
                  editUserroleError :"",
                  editUsermobileError :"",
                  editUserPasswordError:"",
                  editUserCurrentPasswordError:''
                })
                this.getUsersCall();
              }else {
                if(response.errors[0]?.token == "Token has Expired, Please login again."){
                  localStorage.removeItem("token");
                  this.props.navigation.navigate("EmailAccountLoginBlock");
                }else if(response.errors.full_phone_number){
                  this.setState({ editUsermobileError:response.errors.full_phone_number[0],
                  editUserstatusError:"",
                  editUserNameError :"",
                  editUserroleError :"", });
                  this.setState({ editUseremailError:response.errors.email?response.errors.email[0]:'' });
                }else if(response.errors.email){
                  this.setState({ 
                  editUseremailError:response.errors.email[0],
                  editUserstatusError:"",
                  editUserNameError :"",
                  editUserroleError :"",
                  editUsermobileError :"", 
                  
                });

                }else if(response.errors.role_id){
                  this.setState({ editUserroleError:  response.errors.role_id[0],
                    editUserstatusError:"",
                    editUserNameError :"",
                    editUseremailError :"",
                    editUsermobileError :"",
                  })
                }else if(response.errors.status){
                  this.setState({ editUserstatusError:  response.errors.status[0],
                    editUserNameError :"",
                    editUseremailError :"",
                    editUserroleError :"",
                    editUsermobileError :"",
                  });
                }else if(response.errors.first_name){
                  this.setState({ editUserNameError:  response.errors.first_name[0],
                    editUserstatusError:"",
                    editUseremailError :"",
                    editUserroleError :"",
                    editUsermobileError :"",
                  });
                }else{
                  this.setState({editUserstatusError:"",
                  editUserNameError :"",
                  editUseremailError :"",
                  editUserroleError :"",
                  editUsermobileError :"",})
                }
                // this.setState({
                //   formErrorMessage: response.errors.message[0] ||response.errors? response.errors[0].message: response.errors.message[0]
                // });
                // this.setState({ formErrorMessage: response.errors ? response.errors : response.error[0]});
              }
            }
            break;

            case this.RequestMessage.CopyUser.messageId:
            if (response !== null) {
              if(!response.errors){
                toast.success("User created successfully");
                this.setState({ isUserFormModalOpened: false ,})
                this.setState({editUserstatusError:"",
                  editUserNameError :"",
                  editUseremailError :"",
                  editUserroleError :"",
                  editUsermobileError :"",
                  editUserPasswordError:"",
                  editUserCurrentPasswordError:''
                })
                this.getUsersCall();
              }else {
                if(response.errors[0]?.token == "Token has Expired, Please login again."){
                  localStorage.removeItem("token");
                  this.props.navigation.navigate("EmailAccountLoginBlock");
                }else if(response.errors.full_phone_number){
                  this.setState({ editUsermobileError:response.errors.full_phone_number[0],
                  editUserstatusError:"",
                  editUserNameError :"",
                  editUserroleError :"", });
                  this.setState({ editUseremailError:response.errors.email?response.errors.email[0]:'' });
                }else if(response.errors.email){
                  this.setState({ 
                  editUseremailError:response.errors.email[0],
                  editUserstatusError:"",
                  editUserNameError :"",
                  editUserroleError :"",
                  editUsermobileError :"", 
                  
                });

                }else if(response.errors.role_id){
                  this.setState({ editUserroleError:  response.errors.role_id[0],
                    editUserstatusError:"",
                    editUserNameError :"",
                    editUseremailError :"",
                    editUsermobileError :"",
                  })
                }else if(response.errors.status){
                  this.setState({ editUserstatusError:  response.errors.status[0],
                    editUserNameError :"",
                    editUseremailError :"",
                    editUserroleError :"",
                    editUsermobileError :"",
                  });
                }else if(response.errors.first_name){
                  this.setState({ editUserNameError:  response.errors.first_name[0],
                    editUserstatusError:"",
                    editUseremailError :"",
                    editUserroleError :"",
                    editUsermobileError :"",
                  });
                }else{
                  this.setState({editUserstatusError:"",
                  editUserNameError :"",
                  editUseremailError :"",
                  editUserroleError :"",
                  editUsermobileError :"",})
                }
                this.setState({
                  formErrorMessage: response.errors[0] ? response.errors[0].message: response.errors[0].message 
                });
                console.log("-------------------------",response.errors[0].message)
                // this.setState({ formErrorMessage: response.errors ? response.errors : response.error[0]});
              }
            }
            break;
            case this.RequestMessage.GetRolesList.messageId:
              if (response !== null) {
                if(!response.errors && !response.error){
                  const roleData = response?.data && response?.data.map((item: roleData,index:number) => {
                      return item.attributes;
                  })
                this.setState({
                  userRoleList: roleData,
                 // isLoading: false
                });
                } else {
                  if(response?.errors && response?.errors[0]?.token == "Token has Expired, Please login again."){
                    localStorage.removeItem("token");
                    this.props.navigation.navigate("EmailAccountLoginBlock");
                  }
                }
              }
              break;
      }
    }
    // Customizable Area End 
  }
   // Customizable Area Start

  handleSearchChange = (evt: any) => {
    const { value } = evt.target;
    this.setState({searchKeyword: value});
    const debouncedSave = debounce((value) => {
      
      if(value !== ""){
        this.setState({currentPage: 0});
        this.getSearchUsersCall(0,value);
      }else{
        this.getUsersCall();
      }
    }, 100);
		debouncedSave(value);
  }
 
  public async componentDidMount() {
    super.componentDidMount();
    this.getRolesList();
    this.getUsersCall();
  }

  public onClickCreateUser() {
    this.setState({
      isUserFormModalOpened: true,
      userFormMode: FormMode.Create
    });
  }

  public onSelectAllUsers(event: React.ChangeEvent<HTMLInputElement>) {
    const { users } = this.state;
    const { checked } = event.target;

    const updatedUsers = users.map((user) => {
      return {
        ...user,
        selected: checked
      }
    });

    this.setState({
      users: updatedUsers,
      selectAllCheckboxChecked: this.isSelectAllCheckboxChecked(updatedUsers)
    });

  }

  public onSelectUser(event: React.ChangeEvent<HTMLInputElement>, row: IUser) {
    const { users } = this.state;
    const { checked } = event.target;

    const updatedUsers = users.map((user) => {
      return {
        ...user,
        selected: row.id === user.id ? checked : user.selected
      }
    });

    this.setState({
      users: updatedUsers,
      selectAllCheckboxChecked: this.isSelectAllCheckboxChecked(updatedUsers)
    });

  }

  public onClickActionButton(event: any, item: IUser) {
    this.setState({
      anchorEl: event.currentTarget,
      actionedTableRow: item,
      actionMenuItems: this.tableActionMenuItems
    });
  }

  public onCloseActionMenu() {
    this.setState({
      anchorEl: null,
      actionMenuItems: []
    });
  }

  public onClickPagination(page: any) {
    this.setState({currentPage: page.selected});
    this.tableRef.current.scrollIntoView()
    if(this.state.searchKeyword !== ""){
    this.getSearchUsersCall(page.selected + 1, this.state.searchKeyword);
    }else{
      this.getUsersCall(page.selected + 1);
    }
  }

  public onCloseDeleteModal() {
    this.setState({
      deleteModalOpen: false,
      actionedTableRow: null
    });
  }

  public onSubmitDeleteModal() {
    this.deleteUserCall(this.state.actionedTableRow);
  }

  public onCloseUserFormModal() {
    this.setState({editUserstatusError:"",
    editUserNameError :"",
    editUseremailError :"",
    editUserroleError :"",
    editUsermobileError :"",
    editUserCurrentPasswordError:'',
    editUserPasswordError:"",
    formErrorMessage:""
  })
    this.setState({ isUserFormModalOpened: false });
  }

  public onSubmitCreateUserForm(form: IUserForm) {
    // Fill in the code
    form.full_phone_number = form.country_code +form.phone_number
    this.createUserCall(form);
    // u can look usage of this-like method on TaxRatesController:474
  }

  public onSubmitEditUserForm(form: IUserForm) {
    // Fill in the code
    form.full_phone_number = form.country_code +form.phone_number
    // console.log('myedit form', form);
    this.editUserCall(form);
    // u can look usage of this-like method on TaxRatesController:483
  }
  public onSubmitCopyUserForm(form: IUserForm) {
    // Fill in the code
    form.full_phone_number = form.country_code +form.phone_number
    this.copyUserCall(form);
    // u can look usage of this-like method on TaxRatesController:483
  }

  public onClickExportCSVButton() {
    this.exportUsersCall();
  }

  //#region Filter Methods
  public onChangeFilterRole(event: any) {
    this.setState({
      filterForm: {
        ...this.state.filterForm,
        role_id: event.target.value
      }
    });
  }

  public onChangeFilterStatus(event: any) {
    this.setState({
      filterForm: {
        ...this.state.filterForm,
        status: event.target.value
      }
    });
  }
  handleValidation = (form: IFilterForm) => {
    let isValid = true;
    if (form.role_id === "" && !form.status) {
        this.setState({ filterRoleError:("Atleast one field is required")
        });
        isValid = false;
    }else {
      this.setState({ filterRoleError: ''});
      }
    
    // if (form.status === "" &&!form.role_id) {
    //   this.setState({ filterStatusError:("Status is required")  });
    //     isValid = false;
    // } else {
    //   this.setState({ filterStatusError: ''});
    //   }

    return isValid;
};

  public onFilterFormSubmit() {
    //  e.preventDefault();
    if(this.handleValidation(this.state.filterForm))
    {
      this.filterUsersCall(this.state.filterForm);
    }
  }
  //#endregion Filter Methods

  private editUser() {
    this.setState({editUserstatusError:"",
    editUserNameError :"",
    editUseremailError :"",
    editUserroleError :"",
    editUsermobileError :"",})
    this.openUserModalWithInitialData(this.state.actionedTableRow, FormMode.Edit);
  }

  private viewUser() {
    this.openUserModalWithInitialData(this.state.actionedTableRow, FormMode.View);
  }

  private deleteUser() {
    const { users } = this.state;
    const selectedUsers = users.filter(el => el.selected);
    const activeRow = this.state.actionedTableRow as IUser;
    const selectOther = !selectedUsers.some(el => el.id === activeRow.id);

    if (selectOther) {
      const updatedUsers = users.map(el => {
        return { ...el, selected: el.id === activeRow.id ? true : false };
      });

      this.setState({
        users: updatedUsers,
        selectAllCheckboxChecked: false,
        deleteMessage: "single",
        deleteModalOpen: true
      });
    }
    else {
      this.setState({
        deleteMessage: selectedUsers.length > 1 ? "multiple" : "single",
        deleteModalOpen: true
      });
    }
  }

  private copyUser() {
    this.setState({editUserstatusError:"",
                  editUserNameError :"",
                  editUseremailError :"",
                  editUserroleError :"",
                  editUsermobileError :"",})
    this.openUserModalWithInitialData(this.state.actionedTableRow, FormMode.Copy);
  }

  private openUserModalWithInitialData(user: IUser | null, mode: FormMode) {
    if (user !== null) {
      // console.log("--------------------------------------------------",user)
      const { attributes: {
        activated,
        email,
        first_name,
        country_code,
        phone_number,
        role_id,
        custom_id,
        role,
        current_password,
        new_password
      } } = user as IUser;

      this.setState({
        isUserFormModalOpened: true,
        userFormMode: mode,
        userForm: {
          id: custom_id,
          activated,
          email,
          first_name,
          country_code,
          phone_number,
          role_id: role_id,
          role: role,
          full_phone_number: country_code+phone_number,
          current_password,
          new_password
        }
      });
    }
  }

  private selectedUsers(users?: Array<IUser>): Array<IUser> {
    if (users === undefined) {
      users = this.state.users;
    }

    return users.filter((user) => user.selected);
  }

  private isSelectAllCheckboxChecked(users: Array<IUser>): false | "indeterminate" | true {
    const selectedUsers = this.selectedUsers(users);
    const { length } = selectedUsers;

    return length !== 0 && (length === users.length ? true : 'indeterminate');
  }

  //#region Service Calls 
  private getUsersCall(page: number = 1) {
    this.setState({ isLoading: true });

    this.RequestMessage.GetUsers.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.UsersEndPoint}?page=${page}`
    );

    runEngine.sendMessage(this.RequestMessage.GetUsers.id, this.RequestMessage.GetUsers);
  }
  private createUserCall(form: IUserForm) {
    const { id, ...rest } = form;
    if (id !== undefined) {
      const datares = {
        first_name: form.first_name,
        email: form.email,
        full_phone_number: form.full_phone_number,
        activated: form.activated,
        role_id: form.role_id,
      };
      this.RequestMessage.CreateUser.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify({ data:{ attributes: datares } })
      );

      this.RequestMessage.CreateUser.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.UsersEndPoint}`
      );

      runEngine.sendMessage(this.RequestMessage.CreateUser.id, this.RequestMessage.CreateUser);
    }
  }
  private getRolesList() {
    this.setState({ isLoading: true });

    this.RequestMessage.GetRolesList.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.UsersEndPoint}/get_roles`
    );

    runEngine.sendMessage(this.RequestMessage.GetRolesList.id, this.RequestMessage.GetRolesList);
  }

  private getSearchUsersCall(page: number = 1, searchKey: string) {
    this.setState({ isLoading: true });

    this.RequestMessage.SearchUsers.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.UsersEndPoint}/search?page=${page? page: 1}&name=${searchKey}`
    );

    runEngine.sendMessage(this.RequestMessage.SearchUsers.id, this.RequestMessage.SearchUsers);
  }
  private copyUserCall(form: IUserForm) {
    const { id, ...rest } = form;
    if (id !== undefined) {
      const datares = {
        first_name: form.first_name,
        email: form.email,
        full_phone_number: form.full_phone_number,
        activated: form.activated,
        role_id: form.role_id,
      };
      this.RequestMessage.CopyUser.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify({ data:{ attributes: datares } })
      );

      this.RequestMessage.CopyUser.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.UsersEndPoint}`
      );

      runEngine.sendMessage(this.RequestMessage.CopyUser.id, this.RequestMessage.CopyUser);
    }
  }
  private editUserCall(form: IUserForm) {
    const { id, ...rest } = form;

    if (id !== undefined) {
      const datares = {
        role_id: form.role_id,
        first_name: form.first_name,
        email: form.email,
        full_phone_number: form.full_phone_number,
        activated: form.activated,
        current_password: form.current_password,
        password: form.new_password
      };
      this.RequestMessage.EditUser.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify({ data:{ attributes: datares } })
      );

      this.RequestMessage.EditUser.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.UsersEndPoint}/${id}`
      );

      runEngine.sendMessage(this.RequestMessage.EditUser.id, this.RequestMessage.EditUser);
    }
  }

  private deleteUserCall(user: IUser | null) {
    if (user !== null) {
      this.RequestMessage.DeleteUser.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.UsersEndPoint}/${user.id}`
      );

      runEngine.sendMessage(this.RequestMessage.DeleteUser.id, this.RequestMessage.DeleteUser);
    }

    const selectedUsers = this.state.users.filter(el => el.selected);
    if (selectedUsers.length === 1) {
      this.RequestMessage.DeleteUser.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.UsersEndPoint}/${selectedUsers[0].id}`
      );
    } else if (selectedUsers.length > 1) {
      const ids = selectedUsers.reduce((p, c) => p + (p ? "," : "") + c.id, "");
      this.RequestMessage.DeleteUser.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.UsersEndPoint}/bulk_destroy?ids=${ids}`
      );
    } else return;
    
      runEngine.sendMessage(this.RequestMessage.DeleteUser.id, this.RequestMessage.DeleteUser);
  }

  private filterUsersCall(form: IFilterForm) {
    this.RequestMessage.FilterUsers.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.UsersEndPoint}/filter?${this.getFilterQuery(form)}`
    );

    runEngine.sendMessage(this.RequestMessage.FilterUsers.id, this.RequestMessage.FilterUsers);
  }

  public exportUsersCall() {
    const requestOptions = {
      method: "GET",
      headers: JSON.parse(this.getHeaderMessage()),
    };

    fetch(`${configJSON.APIBaseURL}/${configJSON.UsersEndPoint}/export`, requestOptions)
      .then((response) => {
        const file_sub_name = new Date().getTime()
        response.blob().then(blob => downloadCSV(blob, "user_csv_" + file_sub_name))
      })
      .catch((error) => {
        toast.success(error.message);
      });
  }
  //#endregion Service Calls 

  private buildRequestMessage(method: Method): Message {
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.UsersEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.userManagementURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      this.getHeaderMessage()
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method.toString()
    );

    return requestMessage;
  }

  private getHeaderMessage() {
    const header = {
      "Content-Type": configJSON.APIContentType,
       token: localStorage.getItem("token"),
    };

    return JSON.stringify(header);
  }

  private getFilterQuery(form: IFilterForm) {
    return Object.entries(form)
      .filter(([key, value]) => value !== "" && value !== null)
      .map(([key, value]) => `${key}=${value}`)
      .join("&");
  }

  handleModalAction = (type: string, action: boolean) => {
        if(type === "bulkUpdate") {
      this.setState({ bulkUpdateUserModalOpen: action });
    }
    return null;
  }

  handleModalFormSubmit = (formData: any) => {

    if(!formData.userData) return null;
    if(formData.type && formData.type == "bulkUpdate"){
      console.log('formstate',formData);
      const ids = formData.userData.filter((item: IUser) => item.selected).map((itm: IUser) => itm.id);
      this.bulkUpdateUser(ids,formData.role,formData.status);
    }else{
    }  
  }

 
  bulkUpdateUser = (userIds: string[],role_id: string,status: string) => {
    this.setState({ isLoading: true });
    let token = localStorage.getItem("token");
    const header = {
      "Content-Type": configJSON.APIContentType,
      token,
    };
    const data = {
      ids: userIds,
      ...(status && {activated: (status === "active" ? true:false)}),
      ...(role_id && {role_id: role_id})
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiBulkUpdateUser = requestMessage.messageId;
    let apiEndPoint = configJSON.UsersEndPoint+ `/bulk_update`
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.userManagementURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PUT
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  onCloseImportModal = () => {
      this.setState({ importErrorMessage: '' });
  }

  handleImportModalFormSubmit = (fileData: any) => {
    this.importUserData(fileData);
  }

  handleDownloadCSVFile = () => {
        const requestOptions = {
          method: "GET",
          headers: JSON.parse(this.getHeaderMessage()),
        };
    
        fetch(`${configJSON.APIBaseURL}/${configJSON.UsersEndPoint}/account_csv_sample_file`, requestOptions)
        .then((response) => {
          const file_sub_name = new Date().getTime()
          response.blob().then(blob => downloadCSV(blob, "user_csv_sample_"+file_sub_name))
        })
        .catch((error) => {
          toast.success(error.message);
          });
  }
  
  importUserData = (file: File) => {

    const formData = new FormData();
    formData.append("file", file);
    let token = localStorage.getItem("token");

    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: localStorage.getItem("token"),
    };

    const headerData =  JSON.stringify(header);
     const requestOptions = {
        method: "POST",
        headers: JSON.parse(headerData),
        body: formData
      };
    fetch(`${configJSON.userManagementURL}/${configJSON.UsersEndPoint}/import`, requestOptions)
    .then((response) => {
      console.log("status",response.status);
      const contentType = response.headers.get("content-type");
      if (contentType && contentType.indexOf("application/json") !== -1) {
        return response.json();
      } else {
        return response.blob();
      }
    })
    .then((response) => {
      if(response instanceof Blob){
        const file_sub_name = new Date().getTime();
        downloadCSV(response, "user_invalid_csv_"+file_sub_name);
      }else{
        if(response.message){
          toast.success(response.message);
          this.setState({ importUserModalOpen: false,users: [], selectAllCheckboxChecked: false, closeImportModal: true }, () => {
            this.setState({ closeImportModal: false });
          });
          this.getUsersCall();
        }else if(response?.errors && response?.errors[0]?.token == "Token has Expired, Please login again."){
            localStorage.removeItem("token");
            this.props.navigation.navigate("EmailAccountLoginBlock");
        }else{
          this.setState({ importErrorMessage: response.common_error});
        }
      }
    })
    .catch((error) => {
      toast.success(error?.message ? error.message : error);
    });
  }

  // Customizable Area End
}