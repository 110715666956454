import React, { Component } from 'react';
// Customizable Area Start
import {
    makeStyles,
    Theme,
    createStyles,
    withStyles,

} from "@material-ui/core/styles";
import { Styles } from "@material-ui/styles";
import {
    FormLabel,
    Grid,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography
} from "@material-ui/core";

import FormControl from '@material-ui/core/FormControl';
import OutlinedSelect from "../../../components/src/OutlinedSelect";
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { deleteIcon,viewIcon,editIcon } from "./assets";
import { IconButton as MUIIConButton } from "@material-ui/core";
import {feeAssignmentData,installmentMultiFieldAttributes} from './FeeAssignmentController';

interface feeStructureInitialField {
    id: number,
    amount: string,
    tax_ids: string[],
}

interface installmentCycleField {
    name: string,
    due_date: Date|null,
    fine_id: number
}

interface installmentAttributes {
    name: string,
    installment_code: string,
    total_amount: number,
    concession: any,
    concession_type: string,
    discounted_amount: number,
    bill_duration_no: number|null,
    bill_duration: string,
    billing_cycle: number|null,
    payment_collection: string,
    invoice_template_id: number,
    email_template_id: number,
    payment_option: string,
    installment_details_attributes: installmentCycleField[],

}

interface feeAssignmentFormstate {
    feeAssignmentType: string;
    academic_year_id: number;
    grade_id: number;
    division_id: number;
    name: string;
    is_class_and_division: boolean,
    is_group: boolean,
    is_class: boolean,
    fee_type: string,
    sub_fee_structures_attributes: feeStructureInitialField[],
    installments: installmentAttributes,
    installmentFormAttributes: installmentAttributes[],
    sum_total_amount:number
}

type MyProps = {
    handleChange: (e: any) => void, 
    feeAssignmentFormstate: feeAssignmentFormstate,
    viewForm: boolean,
    feeAssignmentData:feeAssignmentData
    handleDetailsPlansForm: (data:installmentMultiFieldAttributes,indxid:number) => void,
    handleInstallmentPlan: (index:number) => void,
    handleDeleteModalAction: (index:number) => void,
};

// Customizable Area End
export default class InstallmentPlans extends Component<MyProps> {

    render() {
        // Customizable Area Start
        const { feeAssignmentFormstate, 
            handleChange,
            viewForm,
            feeAssignmentData,
            handleDetailsPlansForm,
            handleInstallmentPlan,
            handleDeleteModalAction } = this.props;
        console.log('installment attr',feeAssignmentFormstate.installmentFormAttributes);
        // Customizable Area End
        return (
            // Customizable Area Start
            viewForm  && viewForm === true ?
            <>
                <Grid
                    container
                    style={{
                        marginTop: "10px",
                        marginBottom: "10px",
                        borderRadius: 10,
                    }}
                >
                    <Grid item xs={12}>
                        <Typography style={{ fontWeight: 600, fontSize:"20px" }} variant="subtitle1" > Plans </Typography>
                    </Grid>
                    {feeAssignmentData.attributes.installments.data.length > 0?
                        feeAssignmentData.attributes.installments.data.map((fieldVal: installmentMultiFieldAttributes, indx: number) => (
                            <Grid key={indx}
                                component={Paper}
                                container
                                spacing={0}
                                style={{
                                    marginTop: "10px",
                                    marginBottom: "10px",
                                    flexGrow: 1,
                                    boxShadow: "0px 5px 10px 0px #d9d9d9",
                                    borderRadius: 10,
                                    height: "120px"
                                }}
                            >
                                <Grid item container style={{padding: "18px",width: "95%" ,justifyContent: "space-between"}}>
                                    <Grid item>
                                        <Typography style={{ fontWeight: 600, color: "#485871",fontSize:"18px",marginBottom: "6px" }}>{fieldVal.attributes.name}</Typography>
                                        <Typography style={{ color: "#9ca3b3",fontSize:"16px",marginBottom: "6px" }}>Installment Code: {fieldVal.attributes.installment_code}</Typography>
                                        <Typography style={{ color: "#9ca3b3",fontSize:"16px",marginBottom: "6px" }}>Expires after: {fieldVal.attributes.billing_cycle +" cycles"}</Typography>

                                        </Grid>
                                    <Grid item>
                                        <Typography style={{color: "#9ca3b3",fontSize:"16px",marginBottom: "6px"}}>Total Amount</Typography>
                                        {/* Rs.{fieldVal.attributes.total_amount} */}
                                        <Typography style={{ fontWeight: 600, color: "#485871",fontSize:"18px",marginBottom: "6px" }}>Rs.{fieldVal.attributes.total_amount} </Typography>

                                    </Grid>
                                </Grid>
                                <Grid item style={{ width: "5%", padding: "0px",height: "120px",borderRadius: "0px 10px 10px 0px", backgroundColor: "#fcf3d4" }}>
                                    <MUIIConButton
                                        onClick={(evt: object) => handleDetailsPlansForm(fieldVal,indx)}
                                        style={{ marginTop: "38px" }}
                                    >
                                        <img height="20px" width="20px" src={viewIcon} />
                                    </MUIIConButton>
                                </Grid>
                            </Grid>
                        ))
                   : <div style={{ color: "red", fontWeight: 600,
                   fontSize: "14px",
                      textAlign: "center",width:"100%" }}>{"No Record Found"}</div>}
                </Grid>
            </>
            :
            <>
                <Grid
                    container
                    style={{
                        marginTop: "10px",
                        marginBottom: "10px",
                        borderRadius: 10,
                    }}
                >
                    <Grid item xs={12}>
                        <Typography style={{ fontWeight: 600, fontSize:"20px" }} variant="subtitle1" > Plans </Typography>
                    </Grid>
                    {
                        feeAssignmentFormstate.installmentFormAttributes.map((fieldData: installmentAttributes, indx: number) => (
                            <Grid key={indx}
                                component={Paper}
                                container
                                spacing={0}
                                style={{
                                    marginTop: "10px",
                                    marginBottom: "10px",
                                    flexGrow: 1,
                                    boxShadow: "0px 5px 10px 0px #d9d9d9",
                                    borderRadius: 10,
                                    height: "120px"
                                }}
                            >
                                <Grid item container style={{padding: "18px",width: "95%" ,justifyContent: "space-between"}}>
                                    <Grid item>
                                        <Typography style={{ fontWeight: 600, color: "#485871",fontSize:"18px",marginBottom: "6px" }}>{fieldData.name}</Typography>
                                        <Typography style={{ color: "#9ca3b3",fontSize:"16px",marginBottom: "6px" }}>Installment Code: {fieldData.installment_code}</Typography>
                                        <Typography style={{ color: "#9ca3b3",fontSize:"16px",marginBottom: "6px" }}>Expires after: {fieldData.billing_cycle +" cycles"}</Typography>

                                        </Grid>
                                    <Grid item>
                                        <Typography style={{color: "#9ca3b3",fontSize:"16px",marginBottom: "6px"}}>Total Amount</Typography>
                                        <Typography style={{ fontWeight: 600, color: "#485871",fontSize:"18px",marginBottom: "6px" }}>Rs.{feeAssignmentFormstate.installments.concession  ? feeAssignmentFormstate.installments.discounted_amount: feeAssignmentFormstate.sum_total_amount}</Typography>

                                    </Grid>
                                </Grid>
                                <Grid item style={{ width: "5%", padding: "0px",height: "120px",borderRadius: "0px 10px 10px 0px", backgroundColor: "#fcf3d4" }}>
                                    <MUIIConButton
                                        onClick={(evt: object) => handleDeleteModalAction(indx)}
                                        style={{ marginTop: "20px"}}
                                        disabled={feeAssignmentFormstate.installmentFormAttributes.length < 2 ?true :false }
                                    >
                                        <img height="20px" width="20px"  src={deleteIcon} />
                                    </MUIIConButton>
                                    <MUIIConButton
                                        onClick={(evt: object) => handleInstallmentPlan(indx)}
                                        style={{ marginTop: "0px"}}
                                    >
                                        <img height="20px" width="20px" src={editIcon} />
                                    </MUIIConButton>
                                    
                                </Grid>
                            </Grid>
                        ))
                    }
                </Grid>
            </>
            // Customizable Area End
        );
    }
}
