import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
// Customizable Area Start
import { deleteIcon } from "./assets";
import { runEngine } from "../../../framework/src/RunEngine";
export const configJSON = require("./config.js");

export interface Props {
    open: boolean;
    handleClose: (type: string, action: boolean) => void;
    classes: any;
    menuList: [];
    roleMenuListerrorMessage: string;
    roleDetailserrorMessage: string;
    roleDetails: roleData;
    DateFormateConverter: any;
}
  
  interface roleAttribute {
    id: number;
    name: string;
    status: string;
    created_by: string;
    custom_id: string;
    created: Date;
    role_premissions:rolePremission;
  }
  
  interface roleData {
    id: string;
    attributes: roleAttribute
  }

  interface roleMenuSubmenu {
    id: number;
    title: string;
    permission_category_id: number,
    created_at: Date;
  }
  interface rolePremission {
    data: rolePremissionAttribute[],
  }

  interface rolePremissionAttribute {
    attributes: rolePremissionData;
  }

  interface rolePremissionData {
    id: number,
    actions: Array<string>,
    menu:roleMenuSubmenu,
  }

interface prmField {
    fullAccessStatus: boolean,
    viewStatus: boolean,
    editStatus: boolean,
    createStatus: boolean,
    deleteStatus: boolean,
    approveStatus: boolean,
}

interface roleMenuSubmenu {
    id: number;
    title: string;
    permission_category_id: number,
    fullAccessStatus: boolean,
    viewStatus: boolean,
    editStatus: boolean,
    createStatus: boolean,
    deleteStatus: boolean,
    approveStatus: boolean
  }
  
  interface roleMenuAttributes {
    id: number;
    title: string;
    menus: roleMenuSubmenu[];
  }
  
  interface roleMenuData {
    attributes: roleMenuAttributes;
  }

interface rolePerm {
    [key: number]: prmField;
}

interface S {
    closeModal: boolean;
    data: any;
    noteHeader: string;
    disabledbutton: boolean;
    loadingSpinner: boolean;
    deleteIcon: any;
    rolePermissions: rolePerm;

}
interface SS {
    id: any;
}

const updateRoleMappingStatus = (roleDetails: roleData | null) => {
  let permissions: rolePerm = {};
  if (roleDetails && roleDetails.attributes && roleDetails.attributes.role_premissions && roleDetails.attributes.role_premissions.data && roleDetails.attributes?.role_premissions?.data.length) {
    roleDetails?.attributes?.role_premissions?.data.map((item: rolePremissionAttribute) => {
      if (item.attributes.menu) {
        permissions[item.attributes.menu.id] = { createStatus: false, editStatus: false, viewStatus: false, approveStatus: false, fullAccessStatus: false, deleteStatus: false };
        if (item.attributes.actions.includes('Create')) {
          permissions[item.attributes.menu.id].createStatus = true;
        }
        if (item.attributes.actions.includes('Edit')) {
          permissions[item.attributes.menu.id].editStatus = true;
        }
        if (item.attributes.actions.includes('View')) {
          permissions[item.attributes.menu.id].viewStatus = true;
        }
        if (item.attributes.actions.includes('Approve')) {
          permissions[item.attributes.menu.id].approveStatus = true;
        }
        if (item.attributes.actions.includes('FullAccess')) {
          permissions[item.attributes.menu.id].fullAccessStatus = true;
        }
        if (item.attributes.actions.includes('Delete')) {
          permissions[item.attributes.menu.id].deleteStatus = true;
        }
      }
    });
    return permissions;
  }
  return null;
} 
// Customizable Area End
export default class ViewRolesController extends BlockComponent<Props, S, SS> {
   
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [];

        this.state = {
            closeModal: false,
            data: [],
            noteHeader: '',
            disabledbutton: true,
            loadingSpinner: false,
            deleteIcon: deleteIcon,
            rolePermissions: {}
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    // Customizable Area Start
    async componentDidMount() {
      super.componentDidMount();
    }
    
    componentDidUpdate(prevProps: Props) {
       
        if(prevProps.roleDetails !== this.props.roleDetails){
          this.setState({ rolePermissions: {} });
          const detailsResult = updateRoleMappingStatus(this.props.roleDetails);
          if(detailsResult){
            this.setState({rolePermissions: detailsResult});
          }
        }
    }

    modalhandleClose = () => {
        this.setState({ closeModal: true })
        this.props.handleClose("view", false);
    }
    // Customizable Area End
}
