import { Button, DialogTitle, FormControl, FormLabel, Grid, Popover, TextField, Tooltip, Typography } from "@material-ui/core";
import OutlinedSelect from "../../../components/src/OutlinedSelect";
import CustomModalWeb from "../../CommonLayout/CustomModal/src/CustomModal.web";
import React, { FunctionComponent, MouseEventHandler, useState } from "react";
import { Theme } from "@material-ui/core";
import withStyles, { Styles } from "@material-ui/core/styles/withStyles";
import { formSubmitBtn } from "../../CommonLayout/Layout/src/color";

interface ITDSModalProps {
  isOpen: boolean;
  onClose?: () => void;
  onSubmit?: (tds: any) => void;
  onCreateSection?: (text: any) => void;
  tds: any;
  tdSection: any;

  classes?: any;
}

export const TDSModalWithoutStyles = (props: ITDSModalProps) => {
  const { isOpen, onClose, onSubmit, onCreateSection, tds, classes, tdSection } = props;
  const [form, setForm] = React.useState({
    section_id: '',
    tax_rate: '',
    tax_name: '',
  });
  const [newSectionPopover, setNewSectionPopover] = useState(false);
  const [newSectionText, setNewSectionText] = useState('');

  return (
    <CustomModalWeb
      title={"Configure TDS"}
      handleSubmit={() => onSubmit && onSubmit(form)}
      buttonTitle={"Save"}
      open={isOpen}
      handleClose={onClose}
      modalClasses={classes.modal}
    >
      <DialogTitle id="alert-dialog-title" style={{ paddingLeft: 0 }}>
        <Typography style={{ fontWeight: 600 }}>
          TDS
        </Typography>
      </DialogTitle>
      <Grid container direction="row" style={{ marginTop: 20 }}>
        <Grid item xs={12} style={{ paddingRight: 12 }}>
          <FormControl style={{ width: '100%' }} className="TDSSectionRef">
            <FormLabel style={{ marginBottom: "8px" }}>
              Section*
            </FormLabel>
            <OutlinedSelect
              val={form.section_id}
              options={tdSection.map((item: any) => ({ id: item.id, name: item.attributes.name }))}
              label=""
              labelStyle={{ display: 'none' }}
              formControlStyle={{ minWidth: 200 }}
              placeholder="Select"
              name={'section'}
              handleChange={(e: any) => {
                setForm({ ...form, section_id: e.target.value });
              }}
              actionButtonLabel={'New Section'}
              hasActionButton
              onActionButtonClick={(e) => {
                setNewSectionText('');
                setNewSectionPopover(true);
              }}
            />
          </FormControl>
        </Grid>
      </Grid>

      <Grid container direction="row" style={{ marginTop: 20 }}>
        <Grid item xs={12} style={{ paddingRight: 12 }}>
          <FormControl style={{ width: '100%' }}>
            <FormLabel style={{ marginBottom: "8px" }}>
              Tax Rate(%)*
            </FormLabel>
            <TextField
              value={form.tax_rate}
              color={'primary'}
              variant="outlined"
              type={'text'}
              name={'pan_number'}
              placeholder={'10%'}
              onChange={(e: any) => {
                setForm({ ...form, tax_rate: e.target.value });
              }}
            />
          </FormControl>
        </Grid>
      </Grid>

      <Grid container direction="row" style={{ marginTop: 20 }}>
        <Grid item xs={12} style={{ paddingRight: 12 }}>
          <FormControl style={{ width: '100%' }}>
            <FormLabel style={{ marginBottom: "8px" }}>
              Tax Name*
            </FormLabel>
            <TextField
              value={form.tax_name}
              color={'primary'}
              variant="outlined"
              type={'text'}
              name={'pan_number'}
              placeholder={'10%'}
              onChange={(e: any) => {
                setForm({ ...form, tax_name: e.target.value });
              }}
            />
          </FormControl>
        </Grid>
      </Grid>

      <Popover
        open={newSectionPopover}
        anchorEl={document.querySelector('.TDSSectionRef')}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        PaperProps={{
          style: {
            width: 290,
            padding: 20,
            borderRadius: 5,
            boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.1)',
          },
        }}
      >
        <Grid item xs={12}>
          <FormControl style={{ width: "100%", marginBottom: 20 }}>
            <TextField
              value={newSectionText}
              color={"primary"} 
              variant="outlined"
              type={"text"}
              name={"section-name"}
              placeholder={"Section Name"}
              onChange={e => {
                setNewSectionText(e.target.value);
              }}
            />
          </FormControl>
        </Grid>
        <Button
          className={classes.paymentActionBtn}
          style={{
            borderRadius: "5px",
            boxShadow: "none",
            textTransform: "none",
            fontSize: 16,
            padding: "8px 45px",
            border: "1px solid",
            lineHeight: 1.5,
            backgroundColor: "white",
            color: "rgb(63 63 63)",
            borderColor: "#ea586b",
            marginRight: "20px"
          }}
          onClick={() => { setNewSectionPopover(false); }}
        >
          Cancel
        </Button>

        <Button
          style={{
            borderRadius: "5px",
            boxShadow: "none",
            textTransform: "none",
            fontSize: 16,
            padding: "8px 45px",
            border: "1px solid",
            lineHeight: 1.5,
            background: formSubmitBtn,
            color: "white"
          }}
          type="submit"
          onClick={() => { onCreateSection && onCreateSection(newSectionText); setNewSectionPopover(false); }}
        >
          Save
        </Button>
      </Popover>
    </CustomModalWeb>
  );
}


const styles: Styles<Theme, {}, string> = {
  // Customizable Area Start
  modal: {
    "& .MuiDialog-paper": {
      width: 400,
    },
    "& .MuiDialogTitle-root": {
      display: "none"
    },
    "& .MuiDialogContent-root .MuiDialogTitle-root": {
      display: "block"
    }
  },
  // Customizable Area End
};

export const TDSModal = withStyles(styles)(TDSModalWithoutStyles);