import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { calenderIcon } from "./assets";
import { runEngine } from "../../../framework/src/RunEngine";
import { dateFormateToDDMMYY } from "../../../framework/src/Utilities";
import moment from "moment";
export const configJSON = require("./config.js");

export interface Props {
    open: boolean;
    handleClose: (type: string, action: boolean) => void;
    classes: any;
    handleSubmit: (formData: object) => void;
    concessionTypeList: [];
    parentAccountList: [];
    handleChange: () => void;
    concessionCreateerrorMessage: string;
    concessionErrorMessage: concessionErrorAttribute;
    concessionDetailserrorMessage: string;
    concessionDetails: concessionData | null;
    handleSubmitConcessionType: (FormData: object) => void;
    handleEditConcessionTypeSubmit:(FormData: object) => void;
    handleDeleteConcessionTypeSubmit:() => void;
    selectedConcessionTypeItem:any;
    handleConcessionTypeEditmodal:any;
    handleConcessionTypeEditmodalClose:any;
    handleConcessionTypemodalOpen:any;
    handleConcessionTypemodal:any;
    handleConcessionTypemodalClose:any;
    handleConcessionTypeDeletemodalClose:any
    handleConcessionTypeDeletemodal:any
    handleConcessionTypeEditmodalOpen:any
  handleConcessionTypeDeletemodalOpen:any
  concessionTypeCreateerrorMessage:any
}

interface concessionAttribute {
    id:number,
    custom_id:number
    fee_concession_type_id: string,
    name: string,
    valid_until: string,
    mode: string,
    amount: any,
    }
    interface concessionErrorAttribute {
        name: string;
        fee_concession_type: any;
        valid_until: any;
        mode: string;
        amount:any;
      }
    
    interface concessionData {
      id: number
      attributes: concessionAttribute;
    }

  interface InitialField {
    fee_concession_type_id: string,
    name: string,
    valid_until: any,
    mode: string,
    amount: any,
  }

interface S {
    closeCopyModal: boolean;
    data: any;
    noteHeader: string;
    disabledbutton: boolean;
    loadingSpinner: boolean;
    concessionFormstate: InitialField;
    concessionNameError:string;
    concessionValidUntilError:string;
    concessionTypeError: string;
    concessionAmountError:string;
    concessionModeError:string;
    subAccountStatus: boolean;
    parentAccError: string;
    calenderIcon:any;
}
interface SS {
    id: any;
}

const concessionInitialField: InitialField =
{ fee_concession_type_id: "",name: "",mode: "",amount: "",valid_until: "",
};

  
export default class CopyConcessionController extends BlockComponent<Props, S, SS> {
   
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [];

        this.state = {
            closeCopyModal: false,
            data: [],
            noteHeader: '',
            disabledbutton: true,
            loadingSpinner: false,
            concessionFormstate: concessionInitialField,
            concessionValidUntilError:"",
            concessionAmountError:"",
            concessionNameError:'',
            concessionTypeError: "",
            concessionModeError:"",
            subAccountStatus: false,
            calenderIcon:calenderIcon,
            parentAccError: "",
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        super.componentDidMount();
    }
    
    modalhandleClose = () => {
        this.setState({ closeCopyModal: true ,
        concessionValidUntilError:"",
        concessionAmountError:"",
        concessionNameError:'',
        concessionTypeError: "",
        concessionModeError:"",})
        this.props.handleClose("copy", false);
    }

    componentDidUpdate(prevProps: Props) {
        if (prevProps.open !== this.props.open) {
            this.resetFormState();
        }
      if (prevProps.concessionDetails !== this.props.concessionDetails) {
      
        const { concessionDetails } = this.props;
        if( concessionDetails ){
            const dateValue = typeof concessionDetails.attributes.valid_until === "string" ? new Date(concessionDetails.attributes.valid_until) : concessionDetails.attributes.valid_until;
            this.setState({ concessionFormstate: { 
              fee_concession_type_id: concessionDetails.attributes.fee_concession_type_id, 
              name: concessionDetails.attributes.name, 
              mode: concessionDetails.attributes.mode, 
              amount: concessionDetails.attributes.amount, 
              valid_until:  concessionDetails.attributes.valid_until,}
              
            });
          }
    
      }
    }
    resetFormState() {
        this.setState({ concessionFormstate: concessionInitialField, concessionNameError: "", concessionTypeError: "", concessionModeError: '', concessionAmountError: "" , concessionValidUntilError :"", });
    }

    handleChange = (e: any) => {
        let value = e.target.value;
      
            this.setState({ concessionFormstate: { ...this.state.concessionFormstate, [e.target.name]: value } });
    };
    handleDateChange=(value:any)=>{
        // let value1= 
          this.setState({
            concessionFormstate: {
              ...this.state.concessionFormstate,
              valid_until: value
            }
          });
      
        // console.log(value1  )
      }
    handleValidation = (formState: InitialField) => {
        let isValid = true;
        const regex = /^[a-zA-Z0-9_\s]{2,40}$/i;
        const regex2 = /^[0-9]+$/;
        if (formState.name === "") {
            this.setState({ concessionNameError: ('Concession name is required') });
            isValid = false;
        } else if (formState.name && regex.test(formState.name) === false) {
            this.setState({
              concessionNameError: "Concession name is invalid"
            });
            isValid = false;
          }else {
            this.setState({ concessionNameError: '' });
        }
        if (formState.fee_concession_type_id === "0" || formState.fee_concession_type_id === "-1") {
            this.setState({
                concessionTypeError: (
                    'Concession type is required'
                )
            });
            isValid = false;
        } else {
            this.setState({ concessionTypeError: '' });
        }
        if (formState.mode === '' || formState.mode === "null") {
                this.setState({
                    concessionModeError: (
                        'Mode is required'
                    )
                });
                isValid = false;
            } else {
                this.setState({ concessionModeError: '' });
            
        }
        if (formState.valid_until === '') {
            this.setState({
                concessionValidUntilError: (
                    'Date is required'
                )
            });
            isValid = false;
        } else {
            this.setState({ concessionValidUntilError: '' });
        }
        if (formState.amount === '' || formState.amount === '0' || formState.amount === 0) {
            this.setState({
                concessionAmountError: (
                    'Amount is required'
                )
            });
            isValid = false;
        }else if ( regex2.test(formState.amount) === false) {
            this.setState({
              concessionAmountError: "Amount is Invalid"
            });
            isValid = false;
          } else if (formState.mode === "percentage" && formState.amount > 100 ) {
      
            this.setState({
              concessionAmountError: "You cannot Enter more than 100%"
            });
            isValid = false;
          
          }else {
            this.setState({ concessionAmountError: '' });
        }
        return isValid;
    };

    handleSubmit = (e: any) => {
        e.preventDefault();
        if (this.handleValidation(this.state.concessionFormstate)) {
            console.log('copy data', this.state.concessionFormstate);
            this.props.handleSubmit({ concessionData: this.state.concessionFormstate });
        }
    };

}