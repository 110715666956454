import React from "react";
import { Linking, ScrollView } from "react-native";

// Customizable Area Start
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast, cssTransition } from "react-toastify";
import OtpInput from "react-otp-input";
const { baseURL } = require("../../../framework/src/config");
let token: any;
let myInterval: any;
let userPhoneNumber: any;
let firstNumber: any;
let secondNumber: any;
let thirdNumber: any;
let is_otp_from_lms:any;
// Customizable Area End

import OTPInputAuthController, {
  Props,
} from "../../otp-input-confirmation/src/OTPInputAuthController";
import Loader from "../../../components/src/Loader.web";

export default class OTPInputAuth extends OTPInputAuthController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    token = localStorage.getItem("forgot_token");
    is_otp_from_lms = localStorage.getItem("is_otp_from_lms");
    // let message = localStorage.getItem("otp message");
    // if(message){
    //   this.props.navigation.navigate("NewPassword");
    // }
    userPhoneNumber = localStorage.getItem("Phone_number");
    if (userPhoneNumber === null) {
      this.props.navigation.navigate("EmailAccountLoginBlock");
    }

    // Customizable Area End
  }
  // Customizable Area Start
  async componentDidMount() {
    if (userPhoneNumber) {
      firstNumber = userPhoneNumber.slice(0, 2);
      secondNumber = userPhoneNumber.slice(2, 4);
      thirdNumber = userPhoneNumber.slice(-2);
    }
    this.setState({ otpAuthToken: token });
    myInterval = setInterval(() => {
      const { seconds, minutes } = this.state;

      if (seconds > 0) {
        this.setState(({ seconds }) => ({
          seconds: seconds - 1,
        }));
      }
    }, 1000);
  }
  async componentWillUnmount() {
    clearInterval(myInterval);
  }

  handleChange = (event: any) => {
    this.setState({ otp: event });
    let length = event.length;
    if (length === 4) {
      this.setState({ enableSave: true });
    } else {
      this.setState({ enableSave: false });
    }
    console.log(this.state.otp);
  };
  handleValidation: any = () => {
    let isValid = true;
    if (this.state.otp === "") {
      this.setState({
        otpError: (
          <p className="errorMsg" style={{ marginTop: "0px" }}>
            Invalid OTP provided
          </p>
        ),
        isLoading: false,
      });
      isValid = false;
    } else {
      this.setState({ otpError: "" });
    }
    return isValid;
  };
  handleSubmit = (e: any) => {
    e.preventDefault();
    if (this.handleValidation()) {
      this.setState({ isLoading: true });

      const jsonPayload = {
        data: {
          otp_code: this.state.otp,
          token: this.state.otpAuthToken,
          is_otp_from_lms: JSON.parse(is_otp_from_lms)
        },
      };

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(jsonPayload),
      };

      fetch(baseURL + "/otp_confirmations/otp_confirm_forgot_pass_lms", requestOptions)
        .then((response) => response.json())
        .then((data) => {
          if (data.errors) {
            this.setState({ isLoading: false });
            this.setState({
              otpError: (
                <p className="errorMsg">
                  {data.errors[0].otp ? data.errors[0].otp : data.errors[0].pin}
                </p>
              ),
            });
          } else {
            localStorage.setItem('otp message', data.messages[0].otp)
            this.props.navigation.navigate("NewPassword");
            this.setState({ isLoading: false });
          }
        });
    }
  };
  resendTimer: any = (e: any) => {
    e.preventDefault();

    this.setState({ isLoading: true });

    // const jsonPayload = {
    //   data: {
    //     type: "sms_account",
    //     attributes: {
    //       full_phone_number: userPhoneNumber,
    //     },
    //   },
    // };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        token:this.state.otpAuthToken
      },
      // body: JSON.stringify(jsonPayload),
    };

    fetch(baseURL + "/bx_block_login/login/resend_otp_with_lms", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data.errors) {
          this.setState({ isLoading: false });
          // console.log("--------------", data.errors[0].otp);
          this.setState({
            otpError: <p className="errorMsg">{data.errors[0].otp ? data.errors[0].otp : data.errors[0].pin === null ? "Invalid OTP":data.errors[0].pin }</p>,
          });
        } else {
          localStorage.setItem("forgot_token", data.meta.token);
          toast.success("OTP send successfully");
          this.setState({
            seconds: 59,
            isLoading: false,
            otpAuthToken: data.meta.token,
          });
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false });
      });
  };

  // Customizable Area End

  render() {
    return (
      //Merge Engine DefaultContainer
      <ScrollView keyboardShouldPersistTaps="always">
        {/* Customizable Area Start */}
        <>
          {this.state.isLoading && (
            <Loader positionType={"left"} loading={true} />
          )}
          <div className="container">
            <div className="leftSide">
              <div className="leftContainer">
                <img
                  src={this.state.logoImage}
                  alt="logo"
                  className="logoImgStyle"
                />
                <p className="headingStyle">
                  Manage your <span className="d-block">Finance</span>
                </p>

                <div className="mainImg">
                  <img
                    src={this.state.mainImage}
                    alt="mainImage"
                    className="mainImageStyle"
                  />
                </div>
              </div>
            </div>
            {/* right side */}
            <div className="rightSide">
              <div className="commonRightContainer">
                <h1>Enter OTP</h1>
                <p className="otpParaStyle">
                  We have sent the code for verification to your mobile number
                  <span>
                    {"+" +
                      firstNumber +
                      " " +
                      secondNumber +
                      "######" +
                      thirdNumber}
                  </span>
                </p>
                <form onSubmit={this.handleSubmit}>
                  <div className="otpInputContainer">
                    <OtpInput
                      value={this.state.otp}
                      numInputs={4}
                      className={
                        this.state.otpError ? "otpInput2 otpInput" : "otpInput"
                      }
                      onChange={this.handleChange}
                    />
                    <p>{this.state.otpError}</p>
                  </div>
                  <p className="otpParaStyle1">
                    Didn't receive the OTP?{" "}
                    <button
                      onClick={this.resendTimer}
                      className="resendOtpStyle"
                      style={{
                        opacity: this.state.seconds === 0 ? "1" : "0.5",
                      }}
                      disabled={this.state.seconds === 0 ? false : true}
                    >
                      Resend OTP
                    </button>
                    {this.state.seconds === 0 ? null : (
                      <h1 className="resendOtpTimer">
                        {" "}
                        {this.state.minutes}:
                        {this.state.seconds < 10
                          ? `0${this.state.seconds}`
                          : this.state.seconds}
                      </h1>
                    )}
                  </p>
                  <button type="submit" className="sendButton">
                    Confirm
                  </button>
                </form>
              </div>
            </div>
          </div>
        </>
        {/* Customizable Area End */}
      </ScrollView>
    );
  }
}
// Customizable Area Start
// Customizable Area End
