import React from "react";
// Customizable Area Start
import { withStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Styles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import TextField from '@material-ui/core/TextField';
import OutlinedSelect from "../../../components/src/OutlinedSelect";

import CustomModal from "../../CommonLayout/CustomModal/src/CustomModal.web";
import { phoneCodes } from "./phoneCodes";
import { PaymentTermsModal } from "./paymentTermsModal";
import { TDSModal } from "./tdsModal";
import { FormControlLabel } from "@material-ui/core";
// Customizable Area End

import VendorFormController, { FormMode, Props } from "./VendorFormController";
import { classNames } from "../../CommonLayout/HRMSCommons/src/classNames";
import { Button, ButtonType } from "../../CommonLayout/HRMSCommons/src/button/default";
import Checkbox from '@material-ui/core/Checkbox';


// Customizable Area Start
const useStyles: Styles<Theme, {}, string> = (theme: Theme) => ({
  form: {
    '& .MuiSelect-select.Mui-disabled': {
      color: "#7E7E7E"
    },
    '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
      borderColor: "rgba(0, 0, 0, 0.26) !important"
    }
  },
  question: {
    backgroundColor: "#FFF9EE",
    marginTop: 20,
    marginLeft: -24,
    width: "calc(100% + 48px)",

    '& h6, & label': {
      color: "#7E766B",
      fontSize: 14,
      fontWeight: 600,
    },
    '& .MuiRadio-colorSecondary.Mui-checked': {
      color: '#fe4554'
    }
  },
  viewMode: {
    '& .question': {
      '& h6, & label': {
        color: "#1E1E1E",
      }
    },
    '& .MuiFormLabel-root.Mui-disabled': {
      color: "#44556C",
    },
    '& .MuiInputBase-root > *': {
      opacity: 0,

      '&.MuiSelect-root.Mui-disabled, &.MuiInputBase-input.Mui-disabled': {
        opacity: 1,
        color: "#7E7E7E",
        paddingLeft: 0,
        fontWeight: 500
      }
    }
  }
});
// Customizable Area End

class VendorForm extends VendorFormController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const {
      classes,
      isOpen,
      formMode,
      title,
      submitLabel,
      currency,
      paymentTerms,
      sourceOfSupply,
      tds,
      gstTreatment,
      tdSection,
    } = this.props;
    const { form, formErrors } = this.state;
    console.log('props', this.state)

    return (
      <CustomModal
        title={title}
        handleSubmit={this.onSubmit.bind(this)}
        buttonTitle={submitLabel}
        open={isOpen}
        handleClose={this.handleFormClose.bind(this)}
        submitLoading={this.state.isSubmitting}
        hideCancelButton={formMode === FormMode.View}
      >

        <PaymentTermsModal
          isOpen={this.state.isPaymentTermsModalOpened}
          onClose={this.onClosePaymentTermsModal.bind(this)}
          onSubmit={this.onPaymentTermsModalSubmit.bind(this)}
          paymentTerms={paymentTerms}
        />

        <TDSModal
          isOpen={this.state.isTDSModalOpened}
          onClose={this.onCloseTDSModal.bind(this)}
          tds={tds}
          tdSection={tdSection}
          onSubmit={this.onTDSModalSubmit.bind(this)}
          onCreateSection={this.onSectionSubmit.bind(this)}
        />

        <Grid container direction="column" className={classNames(classes.form, FormMode.View === formMode ? classes.viewMode : '')}>
          <Grid item xs={12} style={{ paddingRight: 12 }}>
            <FormControl>
              <FormLabel style={{ marginBottom: "8px" }}>
                Vendor ID*
              </FormLabel>
              <TextField
                value={(form.id || 0).toString().padStart(4, '0')}
                color={'primary'}
                variant="outlined"
                type={'text'}
                name={'ID'}
                disabled
              />
            </FormControl>
          </Grid>

          <Grid container direction="row" style={{ marginTop: 20 }}>
            <Grid item xs={12} sm={4} style={{ paddingRight: 12 }}>
              <OutlinedSelect
                val={form.salutation}
                options={[
                  { id: "Mr", name: "Mr" },
                  { id: "Ms", name: "Ms" },
                  { id: "Mrs", name: "Mrs" }
                ]}
                label="Salutation*"
                placeholder="Select"
                name={'salutation'}
                disabled={formMode === FormMode.View}
                handleChange={this.onChangeSalutation.bind(this)}
              />
              {
                formErrors.salutation && (
                  <Typography variant="caption" style={{ color: 'red' }}>
                    {formErrors.salutation}
                  </Typography>
                )
              }
            </Grid>
            <Grid item xs={12} sm={4} style={{ paddingRight: 12 }}>
              <FormControl style={{ width: '100%' }}>
                <FormLabel style={{ marginBottom: "8px" }}>
                  First Name*
                </FormLabel>
                <TextField
                  value={form.first_name}
                  color={'primary'}
                  variant="outlined"
                  type={'text'}
                  name={'first_name'}
                  placeholder={'John'}
                  disabled={formMode === FormMode.View}
                  onChange={this.onChangeFirstName.bind(this)}
                  required
                />
                {
                  formErrors.first_name && (
                    <Typography variant="caption" style={{ color: 'red' }}>
                      {formErrors.first_name}
                    </Typography>
                  )
                }
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControl style={{ width: '100%' }}>
                <FormLabel style={{ marginBottom: "8px" }}>
                  Last Name*
                </FormLabel>
                <TextField
                  value={form.last_name}
                  color={'primary'}
                  variant="outlined"
                  type={'text'}
                  name={'last_name'}
                  placeholder={'Doe'}
                  disabled={formMode === FormMode.View}
                  onChange={this.onChangeLastName.bind(this)}
                  required
                />
                {
                  formErrors.last_name && (
                    <Typography variant="caption" style={{ color: 'red' }}>
                      {formErrors.last_name}
                    </Typography>
                  )
                }
              </FormControl>
            </Grid>
          </Grid>

          <Grid container direction="row" style={{ marginTop: 20 }}>
            <Grid item xs={12} style={{ paddingRight: 12 }}>
              <FormControl>
                <FormLabel style={{ marginBottom: "8px" }}>
                  Company Name*
                </FormLabel>
                <TextField
                  value={form.company_name}
                  color={'primary'}
                  variant="outlined"
                  type={'text'}
                  name={'company_name'}
                  placeholder={'Enter Company Name'}
                  disabled={formMode === FormMode.View}
                  onChange={this.onChangeCompanyName.bind(this)}
                  required
                />
                {
                  formErrors.company_name && (
                    <Typography variant="caption" style={{ color: 'red' }}>
                      {formErrors.company_name}
                    </Typography>
                  )
                }
              </FormControl>
            </Grid>
          </Grid>

          <Grid container direction="row" style={{ marginTop: 20 }}>
            <Grid item xs={12} sm={6} style={{ paddingRight: 12 }}>
              <FormControl>
                <FormLabel style={{ marginBottom: "8px" }}>
                  Vendor Email*
                </FormLabel>
                <TextField
                  value={form.vendor_email}
                  color={'primary'}
                  variant="outlined"
                  type={'email'}
                  name={'vendor_email'}
                  placeholder={'Enter Vendor\'s email'}
                  disabled={formMode === FormMode.View}
                  onChange={this.onChangeVendorEmail.bind(this)}
                  required
                />
                {
                  formErrors.vendor_email && (
                    <Typography variant="caption" style={{ color: 'red' }}>
                      {formErrors.vendor_email}
                    </Typography>
                  )
                }
              </FormControl>
            </Grid>
          </Grid>

          <Grid container direction="row" style={{ marginTop: 20 }}>
            <Grid item xs={12} sm={6} style={{ paddingRight: 12 }}>
              <FormControl style={{ width: '100%' }}>
                <FormLabel style={{ marginBottom: "8px" }}>
                  Contact Number (Mobile)*
                </FormLabel>
                <Grid container direction="row" spacing={1}>
                  <Grid item xs={3}>
                    <OutlinedSelect
                      val={(form.phone_no_mob_code || '').toString()}
                      options={this.countryCodes}
                      label=""
                      labelStyle={{ display: 'none' }}
                      formControlStyle={{ minWidth: 'initial' }}
                      placeholder="Code"
                      name={'phone_no_mob_code'}
                      disabled={formMode === FormMode.View}
                      handleChange={this.onChangePhoneNoMobCode.bind(this)}
                    />
                  </Grid>
                  <Grid item xs={9}>
                    <TextField
                      value={form.phone_no_mob}
                      color={'primary'}
                      variant="outlined"
                      type={'number'}
                      name={'phone_no_mob'}
                      placeholder={'Enter Mobile Number'}
                      style={{ width: '100%' }}
                      disabled={formMode === FormMode.View}
                      onChange={this.onChangePhoneNoMob.bind(this)}
                      required
                    />
                  </Grid>
                </Grid>
                {
                  formErrors.phone_no_mob && (
                    <Typography variant="caption" style={{ color: 'red' }}>
                      {formErrors.phone_no_mob}
                    </Typography>
                  )
                }
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} style={{ paddingRight: 12 }}>
              <FormControl style={{ width: '100%' }}>
                <FormLabel style={{ marginBottom: "8px" }}>
                  Contact Number (Work)*
                </FormLabel>
                <Grid container direction="row" spacing={1}>
                  <Grid item xs={3}>
                    <OutlinedSelect
                      val={(form.phone_no_work_code || '').toString()}
                      options={this.countryCodes}
                      label=""
                      labelStyle={{ display: 'none' }}
                      formControlStyle={{ minWidth: 'initial' }}
                      placeholder="Code"
                      name={'phone_no_work_code'}
                      disabled={formMode === FormMode.View}
                      handleChange={this.onChangePhoneNoWorkCode.bind(this)}
                    />
                  </Grid>
                  <Grid item xs={9}>
                    <TextField
                      value={form.phone_no_work}
                      color={'primary'}
                      variant="outlined"
                      type={'number'}
                      name={'phone_no_work'}
                      placeholder={'Enter Work Number'}
                      style={{ width: '100%' }}
                      disabled={formMode === FormMode.View}
                      onChange={this.onChangePhoneNoWork.bind(this)}
                    />
                  </Grid>
                </Grid>
                {
                  formErrors.phone_no_work && (
                    <Typography variant="caption" style={{ color: 'red' }}>
                      {formErrors.phone_no_work}
                    </Typography>
                  )
                }
              </FormControl>

            </Grid>
          </Grid>

          <Grid container direction="row" style={{ marginTop: 20 }}>
            <Grid item xs={12} sm={6} style={{ paddingRight: 12 }}>
              <FormControl>
                <FormLabel style={{ marginBottom: "8px" }}>
                  Website*
                </FormLabel>
                <TextField
                  value={form.website_url}
                  color={'primary'}
                  variant="outlined"
                  type={'text'}
                  name={'vendor_website_url'}
                  placeholder={'Enter Vendor\'s website_url'}
                  disabled={formMode === FormMode.View}
                  onChange={this.onChangeVendorWebsiteURL.bind(this)}
                  required
                />
                {
                  formErrors.vendor_email && (
                    <Typography variant="caption" style={{ color: 'red' }}>
                      {formErrors.vendor_email}
                    </Typography>
                  )
                }
              </FormControl>
            </Grid>
          </Grid>

          <Grid container direction="row" style={{ marginTop: 20 }}>
            <Grid item xs={12} sm={6} style={{ paddingRight: 12 }}>
              <FormControl>
                <FormLabel style={{ marginBottom: "8px" }}>
                  GST Treatment*
                </FormLabel>
                <OutlinedSelect
                  val={(form.gst_treatment_id || '').toString()}
                  options={gstTreatment.map(({ id, name, description }) => ({ id, name, description }))}
                  label=""
                  labelStyle={{ display: 'none' }}
                  formControlStyle={{ minWidth: 200 }}
                  placeholder="Select GST Treatment"
                  name={'gst_treatment_id'}
                  disabled={formMode === FormMode.View}
                  handleChange={this.onChangetGSTTreatment.bind(this)}
                  optionWithDescription
                />
                {
                  formErrors.gst_treatment_id && (
                    <Typography variant="caption" style={{ color: 'red' }}>
                      {formErrors.gst_treatment_id}
                    </Typography>
                  )
                }
              </FormControl>
            </Grid>
          </Grid>

          <Grid container direction="row" style={{ marginTop: 20 }}>
            <Grid item xs={12} sm={6} style={{ paddingRight: 12 }}>
              <FormControl>
                <FormLabel style={{ marginBottom: "8px" }}>
                  GSTIN/UI*
                </FormLabel>
                <TextField
                  value={form.gstin_uin}
                  color={'primary'}
                  variant="outlined"
                  type={'text'}
                  name={'gstin_uin'}
                  placeholder={'Enter GSTIN/UI'}
                  disabled={formMode === FormMode.View}
                  onChange={this.onChangeGSTIN_UIN.bind(this)}
                  required
                />
                {/* <Button
                  title={'Get GST Details'}
                  buttonType={ButtonType.Secondary}
                >
                  Get GST Details
                </Button> */}
                {
                  formErrors.gstin_uin && (
                    <Typography variant="caption" style={{ color: 'red' }}>
                      {formErrors.gstin_uin}
                    </Typography>
                  )
                }
              </FormControl>
            </Grid>
          </Grid>

          <Grid container direction="row" style={{ marginTop: 20 }}>
            <Grid item xs={12} sm={6} style={{ paddingRight: 12 }}>
              <FormControl>
                <FormLabel style={{ marginBottom: "8px" }}>
                  PAN Number*
                </FormLabel>
                <TextField
                  value={form.pan_number}
                  color={'primary'}
                  variant="outlined"
                  type={'text'}
                  name={'pan_number'}
                  placeholder={'Enter PAN Number'}
                  disabled
                  onChange={this.onChangePanNumber.bind(this)}
                  required
                />
                {
                  formErrors.pan_number && (
                    <Typography variant="caption" style={{ color: 'red' }}>
                      {formErrors.pan_number}
                    </Typography>
                  )
                }
              </FormControl>
            </Grid>
          </Grid>

          <Grid container direction="row" style={{ marginTop: 20 }}>
            <Grid item xs={12} sm={6} style={{ paddingRight: 12 }}>
              <FormControl>
                <FormLabel style={{ marginBottom: "8px" }}>
                  Source of Supply*
                </FormLabel>
                <OutlinedSelect
                  val={form.state_source_of_supply}
                  options={Object.entries(sourceOfSupply).map(([key, item]) => ({ id: `[${key}] ${item}`, name: `[${key}] ${item}` }))}
                  label=""
                  labelStyle={{ display: 'none' }}
                  formControlStyle={{ minWidth: 200 }}
                  placeholder="Select Source of Supply"
                  name={'state_source_of_supply'}
                  disabled={formMode === FormMode.View}
                  handleChange={this.onChangeStateSourceOfSupply.bind(this)}
                />
                {
                  formErrors.state_source_of_supply && (
                    <Typography variant="caption" style={{ color: 'red' }}>
                      {formErrors.state_source_of_supply}
                    </Typography>
                  )
                }
              </FormControl>
            </Grid>
          </Grid>

          <Grid container direction="row" style={{ marginTop: 20 }}>
            <Grid item xs={12} sm={6} style={{ paddingRight: 12 }}>
              <FormControl>
                <FormLabel style={{ marginBottom: "8px" }}>
                  Currency*
                </FormLabel>
                <OutlinedSelect
                  val={form.currency}
                  options={currency.map(([name, id]) => ({ id, name }))}
                  label=""
                  labelStyle={{ display: 'none' }}
                  formControlStyle={{ minWidth: 200 }}
                  placeholder="Select Currency"
                  name={'currency'}
                  disabled={formMode === FormMode.View}
                  handleChange={this.onChangeCurrency.bind(this)}
                />
                {
                  formErrors.currency && (
                    <Typography variant="caption" style={{ color: 'red' }}>
                      {formErrors.currency}
                    </Typography>
                  )
                }
              </FormControl>
            </Grid>
          </Grid>

          <Grid container direction="row" style={{ marginTop: 20 }}>
            <Grid item xs={12} sm={6} style={{ paddingRight: 12 }}>
              <FormControl>
                <FormLabel style={{ marginBottom: "8px" }}>
                  Payment Terms*
                </FormLabel>
                <OutlinedSelect
                  val={(form.payment_term_id || '').toString()}
                  options={paymentTerms.map(({ id, term_name }) => ({ id, name: term_name }))}
                  label=""
                  labelStyle={{ display: 'none' }}
                  formControlStyle={{ minWidth: 200 }}
                  placeholder="Select Payment Terms"
                  name={'payment_term_id'}
                  disabled={formMode === FormMode.View}
                  handleChange={this.onChangePaymentTerms.bind(this)}
                  actionButtonLabel={"Configure Terms"}
                  hasActionButton
                  onActionButtonClick={this.openPaymentTermsModal.bind(this)}
                />
                {
                  formErrors.payment_term_id && (
                    <Typography variant="caption" style={{ color: 'red' }}>
                      {formErrors.payment_term_id}
                    </Typography>
                  )
                }
              </FormControl>
            </Grid>
          </Grid>

          <Grid container direction="row" style={{ marginTop: 20 }}>
            <Grid item xs={12} sm={6} style={{ paddingRight: 12 }}>
              <FormControl>
                <FormLabel style={{ marginBottom: "8px" }}>
                  TDS*
                </FormLabel>
                <OutlinedSelect
                  val={(form.account_td_id || '').toString()}
                  options={tds.map(({ id, attributes: { tax_name, section } }) => ({ id, name: `${tax_name} (${section})` }))}
                  label=""
                  labelStyle={{ display: 'none' }}
                  formControlStyle={{ minWidth: 200 }}
                  placeholder="Select TDS"
                  name={'account_td_id'}
                  disabled={formMode === FormMode.View}
                  handleChange={this.onChangeTDS.bind(this)}
                  actionButtonLabel={"Configure TDS"}
                  hasActionButton
                  onActionButtonClick={this.openTDSModal.bind(this)}
                />
                {
                  formErrors.account_td_id && (
                    <Typography variant="caption" style={{ color: 'red' }}>
                      {formErrors.account_td_id}
                    </Typography>
                  )
                }
              </FormControl>
            </Grid>
          </Grid>

          <Grid container direction="row" style={{ marginTop: 20 }}>
            <Grid item xs={12} style={{ paddingRight: 12 }}>
              <FormControl style={{ width: '100%' }}>
                <FormLabel style={{ marginBottom: "8px" }}>
                  Billing Address*
                </FormLabel>
                <TextField
                  value={form.billing_address}
                  color={'primary'}
                  variant="outlined"
                  type={'text'}
                  name={'billing_address'}
                  placeholder={'Enter Billing Address Line 1'}
                  disabled={formMode === FormMode.View}
                  style={{ width: '100%' }}
                  onChange={this.onChangeBillingAddressLine1.bind(this)}
                  required
                />
                {
                  formErrors.billing_address && (
                    <Typography variant="caption" style={{ color: 'red' }}>
                      {formErrors.billing_address}
                    </Typography>
                  )
                }
                <TextField
                  value={form.billing_address2}
                  color={'primary'}
                  variant="outlined"
                  type={'text'}
                  name={'billing_address2'}
                  placeholder={'Enter Billing Address Line 2'}
                  disabled={formMode === FormMode.View}
                  onChange={this.onChangeBillingAddressLine2.bind(this)}
                  style={{ marginTop: 10, width: '100%' }}
                  required
                />
                {
                  formErrors.billing_address2 && (
                    <Typography variant="caption" style={{ color: 'red' }}>
                      {formErrors.billing_address2}
                    </Typography>
                  )
                }
              </FormControl>
            </Grid>
          </Grid>

          <Grid container direction="row" style={{ marginTop: 20 }}>
            <Grid item xs={12} style={{ paddingRight: 12 }}>
              <FormControl style={{ width: '100%' }}>
                <Grid container direction="row">
                  <FormLabel style={{ marginBottom: "8px" }}>
                    <span style={{ marginRight: "24px" }}>Shipping Address*</span>
                    {
                      formMode !== FormMode.View && (
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={form.is_shipping_address_same_as_billing_address}
                              onChange={this.onChangeIsShippingAddressSameAsBillingAddress.bind(this)}
                              name="checkedA"
                            />
                          }
                          label="Same as Billing Address"
                        />
                      )
                    }
                  </FormLabel>
                </Grid>
                <TextField
                  value={form.shipping_address}
                  color={'primary'}
                  variant="outlined"
                  type={'text'}
                  name={'shipping_address'}
                  placeholder={'Enter Shipping Address Line 1'}
                  disabled={formMode === FormMode.View || form.is_shipping_address_same_as_billing_address}
                  style={{ width: '100%' }}
                  onChange={this.onChangeShippingAddressLine1.bind(this)}
                  required
                />
                {
                  formErrors.shipping_address && (
                    <Typography variant="caption" style={{ color: 'red' }}>
                      {formErrors.shipping_address}
                    </Typography>
                  )
                }
                <TextField
                  value={form.shipping_address2}
                  color={'primary'}
                  variant="outlined"
                  type={'text'}
                  name={'shipping_address2'}
                  placeholder={'Enter Shipping Address Line 2'}
                  disabled={formMode === FormMode.View || form.is_shipping_address_same_as_billing_address}
                  onChange={this.onChangeShippingAddressLine2.bind(this)}
                  style={{ marginTop: 10, width: '100%' }}
                  required
                />
                {
                  formErrors.shipping_address2 && (
                    <Typography variant="caption" style={{ color: 'red' }}>
                      {formErrors.shipping_address2}
                    </Typography>
                  )
                }
              </FormControl>
            </Grid>
          </Grid>

          <Grid container direction="row" style={{ marginTop: 20 }}>
            <Grid item xs={12} style={{ paddingRight: 12 }}>
              <FormControl style={{ width: '100%' }}>
                <FormLabel style={{ marginBottom: "8px" }}>
                  Remarks
                </FormLabel>
                <TextField
                  value={form.remarks}
                  color={'primary'}
                  variant="outlined"
                  type={'text'}
                  name={'remarks'}
                  placeholder={formMode !== FormMode.View ? 'Enter Remarks' : '-'}
                  disabled={formMode === FormMode.View}
                  style={{ width: '100%' }}
                  onChange={this.onChangeRemarks.bind(this)}
                  multiline
                  rows={4}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      </CustomModal>
    );
    // Customizable Area End
  }
}

export default withStyles(useStyles)(VendorForm);
