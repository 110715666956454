import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { deleteIcon } from "./assets";
import { runEngine } from "../../../framework/src/RunEngine";
export const configJSON = require("./config.js");

export interface Props {
    open: boolean;
    handleClose: (type: string, action: boolean) => void;
    classes: any;
    concessionDetailserrorMessage: string;
    concessionDetails: concessionData;
    DateFormateConverter: any;
}
  
interface concessionAttribute {
  custom_id: number;
  name: string;
  fee_concession_type: fee_concession_type;
  valid_until: any;
  mode: string;
  amount:any;
}
interface fee_concession_type {
  id: number;
  data:{
    attributes:{
      id:any,
      name:any
    }
  }
}
  interface concessionData {
    id: number;
    attributes: concessionAttribute
  }


interface S {
    closeModal: boolean;
    data: any;
    noteHeader: string;
    disabledbutton: boolean;
    loadingSpinner: boolean;
    deleteIcon: any;

}
interface SS {
    id: any;
}

// const updateRoleMappingStatus = (accountDetails: accountData | null) => {
//   let permissions: rolePerm = {};
//   if (roleDetails && roleDetails.attributes && roleDetails.attributes.role_premissions && roleDetails.attributes.role_premissions.data && roleDetails.attributes?.role_premissions?.data.length) {
//     roleDetails?.attributes?.role_premissions?.data.map((item: rolePremissionAttribute) => {
//       if (item.attributes.menu) {
       
//       }
//     });
//     return permissions;
//   }
//   return null;
// } 

export default class ViewConcessionController extends BlockComponent<Props, S, SS> {
   
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [];

        this.state = {
            closeModal: false,
            data: [],
            noteHeader: '',
            disabledbutton: true,
            loadingSpinner: false,
            deleteIcon: deleteIcon,
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
      super.componentDidMount();
    }
    
    // componentDidUpdate(prevProps: Props) {
       
    //     if(prevProps.roleDetails !== this.props.roleDetails){
    //       this.setState({ rolePermissions: {} });
    //       const detailsResult = updateRoleMappingStatus(this.props.roleDetails);
    //       if(detailsResult){
    //         this.setState({rolePermissions: detailsResult});
    //       }
    //     }
    // }

    modalhandleClose = () => {
        this.setState({ closeModal: true })
        this.props.handleClose("view", false);
    }
}
