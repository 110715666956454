import React, { FunctionComponent } from "react";

// Customizable Area Start
import { Menu, MenuItem, PopoverOrigin, Theme } from "@material-ui/core";
import withStyles, { Styles } from "@material-ui/core/styles/withStyles";
import { ButtonTheme } from "./button/default";
import { classNames } from "./classNames";
// Customizable Area End

// Customizable Area Start
export interface IActionMenuItem {
  label: string;
  action: (item: IActionMenuItem) => void;
}
// Customizable Area End

interface Props {
  // Customizable Area Start
  anchorEl: HTMLElement | null;
  items: Array<IActionMenuItem>;
  arrowSide?: "left" | "right" | "center";
  anchorOrigin?: PopoverOrigin;
  onClose?: () => void;
  classes?: any;
  // Customizable Area End
}

const ActionMenuWithOutStyles: FunctionComponent<Props> = (props) => {
  // Customizable Area Start
  const defaults: Props = {
    anchorEl: null,
    items: [],
    arrowSide: 'center',
    onClose: () => { }
  };

  props = { ...defaults, ...props };
  const { classes, anchorEl, arrowSide, items, anchorOrigin, onClose } = props;

  return (
    <Menu
      id="ActionMenu"
      keepMounted
      anchorEl={anchorEl}
      anchorReference="anchorEl"
      anchorOrigin={anchorOrigin || { horizontal: -25, vertical: "center" }}
      open={!!anchorEl}
      onClose={onClose}
      className={classes.menu}
      style={ anchorOrigin ? { marginTop: anchorOrigin.vertical } : {} }
    >
      <div className={classNames(classes.arrowUp, arrowSide)}> </div>
      {
        items.map(item => (
          <MenuItem
            className={classes.actionMenuItem}
            key={item.label}
            onClick={() => { item.action(item); onClose && onClose(); }}
          >
            {item.label}
          </MenuItem>
        ))
      }
    </Menu>
  );
  // Customizable Area End
};

const styles: Styles<Theme, {}, string> = {
  // Customizable Area Start
  menu: {
    marginLeft: "0",
    marginTop: "0",

    "& .MuiPaper-root": {
      overflow: "visible",
    }
  },
  actionMenuItem: {
    '&:hover': {
      backgroundColor: ButtonTheme.PrimaryBackground,
      borderColor: ButtonTheme.PrimaryBackground,
      boxShadow: 'none',
      color: "white",
    }
  },
  arrowUp: {
    position: "absolute",
    width: "16px",
    height: "12px",
    top: "-40px",
    zIndex: 1,
   
    '&.left': {
      left: "10px",
    },
    '&.right': {
      right: "10px",
      boxShadow: "0px 29px 8px -3px rgb(0 0 0 / 20%)",
    },
    '&.center': {
      left: "50%",
      transform: "translateX(-50%)",
    },
    '&:before': {
      content: "''",
      position: "absolute",
      top: "30px",
      left: "0",
      borderStyle: "solid",
      borderWidth: "0 8px 12px",
      borderColor: "#FFF transparent",
      display: "block",
      width: 0,
      zIndex: 0,
      backgorundColor:"white"
    }
  }
  // Customizable Area End
};

export const ActionMenu = withStyles(styles)(ActionMenuWithOutStyles);
