Object.defineProperty(exports, "__esModule", {
  value: true
});

const urlConfig = require("../../../framework/src/config");

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "RolesPermissions";
exports.labelBodyText = "RolesPermissions Body";
exports.btnExampleTitle = "CLICK ME";

exports.GET = "GET";
exports.POST = "POST";
exports.PUT = "PUT";
exports.DELETE = "DELETE";
exports.PATCH = "PATCH";
exports.roleManagementURL = urlConfig.baseURL;
exports.getRoleEndPoint = "bx_block_roles_permissions/roles";

// Customizable Area End