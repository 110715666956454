import React from "react";
// Customizable Area Start
import {
    TextField,
} from "@material-ui/core";
import { withStyles, } from '@material-ui/core/styles';
import CustomStepModal from '../../CommonLayout/CustomStepModal/src/CustomStepModal.web';
import FeeAssignmentForm from "./FeeAssignmentForm";
import FeeStructureForm from "./FeeStructureForm";
import InstallmentAddForm from "./InstallmentAddForm";
import InstallmentForm from "./InstallmentForm";
import InstallmentPlans from "./InstallmentPlans";
import AddIcon from '@material-ui/icons/Add';
import CustomDeleteModal from '../../CommonLayout/CustomDeleteModal/src/CustomDeleteModal.web';
import CreateFeeAssignmentController, {
    Props,
} from "./CreateFeeAssignmentController";
// Customizable Area End

class CreateFeeAssignment extends CreateFeeAssignmentController {

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End

    }
    // Customizable Area Start

    renderStepForm = (step: number,currentForm:string) => {
        
        console.log('form-step',step);
        console.log('currentForm',currentForm);


        if(step == 0){
            return (<FeeAssignmentForm 
                studentNext={this.handleStudentNext}
                classes={this.props.classes}
                handleCheckStudentItem={this.handleCheckStudentItem}
                checkStudentRow={this.state.checkStudentRow}
                handleCheckAllStudents={this.handleCheckAllStudents}
                allStudentRowsChecked={this.state.allStudentRowsChecked}
                feeAssignmentDivisionError={this.state.feeAssignmentDivisionError}
                feeAssignmentGroupError={this.state.feeAssignmentGroupError}
                feeAssignmentClassError={this.state.feeAssignmentClassError}
                feeAssignmentTypeError={this.state.feeAssignmentTypeError}
                feeStructureNameError={this.state.feeStructureNameError}
                academicYearError={this.state.academicYearError}
                feeAssignmentFormstate={this.state.feeAssignmentFormstate} 
                handleChange={this.handleChange}
                handleAddStudent={this.handleAddStudent}
                AddStudentStatus={this.state.AddStudentStatus}
                filterModalStatus={this.state.filterModalStatus}
                handleFilterButton={this.handleFilterButton}
                onCloseFilterPopover={this.onCloseFilterPopover}
                academicYearList={this.props.academicYearList}
                academicClassList={this.props.academicClassList}
                divisionList={this.props.divisionList}
                filterStudenthandleChange={this.filterStudenthandleChange}
                studentListerrorMessage={this.props.studentListerrorMessage}
                studentList={this.props.studentList}
                handelCancel={this.handelCancel}
                handelStudentCancel={this.handelStudentCancel}
                viewForm={this.props.viewForm}
                feeAssignmentData={this.props.feeAssignmentData}
                feeStructureNameList={this.props.feeStructureNameList}
                studentFilterApply={this.studentFilterApply}
                studentFilterFeeStrucure={this.state.studentFilterFeeStrucure}
                studentFilterStatus={this.state.studentFilterStatus}
                filterStudentError={this.state.filterStudentError}
                searchStudentKeyword={this.state.searchStudentKeyword}
                handleSearchStudentChange={this.handleSearchStudentChange}
            />)
        }else  if(step == 1){
            return (<FeeStructureForm 
                feeStructureError={this.state.feeStructureError}
                feeAssignmentFormstate={this.state.feeAssignmentFormstate} 
                handleChange={this.handleChange}
                feeList={this.props.feeList}
                taxList={this.props.taxList}
                handleFeeStructureField={this.handleFeeStructureField}
                viewForm={this.props.viewForm}
                feeAssignmentData={this.props.feeAssignmentData}
                handleRemoveInstallmentStructure={this.handleRemoveInstallmentStructure}
            />)
        }else  if(step == 2 ){
            //if(currentStep ===1 && showForm=== false){
            if(currentForm === "installmentAddForm"){
                return (<InstallmentAddForm 
                    feeAssignmentFormstate={this.state.feeAssignmentFormstate} 
                    handleChange={this.handleChange}
                    handleNext={this.handleNext}
                    classes={this.props.classes}
                />)
            }else{
                if(currentForm === "installmentForm"){
                    //currentStep ===1 && showForm === true){
                    
                    return (<InstallmentForm 
                        maxDueDate={this.state.maxDueDate}
                        minDueDate={this.state.minDueDate}
                        calculateMonth={this.calculateMonth}
                        calculateWeek={this.calculateWeek}
                        calculateYear={this.calculateYear}
                        handleDateChange={this.handleDateChange}
                        feeInstallmentError={this.state.feeInstallmentError}
                        feeAssignmentFormstate={this.state.feeAssignmentFormstate}
                        handleInstallmentForm={this.handleInstallmentForm}
                        invoiceTemplateList={this.props.invoiceTemplateList}
                        emailTemplateList={this.props.emailTemplateList}
                        handleInstallmentCycleField={this.handleInstallmentCycleField}
                        fineList={this.props.fineList}
                        feeInstallmentNameError={this.state.feeInstallmentNameError}
                        feeInstallmentDurationError={this.state.feeInstallmentDurationError}
                        feeInstallmentBillDurationError={this.state.feeInstallmentBillDurationError }
                        feeInstallmentBillingCycleError={ this.state.feeInstallmentBillingCycleError}
                        feeInstallmentConcessionTypeError={this.state.feeInstallmentConcessionTypeError}
                        feeInstallmentConcessionError={this.state.feeInstallmentConcessionError}
                        viewForm={this.props.viewForm}
                        feeAssignmentData={this.props.feeAssignmentData}
                        installmentPlanDetailsData={this.state.installmentPlanDetailsData}
                        installPlanAction={this.state.installPlanAction}
                        updatePlanIndex={this.state.updatePlanIndex}
                    />)
                    
                }
                if(currentForm === "installmentPlanForm"){
                    return (<InstallmentPlans
                        feeAssignmentFormstate={this.state.feeAssignmentFormstate} 
                        handleChange={this.handleChange}
                        viewForm={this.props.viewForm}
                        feeAssignmentData={this.props.feeAssignmentData}
                        handleDetailsPlansForm={this.handleDetailsPlansForm}
                        handleInstallmentPlan={this.handleInstallmentPlan}
                        handleDeleteModalAction={this.handleDeleteModalAction}
                    />)
                }
                
            }
        }
        // else  if(step == 4){
        //     return (<InstallmentPlans
        //         feeAssignmentFormstate={this.state.feeAssignmentFormstate} 
        //         handleChange={this.handleChange}
        //         viewForm={this.props.viewForm}
        //         feeAssignmentData={this.props.feeAssignmentData}
        //         handleDetailsPlansForm={this.handleDetailsPlansForm}
        //         handleInstallmentPlan={this.handleInstallmentPlan}
        //         handleDeleteModalAction={this.handleDeleteModalAction}
        //     />)
        // }
    }
    // Customizable Area End

    render() {
        // Customizable Area Start

        const { open, handleClose } = this.props;
        const { step ,deleteModalOpen,currentForm} = this.state;
        const steps = ['Fee Assignment', 'Fee Structure', 'Installment',];
        // Customizable Area End

        return (
            // Customizable Area Start
            <>
                <CustomStepModal
                    title={steps[step]}
                    handleSubmit={currentForm == 'installmentPlanForm' ? this.handleFeeAssignmentSubmit : this.handleNext}
                    buttonTitle={currentForm == 'installmentPlanForm' ? "Save" : "Next"}
                    open={open}
                    handleClose={(evt: object) => handleClose("create", false)}
                    isError={false}
                    isStep={true}
                    stepLabel={steps}
                    activeStep={step}
                    isHeaderButton={step === 1 || (currentForm == 'installmentPlanForm' && this.props.viewForm ==false) ? true: false}
                    headerButtonTitle={'Add'}
                    headerButtonIcon={<AddIcon/>}
                    handleButtonClick={step === 1 ? this.handleButtonClick :  this.handleInstallmentAddMore}
                    isHideButton={ currentForm === "installmentAddForm" ? true: false}
                    viewForm={this.props.viewForm}
                    currentForm={currentForm}
                >
                    {this.renderStepForm(step,currentForm)}
                </CustomStepModal>
                <CustomDeleteModal DialogueDeletetitle={'Delete Plans'}
                    open={deleteModalOpen}
                    handleClose={this.handleDeleteModalClose}
                    handleSubmit={this.handleModalFormDelete}
                />
           </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start

export const themeCreatStyle = ((theme: any) => ({

    background_color: {
        backgroundColor: theme.palette.primary.main
    },
    title_font: {
        color: theme.props.titleColor,
        fontFamily: theme.props.titleFont
    },
    subTitle_font: {
        color: theme.props.subtitleColor,
        fontFamily: theme.props.subtitleFont
    },
    heading_font: {
        color: theme.props.headingColor,
        fontFamily: theme.props.headingFont
    },
    subHeading_font: {
        color: theme.props.subheadingColor,
        fontFamily: theme.props.subheadingFont
    },
    bodyText_font: {
        color: theme.props.textColor,
        fontFamily: theme.props.textFont
    },
    button_color: {
        backgroundColor: theme.props.buttonColor,
        color: "white",
    },
    icon_color: {
        color: theme.props.iconColor
    },
    button_color_disabled: {
        color: theme.props.buttonColor,
        backgroundColor: "white",
        border: `1px solid ${theme.props.buttonColor}`,
        '&:hover': {
            background: "#fff",
        }
    }
}));
// Customizable Area End

export default withStyles(themeCreatStyle)(
    CreateFeeAssignment as React.ComponentType<any>
)
