import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { HISTORY } from "../../../../components/src/comman";

// Customizable Area Start
import {
  imgPasswordInVisible,
  imgPasswordVisible,
  logo,
  logo1,
  userRolesIcon,
  userIcon,
  roleIcon,
  drawerOpenIcon,
  drawerCloseIcon,
  sidebarTaxIcon,
  sidebarTaxRateIcon,
  concession,
  moduloWhite,
  masterData,
  tax,
  users,
  account,
  sidebarSettingsIcon,
  sidebarOrganizationIcon,
  feeAssignmentIcon,
  feeManagement,
  sidebarCustomersIcon,
  expenseIcon,
  feeMasters,
  organizationIcon,
  fineIcon,
  paymentPreferenceIcon,
  vendorsIcon,
  recurringExpenseIcon,
  salesIcon,
  PurchaseIcon
} from "./assets";

export const configJSON = require("./config");
// Customizable Area End


export interface Props {
  navigation: any;
  // Customizable Area Start
  classes: any;
  handleDrawerClick: any;
  open: boolean;
  classname: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  txtInputValue: string;
  txtSavedValue: string;
  selectedMenuId: string;
  activeMenu: string;
  enableField: boolean;
  logo: any;
  logo1: any;
  userRolesIcon: any;
  userIcon: any;
  roleIcon: any;
  drawerOpenIcon: any;
  drawerCloseIcon: any;
  masterData: any;
  tax: any;
  concession: any;
  moduloWhite: any;
  taxIcon: any;
  taxRatesIcon: any;
  settingsIcon: any;
  organizationIcon: any;
  customersIcon: any;
  fineIcon: any;
  vendorsIcon: any;
  paymentPreferenceIcon: any;
  recurringExpenseIcon: any;
  expenseIcon: any;
  PurchaseIcon: any;
  salesIcon: any;
  users: any;
  account: any;
  sidebarOrganizationIcon: any;
  feeAssignmentIcon: any;
  feeManagement: any;
  feeMasters: any;
  multilevelMenuOpen: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class SidebarController extends BlockComponent<Props, S, SS> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [getName(MessageEnum.AccoutLoginSuccess)];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      logo: logo,
      logo1: logo1,
      userRolesIcon: userRolesIcon,
      userIcon: userIcon,
      roleIcon: roleIcon,
      drawerOpenIcon: drawerOpenIcon,
      drawerCloseIcon: drawerCloseIcon,
      selectedMenuId: "1",
      taxIcon: sidebarTaxIcon,
      taxRatesIcon: sidebarTaxRateIcon,
      masterData: masterData,
      tax,
      concession,
      moduloWhite,
      users,
      account,
      settingsIcon: sidebarSettingsIcon,
      organizationIcon: organizationIcon,
      sidebarOrganizationIcon: sidebarOrganizationIcon,
      feeAssignmentIcon: feeAssignmentIcon,
      feeManagement: feeManagement,
      customersIcon: sidebarCustomersIcon,
      fineIcon,
      PurchaseIcon,
      expenseIcon,
      vendorsIcon,
      salesIcon,
      paymentPreferenceIcon,
      recurringExpenseIcon,
      feeMasters,
      activeMenu: "",
      multilevelMenuOpen: false
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }
    // Customizable Area End
  }

  // Customizable Area Start
  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address"
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed()
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  handleClickMenuItem = (item: any, e: any) => {
    // e.preventDefault();
    this.setState({
      activeMenu: item.id,
      multilevelMenuOpen: false,
    });
    this.props.navigation.navigate(item.href);
  };

  handleMenuClick = (item: any, open: any) => {
    this.setState({
      multilevelMenuOpen: !this.state.multilevelMenuOpen,
      activeMenu: item.id
    });
  };
  // Customizable Area End
}