import React from "react";
// Customizable Area Start
import { withStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Styles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import Layout from '../../CommonLayout/Layout/src/Layout.web';
import Pagination from "../../CommonLayout/Pagination/src/Pagination.web";
import {bulkUpdateIcon} from "./assets";
import CreateFeeAssignment from './CreateFeeAssignment.web';
import { defaultBtn } from "../../CommonLayout/Layout/src/color";
import FeeAssignmentController, {
  Props,
} from "./FeeAssignmentController";
import Loader from "../../../components/src/Loader.web";
import { capitalizeFirst } from "../../../framework/src/Utilities";
import ReactToPrint from 'react-to-print';
import PageHeader from "../../CommonLayout/HRMSCommons/src/layout/PageHeader";
import { classNames } from "../../CommonLayout/HRMSCommons/src/classNames";
import CustomDeleteModal from '../../CommonLayout/CustomDeleteModal/src/CustomDeleteModal.web';
import { FormControl, FormLabel, Select } from "@material-ui/core";
import DatePicker from "react-date-picker";
import OutlinedSelect from "../../../components/src/OutlinedSelect";

const useStyles: Styles<Theme, {}, string> = (theme: Theme) => ({
  searchbar: {
    width: "85%",
    marginLeft: "139px",
  },
  gridContainer: {
    marginTop: "95px",
    marginRight: "8px",
    marginBottom: "20px",
  },menu: {
    marginLeft: "-25px",
    marginTop: "15px",
    width:"100%",
    "& .MuiPaper-root": {
      overflow: "visible",
      // top: "268px !important",
      // left: "1705px !important" 
    }
  },  arrowUp: {
    position: "absolute",
    width: "16px",
    height: "12px",
    top: "-12px",
    zIndex: 1,
   
    '&.left': {
      left: "10px",
    },
    '&.right': {
      right: "10px",
      boxShadow: "0px 29px 8px -3px rgb(0 0 0 / 20%)",
    },
    '&.center': {
      left: "73%",
      transform: "translateX(-50%)",
    },
    '&:before': {
      content: "''",
      position: "absolute",
      top: "0",
      left: "0",
      borderStyle: "solid",
      borderWidth: "0 8px 12px",
      borderColor: "#FFF transparent",
      display: "block",
      width: 0,
      zIndex: 0,
    }
  },
  pageTitle: {
    fontWeight: "bold"
  },
  actionBtn: {
    marginRight: "8px",
    borderRadius: "8px",
  },
  featureBtn: {
    borderRadius: "5px",
    border: `1px solid #ffb2b2`,
    backgroundColor: "#ffffff",
    margin: "10px",
    padding: "18px",
    '&:hover': {
      border: `1px solid ${defaultBtn}`,
      borderColor: defaultBtn,
      color: "white",
      opacity:"1",
    },
    '&:active': {
      border: `1px solid ${defaultBtn}`,
      borderColor: defaultBtn,
      opacity:"1",
      boxShadow: 'none',
      color: "white",
    },'&:focus': {
      border: `1px solid ${defaultBtn}`,
      borderColor: defaultBtn,
    },
  },
  tableContainer: {
    position: "relative",
    flexGrow: 1,
    marginTop: "14px",
    boxShadow: "0px 5px 10px 0px #d9d9d9",
   // height: "calc(100vh - 381px) !important",
    borderRadius: 10
  },
  table: {
    borderCollapse: "separate",
    borderSpacing: "0 10px",
    padding: "0 25px",

    "& td": {
      backgroundColor: "#F9F9F9",
      border: "none"
    }
  },
  tableGrid: {
    minHeight: "calc(100vh - 120px)",
    maxHeight: "calc(100vh - 120px)",
    flexDirection: "column",
    flexWrap: "nowrap",

  },
  heading: {
    width: "81%",
    backgroundColor: "#fcf3d4",
    height: 60,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 20,
    borderRadius: 5
  },
  filterBar: {
    width: "18%",
    height: 60,
    display: "flex",
    alignItems: "center",
    borderRadius: 5,

  },
  bulkUpdateBtn: {
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 16,
    lineHeight: 1.5,
    backgroundColor: "#fffff",
    border: "1px solid #4b4f5f",
    borderRadius: "5px",
    color: "#455366",
  },

  createBtn: {

    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 16,
    padding: '14px 44px',
    outline: 0,
    border: 0,
    width:185,
    lineHeight: 1.5,
    backgroundColor: defaultBtn,
    borderColor: defaultBtn,
    color: "white",
    orderRadius: "10px",
    fontWeight: "bold",
    '&:hover': {
      backgroundColor: defaultBtn,
      borderColor: defaultBtn,
      boxShadow: 'none',
      color: "white",
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: defaultBtn,
      borderColor: defaultBtn,
      color: "white",
      border: "none",
      outline:0,
    },
  },
  menuItem: {
    "&:hover": {
      background: defaultBtn,
      color: "white"
    },
  },
  headingLayout: {
    width: "63%",
    backgroundColor: "#fcf3d4",
    height: "60px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "20px",
    borderRadius: "5px",
  },
  desTextConcat: {
    display: "block",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    width: "190px",
  },
});


interface feeAssignmentAttribute {
  is_class: boolean;
  is_class_and_division:boolean;
  is_group: boolean;
  name: string;
  academic_year: string;
  grade: string;
  division: string;
  fee_type: string;
  custom_id: string;
}

interface OptionItem {
  id: string;
  name: string;
}

interface feeAssignmentData {
  id: number;
  attributes: feeAssignmentAttribute
}
// Customizable Area End

class FeeAssignment extends FeeAssignmentController {

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // localStorage.clear();
    // Customizable Area End
  }
  render() {

    // Customizable Area Start
    console.log('feeassgn+++++++++++++', this.state.feeAssignmentList);
    const { classes } = this.props;
    const { checkBulkUpdateRow, disableBukUpload, allRowsChecked,searchKeyword, page, importExportanchorEl } = this.state;
    // Customizable Area End
    return (
       // Customizable Area Start
      <Layout searchKeyword={searchKeyword} handleGlobalSearch={this.handleSearchChange} navigation={this.props.navigation} >
        <Grid className={classes.tableGrid} container>
            <PageHeader
              createButtonTitle={'Create'}
              onFilterFormSubmit={this.onFilterFormSubmit}
              filterForm={this.renderFilterForm()}
              FilterTitle="Select Fee Assignment"
              title="Fee Assignment" 
              onClickCreateButton={this.onClickCreateFeeAssignment}
              printReference={this.selectorRef}
            />
          
            {/* {!disableBukUpload && <Grid style={{ paddingTop: "20px" }}><Button
              className={classes.bulkUpdateBtn}
                >
                <img style={{marginRight: "6px"}} height="18px" width="18px" src={bulkUpdateIcon} /> Bulk Update
              </Button></Grid>

            } */}
           
              {this.state.feeAssignmentListerrorMessage ?
                <p style={{ color: "red" }}>{this.state.feeAssignmentListerrorMessage}</p>
                :
                <>
                <TableContainer ref={(el: any) => this.selectorRef = el} style={{ overflow: this.state.showLoader ? 'hidden' : '' }} component={Paper} className={classes.tableContainer}>
                  {this.state.showLoader && <Loader loading={this.state.showLoader} positionType={'center'} />}
                  <Table ref={this.tableRef} className={classes.table} aria-label="a dense table">

                  <TableHead style={{position: "sticky",top: "0px", zIndex: 9, }}>
                        <TableRow>
                          <TableCell padding="checkbox">                          
                            <Checkbox
                              indeterminate={(allRowsChecked === "indeterminate")?true:false}
                              onChange={this.handleCheckAllAccount}
                              checked={!!allRowsChecked}
                              inputProps={{ 'aria-label': 'select all desserts' }}
                            />
                          </TableCell>
                          <TableCell>ID</TableCell>
                          <TableCell align="left">Fee Structure Name</TableCell>
                          <TableCell align="left">Academic Year</TableCell>
                          <TableCell align="left">Class</TableCell>
                          <TableCell align="left">Division</TableCell>
                          <TableCell align="left">Fee Type</TableCell>
                          <TableCell align="left"></TableCell>
                        </TableRow>
                      </TableHead>
                     
                        <TableBody>
                          {(this.state.feeAssignmentList && this.state.feeAssignmentList.length) ? this.state.feeAssignmentList.map((row: feeAssignmentData) => (
                            <TableRow key={row.id}>
                              <TableCell padding="checkbox">
                                <Checkbox
                                  checked={(checkBulkUpdateRow && checkBulkUpdateRow[row.id]) ? true : false}
                                  onChange={(e) => this.handleCheckAccountItem(e, row.id)}
                                  inputProps={{ 'aria-label': 'select all desserts' }}
                                />
                              </TableCell>
                              <TableCell>
                                <Grid style={{ display: "flex", alignItems: "center" }}>
                                  <Typography variant="subtitle1" >{row.attributes.custom_id}</Typography>
                                </Grid>
                              </TableCell>
                              <TableCell>
                                <Grid style={{ display: "flex", alignItems: "center" }}>
                                  <Typography variant="subtitle1" >{row.attributes.name ? capitalizeFirst(row.attributes.name): '-'}</Typography>
                                </Grid>
                              </TableCell>
                              <TableCell>
                                <Grid style={{ display: "flex", alignItems: "center" }}>
                                  <Typography variant="subtitle1" >{row.attributes.academic_year ? capitalizeFirst(row.attributes.academic_year): '-'}</Typography>
                                </Grid>
                              </TableCell>
                              <TableCell>
                                <Grid style={{ display: "flex", alignItems: "center" }}>
                                  <Typography variant="subtitle1" >{row.attributes.grade ? capitalizeFirst(row.attributes.grade): '-'}</Typography>
                                </Grid>
                              </TableCell>
                              <TableCell>
                                <Grid style={{ display: "flex", alignItems: "center" }}>
                                  <Typography  variant="subtitle1" >{row.attributes.division ? capitalizeFirst(row.attributes.division): '-'}</Typography>
                                </Grid>
                              </TableCell>
                              <TableCell>
                                <Grid style={{ display: "flex", alignItems: "center" }}>
                                  <Typography  variant="subtitle1" >{row.attributes.fee_type ? capitalizeFirst(row.attributes.fee_type): '-'}</Typography>
                                </Grid>
                              </TableCell>
                              <TableCell>
                                <Grid style={{ display: "flex", alignItems: "center" }}>
                                  <Typography variant="subtitle1" ><IconButton onClick={(e) => this.handleAnchorClick(e, row)} className={classes.actionBtn}><img height="18px" width="4px" src={this.state.actionIcon} /></IconButton></Typography>
                                </Grid>
                              </TableCell>
                            </TableRow>
                          )) : !this.state.showLoader ? 
                          <TableRow>
                            <TableCell colSpan={12} style={{ color: "red", fontWeight: 600,
                        fontSize: "18px",
                           textAlign: "center" }}>{"No Record Found"}</TableCell>
                          </TableRow> : null}
                          {/* <TableRow >
                              <TableCell padding="checkbox">
                                <Checkbox
                                  // checked={(checkBulkUpdateRow && checkBulkUpdateRow[row.id]) ? true : false}
                                  // onChange={(e) => this.handleCheckAccountItem(e, row.id)}
                                  inputProps={{ 'aria-label': 'select all desserts' }}
                                />
                              </TableCell>
                              <TableCell>
                                <Grid style={{ display: "flex", alignItems: "center" }}>
                                  <Typography variant="subtitle1" >{"00001"}</Typography>
                                </Grid>
                              </TableCell>
                              <TableCell>
                                <Grid style={{ display: "flex", alignItems: "center" }}>
                                  <Typography variant="subtitle1" >{"Fee structure 1"}</Typography>
                                </Grid>
                              </TableCell>
                              <TableCell>
                                <Grid style={{ display: "flex", alignItems: "center" }}>
                                  <Typography variant="subtitle1" >{"2022-2023"}</Typography>
                                </Grid>
                              </TableCell>
                              <TableCell>
                                <Grid style={{ display: "flex", alignItems: "center" }}>
                                  <Typography variant="subtitle1" >{"Grade 10"}</Typography>
                                </Grid>
                              </TableCell>
                              <TableCell>
                                <Grid style={{ display: "flex", alignItems: "center" }}>
                                  <Typography className={classes.desTextConcat} variant="subtitle1" >{'A'}</Typography>
                                </Grid>
                              </TableCell>
                              <TableCell>
                                <Grid style={{ display: "flex", alignItems: "center" }}>
                                  <Typography className={classes.desTextConcat} variant="subtitle1" >{ "Academic_fee"}</Typography>
                                </Grid>
                              </TableCell>
                              <TableCell>
                                <Grid style={{ display: "flex", alignItems: "center" }}>
                                  <Typography variant="subtitle1" ><IconButton onClick={(e) => this.handleAnchorClick(e,this.state.feeAssignmentList)} className={classes.actionBtn}><img height="18px" width="4px" src={this.state.actionIcon} /></IconButton></Typography>
                                </Grid>
                              </TableCell>
                            </TableRow>
                            <TableRow >
                              <TableCell padding="checkbox">
                                <Checkbox
                                  // checked={(checkBulkUpdateRow && checkBulkUpdateRow[row.id]) ? true : false}
                                  // onChange={(e) => this.handleCheckAccountItem(e, row.id)}
                                  inputProps={{ 'aria-label': 'select all desserts' }}
                                />
                              </TableCell>
                              <TableCell>
                                <Grid style={{ display: "flex", alignItems: "center" }}>
                                  <Typography variant="subtitle1" >{"00001"}</Typography>
                                </Grid>
                              </TableCell>
                              <TableCell>
                                <Grid style={{ display: "flex", alignItems: "center" }}>
                                  <Typography variant="subtitle1" >{"Fee structure 1"}</Typography>
                                </Grid>
                              </TableCell>
                              <TableCell>
                                <Grid style={{ display: "flex", alignItems: "center" }}>
                                  <Typography variant="subtitle1" >{"2022-2023"}</Typography>
                                </Grid>
                              </TableCell>
                              <TableCell>
                                <Grid style={{ display: "flex", alignItems: "center" }}>
                                  <Typography variant="subtitle1" >{"Grade 10"}</Typography>
                                </Grid>
                              </TableCell>
                              <TableCell>
                                <Grid style={{ display: "flex", alignItems: "center" }}>
                                  <Typography className={classes.desTextConcat} variant="subtitle1" >{'A'}</Typography>
                                </Grid>
                              </TableCell>
                              <TableCell>
                                <Grid style={{ display: "flex", alignItems: "center" }}>
                                  <Typography className={classes.desTextConcat} variant="subtitle1" >{ "Academic_fee"}</Typography>
                                </Grid>
                              </TableCell>
                              <TableCell>
                                <Grid style={{ display: "flex", alignItems: "center" }}>
                                  <Typography variant="subtitle1" ><IconButton onClick={(e) => this.handleAnchorClick(e,this.state.feeAssignmentList)} className={classes.actionBtn}><img height="18px" width="4px" src={this.state.actionIcon} /></IconButton></Typography>
                                </Grid>
                              </TableCell>
                            </TableRow>
                            <TableRow >
                              <TableCell padding="checkbox">
                                <Checkbox
                                  // checked={(checkBulkUpdateRow && checkBulkUpdateRow[row.id]) ? true : false}
                                  // onChange={(e) => this.handleCheckAccountItem(e, row.id)}
                                  inputProps={{ 'aria-label': 'select all desserts' }}
                                />
                              </TableCell>
                              <TableCell>
                                <Grid style={{ display: "flex", alignItems: "center" }}>
                                  <Typography variant="subtitle1" >{"00001"}</Typography>
                                </Grid>
                              </TableCell>
                              <TableCell>
                                <Grid style={{ display: "flex", alignItems: "center" }}>
                                  <Typography variant="subtitle1" >{"Fee structure 1"}</Typography>
                                </Grid>
                              </TableCell>
                              <TableCell>
                                <Grid style={{ display: "flex", alignItems: "center" }}>
                                  <Typography variant="subtitle1" >{"2022-2023"}</Typography>
                                </Grid>
                              </TableCell>
                              <TableCell>
                                <Grid style={{ display: "flex", alignItems: "center" }}>
                                  <Typography variant="subtitle1" >{"Grade 10"}</Typography>
                                </Grid>
                              </TableCell>
                              <TableCell>
                                <Grid style={{ display: "flex", alignItems: "center" }}>
                                  <Typography className={classes.desTextConcat} variant="subtitle1" >{'A'}</Typography>
                                </Grid>
                              </TableCell>
                              <TableCell>
                                <Grid style={{ display: "flex", alignItems: "center" }}>
                                  <Typography className={classes.desTextConcat} variant="subtitle1" >{ "Academic_fee"}</Typography>
                                </Grid>
                              </TableCell>
                              <TableCell>
                                <Grid style={{ display: "flex", alignItems: "center" }}>
                                  <Typography variant="subtitle1" ><IconButton onClick={(e) => this.handleAnchorClick(e,this.state.feeAssignmentList)} className={classes.actionBtn}><img height="18px" width="4px" src={this.state.actionIcon} /></IconButton></Typography>
                                </Grid>
                              </TableCell>
                            </TableRow> */}
                        </TableBody>
                     
                    </Table>
                  </TableContainer>
                  <Menu
                    id="simple-menu"
                    keepMounted
                    anchorEl={this.state.anchorEl}
                    open={Boolean(this.state.anchorEl)}
                    onClose={this.handleAnchorClose}
                    className={classes.menu}
                    anchorOrigin={{ horizontal: -20, vertical: "center" }}
                    
                    >
                    <div className={classNames(classes.arrowUp, "center")}> </div>
                    <MenuItem onClick={() => this.handleModalAction("view", true)} className={classes.menuItem} >View</MenuItem>
                    <MenuItem className={classes.menuItem}  onClick={() => this.handleModalAction("delete", true)} >Delete</MenuItem>
                  </Menu>
                  </>
              }

          <CreateFeeAssignment
            submitLoading={this.state.submitLoading}
            open={this.state.createModalOpen}
            handleClose={this.handleModalAction}
            handleSubmit={this.handleSubmit}
            academicYearList={this.state.academicYearList}
            academicClassList={this.state.academicClassList}
            divisionList={this.state.divisionList}
            studentList={this.state.studentList}
            getStudentList={this.getStudentList}
            handleAcademicDivisionByClass={this.getDivisionList}
            handleStudentsByDivision={this.getStudentList}
            studentListerrorMessage={this.state.studentListerrorMessage}
            feeList={this.state.feeList}
            feeAssignmentList={this.getFeeAssignmentList}
            taxList={this.state.taxList}
            fineList={this.state.fineList}
            invoiceTemplateList={this.state.invoiceTemplateList}
            emailTemplateList={this.state.emailTemplateList}
            feeAssignmentData={this.state.feeAssignmentData}
            viewForm={this.state.viewForm}
            feeStructureNameList={this.state.feeStructureNameList}
          />
          <CustomDeleteModal DialogueDeletetitle={'Delete Fee Assignment'}
            open={this.state.deleteModalOpen}
            handleClose={this.handleDeleteModalClose}
            handleSubmit={this.handleModalFormDelete}
            msgHandle={this.state.deleteMessage}
          />
              <Grid container justify="flex-end" alignItems="center">
              {(this.state.feeAssignmentListMeta && this.state.feeAssignmentListMeta.total_pages > 0) &&
                <Pagination page={page} handlePageClick={this.handlePageClick} totalPageCount={this.state.feeAssignmentListMeta ? this.state.feeAssignmentListMeta.total_pages : 0} />
              }
              </Grid>
        </Grid > 
      </Layout>
     
    );
  }
  private renderFilterForm() {
    const { classes } = this.props;
    const {
      filterForm: {
        division_id,
        grade_id,
        academic_year_id,
        name,
      }
    } = this.state;

    return (
      <Grid
        container
        spacing={2}
        style={{
          // marginTop: "10px"
          padding:'10px'
        }}
      >
        <Grid item style={{  padding:'11px'}} md={6}>
        
          <OutlinedSelect
            placeholderTitle={"Select Name"}
            val={name}
            options={this.state.feeStructureNameList}
            label="Fee Structure Name"
            name={"name"}
            formControlStyle={{
              width: "100%",
              margin: 0
            }}
            handleChange={this.onChangeFilter}
            className="outlineHeight"          />
          
        </Grid>
        <Grid style={{  padding:'11px'}} item md={6}>
          <OutlinedSelect
            placeholderTitle={"Select Year"}
            val={academic_year_id}
            options={this.state.academicYearList}
            label="Academic Year"
            name={"academic_year_id"}
            formControlStyle={{
              width: "100%",
              margin: 0
            }}
            handleChange={this.onChangeFilter}
            className="outlineHeight"  
            
            />
          {/* {this.state.filterRoleError ? <p className="errorMsg2">{this.state.filterRoleError}</p> :null} */}
        </Grid>
        <Grid item style={{  padding:'11px'}} md={6}>
          <OutlinedSelect
            placeholderTitle={"Select Class"}
            val={grade_id}
            options={this.state.academicClassList}
            label="Class"
            name={"grade_id"}
            formControlStyle={{
              width: "100%",
              margin: 0
            }}
            handleChange={this.onChangeFilter}
            className="outlineHeight"          />
          {/* {this.state.filterRoleError ? <p className="errorMsg2">{this.state.filterRoleError}</p> :null} */}
        </Grid>
        <Grid item style={{  padding:'11px'}} md={6}>
          <OutlinedSelect
            placeholderTitle={"Select Division"}
            val={division_id}
            options={this.state.divisionList}
            label="Division"
            name={"division_id"}
            formControlStyle={{
              width: "100%",
              margin: 0
            }}
            handleChange={this.onChangeFilter}
            className="outlineHeight"           />
          {/* {this.state.filterRoleError ? <p className="errorMsg2">{this.state.filterRoleError}</p> :null} */}
        </Grid>
      </Grid>
    );
  }
   // Customizable Area End
}

export default withStyles(useStyles)(FeeAssignment);
