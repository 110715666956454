import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
export const configJSON = require("./config.js");
export interface Props {
    open: boolean;
    handleClose: (type: string, action: boolean) => void;
    classes: any;
    accountTypeList: [];
    parentAccountList: [];
    handleSubmit: (formData: object) => void;
    handleChange: () => void;
    accountCreateerrorMessage: string;
    accountCreateNameerrorMessage: string;
    parentListByAccount :(id: string) => void;
}

interface InitialField {
    account_type_id: string,
    name: string,
    code: string,
    description: string,
    parent_academic_account_id: string,
    is_sub_account: boolean,
}

interface S {
    closeModal: boolean;
    data: any;
    noteHeader: string;
    disabledbutton: boolean;
    loadingSpinner: boolean;
    accountFormstate: InitialField;
    accNameError: string;
    accTypeError: string;
    subAccountStatus: boolean;
    parentAccError: string;
}

interface SS {
    id: any;
}

const accountInitialField: InitialField =
{ account_type_id: "",name: "",code: "",description: "",parent_academic_account_id: "",
    is_sub_account: false
};
// Customizable Area End
export default class CreateAccountController extends BlockComponent<Props, S, SS> {

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [];

        this.state = {
            closeModal: false,
            data: [],
            noteHeader: '',
            disabledbutton: true,
            loadingSpinner: false,
            accountFormstate: accountInitialField,
            accNameError: "",
            accTypeError: "",
            subAccountStatus: false,
            parentAccError: "",
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }
    // Customizable Area Start
    async componentDidMount() {
        super.componentDidMount();
    }

    componentDidUpdate(prevProps: Props) {
        if (prevProps.open !== this.props.open) {
            this.resetFormState();
        }
    }

    resetFormState() {
        this.setState({ accountFormstate: accountInitialField, accNameError: "", accTypeError: "", subAccountStatus: false, parentAccError: ""});
    }

    modalhandleClose = () => {
        this.setState({ closeModal: true })
        this.props.handleClose("create", false);
    }

    handleChange = (e: any) => {
        let value = e.target.value;
        if(e.target.name === "is_sub_account"){
            value = e.target.checked ? true : false;
            if(!e.target.checked){
                this.setState({ accountFormstate: {...this.state.accountFormstate, parent_academic_account_id: "", [e.target.name]: value } ,subAccountStatus: value});
            }else{
                this.setState({ accountFormstate: { ...this.state.accountFormstate, [e.target.name]: value}, subAccountStatus: value  });
            }
        }else{
            if(e.target.name === "account_type_id" || e.target.name === "parent_academic_account_id"){
                value = (e.target.value === "null") ? "" : e.target.value;
                if(e.target.name === "account_type_id"){
                this.props.parentListByAccount(value);
                }
                this.setState({ accountFormstate: { ...this.state.accountFormstate, parent_academic_account_id: (e.target.name === "account_type_id") ? "" : this.state.accountFormstate.parent_academic_account_id,is_sub_account:(e.target.name === "account_type_id") ? false : this.state.accountFormstate.is_sub_account, [e.target.name]: value },subAccountStatus:(e.target.name === "account_type_id") ? false :this.state.subAccountStatus});
            }else{
                this.setState({ accountFormstate: { ...this.state.accountFormstate, [e.target.name]: value } });
            }
        }
    };

    handleValidation = (formState: InitialField) => {
        let isValid = true;
        const regex = /^[^-\s][a-zA-Z0-9_\s-]{2,40}[^\s]$/i;
        if (formState.name === "") {
            this.setState({ accNameError: ('Account name is required') });
            isValid = false;
        }else if (formState.name && regex.test(formState.name) === false) {
            this.setState({
                accNameError: ('Account name is invalid'
                )
            });
            isValid = false;
        } else {
            this.setState({ accNameError: '' });
        }
        if (formState.account_type_id === "") {
            this.setState({
                accTypeError: (
                    'Account type is required'
                )
            });
            isValid = false;
        } else {
            this.setState({ accTypeError: '' });
        }
        if (formState.is_sub_account === true) {
            if (formState.parent_academic_account_id === "") {
                this.setState({
                    parentAccError: (
                        'Parent account is required'
                    )
                });
                isValid = false;
            } else {
                this.setState({ parentAccError: '' });
            }
        } else {
            this.setState({ parentAccError: '' });
        }
        return isValid;
    };

    handleSubmit = (e: any) => {
         e.preventDefault();
        if (this.handleValidation(this.state.accountFormstate)) {
            console.log('my account data',this.state.accountFormstate);
           this.props.handleSubmit({ accountData: this.state.accountFormstate });
        }
    };
    // Customizable Area End
}