import React from "react";
// Customizable Area Start
import { withStyles, Theme } from "@material-ui/core/styles";
import { Styles } from "@material-ui/styles";
import Collapse from "@material-ui/core/Collapse";
import IconButtonMU from "@material-ui/core/IconButton";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import Layout from "../../CommonLayout/Layout/src/Layout.web";
import Pagination from "../../CommonLayout/Pagination/src/Pagination.web";
import { IconButton } from "../../CommonLayout/HRMSCommons/src/button/iconButton";
import { Icon } from "../../CommonLayout/HRMSCommons/src/assets";
import { ActionMenu } from "../../CommonLayout/HRMSCommons/src/actionMenu";
import PageHeader from "../../CommonLayout/HRMSCommons/src/layout/PageHeader";
import Loader from "../../../components/src/Loader.web";
import { classNames } from "../../CommonLayout/HRMSCommons/src/classNames";
import { Slider } from "@material-ui/core";
import OutlinedSelect from "../../../components/src/OutlinedSelect";
import FineForm from "./FineForm.web";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
// Customizable Area End

import FeeCollectionListController, { IFine, Props } from "./FeeCollectionListController";

// Customizable Area Start
const useStyles: Styles<Theme, {}, string> = (theme: Theme) => ({
  container: {
    minHeight: "calc(100vh - 120px)",
    maxHeight: "calc(100vh - 120px)",
    flexDirection: "column",
    flexWrap: "nowrap"
  },
  mt20: {
    marginTop: 20
  },
  actionColumn: {
    maxWidth: "100px",
    width: "100px"
  },
  tableContainer: {
    position: "relative",
    flexGrow: 1,
    boxShadow: "0px 5px 10px 0px #d9d9d9",
    borderRadius: 10
  },
  table: {
    borderCollapse: "separate",
    borderSpacing: "0 10px",
    padding: "0 25px",

    "& td": {
      backgroundColor: "#F9F9F9",
      border: "none"
    }
  },
  actionColumnButton: {
    margin: 0
  },
  checkbox: {
    padding: 0
  },
  tableHead: {
    backgroundColor: "#FCF3D4"
  }
});
// Customizable Area End

class FeeCollectionList extends FeeCollectionListController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes, navigation } = this.props;
    const {
      pageMeta: { total_pages },
      isLoading,
      fines,
      anchorEl,
      actionMenuItems,
      formMode,
      selectAllCheckboxChecked
    } = this.state;

    return (
      <Layout navigation={navigation}>
        <Grid container className={classes.container}>
          <PageHeader
            haveCreateButton={false}
            title="Fee Collection"
            importModalTitle="Import Fee Collection by CSV file"
            printReference={this.printReferance}
            filterForm={this.renderFilterForm()}
            onUploadCSVForImport={this.onUploadCSVForImport.bind(this)}
            onClickExportCSVButton={this.onClickExportCSVButton.bind(this)}
            onFilterFormSubmit={this.onFilterFormSubmit.bind(this)}
            onClickDownloadCSVTemplate={this.onClickExportCSVButton.bind(this)}
          // importErrorMessage={this.state.importErrorMessage}
          />

          <TableContainer
            component={Paper}
            className={classNames(classes.mt20, classes.tableContainer)}
          >
            {isLoading && <Loader loading positionType={"center"} />}

            <Table stickyHeader className={classes.table} aria-label="FeeCollectionList">
              <TableHead>
                <TableRow>
                  <TableCell
                    align="center"
                    className={classNames(
                      classes.actionColumn,
                      classes.tableHead
                    )}
                  />
                  <TableCell
                    align="center"
                    className={classNames(
                      classes.actionColumn,
                      classes.tableHead
                    )}
                  >
                    <Checkbox
                      className={classes.checkbox}
                      inputProps={{ "aria-label": "select all tax reates" }}
                      onChange={this.onSelectAllFines.bind(this)}
                      indeterminate={
                        selectAllCheckboxChecked === "indeterminate"
                      }
                      checked={!!selectAllCheckboxChecked}
                      key={`tax-rate-super-checkbox-${selectAllCheckboxChecked}`}
                    />
                  </TableCell>
                  <TableCell className={classes.tableHead}>Full Name</TableCell>
                  <TableCell className={classes.tableHead}>Class</TableCell>
                  <TableCell className={classes.tableHead}>
                    Division
                  </TableCell>
                  <TableCell className={classes.tableHead}>Total Amount</TableCell>
                  <TableCell className={classes.tableHead}>
                    Fee Structure
                  </TableCell>
                  <TableCell className={classes.tableHead}>Installment</TableCell>
                  <TableCell className={classes.tableHead} align="center" />
                </TableRow>
              </TableHead>
              <TableBody>
                {fines.map((row, i) => {
                  const {
                    id,
                    selected,
                    attributes: {
                      custom_id,
                      name,
                      day,
                      fine_amount,
                      fine_category,
                      mode
                    },
                    sub_fines
                  } = row;
                  return (
                    <>
                      <TableRow key={`fine-${id}`}>
                        <TableCell
                          align="center"
                          className={classes.actionColumn}
                        >
                          <IconButtonMU
                            size="small"
                            aria-label="expand row"
                            onClick={() =>
                              this.setState({
                                openedRow:
                                  this.state.openedRow !== undefined
                                    ? undefined
                                    : i
                              })
                            }
                          >
                            {this.state.openedRow === i ? (
                              <KeyboardArrowDownIcon />
                            ) : (
                              <KeyboardArrowRightIcon />
                            )}
                          </IconButtonMU>
                        </TableCell>
                        <TableCell
                          align="center"
                          className={classes.actionColumn}
                        >
                          <Checkbox
                            className={classes.checkbox}
                            value={selected}
                            checked={selected}
                            inputProps={{ "aria-label": "select tax rate" }}
                            key={`tax-rate-${id}-checkbox-${selected}`}
                            onChange={event => this.onSelectFine(event, row)}
                          />
                        </TableCell>
                        <TableCell>{custom_id}</TableCell>
                        <TableCell>{name}</TableCell>
                        <TableCell>{fine_category}</TableCell>
                        <TableCell>{day}</TableCell>
                        <TableCell>{fine_amount}</TableCell>
                        <TableCell>{mode}</TableCell>
                        <TableCell
                          align="center"
                          className={classes.actionColumn}
                        >
                          <IconButton
                            icon={Icon.Action}
                            borderless
                            className={classes.actionColumnButton}
                            onClick={event =>
                              this.onClickActionButton(event, row)
                            }
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          style={{ paddingBottom: 0, paddingTop: 0 }}
                          colSpan={10}
                        >
                          <Collapse
                            in={this.state.openedRow === i}
                            timeout="auto"
                            unmountOnExit
                          >
                            <Box style={{ margin: 1 }}>
                              <Table
                                stickyHeader
                                className={classes.table}
                                aria-label="FeeDetails"
                              >
                                <TableHead>
                                  <TableRow>
                                    <TableCell
                                      className={classes.tableHead}
                                      colSpan={1}
                                    >
                                      Fee Name
                                    </TableCell>
                                    <TableCell
                                      className={classes.tableHead}
                                      colSpan={1}
                                    >
                                      Total Amount
                                    </TableCell>
                                    <TableCell
                                      className={classes.tableHead}
                                      colSpan={1}
                                    >
                                      Concession Type
                                    </TableCell>
                                    <TableCell
                                      className={classes.tableHead}
                                      colSpan={1}
                                    >
                                      Concession
                                    </TableCell>
                                    <TableCell
                                      className={classes.tableHead}
                                      colSpan={1}
                                    >
                                      ConcessionMode
                                    </TableCell>
                                    <TableCell
                                      className={classes.tableHead}
                                      colSpan={1}
                                    >
                                      Amount After Concession
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {row.sub_fines &&
                                    row.sub_fines.map(subFine => (
                                      <TableRow key={subFine.id}>
                                        <TableCell component="th" scope="row">
                                          {subFine.name}
                                        </TableCell>
                                        <TableCell>
                                          {subFine.fine_category}
                                        </TableCell>
                                        <TableCell align="right">
                                          {subFine.day}
                                        </TableCell>
                                        <TableCell align="right">
                                          {subFine.fine_amount}
                                        </TableCell>
                                        <TableCell align="right">
                                          {subFine.mode}
                                        </TableCell>
                                      </TableRow>
                                    ))}
                                </TableBody>
                              </Table>
                            </Box>
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    </>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>

          <Grid container justify="flex-end" alignItems="center">
            <Pagination
              totalPageCount={total_pages}
              handlePageClick={this.onClickPagination.bind(this)}
            />
          </Grid>
        </Grid>

        <ActionMenu
          anchorEl={anchorEl}
          onClose={this.onCloseActionMenu.bind(this)}
          items={actionMenuItems}
        />

        {/* <FineForm
          {...this.taxRateFormProps[taxRateFormMode]}
          isOpen={this.state.isFormModalOpened}
          initialValues={this.state.taxRateForm}
        /> */}
      </Layout>
    );
    // Customizable Area End
  }

  // Customizable Area Start
  private renderFilterForm() {
    const {
      filterForm: { name, fine_category }
    } = this.state;

    return (
      <Grid container direction="column">
        <Grid
          item
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between"
          }}
        >
          <OutlinedSelect
            val={name}
            options={this.state.fineNames.map((el: string) => ({
              id: el,
              name: el
            }))}
            label="Fine Name"
            name={"fine_name"}
            formControlStyle={{
              width: "150px",
              margin: 0
            }}
            handleChange={this.onChangeFilterFineName.bind(this)}
          />

          <OutlinedSelect
            val={fine_category}
            options={[
              { id: "miscellaneous", name: "Miscellaneous" },
              { id: "late_fine", name: "Late Fine" }
            ]}
            label="Fine Category"
            name={"fine_category"}
            formControlStyle={{
              width: "150px",
              margin: 0
            }}
            handleChange={this.onChangeFilterFineCategory.bind(this)}
          />
        </Grid>
      </Grid>
    );
  }
  // Customizable Area End
}

export default withStyles(useStyles)(FeeCollectionList);
