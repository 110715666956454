import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
export const configJSON = require("./config.js");
import { dateFormateToDDMMYY } from "../../../framework/src/Utilities";
import React from "react";
export interface Props {
    open: boolean;
    handleClose: (type: string, action: boolean) => void;
    classes: any;
    menuList: [];
    handleSubmit: (formData: object) => void;
    handleChange: () => void;
    roleMenuListerrorMessage: string;
    roleCreateerrorMessage: string;
    roleCreateNameerrorMessage: string;
}

interface roleMenuSubmenu {
    id: number;
    title: string;
    permission_category_id: number,
    fullAccessStatus: boolean,
    viewStatus: boolean,
    editStatus: boolean,
    createStatus: boolean,
    deleteStatus: boolean,
    approveStatus: boolean
}

interface roleMenuAttributes {
    id: number;
    title: string;
    menus: roleMenuSubmenu[];
}

interface roleMenuData {
    attributes: roleMenuAttributes;
}

interface prmField {
    menu: string,
    fullAccessStatus: boolean,
    viewStatus: boolean,
    editStatus: boolean,
    createStatus: boolean,
    deleteStatus: boolean,
    approveStatus: boolean,
}

interface InitialField {
    name: string,
    status: string,
    created_date: string,
}

interface rolePerm {
    [key: number]: prmField;
}

interface S {
    closeModal: boolean;
    data: any;
    noteHeader: string;
    disabledbutton: boolean;
    loadingSpinner: boolean;
    roleFormstate: InitialField;
    roleNameError: string;
    statusError: string;
    permissionError: string;
    rolePermissions: rolePerm;
    NameCheck: any;
    StatusCheck: any;
}

interface SS {
    id: any;
}

const todayDate = new Date().toISOString().slice(0, 10);
const roleInitialField: InitialField = { name: '', status: '', created_date: dateFormateToDDMMYY(new Date(todayDate)) };
// Customizable Area End
export default class CreateRolesController extends BlockComponent<Props, S, SS> {

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [];

        this.state = {
            closeModal: false,
            data: [],
            noteHeader: '',
            disabledbutton: true,
            loadingSpinner: false,
            roleFormstate: roleInitialField,
            roleNameError: '',
            statusError: '',
            permissionError: '',
            rolePermissions: {},
            NameCheck: React.createRef(),
            StatusCheck: React.createRef(),
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }
    // Customizable Area Start
    async componentDidMount() {
        super.componentDidMount();
    }

    componentDidUpdate(prevProps: Props) {

        if (prevProps.menuList !== this.props.menuList) {
            let permissions: any = {};
            this.props.menuList.map((item: roleMenuData, index: number) => {
                item.attributes.menus.map((menu: roleMenuSubmenu, index: number) => {
                    permissions[menu.id] = { fullAccessStatus: false, createStatus: false, editStatus: false, viewStatus: false, deleteStatus: false, approveStatus: false };
                });
            });
            this.setState({ rolePermissions: permissions });
        }

        if (prevProps.open !== this.props.open) {
            this.resetFormState();
        }
    }

    resetFormState() {
        this.setState({ roleFormstate: roleInitialField, statusError: "", roleNameError: "", rolePermissions: {},permissionError: "" });
    }

    modalhandleClose = () => {
        this.setState({ closeModal: true })
        this.props.handleClose("create", false);
    }

    handleChange = (e: any) => {
        let value = e.target.value;
        if(e.target.name === "status"){
          value = (e.target.value === "null") ? "" : e.target.value;
        }
        this.setState({ roleFormstate: { ...this.state.roleFormstate, [e.target.name]: value.trimStart() } });
        //this.handleValidation({...this.state.roleFormstate, [e.target.name]: value});
    };

    handlePermissionChange = (e: any, id: number) => {

        let prevState: rolePerm = this.state.rolePermissions;
        if (e.target.name === "fullAccessStatus") {
            prevState[id] = { fullAccessStatus: e.target.checked, createStatus: e.target.checked, editStatus: e.target.checked, viewStatus: e.target.checked, deleteStatus: e.target.checked, menu: "", approveStatus: e.target.checked };
        } else if (e.target.name === "viewStatus" && !e.target.checked) {
            prevState[id] = { fullAccessStatus: e.target.checked, createStatus: e.target.checked, editStatus: e.target.checked, viewStatus: e.target.checked, deleteStatus: e.target.checked, menu: "", approveStatus: e.target.checked };
        } else if (e.target.name !== "viewStatus" && e.target.checked) {
            prevState[id] = { ...prevState[id], [e.target.name]: e.target.checked, ['viewStatus']: e.target.checked };
        } else if (this.state.rolePermissions[id] && this.state.rolePermissions[id].fullAccessStatus && !e.target.checked) {
            prevState[id] = { ...prevState[id], [e.target.name]: e.target.checked, ['fullAccessStatus']: e.target.checked };
        } else {
            prevState[id] = { ...prevState[id], [e.target.name]: e.target.checked };
        }
        this.setState({ rolePermissions: prevState });
        //this.handleValidation(this.state.roleFormstate);
    };

    handleValidation = (formState: InitialField) => {
        let isValid = true;
        
        const regex = /^[^-\s][a-zA-Z0-9_\s-]{2,40}[^\s]$/i;
        if (formState.name === "") {
            this.setState({ roleNameError: ('Role name is required') });
            isValid = false;
        }else if (formState.name && regex.test(formState.name) === false) {
            this.setState({
                roleNameError: ('Role name is invalid'
                )
            });
            isValid = false;
        } else {
            this.setState({ roleNameError: '' });
        }
        if (formState.status === "") {
            this.setState({
                statusError: (
                    'Status is required'
                )
            });
            isValid = false;
        } else {
            this.setState({ statusError: '' });
        }

        // permission validation
        let checkPermisson = false;
        for (const iterator in this.state.rolePermissions) {
            const { fullAccessStatus, viewStatus, deleteStatus, editStatus, createStatus, approveStatus } = this.state.rolePermissions[iterator];
            if ((fullAccessStatus || viewStatus || deleteStatus || editStatus || createStatus || approveStatus)) {
                checkPermisson = true;
                break;
            }
        }
        if (!checkPermisson) {
            this.setState({ permissionError: 'Atleast one permission is required' });
            isValid = false;
        } else {
            this.setState({ permissionError: '' });
        }
        return isValid;
    };

    handleSubmit = (e: any) => {
        e.preventDefault();
        if (this.handleValidation(this.state.roleFormstate)) {
            this.props.handleSubmit({ roleData: this.state.roleFormstate, permissionData: this.state.rolePermissions });
        }
    };
    // Customizable Area End
}