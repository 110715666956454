import React, { FunctionComponent, ReactNode } from "react";
// Customizable Area Start
import { Box, Grid, makeStyles, Popover, Theme, Typography } from "@material-ui/core";
import withStyles, { Styles } from "@material-ui/core/styles/withStyles";
import { Button, ButtonType, ButtonTheme } from "./button/default";
import { IconButton } from "./button/iconButton";
import { Icon } from "./assets";
import PageHeaderController, { Props } from "./layout/PageHeaderController";
import { ActionMenu } from "./actionMenu";
import CustomImportModal from "../../CustomImportModal/src/CustomImportModal.web";
import PropTypes from 'prop-types';
import Slider from '@material-ui/core/Slider';
import Tooltip from '@material-ui/core/Tooltip';
// Customizable Area End

// Customizable Area Start

const useStyles = makeStyles((theme) => ({
  root: {
    width: 320 + theme.spacing(3) * 2,
  },
  margin: {
    height: theme.spacing(3),
  },
}));
const AirbnbSlider = withStyles({
  root:{
  color: '#F15B5B',
  height: 3,
  padding: '13px 0',
  marginBottom: "15px",
  backgroundColor: '#fffff !important',
  },

  thumb: {
    height: 27,
    width: 27,
    marginTop: "-11px",
    backgroundColor: 'white',
    border: '1px solid white',
    boxShadow: '0px 0px 6px 0px #ff8f8f',
    
    '&:hover': {
      boxShadow: '0px 0px 6px 4px #ff8f8f',
    },
    "& .MuiSlider-valueLabel":{
      transform: "translateY(50%) scale(-1)",
      backgroundColor:"white",
      color:"black"
     
    },
    "& .PrivateValueLabel-circle-220":{
      display: "flex",
      transform: "rotate(180deg)",
    },
    '& .bar': {
      height: 9,
      width: 1,
      backgroundColor: '#F15B5B',
      color: '#F15B5B',
      marginLeft: 1,
      marginRight: 1,
    },
  },
  valueLabel: {
    transform: "translateY(50%) scale(-1)", 
    backgroundColor:"white",
    color:"black",
    '& *': {
      display: "flex",
      transform: "rotate(180deg)",
    },
  },
  track: {
    height: 3,
  },
  rail: {
    color: '#ffb5b5',
    height: 3,
  },

})(Slider);
	

function AirbnbThumbComponent(props:any) {
  return (
    <span {...props}>
      <span className="bar" />
      <span className="bar" />
      <span className="bar" />
    </span>
  );
}
function ValueLabelComponent(props: any) {
  const { children, open, value } = props;
  const classes = useStyles();
  return (
    <Tooltip open={open}className="valueLabel" enterTouchDelay={0} style={{backgroundColor:"white"}} placement="bottom-start" title={value}>
      {children}
    </Tooltip>
  );
}
ValueLabelComponent.propTypes = {
  children: PropTypes.element.isRequired,
  open: PropTypes.bool.isRequired,
  value: PropTypes.number.isRequired,
};

// Customizable Area End
export default function CustomizedSlider(props:any) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <AirbnbSlider
        ThumbComponent={AirbnbThumbComponent}
        ValueLabelComponent={ValueLabelComponent}
        valueLabelDisplay="on"
        min={props.min}
        max={props.max}
        step={props.step}
        value={[props.start_range,props.end_range]}
        onChange={props.onChange}
        // ValueLabelComponent={ValueLabelComponent}
      />
    </div>
  );
}