import React from "react";
// Customizable Area Start
import { withStyles, Theme } from "@material-ui/core/styles";
import { Styles } from "@material-ui/styles";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import Layout from "../../CommonLayout/Layout/src/Layout.web";
import Pagination from "../../CommonLayout/Pagination/src/Pagination.web";
import CustomDeleteModal from "../../CommonLayout/CustomDeleteModal/src/CustomDeleteModal.web";
import { IconButton } from "../../CommonLayout/HRMSCommons/src/button/iconButton";
import { Icon } from "../../CommonLayout/HRMSCommons/src/assets";
import { ActionMenu } from "../../CommonLayout/HRMSCommons/src/actionMenu";
import PageHeader from "../../CommonLayout/HRMSCommons/src/layout/PageHeader";
import Loader from "../../../components/src/Loader.web";
import { classNames } from "../../CommonLayout/HRMSCommons/src/classNames";
import OutlinedSelect from "../../../components/src/OutlinedSelect";
import TaxRateForm from "./TaxRateForm.web";
import CommonSlider from "../../CommonLayout/HRMSCommons/src/CommonSlider";
import { FormControl, FormLabel } from "@material-ui/core";
// Customizable Area End

import TaxRatesController, { Props } from "./TaxRatesController";

// Customizable Area Start
const useStyles: Styles<Theme, {}, string> = (theme: Theme) => ({
  container: {
    minHeight: "calc(100vh - 120px)",
    maxHeight: "calc(100vh - 120px)",
    flexDirection: "column",
    flexWrap: "nowrap"
  },
  mt20: {
    marginTop: 20
  },
  actionColumn: {
    maxWidth: "100px",
    width: "100px"
  },
  tableContainer: {
    position: "relative",
    flexGrow: 1,
    boxShadow: "0px 5px 10px 0px #d9d9d9",
    borderRadius: 10
  },
  table: {
    borderCollapse: "separate",
    borderSpacing: "0 10px",
    padding: "10px 25px 0",

    "& td": {
      backgroundColor: "#F9F9F9",
      border: "none"
    }
  },
  actionColumnButton: {
    margin: 0
  },
  checkbox: {
    padding: 0
  },
  tableHead: {
    backgroundColor: "#FCF3D4",
    height: 41
  }
});
// Customizable Area End

class TaxRates extends TaxRatesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes, navigation } = this.props;
    const {
      pageMeta: { total_pages },
      isLoading,
      taxRates,
      anchorEl,
      actionMenuItems,
      deleteModalOpen,
      taxRateFormMode,
      selectAllCheckboxChecked,
      currentPage,
      closeImportModal,
      searchText
    } = this.state;

    return (
      <Layout
        navigation={navigation}
        searchKeyword={searchText}
        handleGlobalSearch={this.onChangeSearchInput.bind(this)}
      >
        <Grid container className={classes.container}>
          <PageHeader
            title="Tax Rates"
            createButtonTitle="Create"
            importModalTitle="Import tax by CSV file"
            printReference={this.printReferance}
            filterForm={this.renderFilterForm()}
            FilterTitle="Filter Tax Rate"
            onClickCreateButton={this.onClickCreateTaxRate.bind(this)}
            onUploadCSVForImport={this.onUploadCSVForImport.bind(this)}
            onClickExportCSVButton={this.onClickExportCSVButton.bind(
              this,
              false
            )}
            onFilterFormSubmit={this.onFilterFormSubmit.bind(this)}
            onClickDownloadCSVTemplate={this.onClickExportCSVButton.bind(
              this,
              true
            )}
            closeImportModal={closeImportModal}
          // importErrorMessage={this.state.importErrorMessage}
          />

          <TableContainer
            style={{ overflow: isLoading ? "hidden" : "" }}
            ref={(el: any) => (this.printReferance = el)}
            component={Paper}
            className={classNames(classes.mt20, classes.tableContainer)}
          >
            {isLoading && <Loader loading positionType={"center"} />}

            <Table
              ref={this.tableRef}
              stickyHeader
              className={classes.table}
              aria-label="tax rates"
            >
              <TableHead>
                <TableRow>
                  <TableCell
                    align="center"
                    className={classNames(
                      classes.actionColumn,
                      classes.tableHead
                    )}
                  >
                    <Checkbox
                      className={classes.checkbox}
                      inputProps={{ "aria-label": "select all tax reates" }}
                      onChange={this.onSelectAllTaxRates.bind(this)}
                      indeterminate={
                        selectAllCheckboxChecked === "indeterminate"
                      }
                      checked={!!selectAllCheckboxChecked}
                      key={`tax-rate-super-checkbox-${selectAllCheckboxChecked}`}
                    />
                  </TableCell>
                  <TableCell className={classes.tableHead}>ID</TableCell>
                  <TableCell className={classes.tableHead}>Tax Name</TableCell>
                  <TableCell className={classes.tableHead}>Tax %</TableCell>
                  <TableCell className={classes.tableHead}>Tax Type</TableCell>
                  <TableCell className={classes.tableHead}>
                    Description
                  </TableCell>
                  <TableCell className={classes.tableHead} align="center" />
                </TableRow>
              </TableHead>
              <TableBody>
                {taxRates.map(row => {
                  let {
                    id,
                    selected,
                    attributes: {
                      custom_id,
                      name,
                      tax_percentage,
                      tax_type,
                      description
                    }
                  } = row;

                  return (
                    <TableRow key={`tax-rate-${id}`}>
                      <TableCell
                        align="center"
                        className={classes.actionColumn}
                      >
                        <Checkbox
                          className={classes.checkbox}
                          value={selected}
                          checked={selected}
                          inputProps={{ "aria-label": "select tax rate" }}
                          key={`tax-rate-${id}-checkbox-${selected}`}
                          onChange={event => this.onSelectTaxRate(event, row)}
                        />
                      </TableCell>
                      <TableCell>{custom_id}</TableCell>
                      <TableCell style={{ textTransform: "uppercase" }}>
                        {name}
                      </TableCell>
                      <TableCell>{tax_percentage}%</TableCell>
                      <TableCell>{tax_type}</TableCell>
                      <TableCell style={{ textTransform: "capitalize" }}>
                        <div style={{ maxWidth: 500, overflow: 'hidden', textOverflow: 'ellipsis' }}>
                          {description}
                        </div>
                      </TableCell>
                      <TableCell
                        align="center"
                        className={classes.actionColumn}
                      >
                        <IconButton
                          icon={Icon.Action}
                          borderless
                          className={classes.actionColumnButton}
                          onClick={event =>
                            this.onClickActionButton(event, row)
                          }
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
                {taxRates.length === 0 && !isLoading && (
                  <TableRow>
                    <TableCell
                      colSpan={12}
                      style={{
                        color: "red",
                        fontWeight: 600,
                        fontSize: "18px",
                        textAlign: "center"
                      }}
                    >
                      {"No Record Found"}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          <Grid container justify="flex-end" alignItems="center">
            <Pagination
              page={currentPage - 1}
              totalPageCount={total_pages}
              handlePageClick={this.onClickPagination.bind(this)}
            />
          </Grid>
        </Grid>

        <ActionMenu
          anchorEl={anchorEl}
          onClose={this.onCloseActionMenu.bind(this)}
          items={actionMenuItems}
          arrowSide="right"
          anchorOrigin={{ vertical: -12, horizontal: -48 }}
        />

        <CustomDeleteModal
          DialogueDeletetitle={"Delete Tax Rate"}
          open={deleteModalOpen}
          handleClose={this.onCloseDeleteModal.bind(this)}
          handleSubmit={this.onSubmitDeleteModal.bind(this)}
          msgHandle={this.state.deleteMessage}
        />

        <TaxRateForm
          {...this.taxRateFormProps[taxRateFormMode]}
          isOpen={this.state.isTaxRateFormModalOpened}
          initialValues={this.state.taxRateForm}
        />
      </Layout>
    );
    // Customizable Area End
  }

  // Customizable Area Start
  private renderFilterForm() {
    const { classes } = this.props;
    const {
      filterForm: { name, tax_type, start_range, end_range },
      taxNames
    } = this.state;

    return (
      <Grid container direction="column">
        <Grid
          item
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between"
          }}
        >
          <OutlinedSelect
            val={name}
            options={taxNames.map(({ attributes: { name } }) => ({
              id: name.toUpperCase(),
              name: name.toUpperCase()
            }))}
            label="Tax Name"
            name={"tax_type"}
            formControlStyle={{
              width: "180px",
              margin: 0
            }}
            handleChange={this.onChangeFilterTaxName.bind(this)}
            className={undefined}
          />

          <OutlinedSelect
            val={tax_type}
            options={[
              { id: "SGST", name: "SGST" },
              { id: "CGST", name: "CGST" },
              { id: "IGST", name: "IGST" },
              { id: "UTGST", name: "UTGST" }
            ]}
            label="Tax Type"
            name={"tax_type"}
            formControlStyle={{
              width: "180px",
              margin: 0
            }}
            handleChange={this.onChangeFilterTaxType.bind(this)}
            className={undefined}
          />
        </Grid>

        <Grid item style={{ marginTop: 20 }}>
          <FormControl style={{ width: "100%" }}>
            <FormLabel style={{ marginBottom: "8px" }}>
              Tax %
            </FormLabel>
            <CommonSlider
              onChange={this.onChangeFilterSliderRange.bind(this)}
              start_range={start_range}
              end_range={end_range}
              min={0}
              max={500}
              minDafultValue={0}
              maxDafultValue={500}
            />
          </FormControl>
        </Grid>
      </Grid>
    );
  }
  // Customizable Area End
}

export default withStyles(useStyles)(TaxRates);
