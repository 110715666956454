import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

// Customizable Area Start
export enum FormMode {
  Create,
  Edit,
  View,
  Copy
}

export interface IFeeForm {
  name: string;
  valid_until: Date;
  academic_account_id: number;
  amount: number;
  tax_ids: Array<number>;
  id?: number;
}

export interface ITax {
  id: number;
  attributes: {
    id: number;
    name: string;
    tax_percentage: number;
  }
}

export interface IAcademicAccount {
  id: number;
  attributes: {
    name: string;
  }
}
// Customizable Area End

export interface Props {
  // Customizable Area Start
  classes?: any;
  isOpen: boolean;
  requestMessage: Message;
  initialValues: IFeeForm | null;
  formMode: FormMode;
  title: string;
  submitLabel: string;
  taxes: Array<ITax>;
  academicAccounts: Array<IAcademicAccount>;
  onClose: () => void;
  onSubmit: (form: IFeeForm) => void;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  form: IFeeForm;
  isSubmitting: boolean;
  formErrors: { [key: string]: string };
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class FeeController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  private initialValues: IFeeForm = {
    academic_account_id: '' as any,
    amount: '' as any,
    name: "",
    valid_until: new Date(),
    tax_ids: []
  };
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      form: { ...(props.initialValues || this.initialValues) },
      isSubmitting: false,
      formErrors: {}
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const callID = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const response = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      if (callID === this.props.requestMessage?.messageId) {
        if (response && response.errors !== undefined) {
          Object.entries(response.errors).forEach(([key, value]) => {
            if (Array.isArray(value)) {
              response.errors[key] = value.map(v => `${this.humanizeText(key)} ${v}`).join(", ");
            }
          });
          this.setState({
            formErrors: {
              ...this.state.formErrors,
              ...response.errors
            },
            isSubmitting: false
          });
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  public componentDidUpdate(prevProps: Props) {
    const { isOpen, initialValues, formMode } = this.props;
    if (prevProps.isOpen !== isOpen) {
      if (isOpen) {
        let form: IFeeForm = { ...this.initialValues };

        if ([FormMode.Edit, FormMode.View, FormMode.Copy].includes(formMode)) {
          form = { ...(initialValues || this.initialValues) };

          form.valid_until = new Date((form.valid_until as any)?.split("T")[0]);
        }

        this.setState({
          form,
          formErrors: {},
          isSubmitting: false
        });
      }
    }
  }

  public onChangeFeeName(element: React.ChangeEvent<{ value: string }>) {
    this.setState({
      form: {
        ...this.state.form,
        name: element.target.value.substring(0, 50)
      }
    });
  }

  public onChangeValidUntil(value: Date) {
    this.setState({
      form: {
        ...this.state.form,
        valid_until: value
      }
    });
  }

  public onChangeFeeAccount(element: React.ChangeEvent<{ value: string }>) {
    this.setState({
      form: {
        ...this.state.form,
        academic_account_id: parseInt(element.target.value)
      }
    });
  }

  public onChangeFeeAmount(element: React.ChangeEvent<{ value: string }>) {
    this.setState({
      form: {
        ...this.state.form,
        amount: parseInt(element.target.value)
      }
    });
  }
  
  public onChangeTaxType(element: React.ChangeEvent<{ value: Array<number> }>) {
    this.setState({
      form: {
        ...this.state.form,
        tax_ids: element.target.value
      }
    });
  }

  public onSubmit() {
    this.setState({ isSubmitting: this.props.formMode !== FormMode.View });

    const formErrors: { [key: string]: string } = {};
    Object.entries(this.state.form)
      .filter(([key, value]) => !['description', 'academic_account_id', 'tax_ids'].includes(key))
      .forEach(([key, value]) => {
        if (value === undefined || value === "" || Array.isArray(value) && value.length === 0) {
          formErrors[key] = `${this.humanizeText(key)} is required`;
        }
      });

    if (Object.keys(formErrors).length > 0) {
      this.setState({ formErrors, isSubmitting: false });

      return;
    }
  
    this.props.onSubmit(this.state.form);
  }

  public handleFormClose() {
    this.props.onClose();
  }

  private humanizeText(text: string) {
    return text
      .replace(/(_|-)/g, " ")
      .replace(/(^\w{1})|(\s{1}\w{1})/g, match => match.toUpperCase());
  }
  // Customizable Area End
}
