import React from "react";
// Customizable Area Start
import { withStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Styles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import CustomDeleteModal from '../../CommonLayout/CustomDeleteModal/src/CustomDeleteModal.web';
import CreateAccount from './CreateAccount.web';
import EditAccount from './EditAccount.web';
import ViewAccount from './ViewAccount.web';
import CopyAccount from './CopyAccount.web';
import BulkUpdateAccount from './BulUpdateAccount.web';
import Layout from '../../CommonLayout/Layout/src/Layout.web';
import { printIcon, importExportIcon } from "./assets";
import Pagination from "../../CommonLayout/Pagination/src/Pagination.web";
import { bulkUpdateIcon } from "./assets";
import { defaultBtn } from "../../CommonLayout/Layout/src/color";
import { classNames } from "../../CommonLayout/HRMSCommons/src/classNames";
import AccountController, {
  Props,
} from "./AccountController";
import Loader from "../../../components/src/Loader.web";
import { capitalizeFirst } from "../../../framework/src/Utilities";
import { ActionMenu } from "../../CommonLayout/HRMSCommons/src/actionMenu";
import ReactToPrint from 'react-to-print';
import PageHeader from "../../CommonLayout/HRMSCommons/src/layout/PageHeader";
// Customizable Area End

// Customizable Area Start

const useStyles: Styles<Theme, {}, string> = (theme: Theme) => ({
  searchbar: {
    width: "85%",
    marginLeft: "139px",
  },
  gridContainer: {
    marginTop: "95px",
    marginRight: "8px",
    marginBottom: "20px"
  }, menu: {
    marginLeft: "-25px",
    marginTop: "15px",
    width:"100%",
    "& .MuiPaper-root": {
      overflow: "visible",
    }
  },
  pageTitle: {
    fontWeight: "bold"
  },
  actionBtn: {
    marginRight: "8px",
    borderRadius: "8px",
  },
  featureBtn: {
    borderRadius: "5px",
    border: `1px solid #ffb2b2`,
    backgroundColor: "#ffffff",
    margin: "10px",
    padding: "18px",
    '&:hover': {
      border: `1px solid ${defaultBtn}`,
      borderColor: defaultBtn,
      color: "white",
      opacity: "1",
    },
    '&:active': {
      border: `1px solid ${defaultBtn}`,
      borderColor: defaultBtn,
      opacity: "1",
      boxShadow: 'none',
      color: "white",
    }, '&:focus': {
      border: `1px solid ${defaultBtn}`,
      borderColor: defaultBtn,
    },
  },
  tableContainer: {
    position: "relative",
    flexGrow: 1,
    marginTop: "14px",
    boxShadow: "0px 5px 10px 0px #d9d9d9",
    // height: "calc(100vh - 381px) !important",
    borderRadius: 10
  },
  table: {
    borderCollapse: "separate",
    borderSpacing: "0 10px",
    padding: "0 25px",

    "& td": {
      backgroundColor: "#F9F9F9",
      border: "none"
    }
  },
  tableGrid: {
    minHeight: "calc(100vh - 120px)",
    maxHeight: "calc(100vh - 120px)",
    flexDirection: "column",
    flexWrap: "nowrap",

  },
  heading: {
    width: "81%",
    backgroundColor: "#fcf3d4",
    height: 60,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 20,
    borderRadius: 5
  },
  filterBar: {
    width: "18%",
    height: 60,
    display: "flex",
    alignItems: "center",
    borderRadius: 5,

  },
  bulkUpdateBtn: {
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 16,
    lineHeight: 1.5,
    backgroundColor: "#fffff",
    border: "1px solid #4b4f5f",
    borderRadius: "5px",
    color: "#455366",
  },

  createBtn: {

    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 16,
    padding: '14px 44px',
    outline: 0,
    border: 0,
    width: 185,
    lineHeight: 1.5,
    backgroundColor: defaultBtn,
    borderColor: defaultBtn,
    color: "white",
    orderRadius: "10px",
    fontWeight: "bold",
    '&:hover': {
      backgroundColor: defaultBtn,
      borderColor: defaultBtn,
      boxShadow: 'none',
      color: "white",
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: defaultBtn,
      borderColor: defaultBtn,
      color: "white",
      border: "none",
      outline: 0,
    },
  },
  menuItem: {
    "&:hover": {
      background: defaultBtn,
      color: "white"
    },
  },
  arrowUp: {
    position: "absolute",
    width: "16px",
    height: "12px",
    top: "-12px",
    zIndex: 1,
   
    '&.left': {
      left: "10px",
    },
    '&.right': {
      right: "10px",
      boxShadow: "0px 29px 8px -3px rgb(0 0 0 / 20%)",
    },
    '&.center': {
      left: "73%",
      transform: "translateX(-50%)",
    },
    '&:before': {
      content: "''",
      position: "absolute",
      top: "0",
      left: "0",
      borderStyle: "solid",
      borderWidth: "0 8px 12px",
      borderColor: "#FFF transparent",
      display: "block",
      width: 0,
      zIndex: 0,
    }
  },
  headingLayout: {
    width: "63%",
    backgroundColor: "#fcf3d4",
    height: "60px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "20px",
    borderRadius: "5px",
  },
  desTextConcat: {
    display: "block",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    width: "190px",
  },
});


interface accountAttribute {
  custom_id: number;
  name: string;
  account_type: string;
  code: string;
  description: string;
}

interface accountData {
  id: number;
  attributes: accountAttribute
}

// Customizable Area End

class Account extends AccountController {

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // localStorage.clear();
    // Customizable Area End
  }
 
  
  render() {

    // Customizable Area Start
    const { classes } = this.props;
    const { checkBulkUpdateRow, disableBukUpload, allRowsChecked, searchKeyword, page, importExportanchorEl } = this.state;
    // Customizable Area End

    return (
      // Customizable Area Start
      //handleGlobalSearch={this.handleSearchChange}
      <Layout searchKeyword={searchKeyword} handleGlobalSearch={this.handleSearchChange} navigation={this.props.navigation} >
        <Grid className={classes.tableGrid} container>
          <PageHeader
            createButtonTitle={'Create'}
            title="Accounts"
            onClickCreateButton={this.onClickCreateAccount}
            haveFilterButton={false}
            printReference={this.selectorRef}
            importModalTitle="Import account by CSV file"
            onClickDownloadCSVTemplate={this.handleDownloadCSVFile}
            onClickExportCSVButton={this.handleExportAccount}
            onUploadCSVForImport={this.importAccountData}
            importErrorMessage={this.state.accountImporterrorMessage}
            closeImportModal={this.state.closeImportModal}

          />


          {/* {!disableBukUpload && <Grid style={{ paddingTop: "20px" }}><Button
              className={classes.bulkUpdateBtn}
                onClick={() => this.setState({ bulkUpdateAccountModalOpen: true })}
                >
                <img style={{marginRight: "6px"}} height="18px" width="18px" src={bulkUpdateIcon} /> Bulk Update
              </Button></Grid>

            }
            */}
          {this.state.accountListerrorMessage ?
            <p className="errorMsg2">{this.state.accountListerrorMessage}</p>
            :
            <>
              <TableContainer style={{ overflow: this.state.showLoader ? 'hidden' : '' }} ref={(el: any) => this.selectorRef = el} component={Paper} className={classes.tableContainer}>
                {this.state.showLoader && <Loader loading={this.state.showLoader} positionType={'center'} />}
                <Table ref={this.tableRef} className={classes.table} aria-label="a dense table">

                  <TableHead style={{ position: "sticky", top: "0px", zIndex: 9, }}>
                    <TableRow>
                      <TableCell padding="checkbox">
                        <Checkbox
                          indeterminate={(allRowsChecked === "indeterminate") ? true : false}
                          onChange={this.handleCheckAllAccount}
                          checked={!!allRowsChecked}
                          inputProps={{ 'aria-label': 'select all desserts' }}
                        />
                      </TableCell>
                      <TableCell>ID</TableCell>
                      <TableCell align="left">Account Name</TableCell>
                      <TableCell align="left">Account Type</TableCell>
                      <TableCell align="left">Account Code</TableCell>
                      <TableCell align="left">Description</TableCell>
                      <TableCell align="left"></TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {(this.state.accountList && this.state.accountList.length) ? this.state.accountList.map((row: accountData) => (
                      <TableRow key={row.id}>
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={(checkBulkUpdateRow && checkBulkUpdateRow[row.id]) ? true : false}
                            onChange={(e) => this.handleCheckAccountItem(e, row.id)}
                            inputProps={{ 'aria-label': 'select all desserts' }}
                          />
                        </TableCell>
                        <TableCell>
                          <Grid style={{ display: "flex", alignItems: "center" }}>
                            <Typography variant="subtitle1" >{row.attributes.custom_id}</Typography>
                          </Grid>
                        </TableCell>
                        <TableCell>
                          <Grid style={{ display: "flex", alignItems: "center" }}>
                            <Typography variant="subtitle1" >{capitalizeFirst(row.attributes.name)}</Typography>
                          </Grid>
                        </TableCell>
                        <TableCell>
                          <Grid style={{ display: "flex", alignItems: "center" }}>
                            <Typography variant="subtitle1" >{capitalizeFirst(row.attributes.account_type)}</Typography>
                          </Grid>
                        </TableCell>
                        <TableCell>
                          <Grid style={{ display: "flex", alignItems: "center" }}>
                            <Typography variant="subtitle1" >{capitalizeFirst(row.attributes.code)}</Typography>
                          </Grid>
                        </TableCell>
                        <TableCell>
                          <Grid style={{ display: "flex", alignItems: "center" }}>
                            <Typography className={classes.desTextConcat} variant="subtitle1" >{row.attributes.description ? capitalizeFirst(row.attributes.description) : ''}</Typography>
                          </Grid>
                        </TableCell>
                        <TableCell>
                          <Grid style={{ display: "flex", alignItems: "center" }}>
                            <Typography variant="subtitle1" ><IconButton onClick={(e) => this.handleAnchorClick(e, row)} className={classes.actionBtn}><img height="18px" width="4px" src={this.state.actionIcon} /></IconButton></Typography>
                          </Grid>
                        </TableCell>
                      </TableRow>
                    )) : !this.state.showLoader ?
                      <TableRow>
                        <TableCell colSpan={12} style={{
                          color: "red",
                          fontWeight: 600,
                          fontSize: "18px",
                          textAlign: "center"
                        }}>
                          {"No Record Found"}
                        </TableCell>
                      </TableRow> : null}
                  </TableBody>

                </Table>
              </TableContainer>
              <Menu
                id="simple-menu"
                keepMounted
                anchorEl={this.state.anchorEl}
                open={Boolean(this.state.anchorEl)}
                onClose={this.handleAnchorClose}
                anchorOrigin={{ horizontal: -20, vertical: "center" }}
                className={classes.menu}
                // style={{margin:"10px"}}
                
              >
              <div className={classNames(classes.arrowUp, "center")}> </div>
              
                <MenuItem className={classes.menuItem} onClick={() => this.handleModalAction("edit", true)}>Edit</MenuItem>
                <MenuItem className={classes.menuItem} onClick={() => this.handleModalAction("copy", true)}>Copy</MenuItem>
                <MenuItem className={classes.menuItem} onClick={() => this.handleModalAction("view", true)}>View</MenuItem>
                <MenuItem className={classes.menuItem} onClick={() => this.handleModalAction("delete", true)}>Delete</MenuItem>
              </Menu>
            </>
          }

          <CreateAccount
            accountCreateerrorMessage={this.state.accountCreateerrorMessage}
            accountCreateNameerrorMessage={this.state.accountCreateNameerrorMessage}
            submitLoading={this.state.submitLoading}
            accountTypeList={this.state.accountTypeList}
            parentAccountList={this.state.parentAccountList}
            parentListByAccount={this.handleParentList}
            open={this.state.createModalOpen}
            handleClose={this.handleModalAction}
            handleSubmit={this.handleModalFormSubmit}
          />
          <EditAccount
            accountUpdateerrorMessage={this.state.accountUpdateerrorMessage}
            accountUpdateNameerrorMessage={this.state.accountUpdateNameerrorMessage}
            open={this.state.editModalOpen}
            handleClose={this.handleModalAction}
            handleSubmit={this.handleModalFormSubmit}
            parentListByAccount={this.handleParentList}
            accountTypeList={this.state.accountTypeList}
            parentAccountList={this.state.parentAccountList}
            accountDetails={this.state.accountDetails}
            accountDetailserrorMessage={this.state.accountDetailserrorMessage}
          />
          <CopyAccount
            accountCreateerrorMessage={this.state.accountCreateerrorMessage}
            accountCreateNameerrorMessage={this.state.accountCreateNameerrorMessage}
            open={this.state.copyModalOpen}
            handleClose={this.handleModalAction}
            handleSubmit={this.handleModalFormSubmit}
            parentListByAccount={this.handleParentList}
            accountTypeList={this.state.accountTypeList}
            parentAccountList={this.state.parentAccountList}
            accountDetails={this.state.accountDetails}
            accountDetailserrorMessage={this.state.accountDetailserrorMessage}
          />
          <CustomDeleteModal DialogueDeletetitle={'Delete Account'}
            open={this.state.deleteModalOpen}
            handleClose={this.handleDeleteModalClose}
            handleSubmit={this.handleModalFormDelete}
            msgHandle={this.state.deleteMessage}
          />
          <ViewAccount
            accountDetails={this.state.accountDetails}
            accountDetailserrorMessage={this.state.accountDetailserrorMessage}
            open={this.state.viewModalOpen}
            handleClose={this.handleModalAction}
            handleSubmit={this.handleModalFormSubmit}
          />
          <BulkUpdateAccount
            submitLoading={this.state.submitLoading}
            open={this.state.bulkUpdateAccountModalOpen}
            handleClose={this.handleModalAction}
            handleSubmit={this.handleModalFormSubmit}
            accountTypeList={this.state.accountTypeList}
            parentAccountList={this.state.parentAccountList}
            checkBulkUpdateId={this.state.checkBulkUpdateRow}
            parentListByAccount={this.handleParentList}
            accountbulkUpdateerrorMessage={this.state.accountbulkUpdateerrorMessage}
          />

          <Grid container justify="flex-end" alignItems="center">
            {(this.state.accountListMeta && this.state.accountListMeta.total_pages > 0) &&
              <Pagination page={page} handlePageClick={this.handlePageClick} totalPageCount={this.state.accountListMeta ? this.state.accountListMeta.total_pages : 0} />
            }
          </Grid>
        </Grid >
      </Layout>
      // Customizable Area End
    );
  }
}
export default withStyles(useStyles)(Account);
