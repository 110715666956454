Object.defineProperty(exports, "__esModule", {
  value: true
});

const urlConfig = require("../../../framework/src/config");

// Customizable Area Start
exports.APIBaseURL = urlConfig.baseURL;
exports.APIContentType = "application/json";

exports.VendoresEndPoint = "vendors";
exports.ExpenseTracking = "bx_block_expensetracking/recurring_expense";
exports.GSTTreatment = "bx_block_profile/business_customers";
exports.CustomeresEndPoint = "bx_block_profile";
// Customizable Area End