import React from "react";
// Customizable Area Start
import { withStyles, Theme } from '@material-ui/core/styles';
import { Styles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import Layout from '../../CommonLayout/Layout/src/Layout.web';
import Pagination from "../../CommonLayout/Pagination/src/Pagination.web"
import CustomDeleteModal from '../../CommonLayout/CustomDeleteModal/src/CustomDeleteModal.web';
import { IconButton } from "../../CommonLayout/HRMSCommons/src/button/iconButton";
import { Icon } from "../../CommonLayout/HRMSCommons/src/assets";
import { ActionMenu } from "../../CommonLayout/HRMSCommons/src/actionMenu";
import PageHeader from "../../CommonLayout/HRMSCommons/src/layout/PageHeader";
import Loader from "../../../components/src/Loader.web";
import { classNames } from "../../CommonLayout/HRMSCommons/src/classNames";
import OutlinedSelect from "../../../components/src/OutlinedSelect";
import Button from '@material-ui/core/Button';
import {bulkUpdateIcon} from "./assets";
import BulkUpdateUsers from './BulUpdateUsers.web';
import CustomImportModal from '../../CommonLayout/CustomImportModal/src/CustomImportModal.web';

// Customizable Area End

import UsersController, { Props } from "./UserListController";
import UserForm from "./UserForm.web";

// Customizable Area Start
const useStyles: Styles<Theme, {}, string> = (theme: Theme) => ({
  container: {
    minHeight: "calc(100vh - 120px)",
    maxHeight: "calc(100vh - 120px)",
    flexDirection: "column",
    flexWrap: "nowrap",
  },
  mt20: {
    marginTop: 20,
  },
  actionColumn: {
    maxWidth: "100px",
    width: "100px",
  },
  tableContainer: {
    position: "relative",
    flexGrow: 1,
    marginTop: "14px",
    boxShadow: "0px 5px 10px 0px #d9d9d9",
    // height: "calc(100vh - 381px) !important",
    borderRadius: 10
  },
  table: {
    borderCollapse: "separate",
    borderSpacing: "0 10px",
    padding: "0 25px",

    "& td": {
      backgroundColor: "#F9F9F9",
      border: "none"
    }
  },
  actionColumnButton: {
    margin: 0
  },
  checkbox: {
    padding: 0,
  },
  tableHead: {
    backgroundColor: "#FCF3D4",
  },
  bulkUpdateBtn: {
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 16,
    lineHeight: 1.5,
    backgroundColor: "#fffff",
    border: "1px solid #4b4f5f",
    borderRadius: "5px",
    color: "#455366",
    margin: "16px 0px 24px 26px",

  },

});
// Customizable Area End

class Users extends UsersController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes, navigation } = this.props;
    const {
      pageMeta: { total_pages },
      isLoading,
      users,
      anchorEl,
      actionMenuItems,
      deleteModalOpen,
      selectAllCheckboxChecked,
      userFormMode,
      userForm,
      isUserFormModalOpened,
      formErrorMessage,
      currentPage,
      userRoleList,
      searchKeyword,
      closeImportModal
    } = this.state;

    return (
      <Layout searchKeyword={searchKeyword} handleGlobalSearch={this.handleSearchChange} navigation={navigation}>
        <Grid container className={classes.container}>
          <PageHeader
          FilterTitle={'Filters'}
            importModalTitle="Import user by CSV file"
            printReference={this.selectorRef}
            onClickDownloadCSVTemplate={this.handleDownloadCSVFile}
            onClickExportCSVButton={this.exportUsersCall.bind(this)}
            onUploadCSVForImport={this.importUserData}
            importErrorMessage={this.state.importErrorMessage}
            createButtonTitle={'Create'}
            title="Users"
            filterForm={this.renderFilterForm()}
            onClickCreateButton={this.onClickCreateUser.bind(this)}
            onFilterFormSubmit={this.onFilterFormSubmit.bind(this)}
            //closeImportModal={closeImportModal}
          />
          
           {selectAllCheckboxChecked && <Grid><Button
                  className={classes.bulkUpdateBtn}
                    onClick={() => this.setState({ bulkUpdateUserModalOpen: true })}
                    >
                    <img style={{marginRight: "6px"}} height="18px" width="18px" src={bulkUpdateIcon} /> Bulk Update
                  </Button></Grid>

            }
          {this.state.userListerrorMessage ?
            <p className="errorMsg2">{this.state.userListerrorMessage}</p>
            :
            <>
              <TableContainer style={{ overflow: isLoading ? 'hidden' : '' }} ref={(el:any) => this.selectorRef = el} component={Paper} className={classes.tableContainer}>
              
                {isLoading && <Loader loading positionType={'center'} />}
                {/* <div className={classNames(classes.mt20, classes.tableContainer)} style={{height:"auto"}}> */}
                <Table ref={this.tableRef} stickyHeader className={classes.table} aria-label="users">
                
                  <TableHead>
                    <TableRow>
                      <TableCell align="center" className={classNames(classes.actionColumn, classes.tableHead)}>
                        <Checkbox
                          className={classes.checkbox}
                          inputProps={{ 'aria-label': 'select all users' }}
                          onChange={this.onSelectAllUsers.bind(this)}
                          indeterminate={selectAllCheckboxChecked === 'indeterminate'}
                          checked={!!selectAllCheckboxChecked}
                          key={`user-super-checkbox-${selectAllCheckboxChecked}`}
                        />
                      </TableCell>
                      <TableCell className={classes.tableHead}>ID</TableCell>
                      <TableCell className={classes.tableHead}>Name</TableCell>
                      <TableCell className={classes.tableHead}>Role</TableCell>
                      <TableCell className={classes.tableHead}>Email</TableCell>
                      <TableCell className={classes.tableHead}>Phone</TableCell>
                      <TableCell className={classes.tableHead}>Status</TableCell>
                      <TableCell className={classes.tableHead} align="center"></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(users && users.length) ? users.map((row) => {
                      const { id, selected, attributes: { custom_id, first_name, role, email, full_phone_number, activated,last_name } } = row;

                      return (
                        <TableRow key={`user-${id}`}>
                          <TableCell align="center" className={classes.actionColumn}>
                            <Checkbox
                              className={classes.checkbox}
                              value={selected}
                              checked={selected}
                              inputProps={{ 'aria-label': 'select user' }}
                              key={`user-${id}-checkbox-${selected}`}
                              onChange={event => this.onSelectUser(event, row)}
                            />
                          </TableCell>
                          <TableCell>{custom_id}</TableCell>
                          <TableCell>{first_name.charAt(0).toUpperCase()+ first_name.slice(1)} {last_name}</TableCell>
                          <TableCell>{role ?role.charAt(0).toUpperCase()+role.slice(1) : null}</TableCell>
                          <TableCell style={{paddingLeft:"0px"}}>{email}</TableCell>
                          <TableCell>{full_phone_number ?"+"+ full_phone_number.slice(0,2)+" "+full_phone_number.slice(2) : null}</TableCell>
                          <TableCell>{activated ? "Active" : "Inactive"}</TableCell>
                          <TableCell align="center" className={classes.actionColumn}>
                            <IconButton
                              icon={Icon.Action}
                              borderless
                              className={classes.actionColumnButton}
                              onClick={event => this.onClickActionButton(event, row)}
                            />
                          </TableCell>
                        </TableRow>
                      )
                    }): !this.state.isLoading ? 
                    <TableRow>
                      <TableCell colSpan={12} style={{  color: "red",
                            fontWeight: 600,
                            fontSize: "18px",
                                textAlign: "center"}}>
                              {"No Record Found"}
                            </TableCell>
                    </TableRow> : null}
                  </TableBody>
                  
                </Table>
                {/* </div> */}
              </TableContainer>
              {(total_pages > 0) &&
                  <Grid container justify="flex-end" alignItems="center">
                  <Pagination page={currentPage} totalPageCount={total_pages} handlePageClick={this.onClickPagination.bind(this)} />
                </Grid>
              }
            </>
          }
        </Grid>

        <ActionMenu
          anchorEl={anchorEl}
          onClose={this.onCloseActionMenu.bind(this)}
          items={actionMenuItems}
        />
        <BulkUpdateUsers
               submitLoading={this.state.isLoading}
               open={this.state.bulkUpdateUserModalOpen}
               handleClose={this.handleModalAction}
               handleSubmit={this.handleModalFormSubmit}
               checkBulkUpdateId={this.state.users}
               userbulkUpdateerrorMessage={this.state.userbulkUpdateerrorMessage}
               roleList={userRoleList}
        />
        <CustomDeleteModal DialogueDeletetitle={'Delete User'}
          open={deleteModalOpen}
          handleClose={this.onCloseDeleteModal.bind(this)}
          handleSubmit={this.onSubmitDeleteModal.bind(this)}
        />
        <UserForm
          {...this.userFormProps[userFormMode]}
          isOpen={isUserFormModalOpened}
          initialValues={userForm}
          editUserPasswordError ={this.state.editUserPasswordError}
          editUserCurrentPasswordError ={this.state.editUserCurrentPasswordError}
          editUserstatusError={this.state.editUserstatusError}
          editUserNameError ={this.state.editUserNameError}
          editUseremailError ={this.state.editUseremailError}
          editUserroleError  ={this.state.editUserroleError}
          editUsermobileError  ={this.state.editUsermobileError}
          errormsg={formErrorMessage}
          roleList={userRoleList}
        />
      </Layout>
    );
    // Customizable Area End
  }

  // Customizable Area Start
  private renderFilterForm() {
    const { classes } = this.props;
    const { filterForm: { role_id, status } } = this.state;

    return (
      <Grid container direction="column">
        <Grid item style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
          <OutlinedSelect
            val={role_id}
            options={this.state.userRoleList}
            label="Roles"
            name={'role'}
            formControlStyle={{
              width: '100%',
              marginTop: "20px"
              // margin: 0
            }}
            handleChange={this.onChangeFilterRole.bind(this)} className={undefined}          />
      {/* {this.state.filterRoleError ? <p className="errorMsg2">{this.state.filterRoleError}</p> :null} */}
          <OutlinedSelect
            val={status}
            options={[
              { id: "true", name: "Active" },
              { id: "false", name: "Inactive" },
            ]}
            label="Status"
            name={'status'}
            formControlStyle={{
              width: '100%',
              // margin: 0
              marginTop: "20px",
              marginBottom: "20px"
            }}
            handleChange={this.onChangeFilterStatus.bind(this)} className={undefined}          />
          {this.state.filterRoleError ? <p className="errorMsg2">{this.state.filterRoleError}</p> :null}
        </Grid>
      </Grid>
    );
  }
  // Customizable Area End
}

export default withStyles(useStyles)(Users);