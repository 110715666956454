import React from "react";
import {
  Button,
  makeStyles,
  MenuItem,
  Select,
  TextField
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import DatePicker from "react-date-picker";
import CustomModal from "../../CommonLayout/CustomModal/src/CustomModal.web";
import OutlinedSelect from "../../../components/src/OutlinedSelect";
import CreateConcessionType from "./CreateConcessionType.web";
import CreateConcessionController, {
  Props
} from "./CreateConcessionController";
const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(0, 2, 0, 0),
    minWidth: 132
  },
  formLabel: {
    display: "flex",
    alignItems: "center",
    backgroundColor: theme.palette.common.white,
    paddingLeft: "1px",
    paddingRight: "1px"
  },

  // select: {
  //   padding: theme.spacing(2, 3),
  // }

  select: {
    padding: theme.spacing(2, 3)
  },
  menuPaper: {
    height: "30vh"
  }
}));

interface roleMenuSubmenu {
  id: number;
  title: string;
  permission_category_id: number;
  fullAccessStatus: boolean;
  viewStatus: boolean;
  editStatus: boolean;
  createStatus: boolean;
  deleteStatus: boolean;
  approveStatus: boolean;
}
interface OptionItem {
  id: string;
  name: string;
}

interface roleMenuAttributes {
  id: number;
  title: string;
  menus: roleMenuSubmenu[];
}

interface roleMenuData {
  attributes: roleMenuAttributes;
}

class CreateConcession extends CreateConcessionController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const {
      ConcessionTypeList,
      open,
      handleClose,
      concessionCreateerrorMessage,
      concessionErrorMessage,
      classes,
      handleSubmitConcessionType,
      handleEditConcessionTypeSubmit,
      selectedConcessionTypeItem,
      handleConcessionTypeEditmodal,
      handleConcessionTypeEditmodalClose,
      handleDeleteConcessionTypeSubmit
    } = this.props;
    const {
      concessionFormstate,
      concessionNameError,
      concessionTypeError,
      concessionValidUntilError,
      concessionModeError,
      concessionAmountError
    } = this.state;

    return (
      <CustomModal
        title={"Create Concession"}
        handleSubmit={this.handleSubmit}
        buttonTitle={"Create"}
        open={open}
        handleClose={(evt: object) => handleClose("create", false)}
        isError={false}
        errormsg={
          concessionCreateerrorMessage ? concessionCreateerrorMessage : ""
        }
      >
        <Grid
          container
          spacing={2}
          style={{
            marginTop: "10px"
          }}
        >
          <Grid item xs={4}>
            <FormControl
              variant="outlined"
              className={classes.formControl}
              style={{ width: "100%" }}
            >
              <FormLabel style={{ marginBottom: "8px" }}>
                Concession Type*
              </FormLabel>
              <Select
              className={concessionTypeError ||concessionErrorMessage.fee_concession_type ?"concessionTypeDropdown errorSelect":"concessionTypeDropdown withoutErrorSelect" }
                // className="concessionTypeDropdown"
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left"
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left"
                  },
                  getContentAnchorEl: null,
                  classes: { paper: classes.menuPaper },
                  className: "concessionTypeDropdown"
                }}
                // style={
                //   concessionTypeError
                //     ? {
                //         border: "1px solid #d74a3b",
                //         outline: "0px",
                //         width: "100%"
                //       }
                //     : {
                //         color: "black",
                //         border: "none",
                //         width: "100%",
                //         paddingRight: "0px"
                //       }
                // }
                labelId={`label-${"Concession Type"}`}
                id={`select-outlined-${"Concession Type"}`}
                name={"fee_concession_type_id"}
                value={concessionFormstate.fee_concession_type_id}
                onChange={this.handleChange}
                placeholder="Select Type"
                labelWidth={0}
              >
                <MenuItem value="0" disabled={false}>
                  {"Select Type"}
                </MenuItem>
                {ConcessionTypeList &&
                  ConcessionTypeList.map((item: OptionItem, index: number) => (
                    <MenuItem key={index} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                <MenuItem value="-1" disabled={false}>
                  <Button
                    onClick={this.props.handleConcessionTypemodalOpen}
                    style={{
                      width: "100%",
                      backgroundColor: "#feeaeb",
                      fontSize: "13px",
                      textTransform: "none",
                      borderRadius: "6px",
                      height: "40px"
                    }}
                  >
                    Create Concession Type
                  </Button>
                </MenuItem>
              </Select>
              {concessionErrorMessage.fee_concession_type && (
                <p className="errorMsg2">
                  {concessionErrorMessage.fee_concession_type}
                </p>
              )}
              {concessionTypeError && (
                <p className="errorMsg2">{concessionTypeError}</p>
              )}
            </FormControl>

            <CreateConcessionType
              ConcessionTypeList={ConcessionTypeList}
              open={this.props.handleConcessionTypemodal}
              concessionTypeCreateerrorMessage={this.props.concessionTypeCreateerrorMessage}
              handleClose={this.props.handleConcessionTypemodalClose}
              handleSubmit={handleSubmitConcessionType}
              handleEditConcessionTypeSubmit={handleEditConcessionTypeSubmit}
              importerrorMessage={""}
              selectedConcessionTypeItem={selectedConcessionTypeItem}
              handleConcessionTypeDeletemodalClose={
                this.props.handleConcessionTypeDeletemodalClose
              }
              handleConcessionTypeDeletemodal={
                this.props.handleConcessionTypeDeletemodal
              }
              handleConcessionTypeEditmodal={handleConcessionTypeEditmodal}
              handleConcessionTypeEditmodalClose={
                handleConcessionTypeEditmodalClose
              }
              handleConcessionTypemodal={this.props.handleConcessionTypemodal}
              handleConcessionTypemodalClose={
                this.props.handleConcessionTypemodalClose
              }
              handleConcessionTypeEditmodalOpen={
                this.props.handleConcessionTypeEditmodalOpen
              }
              handleConcessionTypeDeletemodalOpen={
                this.props.handleConcessionTypeDeletemodalOpen
              }
              handleConcessionTypemodalOpen={
                this.props.handleConcessionTypemodalOpen
              }
              handleDeleteConcessionTypeSubmit={
                handleDeleteConcessionTypeSubmit
              }
            />
          </Grid>
          <Grid item xs={4}>
            <FormControl style={{ minWidth: "100%" }}>
              <FormLabel style={{ marginBottom: "8px" }}>
                Concession Name*
              </FormLabel>
              <TextField
                value={concessionFormstate.name}
                type={"text"}
                placeholder={`Enter Name`}
               
                variant="outlined"
                name={"name"}
                className={concessionNameError ||concessionErrorMessage.name ?"errorInput":"withoutError" }
                onChange={this.handleChange}
              />
              {concessionNameError && (
                <p className="errorMsg2">{concessionNameError}</p>
              )}
              {concessionErrorMessage.name && (
                <p className="errorMsg2">{concessionErrorMessage.name}</p>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl style={{ minWidth: "100%" }}>
              <FormLabel style={{ marginBottom: "8px" }}>
                Valid Until*
              </FormLabel>
              <DatePicker
              className={concessionValidUntilError ||concessionErrorMessage.valid_until ?"errorDate":"withoutErrorDate"}
              dayPlaceholder="DD"
              monthPlaceholder="MM"
              yearPlaceholder="YYYY"
              onChange={this.handleDateChange}
            //   style={
            //     concessionValidUntilError
            //     ? {
            //         border: "1px solid #d74a3b",
            //         outline: "0px",
            //         width: "100%"
            //       }
            //     : {
            //         border: "none",
            //         width: "100%"
            //       }
            // }
                calendarClassName="Calender"
                name={"valid_until"}
                value={concessionFormstate.valid_until}
                minDate={new Date()}
                calendarIcon={<img style={{height:"27px"}
              }src={this.state.calenderIcon} />               }
              />
              
              {/* <TextField
                placeholder={`Select date`}
                value={concessionFormstate.valid_until}
                // minDate={}
                style={
                    concessionValidUntilError
                    ? {
                        border: "1px solid #d74a3b",
                        outline: "0px",
                        width: "100%"
                      }
                    : {
                        border: "none",
                        width: "100%"
                      }
                }
                color={"primary"}
                variant="outlined"
                type={"date"}
                InputLabelProps={{ shrink: true }}
                name={"valid_until"}
                onChange={this.handleChange}
              /> */}
              {/* <span style={{position: "absolute",
    top: "37px",
    left: "251px"}}><img style={{height:"27px"}
    } src={this.state.calenderIcon} /></span> */}
            </FormControl>
            {concessionErrorMessage.valid_until && (
              <p className="errorMsg2">
                {concessionErrorMessage.valid_until}
              </p>
            )}
            {concessionValidUntilError && (
              <p className="errorMsg2">{concessionValidUntilError}</p>
            )}
          </Grid>
          <Grid item xs={4}>
            <FormControl style={{ minWidth: "100%" }}>
              <FormLabel style={{ marginBottom: "8px" }}>
                Concession Amount*
              </FormLabel>
              <TextField
                
                value={concessionFormstate.amount}
                type={"number"}
                placeholder={`Concession Amount`}
                className={concessionAmountError ||concessionErrorMessage.amount ?"errorInput":"withoutError" }
                variant="outlined"
                name={"amount"}
                onChange={this.handleChange}
              />
            </FormControl>
            {concessionErrorMessage.amount && (
              <p className="errorMsg2">{concessionErrorMessage.amount}</p>
            )}
            {concessionAmountError && (
              <p className="errorMsg2">{concessionAmountError}</p>
            )}
          </Grid>
          <Grid item xs={4}>
            <OutlinedSelect
              val={concessionFormstate.mode}
              options={[
                { id: "amount", name: "Amount" },
                { id: "percentage", name: "Percentage" }
              ]}
              label="Mode*"
              name={"mode"}
              handleChange={this.handleChange}
              className={concessionModeError ||concessionErrorMessage.mode ?"errorSelect":"withoutErrorSelect" }
            />
            {concessionErrorMessage.mode && (
              <p className="errorMsg2">{concessionErrorMessage.mode}</p>
            )}
            {concessionModeError && (
              <p className="errorMsg2">{concessionModeError}</p>
            )}
          </Grid>
        </Grid>
      </CustomModal>
    );
  }
}

export const themeCreatStyle = (theme: any) => ({
  background_color: {
    backgroundColor: theme.palette.primary.main
  },
  title_font: {
    color: theme.props.titleColor,
    fontFamily: theme.props.titleFont
  },
  subTitle_font: {
    color: theme.props.subtitleColor,
    fontFamily: theme.props.subtitleFont
  },
  heading_font: {
    color: theme.props.headingColor,
    fontFamily: theme.props.headingFont
  },
  subHeading_font: {
    color: theme.props.subheadingColor,
    fontFamily: theme.props.subheadingFont
  },
  bodyText_font: {
    color: theme.props.textColor,
    fontFamily: theme.props.textFont
  },
  button_color: {
    backgroundColor: theme.props.buttonColor,
    color: "white"
  },
  icon_color: {
    color: theme.props.iconColor
  },
  button_color_disabled: {
    color: theme.props.buttonColor,
    backgroundColor: "white",
    border: `1px solid ${theme.props.buttonColor}`,
    "&:hover": {
      background: "#fff"
    }
  }
});

export default withStyles(themeCreatStyle)(
  CreateConcession as React.ComponentType<any>
);
