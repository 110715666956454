import React from "react";
// Customizable Area Start
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withStyles, Theme } from '@material-ui/core/styles';
import { formSubmitBtn, formCancelBtn } from "../../Layout/src/color";
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import { Styles } from '@material-ui/styles';
import GetAppIcon from '@material-ui/icons/GetApp';
// Customizable Area End
// Customizable Area Start
import CustomImportModalController, {
  Props
} from "./CustomImportModalController";

import {
  StyleSheet,
  Platform
} from "react-native";
import { Grid, Typography } from "@material-ui/core";

const useStyles: Styles<Theme, {}, string> = (theme: Theme) => ({
  dialogPaper: {
    // maxWidth: "188vh",
    // minHeight: "286px",
    borderRadius: "26px",

  },
  paperWidthSm: {
    minWidth: "300px",
    maxHeight: "725px",
  },
  activeBtn: {
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 16,
    padding: '8px 38px',
    border: '1px solid',
    lineHeight: 1.5,
    background: formSubmitBtn,
    //borderColor: '#ea5c6b',
    color: "white",
  },

  subTitle: {
    marginBottom: "18px",
  },
  // containBox: {
  //   padding: "30px",
  //   margin: "4px"
  // }
});
// Customizable Area End
class CustomImportModal extends CustomImportModalController {
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { fileError, importFile, downloadIcon } = this.state;
    const { open, handleClose, handleSubmit, classes, title, handleDownloadCSV, importerrorMessage } = this.props;
    // Customizable Area End
    return (
      // Customizable Area Start
      //Merge Engine DefaultContainer
      <>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="simple-dialog-title"
          classes={{ paper: this.props.classes.dialogPaper }}
        >
          {title &&
            <DialogTitle style={{ textAlign: "left" }} id="alert-dialog-title">
              <div style={{
                fontWeight: "bold",
                fontSize: "18px",
                borderBottom: "2px solid #f1f1f1",
                paddingBottom: "14px",
              }}>{title}</div>
            </DialogTitle>
          }
          <DialogContent style={{ textAlign: "left", padding: "26px" }}>
            <Grid>
              <FormControl style={{ minWidth: "100%",marginBottom: importerrorMessage ? '0px' : '20px' }}>
                <TextField
                  type={'file'}
                  style={{
                    border: "none",
                    width: '55%',
                  }}
                  variant="outlined"
                  name={'file'}
                  onChange={(e) => this.handleFileChange(e)}
                />
                {fileError && <p style={{color: "red"}}>{fileError}</p>}
                {importerrorMessage && <p style={{color: "red"}}>{importerrorMessage}</p>}
              </FormControl>
              
              <Typography className={classes.subTitle}>Download a sample csv template to see an example of the format.</Typography>
                <Button onClick={handleDownloadCSV} className={classes.activeBtn}>Download <GetAppIcon style={{   
                  width: "22px",
                  height: "20px",
                  margin: "2px",}}/></Button>
                {/* ><img height={18} width={18} src={downloadIcon}/> */}
            </Grid>
          </DialogContent>
          <DialogActions style={{
            justifyContent: "flex-end",
            margin: "0px 24px 24px 24px",
            // padding: "20px"
          }}>
            <Button style={{
              boxShadow: 'none',
              textTransform: 'none',
              fontSize: 16,
              padding: '8px 45px',
              border: '1px solid',
              lineHeight: 1.5,
              backgroundColor: formCancelBtn,
              borderColor: '#ea5c6b',
              color: "#000",
              marginRight: "20px",
            }}
              onClick={handleClose}>
              Cancel
            </Button>
            <Button
              className={classes.activeBtn}
              onClick={() => handleSubmit(importFile)}
            >
              Upload
            </Button>
          </DialogActions>
        </Dialog>
      </>
      //Merge Engine End DefaultContainer
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 16,
    marginLeft: "auto",
    marginRight: "auto",
    width: Platform.OS === "web" ? "75%" : "100%",
    maxWidth: 650,
    backgroundColor: "#ffffffff"
  }
});

// Customizable Area End

export default withStyles(useStyles)(CustomImportModal);
