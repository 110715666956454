import React from "react";
// Customizable Area Start
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withStyles, Theme } from '@material-ui/core/styles';
import { formSubmitBtn, formCancelBtn } from "../../CommonLayout/Layout/src/color";
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import { Styles } from '@material-ui/styles';
import GetAppIcon from '@material-ui/icons/GetApp';
// Customizable Area End

import EditConcessionTypeController, {
  Props
} from "./EditConcessionTypeController";

import {
  StyleSheet,
  Platform
} from "react-native";
import { Checkbox, FormLabel, Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@material-ui/core";
import { capitalizeFirst } from "framework/src/Utilities";
import moment from "moment";
import { classNames } from "react-select/src/utils";
import Loader from "../../../components/src/Loader.web";

const useStyles: Styles<Theme, {}, string> = (theme: Theme) => ({
  dialogPaper: {
    // maxWidth: "188vh",
    // minHeight: "286px",
    borderRadius: "26px",

  },
  paperWidthSm: {
    minWidth: "300px",
    maxHeight: "725px",
  },
  tableContainer: {
    position: "relative",
    flexGrow: 1,
  
    marginTop:"14px",
    boxShadow: "0px 5px 10px 0px #d9d9d9",
    height: "300px !important",
    borderRadius: 10
  }, 
  table: {
    borderCollapse: "separate",
    // borderSpacing: "0 10px",
    // padding: "0 25px",
  
  
  },
  tableHead: {
    padding:"15px",
    backgroundColor: "#FCF3D4",
    fontSize:"16px"
  },
  tableGrid: {
    paddingTop: "20px",
  },
  activeBtn: {
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 16,
    padding: '8px 38px',
    border: '1px solid',
    lineHeight: 1.5,
    background: formSubmitBtn,
    //borderColor: '#ea5c6b',
    color: "white",
    '&:hover': {
        background: formSubmitBtn,
      },
      '&:active': {
        boxShadow: 'none',
        border: "none",
        outline:0,
      },
  },

  subTitle: {
    marginBottom: "18px",
  },
  // containBox: {
  //   padding: "30px",
  //   margin: "4px"
  // }
});

class EditConcessionType extends EditConcessionTypeController {
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area Start

  // Customizable Area End

  render() {
    const {concessionTypeForm,
           concessionType,
           concessionDescription,
           concessionTypeError,
           concessionDescriptionError } = this.state;
    const { open, handleCloseTypeEditmodal, handleSubmit, classes  } = this.props;
    return (
      //Merge Engine DefaultContainer
      <>
        <Dialog
          open={open}
          onClose={handleCloseTypeEditmodal}
          aria-labelledby="simple-dialog-title"
          style={{border:"1px solid white",borderRadius:"10px" }}
          className=''
          classes={{ paper: this.props.classes.dialogPaper }}
        >
            <DialogTitle style={{ textAlign: "left" }} id="alert-dialog-title">
              <div style={{
                fontWeight: "bold",
                fontSize: "18px",
                borderBottom: "2px solid #f1f1f1",
                paddingBottom: "14px",
              }}>Edit Concession Type</div>
            </DialogTitle>
          
          <DialogContent>
            <Grid item>
              <FormControl style={{ minWidth: "100%" }}>
              <FormLabel style={{ marginBottom: "8px" }}>
                    Enter Concession Type
                </FormLabel>
                <TextField
                className={concessionTypeError ?"input_type1 errorInput":"input_type1 withoutError" }
                value={concessionTypeForm.fee_concession_type}
                placeholder={`Enter Concession Type`}
                  type={'text'}
                  style={ { width: "500px", marginBottom: "32px",} }
                  
                  fullWidth
                  variant="outlined"
                  name='fee_concession_type'
                  onChange={(e) => this.handleChange(e)}
                />
              </FormControl>
              
              {concessionTypeError && <p style={{color: "red"}}>{concessionTypeError}</p>}
              </Grid>
              <Grid item >
            <FormControl style={{ minWidth: "100%" }}>
                <FormLabel style={{ marginBottom: "8px" }}>
                    Description
                </FormLabel>
                <TextField
                    value={concessionTypeForm.description}
                    className="input_type"
                    type={'text'}
                    placeholder={`Enter Description`}
                    multiline={true}
                    style={{
                            border: "none",
                            width: '500px',
                            padding:"0px",
                            marginBottom: "32px",
                        }}
                    variant="outlined"
                    name={'description'}
                    fullWidth
                    onChange={this.handleChange}
                />
            </FormControl>
                {/* ><img height={18} width={18} src={downloadIcon}/> */}
            </Grid>
         </DialogContent>
          <DialogActions style={{
            justifyContent: "center",
            margin: "0px 24px 24px 24px",
            // padding: "20px"
          }}>
            <Button style={{
              boxShadow: 'none',
              textTransform: 'none',
              fontSize: 16,
              padding: '8px 45px',
              border: '1px solid',
              lineHeight: 1.5,
              backgroundColor: formCancelBtn,
              borderColor: '#ea5c6b',
              color: "#000",
              marginRight: "20px",
            }}
              onClick={handleCloseTypeEditmodal}>
              Cancel
            </Button>
            <Button
              className={classes.activeBtn}
              onClick={this.handleSubmit}
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </>
      //Merge Engine End DefaultContainer
    );
  }
}

// Customizable Area Start
const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 16,
    marginLeft: "auto",
    marginRight: "auto",
    width: Platform.OS === "web" ? "75%" : "100%",
    maxWidth: 650,
    backgroundColor: "#ffffffff"
  }
});

// Customizable Area End

export default withStyles(useStyles)(EditConcessionType);
