import React, { FunctionComponent, ReactNode } from "react";
// Customizable Area Start
import { Grid, Popover, Theme, Typography } from "@material-ui/core";
import withStyles, { Styles } from "@material-ui/core/styles/withStyles";
import { Button, ButtonType, ButtonTheme } from "../button/default";
import { IconButton } from "../button/iconButton";
import { Icon } from "../assets";
import ReactToPrint from "react-to-print";

// Customizable Area End

import PageHeaderController, { Props } from "./PageHeaderController";
import { ActionMenu } from "../actionMenu";
import CustomImportModal from "../../../CustomImportModal/src/CustomImportModal.web";
import { leftIcon } from "../../../Pagination/src/assets";

// Customizable Area Start
// Customizable Area End

// Customizable Area Start
const styles: Styles<Theme, {}, string> = (theme: Theme) => ({
  pageTitle: {
    fontWeight: "bold"
  },
  createButton: {
    marginRight: 10,
    marginLeft: 30,
    height: 52
  },
  editButton: {
    marginRight: 0,
    marginLeft: 30,
    height: 60
  },
  addButton: {
    marginRight: 0,
    marginLeft: 30,
    height: 40,
    width: 130
  },
  heading: {
    backgroundColor: "#fcf3d4",
    height: 60,
    display: "flex",
    flexGrow: 1,
    justifyContent: "space-between",
    alignItems: "center",
    padding: 20,
    borderRadius: 5
  },
  actionBar: {
    height: 60,
    display: "flex",
    alignItems: "center",
    diredction: "row"
  },
  filterTitle: {
    color: "#FC585D",
    borderBottom: "1px solid #CCC",
    marginBottom: 10
  },
  actionButtons: {
    display: "flex",
    justifyContent: "space-around",
    marginTop: 10
  },
  iconButton: {
    marginRight: 0,
    marginLeft: 20,
    borderRadius: "5px",
    border: `1px solid #ffb2b2`,
    backgroundColor: "#ffffff",
    margin: "10px",
    padding: "18px",
    "&:hover": {
      border: `1px solid ${ButtonTheme.PrimaryBackground}`,
      borderColor: ButtonTheme.PrimaryBackground,
      color: "white",
      opacity: "1"
    },
    "&:active": {
      border: `1px solid ${ButtonTheme.PrimaryBackground}`,
      borderColor: ButtonTheme.PrimaryBackground,
      opacity: "1",
      boxShadow: "none",
      color: "white"
    },
    "&:focus": {
      border: `1px solid ${ButtonTheme.PrimaryBackground}`,
      borderColor: ButtonTheme.PrimaryBackground
    }
  },
  icon: { width: "15px", marginRight: "20px" }
});
// Customizable Area End

class PageHeader extends PageHeaderController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End

  render() {
    // Customizable Area Start
    const {
      classes,
      title,
      filterForm,
      haveCreateButton,
      haveAddButton,
      createButtonTitle,
      haveCSVButton,
      haveFilterButton,
      havePrintButton,
      haveActionBar,
      haveEditButton,
      printReference,
      importModalTitle,
      importErrorMessage,
      FilterTitle,
      onClickAddButton,
      onClickCreateButton,
      onClickEditButton
    } = { ...this.defaults, ...this.props };
    const { CSVAnchorEl, filterPopoverAnchorEl, importCSVModal } = this.state;

    return (
      <Grid container justify={"space-between"}>
        <Grid item className={classes.heading}>
          <Typography variant="subtitle1" className={classes.pageTitle}>
            {title}
          </Typography>
          {haveAddButton ? (
            <Grid
              item
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <Button
                title="ADD"
                startIcon={
                  <img
                    className={classes.icon}
                    src={(Icon.plus as unknown) as string}
                  />
                }
                buttonType={ButtonType.Primary}
                className={classes.addButton}
                onClick={onClickAddButton}
              />
            </Grid>
          ) : null}
        </Grid>
        {haveCreateButton ? (
          <Grid
            item
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <Button
              title={createButtonTitle}
              buttonType={ButtonType.Primary}
              className={classes.createButton}
              onClick={onClickCreateButton}
            />
          </Grid>
        ) : null}
        {haveActionBar ? (
          <Grid item className={classes.actionBar}>
            {haveFilterButton && (
              <IconButton
                icon={filterPopoverAnchorEl ? Icon.FilterActivated : Icon.Filter}
                onClick={this.onClickFilterButton.bind(this)}
                className={classes.iconButton}
              />
            )}
            {havePrintButton && (
              <ReactToPrint
                trigger={() => (
                  <IconButton
                    className={classes.iconButton}
                    icon={Icon.Printer}
                  />
                )}
                onBeforeGetContent={async () => {
                  const promise = new Promise(res =>
                    setTimeout(() => res(true), 500)
                  );
                  if (this.props.onClickPrint) this.props.onClickPrint();
                  return promise;
                }}
                onAfterPrint={this.props.onAfterPrint}
                content={() => printReference}
              />
            )}
            {haveCSVButton && (
              <IconButton
                icon={CSVAnchorEl ? Icon.CSVActivated : Icon.CSV}
                onClick={this.onClickCSVButton.bind(this)}
                className={classes.iconButton}
              />
            )}
          </Grid>
        ) : null}
        {haveEditButton ? (
          <Grid
            item
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <Button
              title="Edit"
              buttonType={ButtonType.Primary}
              className={classes.editButton}
              onClick={onClickEditButton}
            />
          </Grid>
        ) : null}

        <ActionMenu
          anchorEl={CSVAnchorEl}
          onClose={this.onCloseCSVMenu.bind(this)}
          items={this.CSVMenuItems}
          arrowSide={"right"}
          anchorOrigin={{ vertical: "bottom", horizontal: -70 }}
        />

        <Popover
          open={!!filterPopoverAnchorEl}
          anchorEl={filterPopoverAnchorEl}
          onClose={this.onClosePopover.bind(this)}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right"
          }}
        >
          <Grid
            container
            direction="column"
            style={{ width: 410, padding: 16 }}
          >
            <Grid item className={classes.filterTitle}>
              <Typography variant="subtitle1" style={{ fontWeight: 500 }}>
                {FilterTitle}
              </Typography>
            </Grid>
            <Grid item>{filterForm}</Grid>
            <Grid item className={classes.actionButtons}>
              <Button
                title="Cancel"
                buttonType={ButtonType.Cancel}
                onClick={this.onClickCancelButton.bind(this)}
              />
              <Button
                title="Apply"
                buttonType={ButtonType.Primary}
                onClick={this.onClickApplyButton.bind(this)}
              />
            </Grid>
          </Grid>
        </Popover>

        <CustomImportModal
          title={importModalTitle || title}
          open={importCSVModal}
          handleClose={this.onCloseUploadModal.bind(this)}
          handleSubmit={this.onUploadCSVFile.bind(this)}
          handleDownloadCSV={this.downloadCSVTemplate.bind(this)}
          importerrorMessage={importErrorMessage || ""}
        />
      </Grid>
    );
    // Customizable Area End
  }
}

export default withStyles(styles)(PageHeader);
