Object.defineProperty(exports, "__esModule", {
  value: true
});

const urlConfig = require("../../../framework/src/config");

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "FeeAssignment";
exports.labelBodyText = "FeeAssignment Body";
exports.btnExampleTitle = "CLICK ME";

exports.GET = "GET";
exports.POST = "POST";
exports.PUT = "PUT";
exports.DELETE = "DELETE";
exports.PATCH = "PATCH";
exports.feeAssignmentManagementURL = urlConfig.baseURL;
exports.getFeeAssignmentEndPoint = "bx_block_fee_structure/fee_structures";
exports.getFeeAssignmentBaseEndPoint = "bx_block_fee_structure";
exports.getAcademicYearBaseEndPoint = "bx_block_academic_year/";
exports.getAcademicClassBaseEndPoint = "bx_block_academic_class/";
exports.getDivisionBaseEndPoint = "bx_block_division/";
exports.getStudentBaseEndPoint = "bx_block_student/";
exports.getFeeBaseEndPoint = "bx_block_fee/";
exports.getTaxBaseEndPoint = "bx_block_tax/taxes/";
exports.getFinesEndPoint = "bx_block_fine/fines/";
// Customizable Area End
