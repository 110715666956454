import React from "react";
// Customizable Area Start
/*-- layout bar --*/
import { withStyles, Theme } from "@material-ui/core/styles";
import Header from "../../Header/src/Header.web";
import Sidebar from "../../Sidebar/src/Sidebar.web";
/*-- layout bar end --*/
import { Styles } from "@material-ui/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
// Customizable Area End
import { drawerOpenIcon, drawerCloseIcon } from "../../Sidebar/src/assets";

import LayoutController, { Props } from "./LayoutController";

import {} from "react-native";
import Loader from "../../../../components/src/Loader.web";

class Layout extends LayoutController {
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const open = this.state.drawerStatus;
    const { children, searchKeyword, hideSearchBar } = this.props;
    // Customizable Area End
    return (
      // Customizable Area Start
      //Merge Engine DefaultContainer

      <>
        <Header
          hideSearchBar={hideSearchBar}
          searchKeyword={searchKeyword}
          handleSearchChange={this.props.handleGlobalSearch}
          navigation={this.props.navigation}
          open={open}
          drawerWidth={this.state.drawerWidth}
        />
        <Sidebar
          classname={open ? "sidebarOpenLogoClass" : "sidebarOpenLogoClass2"}
          navigation={this.props.navigation}
          handleDrawerClick={this.handleDrawerClick}
          open={open}
        />
        <Container
          style={{
            backgroundColor: "white",
            boxShadow: "none",
            transition: "all 0.3s ease-out",
            borderTopLeftRadius: "36px",
            borderBottomLeftRadius: "36px",
            marginLeft: this.state.drawerWidth,
            width: `calc(100% - ${this.state.drawerWidth}px)`,
            zIndex: 999,
            height: "100vh",
            maxWidth: "100%",
            position: "relative"
          }}
        >
          <IconButton
            style={{
              position: "absolute",
              top: "74px",
              left: -20,
              zIndex: 1999
            }}
            onClick={this.handleDrawerClick}
          >
            <img
              style={{ backgroundColor: "#f5bd18" }}
              height="22px"
              src={open ? drawerOpenIcon : drawerCloseIcon}
            />
          </IconButton>
          <Grid style={{ marginTop: 95 }}>{children}</Grid>
        </Container>
      </>
      //Merge Engine End DefaultContainer
      // Customizable Area End
    );
  }
}

export default Layout;
