import React from "react";
import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";

// Customizable Area Start
import { IActionMenuItem } from "../../CommonLayout/HRMSCommons/src/actionMenu";
import { downloadCSV } from "framework/src/Utilities";
import { toast } from "react-toastify";
// Customizable Area End

import {
  FormMode,
  Props as IFineFormProps,
  IFineForm,
  ISubFineForm
} from "./FineFormController";
export const configJSON = require("./config");

// Customizable Area Start
enum Method {
  GET = "GET",
  POST = "POST",
  PUT = "PUT",
  DELETE = "DELETE",
  PATCH = "PATCH"
}

export interface IFine {
  id: string;
  type: string;
  selected: boolean;
  attributes: {
    id: number;
    name: string;
    day: number;
    duration: string;
    fine_category: string;
    fine_amount: number;
    mode: string;
    created_at: string;
    updated_at: string;
    custom_id: string;
  };
  sub_fines?: ISubFineForm[];
}

interface IPageMeta {
  message: string;
  total_pages: number;
}

interface IFilterForm {
  name: string;
  fine_category: string;
}
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  anchorEl: HTMLElement | null;
  actionedTableRow: IFine | null;
  actionMenuItems: Array<IActionMenuItem>;
  fines: Array<IFine>;
  fineNames: Array<string>;
  pageMeta: IPageMeta;
  selectAllCheckboxChecked: false | "indeterminate" | true;
  isLoading: boolean;
  deleteModalOpen: boolean;
  filterForm: IFilterForm;
  isFormModalOpened: boolean;
  FineForm: IFineForm | null;
  formMode: FormMode;
  openedRow: number | undefined;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class FineListController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  public tableActionMenuItems: Array<IActionMenuItem> = [
    { label: "Edit", action: this.editFine.bind(this) },
    { label: "View", action: this.viewFine.bind(this) },
  ];

  public RequestMessage = {
    GetFines: this.buildRequestMessage(Method.GET),
    GetAllFines: this.buildRequestMessage(Method.GET),
    FilterFines: this.buildRequestMessage(Method.GET),
    DeleteFine: this.buildRequestMessage(Method.DELETE),
    CreateFine: this.buildRequestMessage(Method.POST),
    EditFine: this.buildRequestMessage(Method.PUT),
    ExportFines: this.buildRequestMessage(Method.GET),
    Null: undefined as any
  };

  // public FineFormProps: { [key: number]: IFineFormProps } = {
  //   [FormMode.Create]: {
  //     title: "Create Tax Rate",
  //     submitLabel: "Create",
  //     initialValues: null,
  //     formMode: FormMode.Create,
  //     isOpen: false,
  //     onClose: this.onCloseFineFormModal.bind(this),
  //     onSubmit: this.onSubmitCreateFineModal.bind(this),
  //     requestMessage: this.RequestMessage.CreateFine
  //   },
  //   [FormMode.Edit]: {
  //     title: "Edit Tax Rate",
  //     submitLabel: "Update",
  //     initialValues: null,
  //     formMode: FormMode.Edit,
  //     isOpen: false,
  //     onClose: this.onCloseFineFormModal.bind(this),
  //     onSubmit: this.onSubmitEditFineModal.bind(this),
  //     requestMessage: this.RequestMessage.EditFine
  //   },
  //   [FormMode.View]: {
  //     title: "View Tax Rate",
  //     submitLabel: "Back to Listing",
  //     initialValues: null,
  //     formMode: FormMode.View,
  //     isOpen: false,
  //     onClose: this.onCloseFineFormModal.bind(this),
  //     onSubmit: this.onCloseFineFormModal.bind(this),
  //     requestMessage: this.RequestMessage.Null
  //   }
  // };

  public printReferance = React.createRef();
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      anchorEl: null,
      actionedTableRow: null,
      fines: [],
      actionMenuItems: [],
      pageMeta: {
        message: "",
        total_pages: 0
      },
      selectAllCheckboxChecked: false,
      isLoading: true,
      deleteModalOpen: false,
      filterForm: {
        name: "",
        fine_category: ""
      },
      isFormModalOpened: false,
      FineForm: null,
      formMode: FormMode.Create,
      fineNames: [],
      openedRow: undefined
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const callID = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const response = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      switch (callID) {
        case this.RequestMessage.GetFines.messageId:
        case this.RequestMessage.FilterFines.messageId:
          if (response) {
            this.setState({
              fines: response.data || [],
              pageMeta: response.meta || {},
              isLoading: false
            });
          }
          break;
        case this.RequestMessage.GetAllFines.messageId:
          if (response) {
            let fineNames = (response.data || [])
              .map((el: any) =>
                el.attributes ? el.attributes.name : undefined
              )
              .filter((el: any) => el);
            fineNames = [...new Set(fineNames)];
            this.setState({
              fineNames,
              isLoading: false
            });
          }
          break;

        case this.RequestMessage.DeleteFine.messageId:
          if (response !== null) {
            this.setState({
              deleteModalOpen: false,
              actionedTableRow: null
            });

            this.getFinesCall();
          }
          break;

        case this.RequestMessage.CreateFine.messageId:
          if (response !== null) {
            this.setState({ isFormModalOpened: false });
            this.getFinesCall();
          }

          break;

        case this.RequestMessage.EditFine.messageId:
          if (response !== null) {
            this.setState({ isFormModalOpened: false });
            this.getFinesCall();
          }

          break;

        case this.RequestMessage.ExportFines.messageId:
          const rawCSV = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
          );
          debugger;
          if (response !== null) {
            const file = new Blob(response, { type: "text/csv" });
            const fileURL = URL.createObjectURL(file);
            window.open(fileURL);
          }

          break;
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  public async componentDidMount() {
    super.componentDidMount();

    this.getFinesCall();
    this.getAllFinesCall();
  }

  public onClickCreate() {
    this.setState({
      isFormModalOpened: true,
      formMode: FormMode.Create
    });
    this.props.navigation.navigate("CreateFine");
    console.log("navigate", this.props.navigation);
  }

  public onSelectAllFines(event: React.ChangeEvent<HTMLInputElement>) {
    const { fines: Fines } = this.state;
    const { checked } = event.target;

    const updatedFines = Fines.map(Fine => {
      return {
        ...Fine,
        selected: checked
      };
    });

    this.setState({
      fines: updatedFines,
      selectAllCheckboxChecked: this.isSelectAllCheckboxChecked(updatedFines)
    });
  }

  public onSelectFine(event: React.ChangeEvent<HTMLInputElement>, row: IFine) {
    const { fines: Fines } = this.state;
    const { checked } = event.target;

    const updatedFines = Fines.map(Fine => {
      return {
        ...Fine,
        selected: row.id === Fine.id ? checked : Fine.selected
      };
    });

    this.setState({
      fines: updatedFines,
      selectAllCheckboxChecked: this.isSelectAllCheckboxChecked(updatedFines)
    });
  }

  public onClickActionButton(event: any, item: IFine) {
    this.setState({
      anchorEl: event.currentTarget,
      actionedTableRow: item,
      actionMenuItems: this.tableActionMenuItems
    });
  }

  public onCloseActionMenu() {
    this.setState({
      anchorEl: null,
      actionMenuItems: []
    });
  }

  public onClickPagination(page: any) {
    this.getFinesCall(page.selected + 1);
  }

  public onCloseDeleteModal() {
    this.setState({
      deleteModalOpen: false,
      actionedTableRow: null
    });
  }

  public onSubmitDeleteModal() {
    this.deleteFineCall(this.state.actionedTableRow);
  }

  public onCloseFineFormModal() {
    this.setState({ isFormModalOpened: false });
  }

  public onSubmitCreateFineModal(form: IFineForm) {
    this.createFineCall(form);
  }

  public onSubmitEditFineModal(form: IFineForm) {
    this.editFineCall(form);
  }

  public onUploadCSVForImport(file: File) {
    this.importFinesCall(file);
  }

  public onClickExportCSVButton() {
    this.exportFinesCall();
  }

  //#region Filter Methods

  public onChangeFilterFineName(event: any) {
    this.setState({
      filterForm: {
        ...this.state.filterForm,
        name: event.target.value
      }
    });
  }

  public onChangeFilterFineCategory(event: any) {
    this.setState({
      filterForm: {
        ...this.state.filterForm,
        fine_category: event.target.value
      }
    });
  }

  public onFilterFormSubmit() {
    this.filterFinesCall(this.state.filterForm);
  }
  //#endregion Filter Methods

  private editFine() {
    const fine = this.state.actionedTableRow;
    if (!fine) return;
    window.location.href = "EditFine?id=" + fine.attributes.id;
  }

  private viewFine() {
    const fine = this.state.actionedTableRow;
    if (!fine) return;
    // this.props.navigation.navigate("ViewFine?id=5");
    window.location.href = "ViewFine?id=" + fine.attributes.id;
  }

  private deleteFine() {
    this.setState({ deleteModalOpen: true });
  }

  private copyFine() {
    const Fine = this.state.actionedTableRow;

    if (Fine) {
      const {
        attributes: { custom_id, id, ...form },
        sub_fines
      } = Fine;

      const to: IFineForm = {
        id: undefined,
        ...form,
        name: form.name + " - Copy",
        sub_fines_attributes: sub_fines || [],
        custom_id
      };

      this.createFineCall(to);
    }
  }



  private selectedFines(Fines?: Array<IFine>): Array<IFine> {
    if (Fines === undefined) {
      Fines = this.state.fines;
    }

    return Fines.filter(Fine => Fine.selected);
  }

  private isSelectAllCheckboxChecked(
    Fines: Array<IFine>
  ): false | "indeterminate" | true {
    const selectedFines = this.selectedFines(Fines);
    const { length } = selectedFines;

    return length !== 0 && (length === Fines.length ? true : "indeterminate");
  }

  //#region Service Calls
  private getFinesCall(page: number = 1) {
    this.setState({ isLoading: true });

    this.RequestMessage.GetFines.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.FinesEndPoint}?page=${page}`
    );

    runEngine.sendMessage(
      this.RequestMessage.GetFines.id,
      this.RequestMessage.GetFines
    );
  }

  private getAllFinesCall() {
    this.setState({ isLoading: true });

    this.RequestMessage.GetAllFines.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.FinesEndPoint}`
    );

    runEngine.sendMessage(
      this.RequestMessage.GetAllFines.id,
      this.RequestMessage.GetAllFines
    );
  }

  private deleteFineCall(Fine: IFine | null) {
    if (Fine !== null) {
      this.RequestMessage.DeleteFine.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.FinesEndPoint}/${Fine.id}`
      );

      runEngine.sendMessage(
        this.RequestMessage.DeleteFine.id,
        this.RequestMessage.DeleteFine
      );
    }
  }

  private createFineCall(form: IFineForm) {
    this.RequestMessage.CreateFine.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({ tax: form })
    );

    runEngine.sendMessage(
      this.RequestMessage.CreateFine.id,
      this.RequestMessage.CreateFine
    );
  }

  private editFineCall(form: IFineForm) {
    const { id, ...rest } = form;

    if (id !== undefined) {
      this.RequestMessage.EditFine.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify({ tax: rest })
      );

      this.RequestMessage.EditFine.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.TaxesEndPoint}/${id}`
      );

      runEngine.sendMessage(
        this.RequestMessage.EditFine.id,
        this.RequestMessage.EditFine
      );
    }
  }

  private filterFinesCall(form: IFilterForm) {
    this.RequestMessage.FilterFines.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.FinesEndPoint}/filter?${this.getFilterQuery(form)}`
    );

    runEngine.sendMessage(
      this.RequestMessage.FilterFines.id,
      this.RequestMessage.FilterFines
    );
  }

  private exportFinesCall() {
    const requestOptions = {
      method: "GET",
      headers: JSON.parse(this.getHeaderMessage())
    };

    fetch(
      `${configJSON.APIBaseURL}/${configJSON.FinesEndPoint}/export`,
      requestOptions
    )
      .then(response => {
        const file_sub_name = new Date().getTime();
        response
          .blob()
          .then(blob => downloadCSV(blob, "role_csv_" + file_sub_name));
      })
      .catch(error => {
        toast.success(error.message);
      });
  }

  private importFinesCall(file: File) {
    const formData = new FormData();
    formData.append("file", file);

    const requestOptions = {
      method: "POST",
      headers: JSON.parse(this.getHeaderMessage()),
      body: formData
    };

    fetch(
      `${configJSON.APIBaseURL}/${configJSON.FinesEndPoint}/import`,
      requestOptions
    )
      .then(response => response.json())
      .then(response => toast.success(response.message))
      .catch(response => toast.error(response.error));
  }
  //#endregion Service Calls

  private buildRequestMessage(method: Method): Message {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.TaxesEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.APIBaseURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      this.getHeaderMessage()
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method.toString()
    );

    return requestMessage;
  }

  private getHeaderMessage() {
    const header = {
      "Content-Type": configJSON.APIContentType,
      token: localStorage.getItem("token")
    };

    return JSON.stringify(header);
  }

  private getFilterQuery(form: IFilterForm) {
    return Object.entries(form)
      .filter(([key, value]) => value !== "" && value !== null)
      .map(([key, value]) => `${key}=${value}`)
      .join("&");
  }
  // Customizable Area End
}
