import React from "react";
// Customizable Area Start
import { withStyles, Theme } from '@material-ui/core/styles';
import { Styles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import Layout from '../../CommonLayout/Layout/src/Layout.web';
import PageHeader from "../../CommonLayout/HRMSCommons/src/layout/PageHeader";
import { classNames } from "../../CommonLayout/HRMSCommons/src/classNames";
import { Typography } from "@material-ui/core";
import { Button } from "../../CommonLayout/HRMSCommons/src/button/default";
// Customizable Area End

import PaymentPreferenceController, { Props } from "./PaymentPreferenceController";

// Customizable Area Start
const useStyles: Styles<Theme, {}, string> = (theme: Theme) => ({
  container: {
    minHeight: "calc(100vh - 120px)",
    maxHeight: "calc(100vh - 120px)",
    flexDirection: "column",
    justifyContent: "flex-start",
    flexWrap: "nowrap",
    boxShadow: "0 0 10px 0px #555",
    borderRadius: 5,
    overflow: "hidden",
    overflowY: "auto",

    '& > div': {
      margin: "initial",
    },

    '& p': {
      fontWeight: 600,
    }
  },
  mt20: {
    marginTop: 20,
  },
  mt10: {
    marginTop: 10,
  },
  bottomSpace: {
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
    boxShadow: "0px 3px 10px -3px #555",
  },
  topRadius: {
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
  },
});
// Customizable Area End

class PaymentPreference extends PaymentPreferenceController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes, navigation } = this.props;
    const { } = this.state;

    return (
      <Layout navigation={navigation} hideSearchBar>
        <Grid container className={classes.container}>
          <PageHeader
            title="Payment Preference"
            haveActionBar={false}
            haveCreateButton={false}
          />
          <Grid container direction="column" className={classes.bottomSpace} style={{ padding: 20 }}>
            <Typography variant="subtitle1" color="textSecondary" component="p">
              Connected Payment Gateways
            </Typography>

            <Grid container direction="column" justify="center" alignItems="center" className={classes.mt20}>
              <img src="https://via.placeholder.com/100x100" width="100" height={100} alt="placeholder" />

              <Typography variant="subtitle1" color="textSecondary" component="p" className={classes.mt10}>
                Add Payment Gateway
              </Typography>
            </Grid>
          </Grid>

          <PageHeader
            title="Set-up Other Payment Gateways"
            haveActionBar={false}
            haveCreateButton={false}
          />
          <Grid container>
            <Grid container direction="column" className={classNames(classes.bottomSpace, classes.mt20, classes.topRadius)} style={{ padding: 20 }}>
              <Grid container direction="row" justify="space-between" alignItems="center">
                <img src="https://via.placeholder.com/100x50" width="100" height={50} alt="placeholder" />

                <Button
                  title="Set-up Now"
                  onClick={() => { }}>
                </Button>
              </Grid>

              <Typography variant="caption" color="textSecondary" component="p" className={classes.mt10}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, nunc sit amet aliquam lacinia, nisl nisl aliquam lorem, eu aliquam nisl nunc sit amet lorem. Sed euismod, nunc sit amet aliquam lacinia, nisl nisl aliquam lorem, eu aliquam nisl nunc sit amet lorem.
                nunc sit amet aliquam lacinia, nisl nisl aliquam lorem, eu aliquam nisl nunc sit amet lorem. Sed euismod, nunc sit amet aliquam lacinia, nisl nisl aliquam lorem, eu aliquam nisl nunc sit amet lorem.
              </Typography>
            </Grid>

            <Grid container direction="column" className={classNames(classes.bottomSpace, classes.mt20, classes.topRadius)} style={{ padding: 20 }}>
              <Grid container direction="row" justify="space-between" alignItems="center">
                <img src="https://via.placeholder.com/100x50" width="100" height={50} alt="placeholder" />

                <Button
                  title="Set-up Now"
                  onClick={() => { }}>
                </Button>
              </Grid>

              <Typography variant="caption" color="textSecondary" component="p" className={classes.mt10}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, nunc sit amet aliquam lacinia, nisl nisl aliquam lorem, eu aliquam nisl nunc sit amet lorem. Sed euismod, nunc sit amet aliquam lacinia, nisl nisl aliquam lorem, eu aliquam nisl nunc sit amet lorem.
                nunc sit amet aliquam lacinia, nisl nisl aliquam lorem, eu aliquam nisl nunc sit amet lorem. Sed euismod, nunc sit amet aliquam lacinia, nisl nisl aliquam lorem, eu aliquam nisl nunc sit amet lorem.
              </Typography>
            </Grid>

            <Grid container direction="column" className={classNames(classes.bottomSpace, classes.mt20, classes.topRadius)} style={{ padding: 20, marginBottom: 0 }}>
              <Grid container direction="row" justify="space-between" alignItems="center">
                <img src="https://via.placeholder.com/100x50" width="100" height={50} alt="placeholder" />

                <Button
                  title="Set-up Now"
                  onClick={() => { }}>
                </Button>
              </Grid>

              <Typography variant="caption" color="textSecondary" component="p" className={classes.mt10}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, nunc sit amet aliquam lacinia, nisl nisl aliquam lorem, eu aliquam nisl nunc sit amet lorem. Sed euismod, nunc sit amet aliquam lacinia, nisl nisl aliquam lorem, eu aliquam nisl nunc sit amet lorem.
                nunc sit amet aliquam lacinia, nisl nisl aliquam lorem, eu aliquam nisl nunc sit amet lorem. Sed euismod, nunc sit amet aliquam lacinia, nisl nisl aliquam lorem, eu aliquam nisl nunc sit amet lorem.
              </Typography>
            </Grid>

          </Grid>
        </Grid>
      </Layout>
    );
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End
}

export default withStyles(useStyles)(PaymentPreference);
