import React from "react";
// Customizable Area Start
import { withStyles, Theme } from "@material-ui/core/styles";
import { Styles } from "@material-ui/styles";
import Grid from "@material-ui/core/Grid";
import Radio from "@material-ui/core/Radio";
import DatePicker from "react-date-picker";
import SearchIcon from "@material-ui/icons/Search";
import UploadIcon from "@material-ui/icons/CloudUpload";
import Layout from "../../CommonLayout/Layout/src/Layout.web";

import PageHeader from "../../CommonLayout/HRMSCommons/src/layout/PageHeader";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextareaAutosize,
  TextField,
  Typography
} from "@material-ui/core";
import OutlinedSelect from "../../../components/src/OutlinedSelect";
import CreateUpdateExpenseController, {
  Props
} from "./CreateUpdateExpenseController";
import { FormMode, IExpenseForm } from "./ExpenseFormController";
import {
  Button,
  ButtonType
} from "../../CommonLayout/HRMSCommons/src/button/default";
import { calenderIcon, uploadIcon } from "./assets";
import VendorSelectionModal from "../../CommonLayout/VendorSelectionModal/src/VendorSelectionModal.web";
// Customizable Area End

// Customizable Area Start
const useStyles: Styles<Theme, {}, string> = () => ({
  container: {
    minHeight: "calc(100vh - 120px)",
    maxHeight: "calc(100vh - 120px)",
    flexDirection: "column",
    flexWrap: "nowrap"
  },
  mt20: {
    marginTop: 20
  },
  actionColumn: {
    maxWidth: "100px",
    width: "100px"
  },

  actionColumnButton: {
    margin: 0
  },
  checkbox: {
    padding: 0
  },
  tableHead: {
    backgroundColor: "#FCF3D4"
  },
  firstExpenseContainer: {
    marginTop: "15px"
  },
  headerContainer: {
    boxShadow: "0px 1px 9px 0px #888888",
    borderRadius: "11px"
  },
  question: {
    backgroundColor: "#FFF9EE",
    marginTop: 20
  },
  searchIconContainer: {
    background: "#eb514f",
    height: "56px",
    display: "flex",
    borderRadius: "4px",
    width: "56px",
    justifyContent: "center",
    alignItems: "center"
  },

  uploadIconContainer: {
    // background: "#eb514f",
    height: "25px",
    display: "flex",
    borderRadius: "10px",
    width: "95%",
    justifyContent: "space-between",
    alignItems: "center",
    border: "dashed",
    padding: "8px",
    cursor: "pointer"
  },
  reverseTypeSelect: {
    backgroundColor: "#F7F7F7",
    width: "60px",
    marginRight: "-13px",
    height: "55px",
    paddingLeft: "10px",
    borderTopRightRadius: "3px",
    borderBottomRightRadius: "4px",
    borderLeft: "solid #D1D1D1"
  }
});
// Customizable Area End

class CreateUpdateExpense extends CreateUpdateExpenseController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderView = (value: string, color: string = "#838383") => (
    <Typography variant="h6" style={{ color: color }}>
      {value}
    </Typography>
  );

  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes, navigation } = this.props;
    const { expenseForm, formMode } = this.state;

    return (
      <Layout navigation={navigation}>
        {/* <Grid container className={classes.container}> */}
        <div style={{ maxHeight: "80vh", overflow: "auto", padding: "5px" }}>
          <Grid
            className={classes.headerContainer}
            container
            direction="column"
          >
            <PageHeader
              title={
                formMode === FormMode.Create
                  ? "Create Expense"
                  : formMode === FormMode.Edit
                  ? "Edit Expense"
                  : "View Expense"
              }
              importModalTitle="Import expense by CSV file"
              printReference={this.printReferance}
              haveFilterButton={false}
              haveActionBar={false}
              haveCSVButton={false}
              haveCreateButton={false}
            />
            {/* {this.renderExpense(expenseForm, undefined, true)} */}
            <Grid
              container
              direction="row"
              wrap="nowrap"
              justify="center"
              alignItems="center"
              className={classes.question}
            >
              <Typography variant="h6" style={{ color: "#47546C" }}>
                {"Expense Type" + (formMode !== FormMode.View ? "*" : "")}
              </Typography>
              {formMode === FormMode.View ? (
                <div style={{ marginLeft: "10px" }}>
                  {this.renderView(expenseForm.expense_type, "#47546C")}
                </div>
              ) : (
                <>
                  <Radio
                    checked={expenseForm.expense_type === "goods"}
                    value={expenseForm.expense_type === "goods"}
                    onClick={() => this.onChangeExpenseType("goods")}
                    name="goods"
                  />
                  <InputLabel htmlFor="is_gst">Goods</InputLabel>
                  <Radio
                    checked={expenseForm.expense_type === "services"}
                    value={expenseForm.expense_type === "services"}
                    onClick={() => this.onChangeExpenseType("services")}
                    name="services"
                  />
                  <InputLabel htmlFor="is_gst">Services</InputLabel>
                </>
              )}
            </Grid>
            <div style={{ padding: "25px" }}>
              <Grid container direction="row" style={{ marginTop: 10 }}>
                <Grid item xs={12} sm={4} style={{ paddingRight: 12 }}>
                  <FormControl style={{ width: "100%" }}>
                    <FormLabel style={{ marginBottom: "8px" }}>
                      {expenseForm.expense_type === "goods"
                        ? "HSN code"
                        : "SAC"}
                    </FormLabel>
                    {formMode === FormMode.View ? (
                      this.renderView(
                        expenseForm.expense_type === "goods"
                          ? expenseForm.hns_code
                          : expenseForm.sac
                      )
                    ) : (
                      <TextField
                        value={
                          expenseForm.expense_type === "goods"
                            ? expenseForm.hns_code
                            : expenseForm.sac
                        }
                        color={"primary"}
                        variant="outlined"
                        type={"text"}
                        name={"hsnSac"}
                        placeholder={
                          expenseForm.expense_type === "goods"
                            ? "Enter HSN code"
                            : "Enter SAC"
                        }
                        onChange={
                          expenseForm.expense_type === "goods"
                            ? this.onChangeHsnCode
                            : this.onChangeSac
                        }
                      />
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4} style={{ paddingRight: 12 }}>
                  <FormControl style={{ width: "100%" }}>
                    <FormLabel style={{ marginBottom: "8px" }}>
                      {"Date" + (formMode === FormMode.View ? "" : " *")}
                    </FormLabel>
                    {formMode === FormMode.View ? (
                      this.renderView(expenseForm.date)
                    ) : (
                      <DatePicker
                      dayPlaceholder="DD"
                      monthPlaceholder="MM"
                      yearPlaceholder="YYYY"
                        onChange={this.handleDateChange}
                        // style={ { border: "none", width: "100%"}}
                        value={expenseForm.date}
                        minDate={new Date()}
                        calendarIcon={
                          <img style={{ height: "27px" }} src={calenderIcon} />
                        }
                      />
                    )}
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container direction="row" style={{ marginTop: 10 }}>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  style={{ marginTop: 30, paddingRight: 12 }}
                >
                  {formMode === FormMode.View ? (
                    <>
                      <FormLabel style={{ marginBottom: "8px" }}>
                        Currency
                      </FormLabel>
                      {this.renderView(expenseForm.currency)}
                    </>
                  ) : (
                    <OutlinedSelect
                      val={expenseForm.currency}
                      options={this.state.currencies.map(el => ({
                        id: el[1],
                        name: el[1]
                      }))}
                      label="Currency*"
                      placeholder="Select Currency"
                      name={"mode"}
                      handleChange={(e: any) => this.handleCurrencyChange(e)}
                      className={undefined}
                    />
                  )}
                </Grid>
                {expenseForm.currency && expenseForm.currency !== "INR" && (
                  <Grid
                    item
                    xs={12}
                    sm={4}
                    style={{ marginTop: 30, paddingRight: 12 }}
                  >
                    <FormControl style={{ width: "100%" }}>
                      <FormLabel style={{ marginBottom: "8px" }}>
                        {"Exchange rate in INR for 1 " +
                          expenseForm.currency +
                          (formMode !== FormMode.View ? " *" : "")}
                      </FormLabel>
                      {formMode === FormMode.View ? (
                        this.renderView(expenseForm.exchange_rate)
                      ) : (
                        <TextField
                          value={expenseForm.exchange_rate}
                          color={"primary"}
                          variant="outlined"
                          type={"text"}
                          name={"exchangeRate"}
                          placeholder={"Enter Exchange Rate"}
                          onChange={this.handleExchangeRate}
                        />
                      )}
                    </FormControl>
                  </Grid>
                )}
                {expenseForm.currency && expenseForm.currency !== "INR" && (
                  <Grid
                    item
                    xs={12}
                    sm={4}
                    style={{ marginTop: 30, paddingRight: 12 }}
                  >
                    <FormControl style={{ width: "100%" }}>
                      <FormLabel style={{ marginBottom: "8px" }}>
                        {"Amount" + (formMode === FormMode.View ? "" : " *")}
                      </FormLabel>
                      {formMode === FormMode.View ? (
                        this.renderView(expenseForm.exchange_amount)
                      ) : (
                        <TextField
                          value={expenseForm.exchange_amount}
                          color={"primary"}
                          variant="outlined"
                          type={"text"}
                          name={"exchangeRate"}
                          placeholder={"Enter Exchange Rate"}
                          onChange={this.handleExchangeAmount}
                        />
                      )}
                    </FormControl>
                  </Grid>
                )}
              </Grid>
              <Grid container direction="row" style={{ marginTop: 10 }}>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  style={{ marginTop: 30, paddingRight: 12 }}
                >
                  {formMode === FormMode.View ? (
                    <>
                      <FormLabel style={{ marginBottom: "8px" }}>
                        Expense Account
                      </FormLabel>
                      {this.renderView(expenseForm.expence_account)}
                    </>
                  ) : (
                    <OutlinedSelect
                      val={expenseForm.expence_account}
                      options={[
                        { id: "ACCOUNT1", name: "ACCOUNT1" },
                        { id: "ACCOUNT2", name: "ACCOUNT2" },
                        { id: "ACCOUNT3", name: "ACCOUNT3" }
                      ]}
                      label="Expense Account*"
                      placeholder="Select Expense Account"
                      name={"mode"}
                      handleChange={(e: any) =>
                        this.handleExpenseAccountChange(e)
                      }
                      className={undefined}
                    />
                  )}
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  style={{ marginTop: 30, paddingRight: 12 }}
                >
                  <FormControl style={{ width: "100%" }}>
                    <FormLabel style={{ marginBottom: "8px" }}>
                      {"Amount (in INR)" +
                        (formMode === FormMode.View ? "" : " *")}
                    </FormLabel>
                    {formMode === FormMode.View ? (
                      this.renderView(expenseForm.expense_amount)
                    ) : (
                      <TextField
                        value={expenseForm.expense_amount}
                        color={"primary"}
                        variant="outlined"
                        type={"text"}
                        name={"hsnSac"}
                        placeholder={"Enter Amount"}
                        onChange={
                          expenseForm.currency && expenseForm.currency !== "INR"
                            ? undefined
                            : this.handleAmountChange
                        }
                      />
                    )}
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  style={{ marginTop: 30, paddingRight: 12 }}
                >
                  {formMode === FormMode.View ? (
                    <>
                      <FormLabel style={{ marginBottom: "8px" }}>Tax</FormLabel>
                      {this.renderView(expenseForm.tax)}
                    </>
                  ) : (
                    <OutlinedSelect
                      val={expenseForm.tax}
                      options={this.state.taxes.map(el => ({
                        id: el,
                        name: el.attributes ? el.attributes.name : ""
                      }))}
                      label="Tax*"
                      placeholder="Select Tax"
                      name={"Tax"}
                      handleChange={this.handleTaxChange}
                      className={undefined}
                    />
                  )}
                </Grid>
              </Grid>
              {expenseForm.tax && (
                <Grid
                  container
                  direction="row"
                  wrap="nowrap"
                  alignItems="center"
                  style={{ marginTop: 30, paddingRight: 12 }}
                >
                  <Typography
                    variant="h6"
                    style={{ color: "#3A4D72", marginRight: "5px" }}
                  >
                    Amount is
                  </Typography>
                  {formMode === FormMode.View ? (
                    this.renderView(
                      expenseForm.is_tax_inclusive
                        ? " Tax Inclusive"
                        : " Tax Exclusive"
                    )
                  ) : (
                    <>
                      <Radio
                        checked={expenseForm.is_tax_inclusive}
                        value={expenseForm.is_tax_inclusive}
                        onClick={() => this.onChangeTaxExclusive(true)}
                        name="Tax Inclusive"
                      />
                      <InputLabel style={{ color: "#3A4D72" }}>
                        Tax Inclusive
                      </InputLabel>
                      <Radio
                        checked={!expenseForm.is_tax_inclusive}
                        value={!expenseForm.is_tax_inclusive}
                        onClick={() => this.onChangeTaxExclusive(false)}
                        name="Tax Exclusive"
                      />
                      <InputLabel style={{ color: "#3A4D72" }}>
                        Tax Exclusive
                      </InputLabel>
                    </>
                  )}
                </Grid>
              )}

              <Grid
                item
                xs={12}
                sm={4}
                style={{ marginTop: 30, paddingRight: 12 }}
              >
                <FormControl style={{ width: "100%" }}>
                  <FormLabel style={{ marginBottom: "8px" }}>Vendor</FormLabel>
                  {formMode === FormMode.View ? (
                    this.renderView(expenseForm.vendor_id)
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between"
                      }}
                    >
                      <TextField
                        value={expenseForm.vendor_id}
                        color={"primary"}
                        variant="outlined"
                        type={"text"}
                        name={"hsnSac"}
                        style={{ width: "100%", marginRight: "15px" }}
                        placeholder={""}
                        // onChange={this.handleVendorIdChange}
                      />
                      <div
                        className={classes.searchIconContainer}
                        onClick={() => this.setState({ vendorModalOpen: true })}
                      >
                        <SearchIcon
                          style={{ color: "white", fontSize: "33px" }}
                        />
                      </div>
                    </div>
                  )}
                </FormControl>
              </Grid>

              <Grid container direction="row" style={{ marginTop: 10 }}>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  style={{ marginTop: 30, paddingRight: 12 }}
                >
                  <FormControl style={{ width: "100%" }}>
                    <FormLabel style={{ marginBottom: "8px" }}>
                      Invoice #
                    </FormLabel>
                    {formMode === FormMode.View ? (
                      this.renderView(expenseForm.invoice)
                    ) : (
                      <TextField
                        value={expenseForm.invoice}
                        color={"primary"}
                        variant="outlined"
                        type={"text"}
                        name={"hsnSac"}
                        placeholder={"Enter Invoice"}
                        onChange={this.handleInvoiceChange}
                      />
                    )}
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  style={{ marginTop: 30, paddingRight: 12 }}
                >
                  {formMode === FormMode.View ? (
                    <>
                      <FormLabel style={{ marginBottom: "8px" }}>
                        Paid Through
                      </FormLabel>
                      {this.renderView(expenseForm.paid_trough)}
                    </>
                  ) : (
                    <OutlinedSelect
                      val={expenseForm.paid_trough}
                      options={[
                        { id: "Advance Tax", name: "Advance Tax" },
                        { id: "Prepaid Expenses", name: "Prepaid Expenses" }
                      ]}
                      label="Paid Through*"
                      placeholder="Paid Through"
                      name={"Tax"}
                      handleChange={this.handlePaidThroughChange}
                    />
                  )}
                </Grid>
              </Grid>
              <Grid container direction="row" style={{ marginTop: 10 }}>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  style={{ marginTop: 30, paddingRight: 12 }}
                >
                  {formMode === FormMode.View ? (
                    <>
                      <FormLabel style={{ marginBottom: "8px" }}>
                        Source Of Supply
                      </FormLabel>
                      {this.renderView(expenseForm.source_of_supply)}
                    </>
                  ) : (
                    <OutlinedSelect
                      val={expenseForm.source_of_supply}
                      options={[
                        { id: "TAX1", name: "TAX1" },
                        { id: "TAX2", name: "TAX2" },
                        { id: "TAX3", name: "TAX3" }
                      ]}
                      label="Source Of Supply*"
                      placeholder="Select"
                      name={"Source Of Supply"}
                      handleChange={this.handleSourceSupplyChange}
                    />
                  )}
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  style={{ marginTop: 30, paddingRight: 12 }}
                >
                  {formMode === FormMode.View ? (
                    <>
                      <FormLabel style={{ marginBottom: "8px" }}>
                        Destination Of Supply
                      </FormLabel>
                      {this.renderView(expenseForm.destination_of_supply)}
                    </>
                  ) : (
                    <OutlinedSelect
                      val={expenseForm.destination_of_supply}
                      options={[
                        { id: "TAX1", name: "TAX1" },
                        { id: "TAX2", name: "TAX2" },
                        { id: "TAX3", name: "TAX3" }
                      ]}
                      label="Destination Of Supply*"
                      placeholder="Select"
                      name={"Destination Of Supply"}
                      handleChange={this.handleDestinationSupplyChange}
                    />
                  )}
                </Grid>
              </Grid>
              <Grid container direction="row" style={{ marginTop: 10 }}>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  style={{ marginTop: 30, paddingRight: 12 }}
                >
                  {formMode === FormMode.View ? (
                    <>
                      <FormLabel style={{ marginBottom: "8px" }}>
                        GST Treatment
                      </FormLabel>
                      {this.renderView(expenseForm.gst_treatment)}
                    </>
                  ) : (
                    <OutlinedSelect
                      val={expenseForm.gst_treatment}
                      options={this.state.gstTreatments.map(el => ({
                        id: el.name,
                        name: el.name
                      }))}
                      label="GST Treatment*"
                      placeholder="Select"
                      name={"GST Treatment"}
                      handleChange={this.handleGstTreatmentChange}
                    />
                  )}
                </Grid>
              </Grid>
              <Grid container direction="row" style={{ marginTop: 10 }}>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  style={{ marginTop: 30, paddingRight: 12 }}
                >
                  <FormControl style={{ width: "100%" }}>
                    <FormLabel style={{ marginBottom: "8px" }}>
                      Attach Expense Receipt
                    </FormLabel>
                    {formMode === FormMode.View ? (
                      this.renderView(expenseForm.expense_recipt, "#FF4162")
                    ) : (
                      <div
                        style={{
                          borderColor: expenseForm.expense_recipt
                            ? "#FD4553"
                            : "#556479"
                        }}
                        className={classes.uploadIconContainer}
                        onClick={() => this.fileInputRef.click()}
                      >
                        <input
                          ref={ref =>
                            (this.fileInputRef = ref as HTMLInputElement)
                          }
                          type={"file"}
                          // accept={".jpeg, .jpg, .png, .JPEG, .PNG, .JPG"}
                          style={{ display: "none" }}
                          name={"file"}
                          onChange={(e: any) =>
                            this.handleFileChange(e.target.files)
                          }
                        />

                        <div
                          style={{
                            color: expenseForm.expense_recipt
                              ? "#FD4553"
                              : "#556479"
                          }}
                        >
                          {expenseForm.expense_recipt
                            ? expenseForm.expense_recipt.name
                            : "Upload File"}
                        </div>
                        <img
                          style={{ width: "33px", color: "#D07A85" }}
                          src={uploadIcon}
                        />
                      </div>
                    )}
                  </FormControl>
                </Grid>
              </Grid>

              <Grid
                item
                xs={12}
                sm={8}
                style={{ marginTop: 30, paddingRight: 12 }}
              >
                <FormControl style={{ width: "100%" }}>
                  <FormLabel style={{ marginBottom: "8px" }}>
                    Reverse Charge
                  </FormLabel>
                  {formMode === FormMode.View ? (
                    this.renderView(
                      expenseForm.reverse_charge
                        ? "This transaction is acceptable for reverse charge"
                        : "This transaction is not acceptable for reverse charge"
                    )
                  ) : (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={expenseForm.reverse_charge}
                          name="is_sub_account"
                        />
                      }
                      label="This transaction is acceptable for reverse charge"
                      onChange={this.handleReverseChargeChange}
                    />
                  )}
                </FormControl>
              </Grid>

              {expenseForm.reverse_charge && (
                <Grid container direction="row" style={{ marginTop: 10 }}>
                  <Grid
                    item
                    xs={12}
                    sm={4}
                    style={{ marginTop: 30, paddingRight: 12 }}
                  >
                    {formMode === FormMode.View ? (
                      <>
                        <FormLabel style={{ marginBottom: "8px" }}>
                          Reverse Charge Account
                        </FormLabel>
                        {this.renderView(expenseForm.reverse_charge_account)}
                      </>
                    ) : (
                      <OutlinedSelect
                        val={expenseForm.reverse_charge_account}
                        options={[
                          { id: "ACCOUNT1", name: "ACCOUNT1" },
                          { id: "ACCOUNT2", name: "ACCOUNT2" },
                          { id: "ACCOUNT3", name: "ACCOUNT3" }
                        ]}
                        label="Reverse Charge Account"
                        placeholder="Select Reverse Charge Account"
                        handleChange={this.handleReverseChargeAccountChange}
                        className={undefined}
                      />
                    )}
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={4}
                    style={{ marginTop: 30, paddingRight: 12 }}
                  >
                    <FormControl style={{ width: "100%" }}>
                      <FormLabel style={{ marginBottom: "8px" }}>
                        Reverse Charge
                      </FormLabel>

                      {formMode === FormMode.View ? (
                        this.renderView(
                          expenseForm.reverse_charge_ammount +
                            " " +
                            expenseForm.reverse_charge_type
                        )
                      ) : (
                        <OutlinedInput
                          type={"text"}
                          value={expenseForm.reverse_charge_ammount}
                          onChange={this.handleReverseChargeAmountChange}
                          endAdornment={
                            <InputAdornment position="end">
                              <div>
                                <Select
                                  value={expenseForm.reverse_charge_type}
                                  onChange={this.handleReverseChargeTypeChange}
                                  className={classes.reverseTypeSelect}
                                >
                                  <MenuItem value={"₹"}>₹</MenuItem>
                                  <MenuItem value={"%"}>%</MenuItem>
                                </Select>
                              </div>
                            </InputAdornment>
                          }
                        />
                      )}
                    </FormControl>
                  </Grid>
                </Grid>
              )}

              <Grid container direction="row" style={{ marginTop: 10 }}>
                <Grid
                  item
                  xs={12}
                  sm={9}
                  style={{ marginTop: 30, paddingRight: 12 }}
                >
                  <FormControl style={{ width: "100%" }}>
                    <FormLabel style={{ marginBottom: "8px" }}>
                      Comments
                    </FormLabel>
                    {formMode === FormMode.View ? (
                      this.renderView(expenseForm.comment)
                    ) : (
                      <TextField
                        rows={3}
                        style={{ width: "100%", borderRadius: "6px" }}
                        name={"Comments"}
                        placeholder={"Enter Comment"}
                        onChange={this.handleCommentChange}
                        multiline
                        value={expenseForm.comment}
                        color={"primary"}
                        variant="outlined"
                        type={"text"}
                      />
                    )}
                  </FormControl>
                </Grid>
              </Grid>
            </div>
            {formMode === FormMode.Create && (
              <div style={{ marginLeft: 25, marginBottom: 25 }}>
                <Button
                  title="Cancel"
                  buttonType={ButtonType.Cancel}
                  onClick={() => this.props.navigation.navigate("Expense")}
                />

                <Button
                  title="Create"
                  buttonType={ButtonType.Primary}
                  style={{ marginLeft: 20 }}
                  onClick={() => this.onCreateExpense(expenseForm)}
                />
              </div>
            )}
            {formMode === FormMode.Edit && (
              <div style={{ marginLeft: 25, marginBottom: 25 }}>
                <Button
                  title="Cancel"
                  buttonType={ButtonType.Cancel}
                  onClick={() => this.props.navigation.navigate("Expense")}
                />

                <Button
                  title="Update"
                  buttonType={ButtonType.Primary}
                  style={{ marginLeft: 20 }}
                  onClick={() => this.onEditExpense(expenseForm)}
                />
              </div>
            )}
            {formMode === FormMode.View && (
              <div style={{ marginLeft: 10, marginBottom: 25 }}>
                <Button
                  title="Back To Listing"
                  buttonType={ButtonType.Primary}
                  style={{ marginLeft: 20 }}
                  onClick={() => this.props.navigation.navigate("Expense")}
                />
              </div>
            )}
          </Grid>
        </div>
        <VendorSelectionModal
          title={"Advanced vendor search"}
          open={this.state.vendorModalOpen}
          handleClose={this.onCloseVendorModal}
          // handleSubmit={this.onSubmitDeleteModal.bind(this)}
        />
      </Layout>
    );
    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End
}

export default withStyles(useStyles)(CreateUpdateExpense);
