export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const actionIcon = require("../assets/actionIcon.png");
export const deleteIcon = require("../assets/deleteIcon.png");
export const viewIcon = require("../assets/viewIcon.png");
export const editIcon = require("../assets/editIcon.png");
export const printIcon = require("../assets/printIcon.png");
export const importExportIcon = require("../assets/export-import-Icon.png");
export const filterIcon = require("../assets/filterIcon.png");
export const bulkUpdateIcon = require("../assets/bulkUpdateIcon.png");
export const SearchImg = require("../assets/searchImg.png");
export const installmentIcon = require("../assets/installmentIcon.png");
