import React from "react";
// Customizable Area Start
import { withStyles, Theme } from "@material-ui/core/styles";
import { Styles } from "@material-ui/styles";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import Layout from "../../CommonLayout/Layout/src/Layout.web";
import Pagination from "../../CommonLayout/Pagination/src/Pagination.web";
import CustomDeleteModal from "../../CommonLayout/CustomDeleteModal/src/CustomDeleteModal.web";
import { IconButton } from "../../CommonLayout/HRMSCommons/src/button/iconButton";
import { Icon } from "../../CommonLayout/HRMSCommons/src/assets";
import { ActionMenu } from "../../CommonLayout/HRMSCommons/src/actionMenu";
import PageHeader from "../../CommonLayout/HRMSCommons/src/layout/PageHeader";
import Loader from "../../../components/src/Loader.web";
import { classNames } from "../../CommonLayout/HRMSCommons/src/classNames";
import OutlinedSelect from "../../../components/src/OutlinedSelect";
import CustomerListForm from "./CustomerListForm.web";
import FormLabel from "@material-ui/core/FormLabel";
import TextField from "@material-ui/core/TextField";
// Customizable Area End

import CustomerListController, { Props } from "./CustomerListController";

// Customizable Area Start
const useStyles: Styles<Theme, {}, string> = (theme: Theme) => ({
  container: {
    minHeight: "calc(100vh - 120px)",
    maxHeight: "calc(100vh - 120px)",
    flexDirection: "column",
    flexWrap: "nowrap"
  },
  emptyDash: {
    height: 1,
    width: 16,
    backgroundColor: "#64748c"
  },
  filterItemContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    flex: 1,
    marginTop: 20
  },
  mt20: {
    marginTop: 20
  },
  actionColumn: {
    maxWidth: "100px",
    width: "100px"
  },
  tableContainer: {
    position: "relative",
    flexGrow: 1,
    boxShadow: "0px 5px 10px 0px #d9d9d9",
    borderRadius: 10
  },
  table: {
    borderCollapse: "separate",
    borderSpacing: "0 10px",
    padding: "0 25px",

    "& td": {
      backgroundColor: "#F9F9F9",
      border: "none"
    }
  },
  actionColumnButton: {
    margin: 0
  },
  checkbox: {
    padding: 0
  },
  tableHead: {
    backgroundColor: "#FCF3D4"
  },
  capitalizeFirst: {
    textTransform: "capitalize"
  }
});
// Customizable Area End

class CustomerList extends CustomerListController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    console.log("merhaba");
    // Customizable Area Start
    const { classes, navigation } = this.props;
    const {
      pageMeta: { total_pages },
      isLoading,
      customerList,
      anchorEl,
      actionMenuItems,
      deleteModalOpen,
      customerFormMode,
      selectAllCheckboxChecked,
      currentPage,
      customerType,
      searchKeyword,
      closeImportModal
    } = this.state;

    return (
      <Layout
        searchKeyword={searchKeyword}
        handleGlobalSearch={this.handleSearchChange}
        navigation={navigation}
        //hideSearchBar
      >
        <Grid container className={classes.container}>
          <PageHeader
            FilterTitle="Filters"
            title="Customers"
            createButtonTitle={"Create"}
            importModalTitle="Import customer by CSV file"
            printReference={this.printReferance}
            filterForm={this.renderFilterForm()}
            onClickCreateButton={this.onClickCreateCustomer.bind(this)}
            onUploadCSVForImport={this.onUploadCSVForImport.bind(this)}
            onClickExportCSVButton={this.onClickExportCSVButton.bind(
              this,
              false
            )}
            onFilterFormSubmit={this.onFilterFormSubmit.bind(this)}
            onClickDownloadCSVTemplate={this.onClickExportCSVButton.bind(
              this,
              true
            )}
            closeImportModal={closeImportModal}
            haveCreateButton
          />

          <TableContainer
            style={{ overflow: isLoading ? "hidden" : "" }}
            component={Paper}
            className={classNames(classes.mt20, classes.tableContainer)}
            ref={(el: any) => (this.printReferance = el)}
          >
            {isLoading && <Loader loading positionType={"center"} />}

            <Table
              ref={this.tableRef}
              stickyHeader
              className={classes.table}
              aria-label="customer list"
            >
              <TableHead>
                <TableRow>
                  <TableCell
                    align="center"
                    className={classNames(
                      classes.actionColumn,
                      classes.tableHead
                    )}
                  >
                    <Checkbox
                      className={classes.checkbox}
                      inputProps={{ "aria-label": "select all customer list" }}
                      onChange={this.onSelectAllCustomerList.bind(this)}
                      indeterminate={
                        selectAllCheckboxChecked === "indeterminate"
                      }
                      checked={!!selectAllCheckboxChecked}
                      key={`customer-list-super-checkbox-${selectAllCheckboxChecked}`}
                    />
                  </TableCell>
                  <TableCell className={classes.tableHead}>
                    Customer Name
                  </TableCell>
                  <TableCell className={classes.tableHead}>
                    Customer Type
                  </TableCell>
                  <TableCell className={classes.tableHead}>
                    Contact Number
                  </TableCell>
                  <TableCell className={classes.tableHead}>Email ID</TableCell>
                  <TableCell className={classes.tableHead}>
                    Company Email
                  </TableCell>
                  <TableCell className={classes.tableHead} align="center" />
                </TableRow>
              </TableHead>
              <TableBody>
                {customerList.map(row => {
                  const {
                    id,
                    selected,
                    attributes: {
                      customer_name,
                      customer_type,
                      contact_no,
                      email,
                      company_email,
                      country_code
                    }
                  } = row;
                  return (
                    <TableRow
                      key={`${customer_type === "business" &&
                        "b"}-customer-${id}`}
                    >
                      <TableCell
                        align="center"
                        className={classes.actionColumn}
                      >
                        <Checkbox
                          className={classes.checkbox}
                          value={selected}
                          checked={selected}
                          inputProps={{ "aria-label": "select tax rate" }}
                          key={`customer-${id}-checkbox-${selected}`}
                          onChange={event =>
                            this.onSelectCustomerList(event, row)
                          }
                        />
                      </TableCell>
                      <TableCell className={classes.capitalizeFirst}>
                        {customer_name}
                      </TableCell>
                      <TableCell className={classes.capitalizeFirst}>
                        {customer_type}
                      </TableCell>
                      <TableCell>
                        {country_code} {contact_no}
                      </TableCell>
                      <TableCell>{email}</TableCell>
                      {company_email ? (
                        <TableCell>{company_email}</TableCell>
                      ) : (
                        <TableCell style={{ textAlign: "center" }}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center"
                            }}
                          >
                            <div
                              style={{ marginRight: 2 }}
                              className={classes.emptyDash}
                            />
                            <div className={classes.emptyDash} />
                          </div>
                        </TableCell>
                      )}
                      <TableCell
                        align="center"
                        className={classes.actionColumn}
                      >
                        <IconButton
                          icon={Icon.Action}
                          borderless
                          className={classes.actionColumnButton}
                          onClick={event =>
                            this.onActionButtonClick(event, row)
                          }
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
                {!customerList.length && !isLoading && (
                  <TableRow>
                    <TableCell
                      colSpan={12}
                      style={{
                        color: "red",
                        fontWeight: 600,
                        fontSize: "18px",
                        textAlign: "center"
                      }}
                    >
                      {"No Record Found"}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          <Grid container justify="flex-end" alignItems="center">
            <Pagination
              page={currentPage - 1}
              totalPageCount={total_pages}
              handlePageClick={this.onClickPagination.bind(this)}
            />
          </Grid>
        </Grid>

        <ActionMenu
          anchorEl={anchorEl}
          onClose={this.onCloseActionMenu.bind(this)}
          items={actionMenuItems}
          arrowSide="right"
          anchorOrigin={{ vertical: -12, horizontal: -48 }}
        />

        <CustomDeleteModal
          DialogueDeletetitle={"Delete Customer"}
          open={deleteModalOpen}
          handleClose={this.onCloseDeleteModal.bind(this)}
          handleSubmit={this.onSubmitDeleteModal.bind(this)}
          msgHandle={this.state.deleteMessage}
        />

        <CustomerListForm
          {...this.customerFormProps[customerFormMode]}
          isOpen={this.state.isCustomerFormModalOpened}
          initialValues={this.state.customerForm}
          businessInitialValues={this.state.businessCustomerForm}
          handleCustomerType={this.handleCustomerType.bind(this)}
          customerType={customerType}
        />
      </Layout>
    );
    // Customizable Area End
  }

  // Customizable Area Start
  private renderFilterForm() {
    const { classes } = this.props;
    const {
      filterForm: {
        customer_type,
        company_name,
        primary_contact,
        secondary_contact,
        contact_number,
        email
      }
    } = this.state;

    return (
      <Grid container direction="column">
        <Grid
          item
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between"
          }}
        >
          <div
            style={{ marginRight: 16, marginTop: 10 }}
            className={classes.filterItemContainer}
          >
            <FormLabel>Customer Type</FormLabel>
            <OutlinedSelect
              val={customer_type}
              options={[
                { id: "individual", name: "Individual" },
                { id: "business", name: "Business" }
              ]}
              label=""
              name={"customer_type"}
              formControlStyle={{
                margin: 0
              }}
              handleChange={(e: any) =>
                this.onFilterElementChange(e, "customer_type")
              }
            />
          </div>

          <div
            style={{ marginTop: 10 }}
            className={classes.filterItemContainer}
          >
            <FormLabel style={{ marginBottom: "8px" }}>Company Name</FormLabel>
            <TextField
              value={company_name}
              color={"primary"}
              variant="outlined"
              type={"text"}
              name={"first_name"}
              placeholder={"Enter Name"}
              onChange={e => this.onFilterElementChange(e, "company_name")}
            />
          </div>
        </Grid>

        <Grid
          item
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between"
          }}
        >
          <div
            style={{ marginRight: 16 }}
            className={classes.filterItemContainer}
          >
            <FormLabel style={{ marginBottom: "8px" }}>
              Primary Contact
            </FormLabel>
            <TextField
              value={primary_contact}
              color={"primary"}
              variant="outlined"
              type={"text"}
              name={"primary_contact"}
              placeholder={"Enter Primary Contact"}
              onChange={e => this.onFilterElementChange(e, "primary_contact")}
            />
          </div>

          <div className={classes.filterItemContainer}>
            <FormLabel style={{ marginBottom: "8px" }}>
              Secondary Contact
            </FormLabel>
            <TextField
              value={secondary_contact}
              color={"primary"}
              variant="outlined"
              type={"text"}
              name={"secondary_contact"}
              placeholder={"Enter Secondary Contact"}
              onChange={e => this.onFilterElementChange(e, "secondary_contact")}
            />
          </div>
        </Grid>

        <Grid
          item
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between"
          }}
        >
          <div
            style={{ marginRight: 16 }}
            className={classes.filterItemContainer}
          >
            <FormLabel style={{ marginBottom: "8px" }}>Email</FormLabel>
            <TextField
              value={email}
              color={"primary"}
              variant="outlined"
              type={"text"}
              name={"email"}
              placeholder={"Enter Email Id"}
              onChange={e => this.onFilterElementChange(e, "email")}
            />
          </div>

          <div className={classes.filterItemContainer}>
            <FormLabel style={{ marginBottom: "8px" }}>
              Contact Number
            </FormLabel>
            <TextField
              value={contact_number}
              color={"primary"}
              variant="outlined"
              type={"text"}
              name={"contact_number"}
              placeholder={"Enter Contact Number"}
              onChange={e => this.onFilterElementChange(e, "contact_number")}
            />
          </div>
        </Grid>
      </Grid>
    );
  }
  // Customizable Area End
}

export default withStyles(useStyles)(CustomerList);
