import React from "react";
import {
  makeStyles,
  Theme,
  createStyles,
  withStyles,
} from "@material-ui/core/styles";
import clsx from "clsx";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import VideoLabelIcon from "@material-ui/icons/VideoLabel";
import StepConnector from "@material-ui/core/StepConnector";
import { StepIconProps } from "@material-ui/core/StepIcon";
import StarOutlineIcon from "@material-ui/icons/StarOutline";

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: "34px",
    marginRight: "-15px",
    zIndex:1,
    marginLeft: "-15px"
  },
  active: {
    "& $line": {
      borderImageSource: "linear-gradient(84deg, #ea5157 100%, #ea5157 100%)",
    },
  },
  completed: {
    "& $line": {
      borderImageSource: "linear-gradient(84deg, #ea5157 100%, #ea5157 100%)",
    },
  },
  line: {
    borderTopWidth: "initial",
    // border: 0,
    // backgroundColor: "#eaeaf0",
    // borderRadius: 1,
    width: "auto",
    borderStyle: "solid",
    borderImageSlice: 1,
    // borderImageSource: "linear-gradient(84deg, #ea5157 100%, #ea5157 100%)"
  },
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: "red",
    zIndex: 1,
    color: "#fff",
    width: "46px",
    height: "46px",
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "22px",
    fontWeight: 600,
    fontFamily: "Open sans"
  },

  active: {
    backgroundImage: "linear-gradient(322deg, #ea5157 0%, #ea5157 118%)",
  },
  completed: {
    backgroundImage: " linear-gradient(322deg, #ea5157 0%, #ea5157 118%)",
  },
});

function ColorlibStepIcon(props: StepIconProps) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;
  const icons: { [index: string]: React.ReactElement } = {
    1: <span>1</span>,
    2: <span>2</span>,
    3: <span>3</span>,
    4: <StarOutlineIcon />,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "96%",
      margin:"20px"
    },
    button: {
      marginRight: theme.spacing(1),
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    extraLine:{
      position: "absolute",
      top: "78px",
      left: "69px",
      backgroundColor: "#ea5157",
      border: "solid #ea5157",
      borderTopWidth: "initial",
      width: "120px",
      minWidth: "350px",
      borderRadius: "12px"
    },
    extraLine2:{
      position: "absolute",
      top: "78px",
      right: "65px",
      width: "120px",
      minWidth: "350px",
      backgroundColor: "#d6d5d5",
      border: "solid #d6d5d5",
      borderTopWidth: "initial",
      // width: "110px",
      borderRadius: "12px",
      zIndex:0
      // opacity:"0.2"
    },
    extraLine3:{
     
      position: "absolute",
      top: "78px",
      right: "65px",
      width: "120px",
      minWidth: "350px",
      backgroundColor: "#ea5157",
      border: "solid #ea5157",
      borderTopWidth: "initial",
      // width: "110px",
      borderRadius: "12px",
      // opacity:"0.2"
    }
  })
);

export default function Steppers(props: any) {
  const classes = useStyles();
console.log("stepLabel",props)
  return (
    <div className={classes.root}>
      <span className={classes.extraLine}></span>
      <Stepper
        alternativeLabel
        activeStep={props.currentForm === 'installmentPlanForm' ? props.activeStep +1:props.activeStep}
        connector={<ColorlibConnector />}
      >
        {props.stepLabel.map((label: any) => (
          
          <Step key={label}>
            {/* StepIconComponent={ColorlibStepIcon} */}
            <StepLabel >{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <span  className={props.currentForm === 'installmentPlanForm' ?classes.extraLine3 :classes.extraLine2}></span>
    </div>
  );
}
