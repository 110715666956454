import React, { Component } from 'react';
// Customizable Area Start

import {
  makeStyles,
  Theme,
  createStyles,
  withStyles,
  
} from "@material-ui/core/styles";
import {
    TextField,
    Typography
} from "@material-ui/core";
import Grid from '@material-ui/core/Grid';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import OutlinedSelect from "../../../components/src/OutlinedSelect";
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Icon from '@material-ui/core/Icon';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import Paper from '@material-ui/core/Paper';
import { deleteIcon } from "./assets";
import { IconButton as MUIIConButton } from "@material-ui/core";
import {feeAssignmentData,subFeeFieldAttributes,taxsAttributes} from './FeeAssignmentController';

interface feeStructureInitialField {
  id: number,
  amount: string,
  tax_ids: string[],
}

interface feeAssignmentFormstate {
  feeAssignmentType: string;
  academic_year_id: number;
  grade_id: number;
  division_id: number;
  grade: string,
  division: string,
  name: string;
  is_class_and_division: boolean,
  is_group: boolean,
  is_class: boolean,
  fee_type: string,
  sub_fee_structures_attributes: feeStructureInitialField[]
}

type MyProps = { 
  handleChange: (e: any) => void,feeAssignmentFormstate: feeAssignmentFormstate, 
  handleFeeStructureField : (e:React.ChangeEvent<HTMLInputElement>, index: number) => void,
  handleRemoveInstallmentStructure: (index:number) => void,
  feeList: [],
  taxList: [],
  feeStructureError:any
  viewForm: boolean,
  feeAssignmentData:feeAssignmentData,
};
// Customizable Area End

export default class FeeStructureForm extends Component<MyProps> {
  render() {
    // Customizable Area Start

    const { 
      feeAssignmentFormstate, 
      handleChange ,
      handleFeeStructureField,
      feeList,
      taxList,
      feeStructureError,
      viewForm,
      feeAssignmentData,
      handleRemoveInstallmentStructure} = this.props;
      // Customizable Area End

  return (
    // Customizable Area Start

    viewForm ?
    <>
      <Grid
        container
        spacing={3}
        style={{
            marginTop: "10px",
            marginBottom: "8px"
        }}
      >
        <Grid style={{ color: "#465872", fontWeight: "bold", marginBottom: "8px" }} xs={6} item>Fee Type
          <RadioGroup row  aria-label="gender" value={feeAssignmentData?.attributes.fee_type}>
            <FormControlLabel  
              disabled
              style={{
                    color: "#ea5157"
              }} 
              value="academic_fee" 
              control={<Radio  />} 
              label="Academic Fee" 
            />
            <FormControlLabel 
              disabled
              style={{
                color: "#ea5157"
              }} 
              value="other_fee" 
              control={<Radio />} 
              label="Other Fee" 
            />
          </RadioGroup>
        </Grid>
      </Grid>
      {

        feeAssignmentData.attributes.sub_fee_structures.data.map((fieldData: subFeeFieldAttributes, indx: number) => {
          const Taxnames = fieldData.attributes.taxs.map((itm:taxsAttributes) => itm.name);
          return (
            <Grid key={indx}
            component={Paper}
            container
            spacing={3}
            style={{
                marginTop: "10px",
                marginBottom: "10px",
                flexGrow: 1,
                boxShadow: "0px 5px 10px 0px #d9d9d9",
                borderRadius: 10,
                height: "120px"
            }}
            >
              <Grid style={{ color: "#465872", fontWeight: "bold", padding: "18px", width:"32%" }} item>Fee Name*
                <Typography style={{color: "#7e7d7d", marginTop: "16px"}}>{fieldData?.attributes.fee_name}</Typography>
              </Grid>
              <Grid style={{ color: "#465872", fontWeight: "bold", padding: "18px", width:"32%" }} item>Fee Amount*
                <Typography style={{color: "#7e7d7d", marginTop: "16px"}}>{fieldData?.attributes.amount}</Typography>
              </Grid>
              <Grid style={{ color: "#465872", fontWeight: "bold", width:"32%", padding: "18px"}} item>Tax*
                <Typography style={{color: "#7e7d7d", marginTop: "16px"}}>{Taxnames ? Taxnames.join(", "): ''}</Typography>
              </Grid>
              <Grid item style={{width:"4%", padding: "0px",backgroundColor:"#fcf3d4"}}>      
              </Grid>
            </Grid>
          );
        })
      }
    </>:
    <>
      <Grid
      container
      spacing={3}
      style={{
          marginTop: "10px",
          marginBottom: "8px"
      }}
      >
        <Grid item xs={6}>
          <FormControl component="fieldset">
              <FormLabel style={{ marginBottom: "8px" }}>
                  Fee Type
              </FormLabel>
            <RadioGroup row  aria-label="gender" value={feeAssignmentFormstate.fee_type} onChange={handleChange} name="fee_type">
              <FormControlLabel  
                style={{
                      color: "#3f5071"
                }} 
                value="academic_fee" 
                control={<Radio  />} 
                label="Academic Fee" 
              />
              <FormControlLabel 
                style={{
                  color: "#3f5071"
                }} 
                value="other_fee" 
                control={<Radio />} 
                label="Other Fee" 
              />
            </RadioGroup>
          </FormControl>
        </Grid>
      </Grid>
      {
        feeAssignmentFormstate.sub_fee_structures_attributes.map((fieldData: feeStructureInitialField, indx: number) => (
        <Grid key={indx}
        component={Paper}
        container
        spacing={3}
        style={{
            marginTop: "10px",
            marginBottom: "10px",
            flexGrow: 1,
            boxShadow: "0px 5px 10px 0px #d9d9d9",
            borderRadius: 10,
            height: "120px"
        }}
        >
          <Grid item style={{width:"32%"}}>
                <OutlinedSelect
                    placeholderTitle="Select Fee Name"
                    val={fieldData.id}
                    options={feeList}
                    label="Fee Name*"
                    name={'id'}
                    className={undefined} 
                    handleChange={(evt: React.ChangeEvent<HTMLInputElement>) => handleFeeStructureField(evt, indx)}      
                />
              {feeStructureError[indx].feeNameError && (
              <p style={{ color: "red", marginTop: "2px",
              fontSize: "14px",
              marginLeft: "3px" }}>{feeStructureError[indx].feeNameError}</p>
            )}
          </Grid>
          <Grid item style={{width:"31%"}}>
            <FormControl style={{ minWidth: "100%" }}>
                <FormLabel style={{ marginBottom: "8px" }}>
                    Fee Amount*
                </FormLabel>
                <TextField
                    value={fieldData.amount}
                    type={'text'}
                    placeholder={`Fee Amount`}
                    variant="outlined"
                    name={'amount'}
                    onChange={(evt: React.ChangeEvent<HTMLInputElement>) => handleFeeStructureField(evt, indx)}
                />
                  {/* {feeAmountError && (
              <p style={{ color: "red" }}>{feeAmountError}</p>
            )} */}
            
            </FormControl>
            {feeStructureError[indx].feeAmountError && (
              <p style={{ color: "red",    marginTop: "2px",
              fontSize: "14px",
              marginLeft: "3px" }}>{feeStructureError[indx].feeAmountError}</p>
            )}
          </Grid>
          <Grid item style={{width:"32%"}}>
                <OutlinedSelect
                placeholderTitle="Select Tax"
                val={fieldData.tax_ids}
                options={taxList}
                label="Tax*"
                name={'tax_ids'}
                handleChange={(evt: React.ChangeEvent<HTMLInputElement>) => handleFeeStructureField(evt, indx)}
                multiple={true} 
                className={undefined}  
               />
               {feeStructureError[indx].feeTaxError && (
              <p style={{ color: "red",    marginTop: "2px",
              fontSize: "14px",
              marginLeft: "3px" }}>{feeStructureError[indx].feeTaxError}</p>
            )}
                 {/* {feeTaxError && (
              <p style={{ color: "red" }}>{feeTaxError}</p>
            )} */}
          </Grid>
          <Grid item style={{width:"5%", padding: "0px",backgroundColor:"#fcf3d4"}}>
                <MUIIConButton 
                  disabled={feeAssignmentFormstate.sub_fee_structures_attributes.length > 1 ? false : true}
                  onClick={(evt: object) => handleRemoveInstallmentStructure(indx)}
                  style={{marginTop: "38px",opacity:feeAssignmentFormstate.sub_fee_structures_attributes.length > 1 ? 1 : 0.4}}
                    >
                    <img height="20px" width="20px" src={deleteIcon} />
                </MUIIConButton>
              
          </Grid>
        </Grid>
        ))
      }
    </>
    // Customizable Area End

  );
  }
}
