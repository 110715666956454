import React, { Component } from 'react';
// Customizable Area Start
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withStyles, Theme } from '@material-ui/core/styles';
import { formSubmitBtn, formCancelBtn } from "../../CommonLayout/Layout/src/color";
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import { Styles } from '@material-ui/styles';

import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';

import {
  StyleSheet,
  Platform
} from "react-native";
import { Checkbox, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@material-ui/core";
import { capitalizeFirst } from "framework/src/Utilities";
import OutlinedSelect from '../../../components/src/OutlinedSelect';
import { SearchImg } from "./assets";
import { filterIcon } from "./assets";
import { IconButton as MUIIConButton, Popover } from "@material-ui/core";
import { ButtonTheme } from "../../CommonLayout/HRMSCommons/src/button/default";

const useStyles: Styles<Theme, {}, string> = (theme: Theme) => ({
  dialogPaper: {
    // maxWidth: "188vh",
    // minHeight: "286px",
    borderRadius: "26px",
    minWidth: "800px",

  },
  paperWidthSm: {
    minWidth: "300px",
    maxHeight: "725px",
  },
  tableContainer: {
    position: "relative",
    flexGrow: 1,

    marginTop: "14px",
    boxShadow: "0px 5px 10px 0px #d9d9d9",
    height: "300px !important",
    borderRadius: 10
  },
  table: {
    borderCollapse: "separate",
    // borderSpacing: "0 10px",
    // padding: "0 25px",

    "& td": {
      backgroundColor: "#F9F9F9",
      border: "none"
    }
  },
  tableHead: {
    padding: "15px",
    backgroundColor: "#FCF3D4",
    fontSize: "16px"
  },
  tableGrid: {
    paddingTop: "20px",
  },
  activeBtn: {
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 16,
    padding: '8px 40px',
    border: '1px solid',
    lineHeight: 1.5,
    background: formSubmitBtn,
    borderRadius: "8px",
    //borderColor: '#ea5c6b',
    color: "white",
    '&:hover': {
      background: formSubmitBtn,
    },
    '&:active': {
      boxShadow: 'none',
      border: "none",
      outline: 0,
    },
  },
  iconButton: {
    marginRight: 0,
    borderRadius: "5px",
    border: `1px solid #c4c4c4`,
    backgroundColor: "#ffffff",
    padding: "18px",
    '&:hover': {
      border: `1px solid ${ButtonTheme.PrimaryBackground}}`,
      borderColor: ButtonTheme.PrimaryBackground,
      color: "white",
      opacity: "1",
    },
    '&:active': {
      border: `1px solid ${ButtonTheme.PrimaryBackground}}`,
      borderColor: ButtonTheme.PrimaryBackground,
      opacity: "1",
      boxShadow: 'none',
      color: "white",
    }, '&:focus': {
      border: `1px solid ${ButtonTheme.PrimaryBackground}}`,
      borderColor: ButtonTheme.PrimaryBackground,
    },
  },
  subTitle: {
    marginBottom: "18px",
  },
  filterTitle: {
    color: "#FC585D",
    borderBottom: "1px solid #CCC",
    marginBottom: 10
  },
  actionButtons: {
    display: "flex",
    justifyContent: "left",
    marginTop: 10
  },
  // containBox: {
  //   padding: "30px",
  //   margin: "4px"
  // }
});
interface feeStructuresAttribute {
  name: string,
  status: string
}

interface studentData {
  id: string,
  name: string,
  email: string,
  fee_structures: feeStructuresAttribute[]
}

interface feeAssignmentFormstate {
  feeAssignmentType: string;
  academic_year_id: number;
  grade_id: number;
  division_id: number;
  name: string;
  is_class_and_division: boolean,
  is_group: boolean,
  is_class: boolean,
}
type MyProps = {
  classes: any
  open: boolean
  handleFilterButton: (event: any) => void
  filterModalStatus: HTMLElement | null
  onCloseFilterPopover: () => void
  handelCancel: () => void
  academicClassList: []
  divisionList: []
  handleChange: (event: any) => void;
  filterStudenthandleChange: (event: any) => void;
  studentListerrorMessage: string;
  studentList: [];
  feeAssignmentFormstate: feeAssignmentFormstate;
  allStudentRowsChecked:any
  handleCheckAllStudents:any
  handleCheckStudentItem:any,
  checkStudentRow:any,
  studentNext:(e:any) => void,
  feeStructureNameList: [];
  studentFilterApply: () => void;
  studentFilterFeeStrucure:number;
  studentFilterStatus:string;
  filterStudentError: string;
  searchStudentKeyword: string;
  handleSearchStudentChange: (e:any) => void;
};
// Customizable Area End

class StudentList extends Component<MyProps> {

  // Customizable Area Start

  // Customizable Area End
 
  render() {
    // Customizable Area Start
    console.log('studnt', this.props.studentList)
    // Customizable Area End
    return (
      // Customizable Area Start
      //Merge Engine DefaultContainer
      <>
        <Dialog
          open={this.props.open}
          aria-labelledby="simple-dialog-title"
          style={{ border: "1px solid white", borderRadius: "10px" }}
          className=''
          classes={{ paper: this.props.classes.dialogPaper }}
        >

          <DialogTitle style={{ textAlign: "center" }} id="alert-dialog-title">
            <div style={{
              fontWeight: "bold",
              fontSize: "18px",
              color: "#44556c",
            }}>Add Students</div>
          </DialogTitle>

          <DialogContent>
            <Grid
              container
              justify={'space-between'}
              style={{
                marginTop: "10px",
              }}
            >
              <Grid item xs={12}>
                <FormControl style={{ width: "89%", marginRight: "22px" }} variant="outlined">
                  <OutlinedInput
                    id="outlined-adornment-weight"
                    value={this.props.searchStudentKeyword}
                    placeholder={'Search for a partcipants'}
                    onChange={this.props.handleSearchStudentChange}
                    //onChange={this.props.handleChange}
                    startAdornment={<InputAdornment position="start"><img height="18px" width="auto" src={SearchImg} /></InputAdornment>}
                    aria-describedby="outlined-weight-helper-text"
                    inputProps={{
                      'aria-label': 'weight',
                    }}
                    labelWidth={0}
                  />
                </FormControl>
                <MUIIConButton
                  onClick={this.props.handleFilterButton}
                  className={this.props.classes.iconButton}
                >
                  <img height="18px" width="auto" src={filterIcon} />
                </MUIIConButton>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={3}
              style={{
                marginTop: "10px",
                marginBottom: "10px",
              }}
            >
              <Grid item xs={6}>
                <OutlinedSelect
                  placeholderTitle="Select Class"
                  val={this.props.feeAssignmentFormstate.grade_id}
                  options={this.props.academicClassList}
                  label="Class*"
                  name={'grade_id'}
                  handleChange={this.props.handleChange} className={undefined}                />
              </Grid>
              <Grid item xs={6}>
                <OutlinedSelect
                  placeholderTitle="Select Division"
                  val={this.props.feeAssignmentFormstate.division_id}
                  options={this.props.divisionList}
                  label="Division"
                  name={'division_id'}
                  handleChange={this.props.handleChange} className={undefined}                />
              </Grid>
            </Grid>
            <Grid>
            
              <TableContainer component={Paper} className={this.props.classes.tableContainer}>
                {/* {this.state.showLoader && <Loader loading={this.state.showLoader} positionType={'center'} />} */}
                <Table className={this.props.classes.table} aria-label="a dense table">

                  <TableHead style={{ position: "sticky", top: "0px", zIndex: 9, }}>
                    <TableRow>
                      <TableCell className={this.props.classes.tableHead}>Id</TableCell>
                      <TableCell className={this.props.classes.tableHead} style={{ minWidth: "130px" }}>Student List</TableCell>
                      <TableCell className={this.props.classes.tableHead} style={{ minWidth: "130px" }}>Fee Structure</TableCell>
                      <TableCell className={this.props.classes.tableHead}>Status</TableCell>
                      <TableCell className={this.props.classes.tableHead}>
                        <Checkbox
                          indeterminate={
                            this.props.allStudentRowsChecked === "indeterminate" ? true : false
                          }
                          onChange={this.props.handleCheckAllStudents}
                          checked={!!this.props.allStudentRowsChecked}
                          inputProps={{ 'aria-label': 'select all desserts' }}
                        />
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody style={{ borderSpacing: "0 10px" }}>
                    {this.props.studentListerrorMessage ? <p style={{ color: "red" }}>{this.props.studentListerrorMessage}</p> :
                      (this.props.studentList && this.props.studentList.length) ? this.props.studentList.map((row: studentData) => {
                        const names = row.fee_structures.map((itm:feeStructuresAttribute) => itm.name);
                        const status = row.fee_structures.map((itm:feeStructuresAttribute) => itm.status);
                        let statusVal = "Pending";
                        if(status.includes("assigned")){
                          statusVal = "Assigned";
                        }
                        return (
                          <TableRow >
                            <TableCell>
                              <Grid>
                                <Typography style={{ paddingLeft: "10px" }} align="left" variant="subtitle1" >{row.id}</Typography>
                              </Grid>
                            </TableCell>
                            <TableCell>
                              <Grid >
                                <Typography style={{ paddingLeft: "10px" }} variant="subtitle1" align="left">{capitalizeFirst(row.name)}</Typography>
                                <Typography style={{ paddingLeft: "10px",fontSize: "14px", color: "#a0a9b8" }} variant="subtitle1" align="left">{capitalizeFirst(row.email)}</Typography>
                              </Grid>
                            </TableCell>
                            <TableCell>
                              <Grid >
                                <Typography style={{ paddingLeft: "10px" }} variant="subtitle1" align="left">
                                  {names.join(", ")}
                                </Typography>
                              </Grid>
                            </TableCell>
                            <TableCell>
                              <Grid >
                                <Typography style={{ paddingLeft: "10px" }} variant="subtitle1" align="left">
                                  {statusVal}
                                </Typography>
                              </Grid>
                            </TableCell>
                            <TableCell style={{ paddingLeft: "16px" }} padding="checkbox">
                              <Checkbox
                                value={row.id}
                                checked={
                                  this.props.checkStudentRow && this.props.checkStudentRow[row.id]
                                    ? true
                                    : false
                                }
                                onChange={e =>this.props.handleCheckStudentItem(e, row.id) }
                                inputProps={{ 'aria-label': 'select all desserts' }}
                              />
                            </TableCell>
                          </TableRow>
                        )
                      }) : this.props.studentList.length === 0 ? <TableRow>
                      <TableCell colSpan={12} 
                        style={{ color: "red", fontWeight: 600,fontSize: "18px",textAlign: "center" }}>
                        {"No Record Found"}
                      </TableCell>
                    </TableRow> : null}
                  </TableBody>

                </Table>
              </TableContainer>
            </Grid>
          </DialogContent>
          <DialogActions style={{
            justifyContent: "center",
            margin: "0px 24px 24px 24px",
            // padding: "20px"
          }}>
            <Button style={{
              borderRadius: "8px",
              boxShadow: 'none',
              textTransform: 'none',
              fontSize: 16,
              padding: '8px 38px',
              border: '1px solid',
              lineHeight: 1.5,
              backgroundColor: formCancelBtn,
              borderColor: '#ea5c6b',
              color: "#000",
              marginRight: "20px"
              }}
              onClick={this.props.handelCancel}
            >
              Cancel
            </Button>
            <Button
              className={this.props.classes.activeBtn}
              onClick={this.props.studentNext}
            >
              Next
            </Button>
          </DialogActions>
        </Dialog>
        <Popover
          open={!!this.props.filterModalStatus}
          anchorEl={this.props.filterModalStatus}
          onClose={this.props.onCloseFilterPopover}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right"
          }}
        >
          <Grid
            container
            direction="column"
            style={{ width: 365, padding: 16 }}
          >
            <Grid item className={this.props.classes.filterTitle}>
              <Typography variant="subtitle1" style={{ fontWeight: 500 }}>
                Select Students
              </Typography>
            </Grid>
            <Grid
              container
              spacing={3}
              style={{
                marginTop: "10px",
                marginBottom: "10px",
              }}
            >
              <Grid item xs={6}>
                <OutlinedSelect
                  placeholderTitle="Enter Name"
                  val={this.props.studentFilterFeeStrucure}
                  options={this.props.feeStructureNameList}
                  label="Fee Structure"
                  name={'fee_structure_name'}
                  handleChange={this.props.filterStudenthandleChange} className={undefined}                />
              </Grid>
              <Grid item xs={6}>
                <OutlinedSelect
                  placeholderTitle="Enter Status"
                  val={this.props.studentFilterStatus}
                  options={[{ id: "pending", name: "Pending" }, { id: "assigned", name: "Assigned" }]}
                  label="Status"
                  name={'status'}
                  handleChange={this.props.filterStudenthandleChange} className={undefined}                />
              </Grid>
              {this.props.filterStudentError ? <p style={{marginLeft: "14px"}} className="errorMsg2">{this.props.filterStudentError}</p> :null}
            </Grid>
            <Grid item className={this.props.classes.actionButtons}>
              <Button style={{
                borderRadius: "8px",
                boxShadow: 'none',
                textTransform: 'none',
                fontSize: 16,
                padding: '8px 38px',
                border: '1px solid',
                lineHeight: 1.5,
                backgroundColor: formCancelBtn,
                borderColor: '#ea5c6b',
                color: "#000",
                marginRight: "20px",
              }}
                onClick={this.props.onCloseFilterPopover}
              >
                Cancel
              </Button>
              <Button
                className={this.props.classes.activeBtn}
                onClick={this.props.studentFilterApply}
              >
                Apply
              </Button>
            </Grid>
          </Grid>
        </Popover>
      </>
      //Merge Engine End DefaultContainer
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 16,
    marginLeft: "auto",
    marginRight: "auto",
    width: Platform.OS === "web" ? "75%" : "100%",
    maxWidth: 650,
    backgroundColor: "#ffffffff"
  }
});
export default withStyles(useStyles)(StudentList);
// Customizable Area End