import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

// Customizable Area Start
export enum FormMode {
  Create,
  Edit,
  View,
  Copy
}

export interface IVendorForm {
  id?: number;
  salutation: string;
  first_name: string;
  last_name: string;
  company_name: string;
  vendor_email: string;
  phone_no_mob_code: string;
  phone_no_mob: string;
  phone_no_work_code: string;
  phone_no_work: string;
  gstin_uin: string;
  pan_number: string;
  state_source_of_supply: string;
  currency: string;
  billing_address: string;
  billing_address2: string;
  shipping_address: string;
  shipping_address2: string;
  is_shipping_address_same_as_billing_address: boolean;
  remarks: string;
  website_url: string;

  payment_term_id: string;
  account_td_id: string;
  gst_treatment_id: string;
}

export interface ISourceOfSupply {
  [key: string]: string;
}

export interface IPaymentTerms {
  id: string;
  type: string;
  account_id: number;
  no_of_days: number;
  status: boolean;
  term_name: string;
}

export interface ITDS {
  id: string;
  type: string;
  attributes: {
    id: number;
    section: string;
    tax_rate: number;
    tax_name: string;
    status: boolean;
  }
}

export interface IGSTTreatment {
  id: string;
  name: string;
  description: string;
  created_at: string;
  updated_at: string;
}

export interface ITDSSection {
  id: string;
  type: string;
  attributes: {
    id: number;
    name: string;
  }
}
// Customizable Area End

export interface Props {
  // Customizable Area Start
  classes?: any;
  isOpen: boolean;
  requestMessage: Message;
  initialValues: IVendorForm | null;
  formMode: FormMode;
  title: string;
  submitLabel: string;
  onClose: () => void;
  onSubmit: (form: IVendorForm) => void;
  currency: Array<[string, string]>;
  paymentTerms: Array<IPaymentTerms>;
  sourceOfSupply: ISourceOfSupply;
  tds: Array<ITDS>;
  gstTreatment: Array<IGSTTreatment>;
  tdSection: Array<ITDSSection>;

  createPaymentTerms: (paymentTerms: Array<IPaymentTerms>) => void;
  createTDS: (tds: any) => void;
  createSection: (section: any) => void;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  form: IVendorForm;
  formErrors: { [key: string]: string };
  isSubmitting: boolean;
  isPaymentTermsModalOpened: boolean;
  isTDSModalOpened: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class VendorsController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  private initialValues: IVendorForm = {
    id: 1,
    salutation: "",
    first_name: "",
    last_name: "",
    company_name: "",
    vendor_email: "",
    phone_no_mob_code: "",
    phone_no_mob: "",
    phone_no_work_code: "",
    phone_no_work: "",
    gstin_uin: "",
    pan_number: "JDS48743J",
    state_source_of_supply: "",
    currency: "",
    billing_address: "",
    billing_address2: "",
    shipping_address: "",
    shipping_address2: "",
    is_shipping_address_same_as_billing_address: true,
    remarks: "",
    website_url: "",
    payment_term_id: "",
    account_td_id: "",
    gst_treatment_id: ""
  };
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      form: { ...(props.initialValues || this.initialValues) },
      formErrors: {},
      isSubmitting: false,
      isPaymentTermsModalOpened: false,
      isTDSModalOpened: false
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const callID = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const response = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      if (callID === this.props.requestMessage?.messageId) {
        if (response && response.errors !== undefined) {
          Object.entries(response.errors).forEach(([key, value]) => {
            if (Array.isArray(value)) {
              response.errors[key] = value.map(v => `${this.humanizeText(key)} ${v}`).join(", ");
            }
          });
          this.setState({
            formErrors: {
              ...this.state.formErrors,
              ...response.errors
            },
            isSubmitting: false
          });
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  public componentDidUpdate(prevProps: Props) {
    const { isOpen, initialValues, formMode } = this.props;
    if (prevProps.isOpen !== isOpen) {
      if (isOpen) {
        let form: IVendorForm = { ...this.initialValues };

        if ([FormMode.Edit, FormMode.View, FormMode.Copy].includes(formMode)) {
          form = { ...(initialValues || this.initialValues) };
        }

        this.setState({
          form,
          isSubmitting: false
        });
      }
    }
  }

  public countryCodes: Array<any> = Array.from({ length: 200 }, (v, k) => k + 91).map(
    (v) => ({
      id: v,
      name: `+${v}`
    })
  );

  public onChangeSalutation(element: React.ChangeEvent<{ value: string }>) {
    this.setState({
      form: {
        ...this.state.form,
        salutation: element.target.value
      }
    });
  }

  public onChangeFirstName(element: React.ChangeEvent<{ value: string }>) {
    this.setState({
      form: {
        ...this.state.form,
        first_name: element.target.value
      }
    });
  }

  public onChangeLastName(element: React.ChangeEvent<{ value: string }>) {
    this.setState({
      form: {
        ...this.state.form,
        last_name: element.target.value
      }
    });
  }

  public onChangeCompanyName(element: React.ChangeEvent<{ value: string }>) {
    this.setState({
      form: {
        ...this.state.form,
        company_name: element.target.value
      }
    });
  }

  public onChangeVendorEmail(element: React.ChangeEvent<{ value: string }>) {
    this.setState({
      form: {
        ...this.state.form,
        vendor_email: element.target.value
      }
    });
  }

  public onChangePhoneNoMobCode(element: React.ChangeEvent<{ value: string }>) {
    this.setState({
      form: {
        ...this.state.form,
        phone_no_mob_code: element.target.value
      }
    });
  }

  public onChangePhoneNoMob(element: React.ChangeEvent<{ value: string }>) {
    this.setState({
      form: {
        ...this.state.form,
        phone_no_mob: element.target.value
      }
    });
  }

  public onChangePhoneNoWorkCode(element: React.ChangeEvent<{ value: string }>) {
    this.setState({
      form: {
        ...this.state.form,
        phone_no_work_code: element.target.value
      }
    });
  }

  public onChangePhoneNoWork(element: React.ChangeEvent<{ value: string }>) {
    this.setState({
      form: {
        ...this.state.form,
        phone_no_work: element.target.value
      }
    });
  }

  public onChangeVendorWebsiteURL(element: React.ChangeEvent<{ value: string }>) {
    this.setState({
      form: {
        ...this.state.form,
        website_url: element.target.value
      }
    });
  }

  public onChangeGSTIN_UIN(element: React.ChangeEvent<{ value: string }>) {
    this.setState({
      form: {
        ...this.state.form,
        gstin_uin: element.target.value
      }
    });
  }

  public onChangePanNumber(element: React.ChangeEvent<{ value: string }>) {
    this.setState({
      form: {
        ...this.state.form,
        pan_number: element.target.value
      }
    });
  }

  public onChangeStateSourceOfSupply(element: React.ChangeEvent<{ value: string }>) {
    this.setState({
      form: {
        ...this.state.form,
        state_source_of_supply: element.target.value
      }
    });
  }

  public onChangeWebsiteUrl(element: React.ChangeEvent<{ value: string }>) {
    this.setState({
      form: {
        ...this.state.form,
        website_url: element.target.value
      }
    });
  }

  public onChangeCurrency(element: React.ChangeEvent<{ value: string }>) {
    this.setState({
      form: {
        ...this.state.form,
        currency: element.target.value
      }
    });
  }

  public onChangePaymentTerms(element: React.ChangeEvent<{ value: string }>) {
    this.setState({
      form: {
        ...this.state.form,
        payment_term_id: element.target.value
      }
    });
  }

  public openPaymentTermsModal() {
    this.setState({
      isPaymentTermsModalOpened: true
    });
  }

  public openTDSModal() {
    this.setState({
      isTDSModalOpened: true
    });
  }

  public onChangeTDS(element: React.ChangeEvent<{ value: string }>) {
    this.setState({
      form: {
        ...this.state.form,
        account_td_id: element.target.value
      }
    });
  }

  public onChangetGSTTreatment(element: React.ChangeEvent<{ value: string }>) {
    this.setState({
      form: {
        ...this.state.form,
        gst_treatment_id: element.target.value
      }
    });
  }

  public onChangeBillingAddressLine1(element: React.ChangeEvent<{ value: string }>) {
    this.setState({
      form: {
        ...this.state.form,
        billing_address: element.target.value,
        ...(
          this.state.form.is_shipping_address_same_as_billing_address ?
            {
              shipping_address: element.target.value
            } : {}
        )
      }
    });
  }

  public onChangeBillingAddressLine2(element: React.ChangeEvent<{ value: string }>) {
    this.setState({
      form: {
        ...this.state.form,
        billing_address2: element.target.value,
        ...(
          this.state.form.is_shipping_address_same_as_billing_address ?
            {
              shipping_address2: element.target.value
            } : {}
        )
      }
    });
  }

  public onChangeShippingAddressLine1(element: React.ChangeEvent<{ value: string }>) {
    this.setState({
      form: {
        ...this.state.form,
        shipping_address: element.target.value
      }
    });
  }

  public onChangeShippingAddressLine2(element: React.ChangeEvent<{ value: string }>) {
    this.setState({
      form: {
        ...this.state.form,
        shipping_address2: element.target.value
      }
    });
  }

  public onChangeIsShippingAddressSameAsBillingAddress(event: any, checked: boolean) {
    this.setState({
      form: {
        ...this.state.form,
        is_shipping_address_same_as_billing_address: checked,
        shipping_address: checked ? this.state.form.billing_address : '',
        shipping_address2: checked ? this.state.form.billing_address2 : '',
      }
    });
  }

  public onChangeRemarks(element: React.ChangeEvent<{ value: string }>) {
    this.setState({
      form: {
        ...this.state.form,
        remarks: element.target.value
      }
    });
  }

  public onClosePaymentTermsModal() {
    this.setState({
      isPaymentTermsModalOpened: false
    });
  }

  public onCloseTDSModal() {
    this.setState({
      isTDSModalOpened: false
    });
  }

  public onPaymentTermsModalSubmit(paymentTerms: Array<IPaymentTerms>) {
    this.props.createPaymentTerms(paymentTerms);
    this.setState({
      isPaymentTermsModalOpened: false
    });
  }

  public onTDSModalSubmit(tds: any) {
    this.props.createTDS(tds);
    this.setState({
      isTDSModalOpened: false
    });
  }

  public onSectionSubmit(section: string) {
    this.props.createSection(section);
  }

  public onSubmit() {
    this.setState({ isSubmitting: this.props.formMode !== FormMode.View });

    const formErrors: { [key: string]: string } = {};
    Object.entries(this.state.form)
      .filter(([key, value]) => !['remarks'].includes(key))
      .forEach(([key, value]) => {
        if (value === undefined || value === "" || Array.isArray(value) && value.length === 0) {
          formErrors[key] = `${this.humanizeText(key)} is required`;
        }
      });

    if (Object.keys(formErrors).length > 0) {
      this.setState({ formErrors, isSubmitting: false });

      return;
    }

    this.props.onSubmit(this.state.form);
  }

  public handleFormClose() {
    this.props.onClose();
  }

  private humanizeText(text: string) {
    return text
      .replace(/(_|-)/g, " ")
      .replace(/(^\w{1})|(\s{1}\w{1})/g, match => match.toUpperCase())
      .replace(/Id/g, "");
  }
  // Customizable Area End
}
