import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";

// Customizable Area Start
import { downloadIcon} from "./assets";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  classes: any;
  open: boolean;
  handleClose: any;
  handleSubmit?: any;
  title: string;
  handleDownloadCSV?: any;
  importerrorMessage: string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  importFile: any;
  fileError: string;
  downloadIcon: any;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class CustomImportModalController extends BlockComponent<
  Props,
  S,
  SS
> {

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [getName(MessageEnum.AccoutLoginSuccess)];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      importFile: null,
      fileError: '',
      downloadIcon: downloadIcon,
      };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start

 
  handleFileChange = (e: any) => {
    this.setState({ importFile: e.target.files[0] });
    console.log('fileeeeeee',e.target.files[0]);
    this.handleValidation(e.target.files[0]);

  }

  handleValidation = (fileState: any) => {
    let isValid = true;
    
    if (fileState.length < 1) {
        this.setState({
            fileError: (
                'File is required'
            )
        });
        isValid = false;
    } else if (fileState.type !== 'text/csv') {
        this.setState({
          fileError: (
              'only csv file can upload'
          )
        });
        isValid = false;
    } else {
        this.setState({ fileError: '' });
    }

    return isValid;
  };
  // Customizable Area End
}
