import React from "react";

// Customizable Area Start
import {
  Container,
  Typography,
  Box,
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemSecondaryAction,
  TextField,
} from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";
// import { createTheme, ThemeProvider } from "@material-ui/core/styles";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import RootRef from "@material-ui/core/RootRef";
import InboxIcon from "@material-ui/icons/Inbox";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";

// const theme = createTheme({
//   palette: {
//     primary: {
//       main: "#fff",
//       contrastText: "#fff",
//     },
//   },
//   typography: {
//     h6: {
//       fontWeight: 500,
//     },
//     subtitle1: {
//       margin: "20px 0px",
//     },
//   },
// });
// Customizable Area End

import DragDropInterfaceController, {
  Props,
  configJSON,
} from "./DragDropInterfaceController";

export default class DragDropInterface extends DragDropInterfaceController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    const getDataFromLS = () => {
      const listItems = localStorage.getItem("DragDropList");
      if (listItems) {
        return JSON.parse(listItems);
      } else {
        return [];
      }
    };

    this.onDragEnd = this.onDragEnd.bind(this);
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <>
        <Container maxWidth={"xl"}>
          <Box>
            <Typography variant="h6">{configJSON.labelTitleText}</Typography>
            <DragDropContext onDragEnd={this.onDragEnd}>
              <div className="col-md-6" style={{ marginTop: "20px" }}>
                <form onSubmit={this.state.isEditing ? this.update : this.add}>
                  <div className="mb-2" style={webStyle.DDForm}>
                    <TextField
                      label="Enter Name"
                      id="name"
                      name="name"
                      value={this.state.item}
                      margin="dense"
                      onChange={this.handleChange}
                      variant="outlined"
                    />
                    {this.state.isEditing ? (
                      <Button
                        type="submit"
                        variant="contained"
                        style={{
                          marginLeft: "10px",
                          height: "40px",
                          width: "90px",
                          textTransform: "capitalize",
                        }}
                        color="primary"
                      >
                        Update
                      </Button>
                    ) : (
                      <Button
                        type="submit"
                        variant="contained"
                        style={{
                          marginLeft: "10px",
                          height: "40px",
                          width: "90px",
                          textTransform: "capitalize",
                        }}
                        color="primary"
                      >
                        Add
                      </Button>
                    )}
                  </div>
                  <div style={{ marginTop: "2px" }}>
                    {this.state.formValidate && (
                      <span
                        style={{
                          color: "red",
                          fontSize: "12px",
                          marginTop: "2px",
                        }}
                      >
                        {" "}
                        Name field is required.
                      </span>
                    )}
                  </div>
                </form>
              </div>
              <Droppable droppableId="droppable">
                {(provided: any, snapshot: any) => (
                  <RootRef rootRef={provided.innerRef}>
                    <List style={this.getListStyle(snapshot.isDraggingOver)}>
                      {this.state.items.length > 0 ? (
                        this.state.items?.map((item: any, index: number) => (
                          <Draggable
                            key={index}
                            draggableId={index.toString()}
                            index={index}
                          >
                            {(provided: any, snapshot: any) => (
                              <ListItem
                                ContainerComponent="li"
                                ContainerProps={{ ref: provided.innerRef }}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={this.getItemStyle(
                                  snapshot.isDragging,
                                  provided.draggableProps.style
                                )}
                              >
                                <ListItemIcon>
                                  <InboxIcon color="inherit" />
                                </ListItemIcon>
                                <ListItemText primary={item} />
                                {/* <IconButton> */}
                                <EditIcon
                                  onClick={(e) => this.edit(index)}
                                  color="primary"
                                />
                                <DeleteIcon
                                  style={{ marginLeft: "10px" }}
                                  color="secondary"
                                  onClick={(e) => this.delete(index)}
                                />
                                {/* </IconButton> */}
                                <ListItemSecondaryAction />
                              </ListItem>
                            )}
                          </Draggable>
                        ))
                      ) : (
                        <div style={{ textAlign: "center" }}>No Records</div>
                      )}
                      {provided.placeholder}
                    </List>
                  </RootRef>
                )}
              </Droppable>
            </DragDropContext>
          </Box>
        </Container>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  DDForm: {
    display: "flex",
    flexlow: "row wrap",
    alignItems: "center",
  },
};

// Customizable Area End
