import React from "react";
// Customizable Area Start
/*-- side bar --*/
import './pagination.web.css';
import ReactPaginate from 'react-paginate';
// Customizable Area End
import PaginationController, {
  Props,
} from "./PaginationController";
import { copyFileSync } from "fs";

class Pagination extends PaginationController {
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { totalPageCount , handlePageClick, page} = this.props;
    // Customizable Area End
    return (
      // Customizable Area Start
      //Merge Engine DefaultContainer
      <div style={{ width: "100%", marginTop: "70px", right: "16px" }}>
        <ReactPaginate
          onPageChange={handlePageClick}
          previousLabel={<>
            <img
              style={{
                width: "18px",
                paddingRight: "14px",
              }}
              src={!page ? this.state.disableLeftIcon : this.state.leftIcon} />
            <span>Previous</span></>}
            nextLabel={<>{"Next"}
            <img style={{
              width: "18px",
              paddingLeft: "20px",
              color: "white"
            }}
              src={(page && page+1 === totalPageCount || totalPageCount == 1) ? this.state.disableRightIcon : this.state.rightIcon} />
          </>}
          nextClassName={(page && page+1 === totalPageCount || totalPageCount == 1) ? "paginationNextBtn" : "activePaginationNextBtn"}
          previousClassName={!page ? "paginationPrevBtn" : "activePaginationPrevBtn"}
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={totalPageCount}
          marginPagesDisplayed={3}
          pageRangeDisplayed={3}
          containerClassName={"pagination"}
          activeClassName={"active"}
        />
      </div>
      //Merge Engine End DefaultContainer
      // Customizable Area End
    );
  }
}

export default Pagination;
