export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const actionIcon = require("../../Account/assets/actionIcon.png");
export const deleteIcon = require("../assets/deleteIcon.png");
export const printIcon = require("../assets/printIcon.png");
export const importExportIcon = require("../assets/export-import-Icon.png");
export const filterIcon = require("../assets/filterIcon.png");
export const bulkUpdateIcon = require("../assets/bulkUpdateIcon.png");
export const percentageIcon = require("../assets/percentageIcon.jpeg");
export const rupeeIcon = require("../assets/rupeeIcon.png");
export const concessionTypeEditIcon = require("../assets/concessionTypeEditIcon.png");
export const dropdownIcon = require("../assets/dropdownIcon.png");
export const calenderIcon = require("../assets/calenderIcon.png");
