import React from "react";
// Customizable Area Start
import TableCell from "@material-ui/core/TableCell";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { withStyles, Theme } from "@material-ui/core/styles";
import { formSubmitBtn, formCancelBtn } from "../../Layout/src/color";
import SearchIcon from "@material-ui/icons/Search";

import { Styles } from "@material-ui/styles";
// Customizable Area End
// Customizable Area Start
import VendorSelectionModalController, {
  Props
} from "./VendorSelectionModalController";

import { StyleSheet, Platform } from "react-native";
import {
  Checkbox,
  FormControl,
  FormLabel,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TextField
} from "@material-ui/core";
import OutlinedSelect from "../../../../components/src/OutlinedSelect";
import { classNames } from "../../HRMSCommons/src/classNames";

const useStyles: Styles<Theme, {}, string> = (theme: Theme) => ({
  dialogPaper: {
    width: "90vh",
    maxWidth: "720px",
    borderRadius: "26px"
  },
  formLabel: { marginBottom: "16px", marginTop: "8px", color: "#405367" },
  formControl: { marginBottom: "8px", marginTop: "8px", width: "100%" },
  mt20: {
    marginTop: 20
  },
  actionColumn: {
    maxWidth: "100px",
    width: "100px"
  },
  tableContainer: {
    position: "relative",
    flexGrow: 1,
    boxShadow: "0px 5px 10px 0px #d9d9d9",
    borderRadius: 10
  },
  table: {
    borderCollapse: "separate",
    borderSpacing: "0 10px",
    padding: "0 25px",

    "& td": {
      backgroundColor: "#F9F9F9",
      border: "none"
    }
  },
  actionColumnButton: {
    margin: 0
  },
  checkbox: {
    padding: 0
  },
  tableHead: {
    backgroundColor: "#FCF3D4"
  },
  searchIconContainer: {
    background: "#eb514f",
    height: "30px",
    display: "flex",
    borderRadius: "4px",
    width: "30px",
    justifyContent: "center",
    alignItems: "center",
    padding: "5px",
    marginLeft: "8px"
  }
});

const keyOptions = [
  { id: "customer_name", name: "Customer Name" },
  { id: "company_name", name: "Company Name" },
  { id: "first_name", name: "First Name" },
  { id: "last_name", name: "Last Name" },
  { id: "vendor_email", name: "Email" },
  { id: "phone", name: "Phone" }
];

// Customizable Area End
class VendorSelectionModal extends VendorSelectionModalController {
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { open, title, handleClose, handleSubmit, classes } = this.props;

    // Customizable Area End
    return (
      // Customizable Area Start
      //Merge Engine DefaultContainer
      <>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="simple-dialog-title"
          classes={{ paper: this.props.classes.dialogPaper }}
        >
          <DialogContent style={{ textAlign: "center" }}>
            <div
              style={{
                fontWeight: "bold",
                fontSize: "18px",
                borderBottom: "2px solid #f1f1f1",
                paddingBottom: "14px"
              }}
            >
              {title}
            </div>
            {/* <img style={{ paddingTop: "14px" }} height="117px" src={this.state.deleteBoxIcon} />
            <DialogTitle style={{ color: "#44556c" }}>
              Advanced Vendor Search
            </DialogTitle> */}
            <FormControl className={classes.formControl}>
              <div style={{ display: "flex" }}>
                <div style={{ width: "190px", marginRight: "-1px" }}>
                  <OutlinedSelect
                    val={this.state.selectedKey}
                    options={keyOptions}
                    label=""
                    name={"code"}
                    placeholder={"code"}
                    styleOverrides={{
                      width: "100%",
                      marginTop: "-8px",
                      height: "40px"
                    }}
                    handleChange={(e: any) =>
                      this.setState({ selectedKey: e.target.value })
                    }
                    className={undefined}
                  />
                </div>

                <TextField
                  style={{ width: "100%" }}
                  value={this.state.searchValue}
                  color={"primary"}
                  variant="outlined"
                  type={"text"}
                  size={"small"}
                  name={"contactNumber"}
                  placeholder={"Enter Number"}
                  className="inputContainerOrganization"
                  onChange={e => this.setState({ searchValue: e.target.value })}
                />
                <div
                  className={classes.searchIconContainer}
                  onClick={() =>
                    this.setState({
                      submittedSearchValue: this.state.searchValue
                    })
                  }
                >
                  <SearchIcon
                    style={{
                      color: "white",
                      fontSize: "33px",
                      cursor: "pointer"
                    }}
                  />
                </div>
              </div>
            </FormControl>
            <TableContainer
              // style={{ overflow: isLoading ? "hidden" : "" }}
              component={Paper}
              className={classNames(classes.mt20, classes.tableContainer)}
              // ref={(el: any) => (this.printReferance = el)}
            >
              {/* {isLoading && <Loader loading positionType={"center"} />} */}

              <Table
                // ref={this.tableRef}
                stickyHeader
                className={classes.table}
                aria-label="Expense"
              >
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.tableHead}>
                      VENDOR NAME
                    </TableCell>
                    <TableCell className={classes.tableHead}>
                      COMPANY NAME
                    </TableCell>
                    <TableCell className={classes.tableHead}>PHONE</TableCell>
                    <TableCell className={classes.tableHead}>ID</TableCell>

                    <TableCell className={classes.tableHead} align="center" />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.props.vendors &&
                    this.props.vendors
                      .filter(el => {
                        if (
                          !this.state.selectedKey ||
                          !this.state.submittedSearchValue
                        )
                          return true;
                        const value =
                          el.attributes[
                            this.state.selectedKey as keyof typeof el.attributes
                          ];
                        if (!value) return false;
                        if (
                          value
                            .toLocaleLowerCase()
                            .indexOf(
                              this.state.submittedSearchValue.toLocaleLowerCase()
                            ) >= 0
                        )
                          return true;
                      })
                      .map((row, i) => {
                        const {
                          id,
                          attributes: {
                            company_name,
                            first_name,
                            last_name,
                            phone_no_mob
                          }
                        } = row;
                        const selected = this.state.selectedVendor === id;
                        return (
                          <TableRow
                            key={`-${id}`}
                            onClick={() =>
                              this.setState({ selectedVendor: id })
                            }
                          >
                            <TableCell
                              style={
                                selected
                                  ? {
                                      backgroundColor: "#FF4553",
                                      color: "white"
                                    }
                                  : {}
                              }
                            >
                              {first_name + " " + last_name}
                            </TableCell>
                            <TableCell
                              style={
                                selected
                                  ? {
                                      backgroundColor: "#FF4553",
                                      color: "white"
                                    }
                                  : {}
                              }
                            >
                              {company_name}
                            </TableCell>
                            <TableCell
                              style={
                                selected
                                  ? {
                                      backgroundColor: "#FF4553",
                                      color: "white"
                                    }
                                  : {}
                              }
                            >
                              {phone_no_mob}
                            </TableCell>
                            <TableCell
                              style={
                                selected
                                  ? {
                                      backgroundColor: "#FF4553",
                                      color: "white"
                                    }
                                  : {}
                              }
                            >
                              {id}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                </TableBody>
              </Table>
            </TableContainer>
          </DialogContent>
          <DialogActions
            style={{
              justifyContent: "flex-start",
              margin: "0px 48px 48px 48px",
              padding: "22px"
            }}
          >
            <Button
              style={{
                boxShadow: "none",
                textTransform: "none",
                fontSize: 16,
                padding: "8px 40px",
                border: "1px solid",
                lineHeight: 1.5,
                backgroundColor: formCancelBtn,
                borderColor: "#ea5c6b",
                color: "#44556c",
                marginRight: "14px",
                borderRadius: "8px"
              }}
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              disabled={!this.state.selectedVendor}
              style={{
                boxShadow: "none",
                textTransform: "none",
                fontSize: 16,
                padding: "8px 40px",
                border: "1px solid",
                lineHeight: 1.5,
                background: formSubmitBtn,
                //borderColor: '#ea5c6b',
                color: "white",
                borderRadius: "8px"
              }}
              onClick={() =>
                handleSubmit && handleSubmit(this.state.selectedVendor)
              }
            >
              Done
            </Button>
          </DialogActions>
        </Dialog>
      </>
      //Merge Engine End DefaultContainer
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 16,
    marginLeft: "auto",
    marginRight: "auto",
    width: Platform.OS === "web" ? "75%" : "100%",
    maxWidth: 650,
    backgroundColor: "#ffffffff"
  }
});

// Customizable Area End

export default withStyles(useStyles)(VendorSelectionModal);
