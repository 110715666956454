import React from "react";
// Customizable Area Start
import { withStyles, Theme } from '@material-ui/core/styles';
import { Styles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import Layout from '../../CommonLayout/Layout/src/Layout.web';
import Pagination from "../../CommonLayout/Pagination/src/Pagination.web"
import CustomDeleteModal from '../../CommonLayout/CustomDeleteModal/src/CustomDeleteModal.web';
import { IconButton } from "../../CommonLayout/HRMSCommons/src/button/iconButton";
import { Icon } from "../../CommonLayout/HRMSCommons/src/assets";
import { ActionMenu } from "../../CommonLayout/HRMSCommons/src/actionMenu";
import PageHeader from "../../CommonLayout/HRMSCommons/src/layout/PageHeader";
import Loader from "../../../components/src/Loader.web";
import { classNames } from "../../CommonLayout/HRMSCommons/src/classNames";
import VendorForm from "./VendorForm.web";
import OutlinedSelect from "../../../components/src/OutlinedSelect";
import { FormControl, FormLabel, TextField } from "@material-ui/core";
// Customizable Area End

import VendorsController, { Props } from "./VendorsController";

// Customizable Area Start
const useStyles: Styles<Theme, {}, string> = (theme: Theme) => ({
  container: {
    minHeight: "calc(100vh - 120px)",
    maxHeight: "calc(100vh - 120px)",
    flexDirection: "column",
    flexWrap: "nowrap",
  },
  mt20: {
    marginTop: 20,
  },
  actionColumn: {
    maxWidth: "100px",
    width: "100px",
  },
  tableContainer: {
    position: "relative",
    flexGrow: 1,
    boxShadow: "0px 5px 10px 0px #d9d9d9",
    borderRadius: 10
  },
  table: {
    borderCollapse: "separate",
    borderSpacing: "0 10px",
    padding: "10px 25px 0",

    "& td": {
      backgroundColor: "#F9F9F9",
      border: "none"
    }
  },
  actionColumnButton: {
    margin: 0
  },
  checkbox: {
    padding: 0,
  },
  tableHead: {
    backgroundColor: "#FCF3D4",
    height: 41,
  }
});
// Customizable Area End

class Vendors extends VendorsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes, navigation } = this.props;
    const {
      pageMeta: { total_pages },
      isLoading,
      vendors,
      anchorEl,
      actionMenuItems,
      deleteModalOpen,
      vendorFormMode,
      selectAllCheckboxChecked,
      currentPage,
      closeImportModal,
      searchText,
      currency,
      paymentTerms,
      sourceOfSupply,
      tds,
      gstTreatment,
      tdSection,
      deleteMessage
    } = this.state;

    return (
      <Layout navigation={navigation} searchKeyword={searchText} handleGlobalSearch={this.onChangeSearchInput.bind(this)}>
        <Grid container className={classes.container}>
          <PageHeader
            title="Vendor"
            createButtonTitle="Create"
            importModalTitle="Import vendor by CSV file"
            printReference={this.printReferance}
            filterForm={this.renderFilterForm()}
            FilterTitle="Filter"
            onClickCreateButton={this.onClickCreateVendor.bind(this)}
            onUploadCSVForImport={this.onUploadCSVForImport.bind(this)}
            onClickExportCSVButton={this.onClickExportCSVButton.bind(this, false)}
            onFilterFormSubmit={this.onFilterFormSubmit.bind(this)}
            onClickDownloadCSVTemplate={this.onClickExportCSVButton.bind(this, true)}
            closeImportModal={closeImportModal}
          // importErrorMessage={this.state.importErrorMessage}
          />

          <TableContainer ref={(el: any) => (this.printReferance = el)} style={{ overflow: isLoading ? 'hidden' : '' }} component={Paper} className={classNames(classes.mt20, classes.tableContainer)}>
            {isLoading && <Loader loading positionType={'center'} />}

            <Table ref={this.tableRef} stickyHeader className={classes.table} aria-label="vendors">
              <TableHead>
                <TableRow>
                  <TableCell align="center" className={classNames(classes.actionColumn, classes.tableHead)}>
                    <Checkbox
                      className={classes.checkbox}
                      inputProps={{ 'aria-label': 'select all vendors' }}
                      onChange={this.onSelectAllVendors.bind(this)}
                      indeterminate={selectAllCheckboxChecked === 'indeterminate'}
                      checked={!!selectAllCheckboxChecked}
                      key={`vendor-super-checkbox-${selectAllCheckboxChecked}`}
                    />
                  </TableCell>
                  <TableCell className={classes.tableHead}>ID</TableCell>
                  <TableCell className={classes.tableHead}>Name</TableCell>
                  <TableCell className={classes.tableHead}>Company</TableCell>
                  <TableCell className={classes.tableHead}>Email</TableCell>
                  <TableCell className={classes.tableHead}>Contact No.</TableCell>
                  <TableCell className={classes.tableHead} align="center"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {vendors.map((row) => {
                  const { id, selected, attributes: {

                    salutation,
                    first_name,
                    last_name,
                    company_name,
                    vendor_email,
                    phone_no_mob,
                    phone_no_work
                  } } = row;
                  return (
                    <TableRow key={`vendor-${id}`}>
                      <TableCell align="center" className={classes.actionColumn}>
                        <Checkbox
                          className={classes.checkbox}
                          value={selected}
                          checked={selected}
                          inputProps={{ 'aria-label': 'select vendor' }}
                          key={`vendor-${id}-checkbox-${selected}`}
                          onChange={event => this.onSelectVendor(event, row)}
                        />
                      </TableCell>
                      <TableCell>{id.toString().padStart(4, '0')}</TableCell>
                      <TableCell style={{ textTransform: 'capitalize' }}>{`${salutation} ${first_name} ${last_name}`}</TableCell>
                      <TableCell style={{ textTransform: 'capitalize' }}>{company_name}</TableCell>
                      <TableCell>{vendor_email}</TableCell>
                      <TableCell>{
                        !!phone_no_mob ? phone_no_mob : phone_no_work
                      }</TableCell>
                      <TableCell align="center" className={classes.actionColumn}>
                        <IconButton
                          icon={Icon.Action}
                          borderless
                          className={classes.actionColumnButton}
                          onClick={event => this.onClickActionButton(event, row)}
                        />
                      </TableCell>
                    </TableRow>
                  )
                })}
                {
                  vendors.length === 0 && !isLoading &&
                  <TableRow>
                    <TableCell colSpan={12} style={{
                      color: "red",
                      fontWeight: 600,
                      fontSize: "18px",
                      textAlign: 'center'
                    }}>
                      {"No Record Found"}
                    </TableCell>
                  </TableRow>
                }
              </TableBody>
            </Table>
          </TableContainer>

          <Grid container justify="flex-end" alignItems="center">
            <Pagination page={currentPage} totalPageCount={total_pages} handlePageClick={this.onClickPagination.bind(this)} />
          </Grid>
        </Grid>

        <ActionMenu
          anchorEl={anchorEl}
          onClose={this.onCloseActionMenu.bind(this)}
          items={actionMenuItems}
          arrowSide="right"
          anchorOrigin={{ vertical: -12, horizontal: -48 }}
        />

        <CustomDeleteModal DialogueDeletetitle={'Delete Vendor Rate'}
          open={deleteModalOpen}
          handleClose={this.onCloseDeleteModal.bind(this)}
          handleSubmit={this.onSubmitDeleteModal.bind(this)}
          msgHandle={deleteMessage}
        />

        <VendorForm
          {...this.vendorFormProps[vendorFormMode]}
          isOpen={this.state.isVendorFormModalOpened}
          initialValues={this.state.vendorForm}
          currency={currency}
          paymentTerms={paymentTerms}
          sourceOfSupply={sourceOfSupply}
          tds={tds}
          gstTreatment={gstTreatment}
          createPaymentTerms={this.onCreatePaymentTerms.bind(this)}
          tdSection={tdSection}
          createTDS={this.onCreateTDS.bind(this)}
          createSection={this.onCreateSection.bind(this)}
        />
      </Layout>
    );
    // Customizable Area End
  }

  // Customizable Area Start
  private renderFilterForm() {
    // first_name, last_name, email, contract No. GST Tteatment

    return (
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <FormControl style={{ width: '100%' }}>
            <FormLabel style={{ marginBottom: "8px" }}>
              First Name
            </FormLabel>
            <TextField
              name="first_name"
              color='primary'
              value={this.state.filterForm.first_name}
              onChange={this.onFilterFormChange.bind(this)}
              variant="outlined"
              placeholder="Enter Name"
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControl style={{ width: '100%' }}>
            <FormLabel style={{ marginBottom: "8px" }}>
              Last Name
            </FormLabel>
            <TextField
              name="last_name"
              color='primary'
              value={this.state.filterForm.last_name}
              onChange={this.onFilterFormChange.bind(this)}
              variant="outlined"
              placeholder="Enter Name"
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControl style={{ width: '100%' }}>
            <FormLabel style={{ marginBottom: "8px" }}>
              Email
            </FormLabel>
            <TextField
              name="vendor_email"
              color='primary'
              value={this.state.filterForm.vendor_email}
              onChange={this.onFilterFormChange.bind(this)}
              variant="outlined"
              placeholder="Mail ID"
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControl style={{ width: '100%' }}>
            <FormLabel style={{ marginBottom: "8px" }}>
              Contract No.
            </FormLabel>
            <TextField
              name="phone_no_mob"
              color='primary'
              value={this.state.filterForm.phone_no_mob}
              onChange={this.onFilterFormChange.bind(this)}
              variant="outlined"
              placeholder="Contract No."
            />
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <OutlinedSelect
            label="GST Treatment"
            name="gst_treatment_id"
            val={this.state.filterForm.gst_treatment_id}
            handleChange={this.onFilterFormChange.bind(this)}
            options={this.state.gstTreatment.map(({ id, name, description }) => ({
              id, name, description
            }))}
            optionWithDescription
            placeholder="GST"
          />
        </Grid>
      </Grid>
    );

  }
  // Customizable Area End
}

export default withStyles(useStyles)(Vendors);
