import React from "react";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import FormLabel from "@material-ui/core/FormLabel";
import { classNames } from "../../../blocks/CommonLayout/HRMSCommons/src/classNames";
import { Checkbox, Input } from "@material-ui/core";
import { PickerItem } from "react-native";
import { spawn } from "child_process";

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(0, 2, 0, 0),
    minWidth: 132
  },
  selectContainer: {
    "& .MuiSelect-root.MuiSelect-select > .optionDescription": {
      display: "none"
    },
    "& .MuiSelect-root.MuiSelect-select button": {
      margin: 0,
      padding: 0,
      backgroundColor: "white",
      color: "#717d8f",
      height: "auto",
      overflow: "hidden",
      minHeight: "1.1876em",
      fontWeight: 600,
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      fontSize: "1rem",
      textAlign: "left"
    }
  },
  optionContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    maxWidth: "292px"
  },
  formLabel: {
    display: "flex",
    alignItems: "center",
    backgroundColor: theme.palette.common.white,
    paddingLeft: "1px",
    paddingRight: "1px"
  },

  // select: {
  //   padding: theme.spacing(2, 3),
  // }

  select: {
    padding: theme.spacing(2, 3)
  },
  menuPaper: {
    maxHeight: "26vh"
  },
  actionBtn: {
    marginLeft: 16,
    padding: 0,
    border: "none",
    color: "#f25d62",
    backgroundColor: "white",
    cursor: "pointer"
  }
}));

interface OptionItem {
  id: string;
  name: any;
  description?: any;
  type?: any;
}

interface SelectProps {
  val: any;
  name?: string;
  handleChange: any;
  options: OptionItem[];
  label: string | React.ReactNode;
  placeholder?: string;
  styleOverrides?: any;
  disabled?: boolean;
  selectStyle?: any;
  multiple?: boolean;
  formControlStyle?: any;
  labelStyle?: any;
  loadMoreItems?: any;
  placeholderTitle?: any;
  className?: any;
  actionButtonClass?: any;
  actionButtonLabel?: string;
  hasActionButton?: boolean;
  optionWithDescription?: boolean;
  onActionButtonClick?: (e?: any) => void;
}

const OutlinedSelect = ({
  val,
  name,
  handleChange,
  options,
  label,
  placeholder = "",
  styleOverrides,
  disabled = false,
  selectStyle,
  className,
  multiple = false,
  formControlStyle = {},
  loadMoreItems = null,
  labelStyle = {},
  hasActionButton,
  actionButtonLabel,
  onActionButtonClick,
  placeholderTitle = "Please Select",
  optionWithDescription,
  actionButtonClass
}: SelectProps) => {
  const classes = useStyles();

  const [labelWidth, setLabelWidth] = React.useState(0);

  return (
    <FormControl
      variant="outlined"
      className={classes.formControl}
      disabled={disabled}
      style={{ width: "100%", ...formControlStyle }}
    >
      <FormLabel style={{ marginBottom: "8px", ...labelStyle }}>
        {label}
      </FormLabel>
      <Select
        fullWidth
        className={classNames(
          className ? className : "null",
          classes.selectContainer
        )}
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left"
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left"
          },
          getContentAnchorEl: null,
          onScroll: loadMoreItems,
          classes: { paper: classes.menuPaper }
        }}
        style={styleOverrides}
        labelId={`label-${label}`}
        id={`select-outlined-${label}`}
        name={name}
        value={val ? val : "null"}
        multiple={multiple}
        onChange={handleChange}
        placeholder={placeholder}
        classes={{
          outlined: selectStyle ? classes.select : ""
        }}
        renderValue={
          multiple
            ? (selected: any) => {
                let selectedValue: string[] = [];
                options.map((PickerItem: OptionItem) => {
                  if (selected.includes(PickerItem.id)) {
                    selectedValue.push(PickerItem.name);
                  }
                });
                return selectedValue.join(", ");
              }
            : undefined
        }
        labelWidth={labelWidth}
      >
        {placeholderTitle ? (
          <MenuItem value="null" disabled={multiple}>
            {placeholderTitle}
          </MenuItem>
        ) : null}
        {options &&
          options.map((item: OptionItem, index: number) => {
            if (item.type === "action") {
              return (
                <MenuItem
                  style={{
                    paddingTop: 0,
                    paddingBottom: 0,
                    paddingLeft: 0,
                    paddingRight: 0
                  }}
                  key={index}
                  value={item.id}
                >
                  <button
                    className={classNames(
                      actionButtonClass ? actionButtonClass : classes.actionBtn
                    )}
                    onClick={onActionButtonClick}
                  >
                    {actionButtonLabel}
                  </button>
                </MenuItem>
              );
            }
            return (
              <MenuItem
                className={optionWithDescription ? classes.optionContainer : ""}
                key={index}
                value={item.id}
              >
                {multiple && <Checkbox checked={val.indexOf(item.id) > -1} />}
                {optionWithDescription ? (
                  <div style={{ fontWeight: 700, fontSize: 15 }}>
                    {item.name}
                  </div>
                ) : (
                  item.name
                )}
                {optionWithDescription && (
                  <div
                    style={{ fontSize: 15, whiteSpace: "normal" }}
                    className={"optionDescription"}
                  >
                    {item.description}
                  </div>
                )}
              </MenuItem>
            );
          })}
        {hasActionButton && (
          <button
            className={classNames(
              actionButtonClass ? actionButtonClass : classes.actionBtn
            )}
            onClick={onActionButtonClick}
          >
            {actionButtonLabel}
          </button>
        )}
      </Select>
    </FormControl>
  );
};

OutlinedSelect.prototypes = {
  val: PropTypes.any.isRequired,
  handleChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  label: PropTypes.string.isRequired,
  styleOverrides: PropTypes.object,
  disabled: PropTypes.bool,
  IconComponent: PropTypes.node.isRequired,
  selectStyle: PropTypes.bool,
  multiple: PropTypes.bool
};

export default OutlinedSelect;
