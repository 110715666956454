import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible} from "./assets";
export const configJSON = require("./config");
// Customizable Area End



export interface Props {
  // Customizable Area Start
  classes?: any;
  open: boolean;
  handleClose: any;
  title?: string | React.ReactNode;
  buttonTitle?: string;
  cancelButtonTitle?: string;
  handleSubmit?: any;
  submitLoading?: boolean;
  isError?: boolean | undefined;
  errormsg?: string | undefined;
  hideCancelButton?: boolean;
  modalClasses?: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class CustomModalController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [getName(MessageEnum.AccoutLoginSuccess)];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start

  // Customizable Area End
}