import 'date-fns';
import React from 'react';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { FormControl, Grid } from '@material-ui/core';
// Customizable Area Start
// import { calenderIcon } from "./assets";
interface Props {
    value:any;
    handleChange:any;
    minDate?:any;
    maxDate?: any;
    placeholder?:string;
    name?:string;
    disabled?:any;
    className?:string;

    
  }
  const calenderIcon=require('../../../PayrollIntegration/assets/calenderIcon.png');
// Customizable Area End
export default function CommonDatePicker(props:Props) {
// Customizable Area Start
// Customizable Area End
  return (
    // Customizable Area Start

    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid style={{position:"relative"}}>
        <KeyboardDatePicker
          disableToolbar
          variant="inline"
          format="MM/dd/yyyy"
          autoOk={true}
          className='input'
          disabled={props.disabled}
          minDate={props.minDate}
          maxDate={props.maxDate}
          name={props.name}
          placeholder={props.placeholder}
          value={props.value}
          onChange={props.handleChange}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
        <span style={{
          position:"absolute",
          right:"26px",
          top:"12px"
        }}>
        <img style={{ 
          height: "27px",
          width: "27px",}} src={calenderIcon}/>
        </span>
        </Grid>
    </MuiPickersUtilsProvider>
    // Customizable Area End
  );
}


