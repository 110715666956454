import React, { FunctionComponent, MouseEventHandler, useState } from "react";
import { Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormLabel, Grid, Input, TextField, Typography } from "@material-ui/core";
import CustomModalWeb from "../../CommonLayout/CustomModal/src/CustomModal.web";
import { Icon } from "../../CommonLayout/HRMSCommons/src/assets";
import { IconButton } from "../../CommonLayout/HRMSCommons/src/button/iconButton";
import { Theme } from "@material-ui/core";
import withStyles, { Styles } from "@material-ui/core/styles/withStyles";
import Button from "@material-ui/core/Button";
import CustomDeleteModalWeb from "../../CommonLayout/CustomDeleteModal/src/CustomDeleteModal.web";
import { formSubmitBtn } from "../../CommonLayout/Layout/src/color";
import { deleteIcon } from "./assets";


interface IPaymentTermsModalProps {
  isOpen: boolean;
  onClose?: () => void;
  onSubmit?: (paymentTerms: any) => void;
  paymentTerms: any;
  classes?: any;

  onClickDelete?: (index: number) => void;
}

const PaymentTermsModalWithoutStyles = (props: IPaymentTermsModalProps) => {

  const { isOpen, onClose, onSubmit, onClickDelete, classes } = props;
  const [paymentTerms, setPaymentTerms] = useState<any>(props.paymentTerms);
  const [deleteModalOpen, setDeleteModalOpen] = useState<any>(false);
  const [deleteItem, setDeleteItem] = useState<any>(null);
  console.log(paymentTerms);

  return (
    <Dialog
      PaperProps={{
        style: {
          borderRadius: "25px",
          maxHeight: "479px"
        }
      }}
      open={isOpen}
      // onClose={handleClose}
      fullWidth={true}
      maxWidth={"sm"}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <div className={classes.paymentHeaderContainer}>
        <DialogTitle id="alert-dialog-title">
          <Typography style={{ fontWeight: 600 }}>
            Configure Payment Terms
          </Typography>
        </DialogTitle>
        <div>
          <Button
            style={{
              borderRadius: "5px",
              boxShadow: "none",
              textTransform: "none",
              fontSize: 16,
              padding: "8px",
              border: "1px solid",
              lineHeight: 1.5,
              background: formSubmitBtn,
              color: "white"
            }}
            type="submit"
            onClick={() => {
              setPaymentTerms([...paymentTerms, { isNew: true, term_name: "", no_of_days: 0 }]);
            }}
          >
            + Add New
          </Button>
        </div>
      </div>

      <DialogContent>
        <Grid container direction="row" style={{ marginTop: 15 }}>
          <Grid item xs={12} sm={5} style={{ paddingRight: 12 }}>
            <FormControl required style={{ width: "100%" }}>
              <FormLabel>Term Name</FormLabel>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={5} style={{ paddingRight: 12 }}>
            <FormControl required style={{ width: "100%" }}>
              <FormLabel>Number of Days</FormLabel>
            </FormControl>
          </Grid>
        </Grid>

        {paymentTerms?.map((el: any, i: number) => {
          return (
            <Grid container direction="row" style={{ marginTop: 8 }}>
              <Grid item xs={12} sm={5} style={{ paddingRight: 12 }}>
                <FormControl required style={{ width: "100%" }}>
                  <TextField
                    value={el?.term_name}
                    color={"primary"}
                    variant="outlined"
                    type={"text"}
                    name={"term-name"}
                    placeholder={"Term Name"}
                    onChange={e => {
                      paymentTerms[i].term_name = e.target.value;

                      setPaymentTerms([...paymentTerms]);
                    }}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={5} style={{ paddingRight: 12 }}>
                <FormControl required style={{ width: "100%" }}>
                  <TextField
                    value={el?.no_of_days}
                    color={"primary"}
                    variant="outlined"
                    type={"text"}
                    name={"no_of_days"}
                    placeholder={"Number of Days"}
                    onChange={e => {
                      paymentTerms[i].no_of_days = parseFloat(e.target.value) || 0;
                      setPaymentTerms([...paymentTerms]);
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={2}>
                <IconButton
                  height={'21px'}
                  icon={deleteIcon}
                  className={classes.paymentDeleteButton}
                  onClick={() => {
                    setDeleteModalOpen(true);
                    setDeleteItem(el);
                  }}
                />
              </Grid>
            </Grid>
          );
        })}
        <CustomDeleteModalWeb
          DialogueDeletetitle={"Delete Payment Term"}
          open={deleteModalOpen}
          handleClose={() => {
            setDeleteModalOpen(false);
            setDeleteItem(null);
          }}
          handleSubmit={() => {
            onClickDelete && onClickDelete(deleteItem);
            setDeleteModalOpen(false);
          }}
        />
      </DialogContent>

      <DialogActions
        style={{
          justifyContent: "flex-start",
          marginLeft: "18px",
          marginBottom: "26px"
        }}
      >
        <Button
          className={classes.paymentActionBtn}
          style={{
            borderRadius: "5px",
            boxShadow: "none",
            textTransform: "none",
            fontSize: 16,
            padding: "8px 45px",
            border: "1px solid",
            lineHeight: 1.5,
            backgroundColor: "white",
            color: "rgb(63 63 63)",
            borderColor: "#ea586b",
            marginRight: "20px"
          }}
          onClick={() => { onClose && onClose(); }}
        >
          Cancel
        </Button>

        <Button
          style={{
            borderRadius: "5px",
            boxShadow: "none",
            textTransform: "none",
            fontSize: 16,
            padding: "8px 45px",
            border: "1px solid",
            lineHeight: 1.5,
            background: formSubmitBtn,
            color: "white"
          }}
          type="submit"
          onClick={() => { onSubmit && onSubmit(paymentTerms); }}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const styles: Styles<Theme, {}, string> = {
  // Customizable Area Start
  modal: {
    "& .MuiDialog-paper": {
      width: 680,
    },
    "& .MuiDialogTitle-root": {
      display: "none"
    }
  },
  paymentHeaderContainer: {
    marginTop: 30,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingRight: 24
  },
  deleteButton: {
    backgroundColor: "#FF414A",
    width: "10px",
    height: "10px",
    margin: "0px",
    borderRadius: "15px"
  },
  paymentDeleteButton: {
    backgroundColor: "#FF414A",
    width: "40px",
    height: "40px",
    margin: "0px",
    borderRadius: "50%",
    marginTop: "8px" // total height 56, iconheight 40, to center (56-40)/2
  },
  // Customizable Area End
};

export const PaymentTermsModal = withStyles(styles)(PaymentTermsModalWithoutStyles);