// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { deleteIcon } from "./assets";
import { runEngine } from "../../../framework/src/RunEngine";
export const configJSON = require("./config.js");

export interface Props {
    open: boolean;
    handleClose: (type: string, action: boolean) => void;
    classes: any;
    accountDetailserrorMessage: string;
    accountDetails: accountData;
    DateFormateConverter: any;
}
  
  interface accountAttribute {
    id: number;
    name: string;
    account_type: string;
    code: string;
    description: string;
    custom_id: string;
    is_sub_account: boolean;
    parent_academic_account_id: string;
    parent_account_name: '';
  }
  
  interface accountData {
    id: number
    attributes: accountAttribute;
  }

interface S {
    closeModal: boolean;
    data: any;
    noteHeader: string;
    disabledbutton: boolean;
    loadingSpinner: boolean;
    deleteIcon: any;

}
interface SS {
    id: any;
}

// const updateRoleMappingStatus = (accountDetails: accountData | null) => {
//   let permissions: rolePerm = {};
//   if (roleDetails && roleDetails.attributes && roleDetails.attributes.role_premissions && roleDetails.attributes.role_premissions.data && roleDetails.attributes?.role_premissions?.data.length) {
//     roleDetails?.attributes?.role_premissions?.data.map((item: rolePremissionAttribute) => {
//       if (item.attributes.menu) {
       
//       }
//     });
//     return permissions;
//   }
//   return null;
// } 
// Customizable Area End
export default class ViewAccountController extends BlockComponent<Props, S, SS> {
   
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [];

        this.state = {
            closeModal: false,
            data: [],
            noteHeader: '',
            disabledbutton: true,
            loadingSpinner: false,
            deleteIcon: deleteIcon,
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    // Customizable Area Start
    async componentDidMount() {
      super.componentDidMount();
    }
    
    // componentDidUpdate(prevProps: Props) {
       
    //     if(prevProps.roleDetails !== this.props.roleDetails){
    //       this.setState({ rolePermissions: {} });
    //       const detailsResult = updateRoleMappingStatus(this.props.roleDetails);
    //       if(detailsResult){
    //         this.setState({rolePermissions: detailsResult});
    //       }
    //     }
    // }

    modalhandleClose = () => {
        this.setState({ closeModal: true })
        this.props.handleClose("view", false);
    }
    // Customizable Area End
}
