import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

// Customizable Area Start
export enum FormMode {
  Create,
  Edit,
  View,
  Copy
}

export interface ISubFineForm {
  name: string;
  day: number;
  duration: string;
  fine_amount: string;
  mode: string;
  fine_category: string | null;

  id?: number;
  created_at?: string;
  updated_at?: string;
  custom_id?: string;
  fine_id?: number;
}

export interface IFineForm {
  id?: string;
  name: string;
  day: number | string;
  duration: string;
  fine_category: string | null;
  fine_amount: string;
  mode: string;
  created_at?: string;
  updated_at?: string;
  custom_id?: string;
  sub_fines_attributes: ISubFineForm[];
}
// Customizable Area End

export interface Props {
  // Customizable Area Start
  classes?: any;
  isOpen: boolean;
  requestMessage: Message;
  initialValues: IFineForm | null;
  formMode: FormMode;
  title: string;
  submitLabel: string;
  navigation?: any;
  onClose: () => void;
  onSubmit: (form: IFineForm) => void;

  // Customizable Area End
}

interface S {
  // Customizable Area Start
  form: IFineForm;
  isSubmitting: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class FineFormController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  private initialValues: IFineForm = {
    created_at: new Date().toString(),
    custom_id: "",
    day: 0,
    duration: "",
    fine_amount: "0",
    fine_category: null,
    mode: "amount",
    name: "",
    updated_at: new Date().toString(),
    sub_fines_attributes: []
  };
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    console.log("props", props);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      form: { ...(props.initialValues || this.initialValues) },
      isSubmitting: false
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const callID = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      if (
        this.props.requestMessage &&
        callID === this.props.requestMessage.messageId
      ) {
        //this.handleFormClose();
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  public componentDidUpdate(prevProps: Props) {
    const { isOpen, initialValues, formMode } = this.props;
    if (prevProps.isOpen !== isOpen) {
      if (isOpen) {
        let form: IFineForm = { ...this.initialValues };

        if ([FormMode.Edit, FormMode.View].includes(formMode)) {
          form = { ...(initialValues || this.initialValues) };
        }

        this.setState({
          form,
          isSubmitting: false
        });
      }
    }
  }

  public onChangeTaxName(element: React.ChangeEvent<{ value: string }>) {
    this.setState({
      form: {
        ...this.state.form,
        name: element.target.value
      }
    });
  }

  public onSubmit() {
    this.setState({ isSubmitting: this.props.formMode !== FormMode.View });

    // TODO: Validate form

    this.props.onSubmit(this.state.form);
  }

  public handleFormClose() {
    this.props.onClose();
  }
  // Customizable Area End
}
