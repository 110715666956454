import React from "react";
// Customizable Area Start
import {
    TextField,
    Typography
} from "@material-ui/core";
import { withStyles, } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CustomModal from '../../CommonLayout/CustomModal/src/CustomModal.web';
import { capitalizeFirst } from "../../../framework/src/Utilities";

import ViewRolesController, {
    Props,
} from "./ViewRolesController";


interface roleMenuSubmenu {
    id: number;
    title: string;
    permission_category_id: number,
    fullAccessStatus: boolean,
    viewStatus: boolean,
    editStatus: boolean,
    createStatus: boolean,
    deleteStatus: boolean,
    approveStatus: boolean
}
interface roleMenuAttributes {
    id: number;
    title: string;
    menus: roleMenuSubmenu[];
}

interface roleMenuData {
    attributes: roleMenuAttributes;
}
interface prmField {
    menu: string,
    viewStatus: boolean,
    editStatus: boolean,
    createStatus: boolean,
    deleteStatus: boolean
}
// Customizable Area End
class ViewRoles extends ViewRolesController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    render() {
        // Customizable Area Start
        const {rolePermissions} = this.state;
        const { open, handleClose,menuList,roleMenuListerrorMessage,roleDetails, DateFormateConverter,roleDetailserrorMessage  } = this.props;
        console.log('role details', roleDetails);
        // Customizable Area End
        return (
            // Customizable Area Start
            <CustomModal cancelButtonTitle={'Back to Listing'}
                open={open}
                title={"View Role"}
                handleClose={() => handleClose("view", false)}>
                {
                    roleMenuListerrorMessage ? <p className="errorMsg2">{roleMenuListerrorMessage}</p> :
                    roleDetailserrorMessage  ? <p className="errorMsg2">{roleDetailserrorMessage}</p> :
                    (<>
                        <Grid
                            container
                            style={{
                                marginTop: "10px"
                                
                            }}
                        >
                            <Grid style={{ color: "#465872", fontWeight: "bold", marginBottom: "36px" }} xs={6} item>Role ID:
                                <Typography style={{color: "#7e7d7d", marginTop: "16px"}}>{roleDetails ? roleDetails.attributes?.custom_id :''}</Typography>
                            </Grid>
                            <Grid style={{ color: "#465872", fontWeight: "bold", marginBottom: "36px" }} xs={6} item>Role Name*:
                                <Typography style={{color: "#7e7d7d", marginTop: "16px"}}>{roleDetails ? capitalizeFirst(roleDetails.attributes?.name): ''}</Typography>
                            </Grid>
                            <Grid style={{ color: "#465872", fontWeight: "bold", marginBottom: "36px" }} xs={6} item>Status*:
                                <Typography style={{color: "#7e7d7d", marginTop: "16px"}}>{roleDetails ? roleDetails.attributes?.status == 'active' ? 'Active' : 'Inactive': 'Inactive'}</Typography>
                            </Grid>
                            <Grid style={{ color: "#465872", fontWeight: "bold", marginBottom: "36px" }} xs={6} item>Created On:
                                <Typography style={{color: "#7e7d7d", marginTop: "16px"}}>{roleDetails ? DateFormateConverter(roleDetails.attributes?.created): ''}</Typography>
                            </Grid>
                            <Grid style={{ color: "#465872", fontWeight: "bold", marginBottom: "36px" }} xs={6} item>Created By:
                                <Typography style={{color: "#7e7d7d", marginTop: "16px"}}>{roleDetails ? capitalizeFirst(roleDetails.attributes?.created_by): ''}</Typography>
                            </Grid>
                        </Grid>
                        {
                        roleMenuListerrorMessage ?
                            <p className="errorMsg2">{roleMenuListerrorMessage}</p>
                            :
                            (
                                menuList.map((menu: roleMenuData, index: number) => (
                                    <>
                                        <Grid key={index} container spacing={0} style={
                                            {
                                                backgroundColor: "#fef9e9",
                                                display: "flex",
                                                alignItems: "center",
                                                padding: 20,
                                                borderRadius: 5,
                                                height: "60px",
                                                marginTop: "34px",
                                            }}>
                                            <Grid item xs={5}>
                                                <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
                                                    {menu.attributes.title}
                                                </Typography>
                                            </Grid>
                                            <Grid item style={{ textAlign: "center" }} xs={2}>
                                                <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
                                                    {'Full Access'}
                                                </Typography>
                                            </Grid>
                                            {["View", "Create", "Edit", "Delete", "Approve"].map((label: string) => (
                                                <Grid item xs={1} style={{ paddingLeft: 8 }}>
                                                    <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
                                                        {label}
                                                    </Typography>
                                                </Grid>
                                            ))}
                                        </Grid>
                                        <div style={{
                                            maxWidth: "100%",
                                            margin: "0px auto",
                                            backgroundColor: "white",
                                            //padding: "20px",
                                            marginTop: "18px",
                                            textAlign: "center"

                                        }}>
                                            {

                                                menu.attributes.menus.map((fieldData: roleMenuSubmenu, indx: number) => (

                                                    
                                                        <Grid
                                                            container
                                                            spacing={0}
                                                            style={{
                                                                padding: 10,
                                                                backgroundColor: "#fafafa",
                                                                marginBottom: "8px",
                                                                borderRadius: "5px",
                                                            }}
                                                        >
                                                            <Grid item xs={5}>
                                                                <Typography style={{ width: "100%",padding: 12, textAlign: "left",color: "#44556c" }}>{fieldData.title}</Typography>
                                                            </Grid>
                                                            <Grid item xs={2} style={{ alignSelf: "center", paddingLeft: 56 }}>
                                                                <FormControlLabel
                                                                    control={<Checkbox disabled
                                                                        checked={(rolePermissions[fieldData.id]) ? rolePermissions[fieldData.id].fullAccessStatus : false}
                                                                        name="fullAccessStatus" />}
                                                                    label=""
                                                                />
                                                            </Grid>
                                                            <Grid item xs={1} style={{ alignSelf: "center" }}>
                                                                <FormControlLabel
                                                                    control={<Checkbox disabled
                                                                        checked={(rolePermissions[fieldData.id]) ? rolePermissions[fieldData.id].viewStatus : false}
                                                                        name="viewStatus" />}
                                                                    label=""
                                                                />
                                                            </Grid>
                                                            <Grid item xs={1} style={{ alignSelf: "center" }}>
                                                                <FormControlLabel
                                                                    control={<Checkbox disabled
                                                                        checked={(rolePermissions[fieldData.id]) ? rolePermissions[fieldData.id].createStatus : false}
                                                                        name="createStatus" />}
                                                                    label=""
                                                                />
                                                            </Grid>
                                                            <Grid item xs={1} style={{ alignSelf: "center" }}>
                                                                <FormControlLabel
                                                                    control={<Checkbox disabled
                                                                        name="editStatus"
                                                                        checked={(rolePermissions[fieldData.id]) ? rolePermissions[fieldData.id].editStatus : false}
                                                                    />}
                                                                    label=""
                                                                />
                                                            </Grid>
                                                            <Grid item xs={1} style={{ alignSelf: "center" }}>
                                                                <FormControlLabel
                                                                    control={<Checkbox disabled
                                                                        name="deleteStatus"
                                                                        checked={(rolePermissions[fieldData.id]) ? rolePermissions[fieldData.id].deleteStatus : false}
                                                                    />}
                                                                    label=""
                                                                />
                                                            </Grid>
                                                            <Grid item xs={1} style={{ alignSelf: "center" }}>
                                                                <FormControlLabel
                                                                    control={<Checkbox disabled
                                                                        checked={(rolePermissions[fieldData.id]) ? rolePermissions[fieldData.id].approveStatus : false}
                                                                        name="approveStatus" />}
                                                                    label=""
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    
                                                ))
                                            }
                                        </div>
                                    </>
                                ))
                            )
                        }
                    </>)
                }
            </CustomModal >
            // Customizable Area End
        );
    }
}
export const themeCreatStyle = ((theme: any) => ({

    background_color: {
        backgroundColor: theme.palette.primary.main
    },
    title_font: {
        color: theme.props.titleColor,
        fontFamily: theme.props.titleFont
    },
    subTitle_font: {
        color: theme.props.subtitleColor,
        fontFamily: theme.props.subtitleFont
    },
    heading_font: {
        color: theme.props.headingColor,
        fontFamily: theme.props.headingFont
    },
    subHeading_font: {
        color: theme.props.subheadingColor,
        fontFamily: theme.props.subheadingFont
    },
    bodyText_font: {
        color: theme.props.textColor,
        fontFamily: theme.props.textFont
    },
    button_color: {
        backgroundColor: theme.props.buttonColor,
        color: "white",
    },
    icon_color: {
        color: theme.props.iconColor
    },
    button_color_disabled: {
        color: theme.props.buttonColor,
        backgroundColor: "white",
        border: `1px solid ${theme.props.buttonColor}`,
        '&:hover': {
            background: "#fff",
        }
    }
}))
export default withStyles(themeCreatStyle)(
    ViewRoles as React.ComponentType<any>
)