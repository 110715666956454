export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const imgDummyUser = require("../assets/dummyUser.png");
export const fontIcon = require("../assets/fontIcon.png");
export const refreshIcon = require("../assets/refreshIcon.png");
export const supportIcon = require("../assets/supportIcon.png");
export const notificationIcon = require("../assets/notificationIcon.png");
export const countryIcon = require("../assets/countryIcon.png");
export const sideMenu1 = require("../assets/sidebarMenu1.png");
export const sideMenu2 = require("../assets/sidebarMenu2.png");
export const sideMenu3 = require("../assets/sidebarMenu3.png");
export const sideMenu4 = require("../assets/sidebarMenu4.png");
export const leftArrowIcon = require("../assets/leftArrowIcon.png");

