import React from "react";

// Customizable Area Start
const { baseURL } = require("../../../framework/src/config");
import ForgotPasswordController, { Props } from "./ForgotPasswordController";
import { ToastContainer, toast, cssTransition } from "react-toastify";
import Loader from "../../../components/src/Loader.web";
var confirmPassword: any;
var Password: any;
var token: any;
let is_otp_from_lms:any;
// Customizable Area End
export default class NewPassword extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
    this.isChangePassword = true;
    // Customizable Area Start
    confirmPassword = React.createRef();
    Password = React.createRef();
    is_otp_from_lms = localStorage.getItem("is_otp_from_lms");
    token = localStorage.getItem("forgot_token");
    if(token === null){
      this.props.navigation.navigate("EmailAccountLoginBlock");
    }
    // Customizable Area End
  }
  // Customizable Area Start
  async componentDidMount() {
    if(token){
      this.setState({ token: token });
    }
    
  }
  handleChange = (e: any) => {
    if (e.target.name === "password") {
      this.setState({ password: e.target.value });
      console.log(e.target.value);
    } else if (e.target.name === "confirmPassword") {
      this.setState({ confirmPassword: e.target.value });
      console.log(e.target.value);
    }
  };
  handleConfirmValidation: any = () => {
    let isValid = true;
    //  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*]){8,}$/i;
     const regex =/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/
    if (Password.current.value === "" ) {
      this.setState({
        passwordError: (<p className="errorMsg">New password is required</p>),
      });
      isValid = false;
    }
    if (confirmPassword.current.value === "") {
      this.setState({
        confirmPasswordError: (
          <p className="errorMsg">Confirm new password is required</p>
        ),
      });
      isValid = false;
    }
    if(Password.current.value){
     if (regex.test(Password.current.value) === false) {
      this.setState({
        passwordError: (
          <p className="errorMsg">
            Please enter a valid password (ex: Aa@12345)
          </p>
        ),
      });
      isValid = false;
    } else {
      this.setState({
        passwordError: null,
      });
    }
  }
   if(confirmPassword.current.value){
  if (Password.current.value !== confirmPassword.current.value) {
      this.setState({
        confirmPasswordError: (
          <p className="errorMsg">Confirm password should be same</p>
        ),
      });
      isValid = false;
    } else {
      this.setState({ confirmPasswordError: null });
    }
  }
    return isValid;
  };
  handleSubmit = (e: any) => {
    e.preventDefault();
    if (this.handleConfirmValidation()) {
      this.setState({ isLoading: true });

      const jsonPayload = {
        data: {
          token: this.state.token,
          new_password: this.state.password,
          is_otp_from_lms: JSON.parse(is_otp_from_lms)
        },
      };

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(jsonPayload),
      };

      fetch(baseURL + "/passwords/update_password_lms", requestOptions)
        .then((response) => response.json())
        .then((data) => {
          console.log(data.errors);
          if (data.errors) {
            if(data.errors[0].token){
              this.props.navigation.navigate("ForgotPassword");
              toast.warn("Unable to change password. Please try again");
            }
            this.setState({ isLoading: false });
            this.setState({
              passwordError: (
                <p className="errorMsg">{data.errors[0].password || data.errors[0].otp ||data.errors[0]}</p>
              ),
            });
          } else {
            toast.success("Password changed successfully");
            localStorage.clear();
            this.props.navigation.navigate("EmailAccountLoginBlock");
            this.setState({ isLoading: false });
          }
        });
    }
  };
  togglePassword = () => {
    if (this.state.passwordType === "text") {
      this.setState({
        passwordType: "password",
      });

      return;
    }
    this.setState({
      passwordType: "text",
    });
  };
  toggleConfirmPassword = () => {
    if (this.state.confirmPasswordType === "password") {
      this.setState({
        confirmPasswordType: "text",
      });

      return;
    }
    this.setState({
      confirmPasswordType: "password",
    });
  };
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <>
      {this.state.isLoading && <Loader positionType={'left'} loading={true} /> }
        <div className="container">
          <div className="leftSide">
            <div className="leftContainer">
              <img
                src={this.state.logoImage}
                alt="logo"
                className="logoImgStyle"
              />
              <p className="headingStyle">
                Manage your <span className="d-block">Finance</span>
              </p>
              <div className="mainImg">
                <img
                  src={this.state.mainImage}
                  alt="mainImage"
                  className="mainImageStyle"
                />
              </div>
            </div>
          </div>
          <div className="rightSide">
            <div className="resetRightContainer">
              <h1>Reset Password</h1>
              <p className="resetParaStyle">
                Your new password must be different from your old password.
              </p>
              <form onSubmit={this.handleSubmit}>
                <div className="resetInputParent">
                  <div className="inputContainer">
                    <input
                      onInput={this.handleConfirmValidation}
                      type={this.state.passwordType}
                      value={this.state.password}
                      ref={Password}
                      style={
                        this.state.passwordError
                          ? {
                              color: "#f15b5b",
                              border: "1px solid #ff0000",
                              outline: "0px",
                            }
                          : { color: "black", border: "none" }
                      }
                      name="password"
                      onChange={this.handleChange}
                      placeholder="New pasword"
                    />
                    <span>
                      <img
                        src={this.state.passwordIconImg}
                        alt="mainImage"
                        className="iconStyle"
                      />{" "}
                    </span>
                    <div onClick={this.togglePassword} className="eyeToggle">
                      <img
                        src={
                          this.state.passwordType === "password"
                            ? this.state.hiddenPasswordIconImg
                            : this.state.visiblePasswordIconImg
                        }
                        alt="mainImage"
                        className=" iconStyle"
                      />
                    </div>
                    <p>{this.state.passwordError}</p>
                  </div>
                  <div className="inputContainer">
                    <input
                      onInput={this.handleConfirmValidation}
                      type={this.state.confirmPasswordType}
                      ref={confirmPassword}
                      style={
                        this.state.confirmPasswordError
                          ? {
                              color: "#f15b5b",
                              border: "1px solid #ff0000",
                              outline: "0px",
                            }
                          : { color: "black", border: "none" }
                      }
                      value={this.state.confirmPassword}
                      placeholder="Confirm New pasword"
                      name="confirmPassword"
                      onChange={this.handleChange}
                    />
                    <span>
                      <img
                        src={this.state.confirmPasswordIconImg}
                        alt="mainImage"
                        className=" iconStyle"
                      />
                    </span>
                    <div
                      onClick={this.toggleConfirmPassword}
                      className="eyeToggle"
                    >
                      <img
                        src={
                          this.state.confirmPasswordType === "text"
                            ? this.state.visiblePasswordIconImg
                            : this.state.hiddenPasswordIconImg
                        }
                        alt="mainImage"
                        className=" iconStyle"
                      />
                    </div>
                    <p>{this.state.confirmPasswordError}</p>
                  </div>
                  <button type="submit" className="confirmButton">
                    Confirm Password
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </>

      //  Customizable Area End
    );
  }
}

// Customizable Area Start
// Customizable Area End
