import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
export const configJSON = require("./config.js");

export interface Props {
    open: boolean;
    handleClose: (type: string, action: boolean) => void;
    classes: any;
    accountTypeList: [];
    parentAccountList: [];
    handleSubmit: (formData: object) => void;
    handleChange: () => void;
    checkBulkUpdateId:{};
    accountbulkUpdateerrorMessage: string;
    parentListByAccount :(id: string) => void;

}

interface S {
    closeModal: boolean;
    data: any;
    noteHeader: string;
    disabledbutton: boolean;
    loadingSpinner: boolean;
    accTypeError: string,
    account_type_id: string,
    parent_academic_account_id: string,
    parentAccError: string,
    commonErrorMsg: string;

}

interface SS {
    id: any;
}
// Customizable Area End
export default class BulkUpdateAccountController extends BlockComponent<Props, S, SS> {

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [];

        this.state = {
            closeModal: false,
            data: [],
            noteHeader: '',
            disabledbutton: true,
            loadingSpinner: false,
            accTypeError: '',
            account_type_id: '',
            parent_academic_account_id: '',
            parentAccError: '',
            commonErrorMsg: '',
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }
    // Customizable Area Start
    async componentDidMount() {
        super.componentDidMount();
    }

    componentDidUpdate(prevProps: Props) {
        if (prevProps.open !== this.props.open) {
            this.setState({  accTypeError: '',account_type_id: '',parent_academic_account_id: '',
            parentAccError: '',});
        }
    }

    handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if(e.target.name === "account_type_id"){
            let value = e.target.value;
            value = (e.target.value === "null") ? "" : e.target.value;
            this.props.parentListByAccount(e.target.value);
            this.setState(prevState => ({
                ...prevState,
                [e.target.name]: value, parent_academic_account_id: "" // No error here, but can't ensure that key is in StateKeys
            }));
        }else{
        this.setState(prevState => ({
            ...prevState,
            [e.target.name]: e.target.value // No error here, but can't ensure that key is in StateKeys
        }));
        }
        this.handleValidation();
    };

    handleValidation: any = () => {
        let isValid = true;
        
        // validation
        if (this.state.parent_academic_account_id === "" ){
            this.setState({
                parentAccError: (
                    'Parent Account is required'
                )
            });
            isValid = false;
        } else {
            this.setState({ parentAccError: '' });
        } 
        if (this.state.account_type_id === "") {
            this.setState({
                accTypeError: (
                    'Account Type is required'
                )
            });
            isValid = false;
        }else {
            this.setState({ accTypeError: '' });
        } 
        
        return isValid;
    };

    handleSubmit = (e: any) => {
        e.preventDefault();
        if (this.handleValidation()) {
            this.props.handleSubmit({ accountData: this.props.checkBulkUpdateId,account_type_id: this.state.account_type_id,parent_academic_account_id: this.state.parent_academic_account_id, type: "bulkUpdate" });
        }
    };
    // Customizable Area End
}
