import React from "react";
// Customizable Area Start
import {
    TextField,
} from "@material-ui/core";
import { withStyles, } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CustomModal from '../../CommonLayout/CustomModal/src/CustomModal.web';
import OutlinedSelect from "../../../components/src/OutlinedSelect";

import CreateAccountController, {
    Props,
} from "./CreateAccountController";
// Customizable Area End
class CreateAccount extends CreateAccountController {

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    render() {
        // Customizable Area Start
        const { accountTypeList, parentAccountList, open, handleClose, accountCreateerrorMessage,accountCreateNameerrorMessage } = this.props;
        const { accountFormstate, accNameError, accTypeError, subAccountStatus, parentAccError } = this.state;
        // Customizable Area End
        return (
            // Customizable Area Start
            <CustomModal
                title={"Create Account"}
                handleSubmit={this.handleSubmit}
                buttonTitle={"Create"}
                open={open}
                handleClose={(evt: object) => handleClose("create", false)}
                isError={false}
                errormsg={accountCreateerrorMessage ? accountCreateerrorMessage : ''}

            >
                <Grid
                    container
                    spacing={2}
                    style={{
                        marginTop: "10px",
                    }}
                >

                    <Grid item xs={4}>
                        <OutlinedSelect
                            placeholderTitle="Select Type"
                            val={accountFormstate.account_type_id}
                            options={accountTypeList}
                            label="Account Type*"
                            name={'account_type_id'}
                            handleChange={this.handleChange}
                            className={accTypeError  ?"errorSelect":"withoutErrorSelect" }                      
                        />
                        {accTypeError &&
                            <p className="errorMsg2">{accTypeError}</p>
                        }
                    </Grid>
                    <Grid item xs={4}>
                        <FormControl style={{ minWidth: "100%" }}>
                            <FormLabel style={{ marginBottom: "8px" }}>
                                Account Name*
                            </FormLabel>
                            <TextField
                                value={accountFormstate.name}
                                type={'text'}
                                placeholder={`Enter Name`}
                                className={accNameError ?"errorInput":"withoutError" }
                                variant="outlined"
                                name={'name'}
                                onChange={this.handleChange}
                            />
                            {
                                accountCreateNameerrorMessage && <p className="errorMsg2">{accountCreateNameerrorMessage}</p>}
                            {   accNameError && <p className="errorMsg2">{accNameError}</p>}
                            
                            
                        </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                        <FormControl style={{ minWidth: "100%" }}>
                            <FormLabel style={{ marginBottom: "8px" }}>
                                Account Code
                            </FormLabel>
                            <TextField
                                value={accountFormstate.code}
                                type={'text'}
                                placeholder={`Enter Code`}
                                style={{
                                        border: "none",
                                        width: '100%'
                                    }}
                                variant="outlined"
                                name={'code'}
                                onChange={this.handleChange}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={8}>
                        <FormControl style={{ minWidth: "100%" }}>
                            <FormLabel style={{ marginBottom: "8px" }}>
                                Description
                            </FormLabel>
                            <TextField
                                value={accountFormstate.description}
                                type={'text'}
                                placeholder={`Enter Description`}
                                multiline={true}
                                style={{
                                        border: "none",
                                        width: '100%'
                                    }}
                                variant="outlined"
                                name={'description'}
                                onChange={this.handleChange}
                            />
                        </FormControl>
                    </Grid>
                    {subAccountStatus &&
                        <Grid item xs={4}>
                            <OutlinedSelect
                                placeholderTitle="Select Account"
                                val={accountFormstate.parent_academic_account_id}
                                options={parentAccountList}
                                label="Parent Account*"
                                name={'parent_academic_account_id'}
                                handleChange={this.handleChange}
                                className={parentAccError && subAccountStatus  ?"errorSelect":"withoutErrorSelect" }                      
                           />
                            {parentAccError && subAccountStatus &&
                                <p className="errorMsg2">{parentAccError}</p>
                            }
                        </Grid>
                    }
                </Grid>
                <Grid
                    container
                    spacing={2}
                    style={{
                        marginTop: "10px",
                    }}
                >
                    <Grid item xs={4}>
                        <FormControlLabel
                            control={<Checkbox checked={accountFormstate.is_sub_account} 
                            name="is_sub_account" />}
                            label="Make this a sub-account"
                            onChange={this.handleChange}
                        />
                    </Grid>
                </Grid>
            </CustomModal>
            // Customizable Area End
        );
    }
}

export const themeCreatStyle = ((theme: any) => ({

    background_color: {
        backgroundColor: theme.palette.primary.main
    },
    title_font: {
        color: theme.props.titleColor,
        fontFamily: theme.props.titleFont
    },
    subTitle_font: {
        color: theme.props.subtitleColor,
        fontFamily: theme.props.subtitleFont
    },
    heading_font: {
        color: theme.props.headingColor,
        fontFamily: theme.props.headingFont
    },
    subHeading_font: {
        color: theme.props.subheadingColor,
        fontFamily: theme.props.subheadingFont
    },
    bodyText_font: {
        color: theme.props.textColor,
        fontFamily: theme.props.textFont
    },
    button_color: {
        backgroundColor: theme.props.buttonColor,
        color: "white",
    },
    icon_color: {
        color: theme.props.iconColor
    },
    button_color_disabled: {
        color: theme.props.buttonColor,
        backgroundColor: "white",
        border: `1px solid ${theme.props.buttonColor}`,
        '&:hover': {
            background: "#fff",
        }
    }
}));

export default withStyles(themeCreatStyle)(
    CreateAccount as React.ComponentType<any>
)