import React from "react";
// Customizable Area Start
/*-- app bar --*/
import { withStyles, Theme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { defaultBtn } from "../../Layout/src/color";

/*-- app bar end --*/

import { Styles } from "@material-ui/styles";
import { Avatar } from "@material-ui/core";

// Customizable Area End

import HeaderController, { Props, configJSON } from "./HeaderController";

import { StyleSheet, ScrollView, Platform } from "react-native";

// Customizable Area Start
const useStyles: Styles<Theme, {}, string> = (theme: Theme) => ({
  /* ---App bar css start--- */
  menuItem: {
    width: "140px",
    "&:hover": {
      background: defaultBtn,
      color: "white"
    }
  },
  // headerMenu:{

  //   top: "72px",
  //   width: "106px",
  //   left: "1324px",
  // },
  appBar: {
    position: "fixed",
    backgroundColor: "white",
    boxShadow: "none",
    transition: "all 0.3s ease-out",
    borderBottom: "1px solid #dddada",
    marginRight: 25,
    height: 74,
    borderTopLeftRadius: 35
  },
  typography: {
    width: "100%"
  },
  iconBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "end",
    marginRight: "-25px",
    transition: "none",
    float: "right"
  },
  avatarIcon: {
    height: "22px",
    width: "22px"
  },
  profileArrowIcon: {
    height: "22px",
    width: "22px",
    marginLeft: "24px"
  },
  userProfilePicDiv: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer"
  },
  Avatarimg: {
    cursor: "pointer",
    height: 55,
    width: 55,
    marginRight: 5,
    borderRadius: "50%"
  },
  margin: {
    margin: theme.spacing(1)
  }

  /* ---App bar css end--- */
});
// Customizable Area End

class Header extends HeaderController {
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area Start
  // Customizable Area End

  render() {
    const {
      classes,
      open,
      drawerWidth,
      handleSearchChange,
      searchKeyword,
      hideSearchBar
    } = this.props;
    const { showCollabsibleIcons } = this.state;

    // Customizable Area End

    return (
      // Customizable Area Start
      //Merge Engine DefaultContainer
      <AppBar
        className={classes.appBar}
        style={{
          width: `calc(100% - ${drawerWidth + 48}px)`
        }}
      >
        <Toolbar>
          {hideSearchBar ? null : searchKeyword !== undefined ? (
            <TextField
              style={{ width: "700px", marginLeft: "-23px" }}
              className={classes.margin}
              id="input-with-icon-textfield"
              value={searchKeyword}
              placeholder="Search"
              onChange={e =>
                handleSearchChange ? handleSearchChange(e) : () => null
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                )
              }}
            />
          ) : (
            <TextField
              style={{ width: "700px", marginLeft: "-23px" }}
              className={classes.margin}
              id="input-with-icon-textfield"
              placeholder="Search"
              onChange={e =>
                handleSearchChange ? handleSearchChange(e) : () => null
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                )
              }}
            />
          )}

          <div className={classes.typography}>
            <div className={classes.iconBox}>
              {showCollabsibleIcons && (
                <div>
                  <IconButton>
                    <Avatar
                      src={this.state.refreshIconImg}
                      className={classes.avatarIcon}
                    />
                  </IconButton>
                  <IconButton>
                    <Avatar
                      src={this.state.notificationIconImg}
                      className={classes.avatarIcon}
                    />
                  </IconButton>
                  <IconButton>
                    <Avatar
                      src={this.state.supportIconImg}
                      className={classes.avatarIcon}
                    />
                  </IconButton>
                  <IconButton>
                    <Avatar
                      src={this.state.fontIconImg}
                      className={classes.avatarIcon}
                    />
                  </IconButton>
                  <IconButton>
                    <Avatar
                      src={this.state.countryIconImg}
                      className={classes.avatarIcon}
                    />
                  </IconButton>
                </div>
              )}
              <IconButton onClick={this.handleArrowButtonClick}>
                <Avatar
                  src={this.state.leftArrowIcon}
                  className={`${
                    classes.profileArrowIcon
                  } ${showCollabsibleIcons && "rotate180"}`}
                />
              </IconButton>
              <div className={classes.usrProfilePicDiv}>
                <Avatar
                  className={classes.Avatarimg}
                  src={this.state.dummyUserIconImg}
                  onClick={e => this.handleAnchorClick(e)}
                />
              </div>
            </div>
          </div>
        </Toolbar>
        <Menu
          style={{
            width: "140px",
            top: 52,
            right: 8
          }}
          keepMounted
          anchorEl={this.state.anchorEl}
          open={Boolean(this.state.anchorEl)}
          onClose={this.handleAnchorClose}
        >
          <MenuItem onClick={this.handleLogout} className={classes.menuItem}>
            Logout
          </MenuItem>
        </Menu>
      </AppBar>
      //Merge Engine End DefaultContainer
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 16,
    marginLeft: "auto",
    marginRight: "auto",
    width: Platform.OS === "web" ? "75%" : "100%",
    maxWidth: 650,
    backgroundColor: "#ffffffff"
  }
});

// Customizable Area End

export default withStyles(useStyles)(Header);