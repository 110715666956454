import React from "react";
// Customizable Area Start
import { withStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Styles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import TextField from '@material-ui/core/TextField';
import OutlinedSelect from "../../../components/src/OutlinedSelect";

import CustomModal from "../../CommonLayout/CustomModal/src/CustomModal.web";
// Customizable Area End

import TaxRateFormController, { FormMode, Props } from "./TaxRateFormController";
import { classNames } from "../../CommonLayout/HRMSCommons/src/classNames";

// Customizable Area Start
const useStyles: Styles<Theme, {}, string> = (theme: Theme) => ({
  form: {
    '& .MuiSelect-select.Mui-disabled': {
      color: "#7E7E7E",
      backgroundColor: "rgb(241, 241, 241)"
    }
  },
  question: {
    backgroundColor: "#FFF9EE",
    marginTop: 20,
    marginLeft: -24,
    width: "calc(100% + 48px)",

    '& h6, & label': {
      color: "#7E766B",
      fontSize: 14,
      fontWeight: 600,
    },
    '& .MuiRadio-colorSecondary.Mui-checked': {
      color: '#fe4554'
    }
  },
  viewMode: {
    '& .question': {
      '& h6, & label': {
        color: "#1E1E1E",
      }
    },
    '& .MuiFormLabel-root.Mui-disabled': {
      color: "#44556C",
    },
    '& .MuiRadio-colorSecondary.Mui-checked': {
      color: 'rgba(0, 0, 0, 0.26) !important'
    },
    '& .MuiInputBase-root > *': {
      opacity: 0,

      '&.MuiSelect-root.Mui-disabled, &.MuiInputBase-input.Mui-disabled': {
        opacity: 1,
        color: "#7E7E7E",
        paddingLeft: 0,
        fontWeight: 500,
        backgroundColor: "rgb(255, 255, 255)"
      }
    }
  }
});
// Customizable Area End

class TaxRateForm extends TaxRateFormController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes, isOpen, formMode, title, submitLabel } = this.props;
    const { form, formErrors } = this.state;
    console.log('props', this.props)

    return (
      <CustomModal
        title={title}
        handleSubmit={this.onSubmit.bind(this)}
        buttonTitle={submitLabel}
        open={isOpen}
        handleClose={this.handleFormClose.bind(this)}
        submitLoading={this.state.isSubmitting}
        hideCancelButton={formMode === FormMode.View}
      >
        <Grid container direction="column" className={classNames(classes.form, FormMode.View === formMode ? classes.viewMode : '')}>
          <Grid container direction="row" wrap="nowrap" justify="center" alignItems="center" className={classNames(classes.question, 'question')}>
            <Typography variant="h6">
              Is this GST?
            </Typography>
            <Radio
              checked={form.is_gst}
              value={!form.is_gst}
              onChange={this.onChangeGST.bind(this, true)}
              name="is_gst"
              disabled={formMode === FormMode.View}
            />
            <InputLabel htmlFor="is_gst">Yes</InputLabel>
            <Radio
              checked={!form.is_gst}
              value={!form.is_gst}
              onChange={this.onChangeGST.bind(this, false)}
              name="is_gst"
              disabled={formMode === FormMode.View}
            />
            <InputLabel htmlFor="is_gst">No</InputLabel>
          </Grid>

          <Grid container direction="row" style={{ marginTop: 20 }}>
            <Grid item xs={12} sm={4} style={{ paddingRight: 12 }}>
              <OutlinedSelect
                val={form.tax_type}
                options={[
                  { id: "SGST", name: "SGST" },
                  { id: "CGST", name: "CGST" },
                  { id: "IGST", name: "IGST" },
                  { id: "UTGST", name: "UTGST" },
                ]}
                label="Tax Type*"
                placeholder="Select Type"
                name={'tax_type'}
                disabled={formMode === FormMode.View || !form.is_gst}
                handleChange={this.onChangeTaxType.bind(this)} className={undefined}              />
              {
                formErrors.tax_type && form.is_gst && (
                  <Typography variant="caption" style={{ color: 'red' }}>
                    {formErrors.tax_type}
                  </Typography>
                )
              }
            </Grid>
            <Grid item xs={12} sm={4} style={{ paddingRight: 12 }}>
              <FormControl style={{ width: '100%' }}>
                <FormLabel style={{ marginBottom: "8px" }}>
                  Tax Name*
                </FormLabel>
                <TextField
                  value={form.name}
                  color={'primary'}
                  variant="outlined"
                  type={'text'}
                  name={'name'}
                  placeholder={'Enter Name'}
                  disabled={formMode === FormMode.View}
                  onChange={this.onChangeTaxName.bind(this)}
                  required
                />
                {
                  formErrors.name && (
                    <Typography variant="caption" style={{ color: 'red' }}>
                      {formErrors.name}
                    </Typography>
                  )
                }
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4} style={{ paddingRight: 12 }}>
              <FormControl style={{ width: '100%' }}>
                <FormLabel style={{ marginBottom: "8px" }}>
                  Tax %*
                </FormLabel>
                <TextField
                  value={form.tax_percentage}
                  color={'primary'}
                  variant="outlined"
                  type={'number'}
                  name={'tax_percentage'}
                  placeholder={'Enter Tax %'}
                  disabled={formMode === FormMode.View}
                  onChange={this.onChangeTaxPercentage.bind(this)}
                  required
                />
                {
                  formErrors.tax_percentage && (
                    <Typography variant="caption" style={{ color: 'red' }}>
                      {formErrors.tax_percentage}
                    </Typography>
                  )
                }
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={8} style={{ marginTop: 20, paddingRight: 12 }}>
              <FormControl style={{ width: '100%' }}>
                <FormLabel style={{ marginBottom: "8px" }}>
                  Description
                </FormLabel>
                <TextField
                  value={form.description}
                  color={'primary'}
                  variant="outlined"
                  type={'text'}
                  name={'description'}
                  placeholder={'Enter Description'}
                  disabled={formMode === FormMode.View}
                  onChange={this.onChangeDescription.bind(this)}
                />
                {
                  formErrors.description && (
                    <Typography variant="caption" style={{ color: 'red' }}>
                      {formErrors.description}
                    </Typography>
                  )
                }
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      </CustomModal>
    );
    // Customizable Area End
  }
}

export default withStyles(useStyles)(TaxRateForm);
