import React from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { IActionMenuItem } from "../../CommonLayout/HRMSCommons/src/actionMenu";
import { downloadCSV } from "../../../framework/src/Utilities";
import { toast } from "react-toastify";
// Customizable Area End

import { FormMode, Props as ITaxRateFormProps, ITaxRateForm } from "./TaxRateFormController";
import { debounce } from "lodash";
export const configJSON = require("./config");

// Customizable Area Start
enum Method {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
  PATCH = 'PATCH',
}

interface ITaxRate {
  id: number;
  type: string;
  selected: boolean;
  attributes: {
    custom_id: string;
    description: string;
    id: number;
    is_gst: boolean;
    name: string;
    tax_percentage: number;
    tax_type: string;
  };
}

interface ITaxName {
  id: number,
  type: string,
  attributes: {
    name: string;
  }
}

interface IPageMeta {
  message: string;
  total_pages: number;
}

interface IFilterForm {
  name: string;
  start_range: number;
  end_range: number;
  tax_type: string;
}
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  anchorEl: HTMLElement | null;
  actionedTableRow: ITaxRate | null;
  actionMenuItems: Array<IActionMenuItem>;
  taxRates: Array<ITaxRate>;
  taxNames: Array<ITaxName>;
  pageMeta: IPageMeta;
  selectAllCheckboxChecked: false | 'indeterminate' | true;
  isLoading: boolean;
  deleteModalOpen: boolean;
  filterForm: IFilterForm;
  isTaxRateFormModalOpened: boolean;
  taxRateForm: ITaxRateForm | null;
  taxRateFormMode: FormMode;
  currentPage: number;
  closeImportModal: boolean,
  searchText: string,
  deleteMessage: "single" | "multiple";
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class TaxRatesController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  public tableActionMenuItems: Array<IActionMenuItem> = [
    { label: "Edit", action: this.editTaxRate.bind(this) },
    { label: "Delete", action: this.deleteTaxRate.bind(this) },
    { label: "View", action: this.viewTaxRate.bind(this) },
    { label: "Copy", action: this.copyTaxRate.bind(this) }
  ];

  public RequestMessage = {
    GetTaxRates: this.buildRequestMessage(Method.GET),
    FilterTaxRates: this.buildRequestMessage(Method.GET),
    DeleteTaxRate: this.buildRequestMessage(Method.DELETE),
    CreateTaxRate: this.buildRequestMessage(Method.POST),
    EditTaxRate: this.buildRequestMessage(Method.PUT),
    ImportTaxRates: this.buildRequestMessage(Method.POST),
    ExportTaxRates: this.buildRequestMessage(Method.GET),
    SearchTaxRates: this.buildRequestMessage(Method.GET),
    GetTaxNames: this.buildRequestMessage(Method.GET),
    Null: undefined as any
  }

  public taxRateFormProps: { [key: number]: ITaxRateFormProps } = {
    [FormMode.Create]: {
      title: "Create Tax Rate",
      submitLabel: "Create",
      initialValues: null,

      formMode: FormMode.Create,
      isOpen: false,
      onClose: this.onCloseTaxRateFormModal.bind(this),
      onSubmit: this.onSubmitCreateTaxRateModal.bind(this),
      requestMessage: this.RequestMessage.CreateTaxRate
    },
    [FormMode.Edit]: {
      title: "Edit Tax Rate",
      submitLabel: "Update",
      initialValues: null,
      formMode: FormMode.Edit,
      isOpen: false,
      onClose: this.onCloseTaxRateFormModal.bind(this),
      onSubmit: this.onSubmitEditTaxRateModal.bind(this),
      requestMessage: this.RequestMessage.EditTaxRate
    },
    [FormMode.View]: {
      title: "View Tax Rate",
      submitLabel: "Back to Listing",
      initialValues: null,
      formMode: FormMode.View,
      isOpen: false,
      onClose: this.onCloseTaxRateFormModal.bind(this),
      onSubmit: this.onCloseTaxRateFormModal.bind(this),
      requestMessage: this.RequestMessage.Null
    },
    [FormMode.Copy]: {
      title: "Copy Tax Rate",
      submitLabel: "Update",
      initialValues: null,
      formMode: FormMode.Copy,
      isOpen: false,
      onClose: this.onCloseTaxRateFormModal.bind(this),
      onSubmit: this.onSubmitCreateTaxRateModal.bind(this),
      requestMessage: this.RequestMessage.CreateTaxRate
    }
  }

  public printReferance: any = React.createRef();
  public tableRef: any = React.createRef();;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      anchorEl: null,
      actionedTableRow: null,
      taxRates: [],
      taxNames: [],
      actionMenuItems: [],
      pageMeta: {
        message: "",
        total_pages: 0
      },
      selectAllCheckboxChecked: false,
      isLoading: true,
      deleteModalOpen: false,
      filterForm: {
        name: '',
        start_range: 0,
        end_range: 0,
        tax_type: ''
      },
      isTaxRateFormModalOpened: false,
      taxRateForm: null,
      taxRateFormMode: FormMode.Create,
      currentPage: 1,
      closeImportModal: false,
      searchText: '',
      deleteMessage: "single"
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start

    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const callID = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const response = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      switch (callID) {
        case this.RequestMessage.GetTaxRates.messageId:
        case this.RequestMessage.FilterTaxRates.messageId:
          if (response !== null) {
            this.setState({
              taxRates: response?.data || [],
              pageMeta: response?.meta || {},
              isLoading: false
            });
          }
          break;

        case this.RequestMessage.GetTaxNames.messageId:
          if (response !== null) {
            this.setState({
              taxNames: response?.data || []
            });
          }

        case this.RequestMessage.DeleteTaxRate.messageId:
          if (response !== null) {
            this.setState({
              deleteModalOpen: false,
              actionedTableRow: null
            });

            this.getTaxRatesCall();
          }
          break;

        case this.RequestMessage.CreateTaxRate.messageId:
          if (response !== null && response.errors === undefined) {
            this.setState({ isTaxRateFormModalOpened: false })
            this.getTaxRatesCall();
          }

          break;

        case this.RequestMessage.EditTaxRate.messageId:
          if (response !== null) {
            this.setState({ isTaxRateFormModalOpened: false })
            this.getTaxRatesCall();
          }

          break;

        case this.RequestMessage.ImportTaxRates.messageId:
          if (response !== null) {
            this.setState({ closeImportModal: true }, () => {
              this.setState({ closeImportModal: false });
            });
            this.getTaxRatesCall();
          }

          break;

        case this.RequestMessage.SearchTaxRates.messageId:
          if (response !== null) {
            this.setState({
              taxRates: response?.data || [],
              pageMeta: response?.meta || {},
              isLoading: false
            });
          }
          break;
      }
    }
    // Customizable Area End 
  }

  // Customizable Area Start
  public async componentDidMount() {
    super.componentDidMount();

    this.getTaxRatesCall();
    this.getTaxNamesCall();
  }

  public onClickCreateTaxRate() {
    this.setState({
      isTaxRateFormModalOpened: true,
      taxRateFormMode: FormMode.Create
    });
  }

  public onSelectAllTaxRates(event: React.ChangeEvent<HTMLInputElement>) {
    const { taxRates } = this.state;
    const { checked } = event.target;

    const updatedTaxRates = taxRates.map((taxRate) => {
      return {
        ...taxRate,
        selected: checked
      }
    });

    this.setState({
      taxRates: updatedTaxRates,
      selectAllCheckboxChecked: this.isSelectAllCheckboxChecked(updatedTaxRates)
    });
  }

  public onSelectTaxRate(event: React.ChangeEvent<HTMLInputElement>, row: ITaxRate) {
    const { taxRates } = this.state;
    const { checked } = event.target;

    const updatedTaxRates = taxRates.map((taxRate) => {
      return {
        ...taxRate,
        selected: row.id === taxRate.id ? checked : taxRate.selected
      }
    });

    this.setState({
      taxRates: updatedTaxRates,
      selectAllCheckboxChecked: this.isSelectAllCheckboxChecked(updatedTaxRates)
    });
  }

  public onClickActionButton(event: any, item: ITaxRate) {
    this.setState({
      anchorEl: event.currentTarget,
      actionedTableRow: item,
      actionMenuItems: this.tableActionMenuItems
    });
  }

  public onCloseActionMenu() {
    this.setState({
      anchorEl: null,
      actionMenuItems: []
    });
  }

  onClickPagination = (page: any) => {
    if (this.tableRef.current !== null) {
      this.tableRef.current.scrollIntoView();
    }

    this.setState({ currentPage: page.selected + 1 }, () => {
      if (this.state.searchText !== '') {

        this.getSearchTaxCall();
      } else {
        this.getTaxRatesCall();
      }
    });
  }

  public onCloseDeleteModal() {
    this.setState({
      deleteModalOpen: false,
      actionedTableRow: null
    });
  }

  public onSubmitDeleteModal() {
    this.deleteTaxRateCall(this.state.actionedTableRow);
  }

  public onCloseTaxRateFormModal() {
    this.setState({ isTaxRateFormModalOpened: false });
  }

  public onSubmitCreateTaxRateModal(form: ITaxRateForm) {
    this.createTaxRateCall(form);
  }

  public onSubmitEditTaxRateModal(form: ITaxRateForm) {
    this.editTaxRateCall(form);
  }

  public onUploadCSVForImport(file: File) {
    this.importTaxRatesCall(file);
  }

  public onClickExportCSVButton(isSample: boolean) {
    this.exportTaxRatesCall(isSample);
  }

  //#region Filter Methods
  public onChangeFilterSliderRange(event: any, value: Array<number> | number) {
    if (Array.isArray(value)) {
      this.setState({
        filterForm: {
          ...this.state.filterForm,
          start_range: value[0],
          end_range: value[1]
        }
      });
    }
  }

  public onChangeFilterTaxName(event: any) {
    this.setState({
      filterForm: {
        ...this.state.filterForm,
        name: event.target.value
      }
    });
  }

  public onChangeFilterTaxType(event: any) {
    this.setState({
      filterForm: {
        ...this.state.filterForm,
        tax_type: event.target.value
      }
    });
  }

  public onFilterFormSubmit() {
    this.filterTaxRatesCall(this.state.filterForm);
  }

  public onChangeSearchInput({ target: { value } }: any) {
    this.setState({ searchText: value });

    this.debouncedSearch(value);
  }

  public debouncedSearch = debounce((value) => {
    if (value !== "") {
      this.setState({ currentPage: 1 }, () => {
        this.getSearchTaxCall(value);
      });
    } else {
      this.getTaxRatesCall();
    }
  }, 500);

  //#endregion Filter Methods

  private editTaxRate() {
    this.onSelectAllTaxRates({ target: { checked: false } } as any);

    this.openTaxRateModalWithInitialData(this.state.actionedTableRow, FormMode.Edit);
  }

  private viewTaxRate() {
    this.onSelectAllTaxRates({ target: { checked: false } } as any);

    this.openTaxRateModalWithInitialData(this.state.actionedTableRow, FormMode.View);
  }

  private deleteTaxRate() {
    const { taxRates } = this.state;
    const selectedTaxRates = taxRates.filter(el => el.selected);
    const activeRow = this.state.actionedTableRow as ITaxRate;
    const selectOther = !selectedTaxRates.some(el => el.id === activeRow.id);

    if (selectOther) {
      const updatedtaxRates = taxRates.map(el => {
        return { ...el, selected: el.id === activeRow.id ? true : false };
      });

      this.setState({
        taxRates: updatedtaxRates,
        selectAllCheckboxChecked: false,
        deleteMessage: "single",
        deleteModalOpen: true
      });
    }
    else {
      this.setState({
        deleteMessage: selectedTaxRates.length > 1 ? "multiple" : "single",
        deleteModalOpen: true
      });
    }
  }

  private copyTaxRate() {
    this.onSelectAllTaxRates({ target: { checked: false } } as any);

    this.openTaxRateModalWithInitialData(this.state.actionedTableRow, FormMode.Copy);
  }

  private openTaxRateModalWithInitialData(taxRate: ITaxRate | null, mode: FormMode) {
    if (taxRate !== null) {
      const { id, attributes: {
        name,
        description,
        tax_percentage,
        tax_type,
        is_gst
      } } = taxRate as ITaxRate;

      this.setState({
        isTaxRateFormModalOpened: true,
        taxRateFormMode: mode,
        taxRateForm: {
          id,
          name,
          description,
          tax_percentage,
          tax_type,
          is_gst
        }
      });
    }
  }

  private selectedTaxRates(taxRates?: Array<ITaxRate>): Array<ITaxRate> {
    if (taxRates === undefined) {
      taxRates = this.state.taxRates;
    }

    return taxRates.filter((taxRate) => taxRate.selected);
  }


  private isSelectAllCheckboxChecked(taxRates: Array<ITaxRate>): false | "indeterminate" | true {
    const selectedTaxRates = this.selectedTaxRates(taxRates);
    const { length } = selectedTaxRates;

    return length !== 0 && (length === taxRates.length ? true : 'indeterminate');
  }

  //#region Service Calls 
  private getTaxRatesCall(page: number = 1) {
    this.setState({ isLoading: true });
    page = this.state.currentPage;

    this.RequestMessage.GetTaxRates.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.TaxesEndPoint}?page=${page}`
    );

    runEngine.sendMessage(this.RequestMessage.GetTaxRates.id, this.RequestMessage.GetTaxRates);
  }

  private getTaxNamesCall() {
    this.RequestMessage.GetTaxNames.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.TaxesEndPoint}/tax_rate_names`
    );

    runEngine.sendMessage(this.RequestMessage.GetTaxNames.id, this.RequestMessage.GetTaxNames);
  }

  private deleteTaxRateCall(taxRate: ITaxRate | null) {
    if (taxRate !== null) {
      this.RequestMessage.DeleteTaxRate.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.TaxesEndPoint}/${taxRate.id}`
      );

      runEngine.sendMessage(this.RequestMessage.DeleteTaxRate.id, this.RequestMessage.DeleteTaxRate);
    }
  }

  private createTaxRateCall(form: ITaxRateForm) {
    this.RequestMessage.CreateTaxRate.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({ tax: form })
    );

    runEngine.sendMessage(this.RequestMessage.CreateTaxRate.id, this.RequestMessage.CreateTaxRate);
  }

  private editTaxRateCall(form: ITaxRateForm) {
    const { id, ...rest } = form;

    if (id !== undefined) {
      this.RequestMessage.EditTaxRate.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify({ tax: rest })
      );

      this.RequestMessage.EditTaxRate.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.TaxesEndPoint}/${id}`
      );

      runEngine.sendMessage(this.RequestMessage.EditTaxRate.id, this.RequestMessage.EditTaxRate);
    }
  }

  private filterTaxRatesCall(form: IFilterForm) {
    this.RequestMessage.FilterTaxRates.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.TaxesEndPoint}/filter?${this.getFilterQuery(form)}`
    );

    runEngine.sendMessage(this.RequestMessage.FilterTaxRates.id, this.RequestMessage.FilterTaxRates);
  }

  private exportTaxRatesCall(isSample: boolean = false) {
    let url = `${configJSON.APIBaseURL}/${configJSON.TaxesEndPoint}/export`;
    const requestOptions = {
      method: "GET",
      headers: JSON.parse(this.getHeaderMessage()),
    };

    if (isSample) {
      url = `${configJSON.APIBaseURL}/${configJSON.TaxesEndPoint}/tax_csv_sample_file`;
    }

    fetch(url, requestOptions)
      .then((response) => {
        const now = new Date().getTime();
        let fileName = `tax_rates_csv_${now}.csv`;

        if (isSample) {
          fileName = `tax_rates_csv_sample_file.csv`;
        }

        response.blob().then(blob => downloadCSV(blob, fileName))
      })
      .catch((error) => {
        toast.success(error.message);
      });
  }

  private importTaxRatesCall(file: File) {
    const formData = new FormData();

    formData.append("file", file);

    this.RequestMessage.ImportTaxRates.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.TaxesEndPoint}/import`
    );

    this.RequestMessage.ImportTaxRates.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    runEngine.sendMessage(this.RequestMessage.ImportTaxRates.id, this.RequestMessage.ImportTaxRates);
  }

  private getSearchTaxCall(searchText: string = "") {
    if (searchText === "") { searchText = this.state.searchText; }

    this.RequestMessage.SearchTaxRates.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.TaxesEndPoint}/search?name=${searchText}&page=${this.state.currentPage}`
    );

    runEngine.sendMessage(this.RequestMessage.SearchTaxRates.id, this.RequestMessage.SearchTaxRates);
  }

  //#endregion Service Calls 

  private buildRequestMessage(method: Method): Message {
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.TaxesEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.APIBaseURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      this.getHeaderMessage()
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method.toString()
    );

    return requestMessage;
  }

  private getHeaderMessage() {
    const header = {
      "Content-Type": configJSON.APIContentType,
      token: localStorage.getItem("token"),
    };

    return JSON.stringify(header);
  }

  private getFilterQuery(form: IFilterForm) {
    const clone = { ...form };

    if (clone.start_range === clone.end_range) {
      clone.start_range = null as any;
      clone.end_range = null as any;
    }

    return Object.entries(clone)
      .filter(([key, value]) => value !== "" && value !== null && value !== "null")
      .map(([key, value]) => `${key}=${value}`)
      .join("&");
  }
  // Customizable Area End
}
