export const imgLocation = require("../assets/location.png");
export const imgLogo = require("../assets/logo.png");
export const imgic_password_invisible = require("../../global_assets/ic_password_invisible.png");
export const imgic_password_visible = require("../../global_assets/ic_password_visible.png");
export const imgsplash = require("../assets/splash.png");
export const imgsurat = require("../assets/surat.png");
export const imgchennai = require("../assets/chennai.png");
export const imgambarsar = require("../assets/ambarsar.png");
export const imgpunjab = require("../assets/punjab.png");
export const imgnagpur = require("../assets/nagpur.png");
export const imgbengaluru = require("../assets/bengaluru.png");
