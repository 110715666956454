import { IBlock } from "framework/src/IBlock";
import { BlockComponent } from "framework/src/BlockComponent";
import { runEngine } from "framework/src/RunEngine";
export const configJSON = require("./config.js");
import { calenderIcon } from "./assets";
export interface Props {
    open: boolean;
    handleClose: (type: string, action: boolean) => void;
    classes: any;
    concessionTypeList: [];
    handleSubmit: (formData: object) => void;
    handleChange: () => void;
    checkBulkUpdateId:{};
    concessionbulkUpdateerrorMessage: string;
    parentListByAccount :(id: string) => void;

}
interface InitialField {
    fee_concession_type_id: string,
    name: string,
    valid_until: any,
    mode: string,
    amount: any,
  }

interface S {
    closeModal: boolean;
    data: any;
    noteHeader: string;
    disabledbutton: boolean;
    loadingSpinner: boolean;
    concessionTypeError: string,
    bulkUpdateFormstate: InitialField;
    concession_type_id: string,
    concessionModeError: string,
    concessionValidUntilError:string,
    commonErrorMsg: string,
    calenderIcon:any
}

interface SS {
    id: any;
}
const concessionInitialField: InitialField =
{ fee_concession_type_id: "",name: "",mode: "",amount: "",valid_until: "",
};

export default class BulkUpdateConcessionController extends BlockComponent<Props, S, SS> {

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [];

        this.state = {
            closeModal: false,
            concessionValidUntilError:"",
            data: [],
            noteHeader: '',
            calenderIcon:calenderIcon,
            disabledbutton: true,
            loadingSpinner: false,
            bulkUpdateFormstate:concessionInitialField,
            concessionTypeError: '',
            concession_type_id: '',
            concessionModeError: '',
            commonErrorMsg: '',
           
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        super.componentDidMount();
    }

    componentDidUpdate(prevProps: Props) {
        if (prevProps.open !== this.props.open) {
            this.setState({  concessionTypeError: '',concession_type_id: '',
            concessionModeError: '',concessionValidUntilError:'',bulkUpdateFormstate:concessionInitialField});
        }
    }

    handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let value = e.target.value;
        this.setState({ bulkUpdateFormstate: { ...this.state.bulkUpdateFormstate, [e.target.name]: value } });
    };
    handleDateChange=(value:any)=>{
        // let value1= 
          this.setState({
            bulkUpdateFormstate: {
              ...this.state.bulkUpdateFormstate,
              valid_until: value
            }
          });
      
        // console.log(value1  )
      }

    handleValidation: any = (formState: InitialField) => {
        let isValid = true;
        
        // validation
        if(formState.fee_concession_type_id === "" && !formState.valid_until){
        
            this.setState({
                concessionTypeError: (
                    'Atleast one field is required'
                )
            });
            isValid = false;
        } else {
            this.setState({ concessionTypeError: '' });
        }
        
        return isValid;
    };

    handleSubmit = (e: any) => {
        e.preventDefault();
        if (this.handleValidation(this.state.bulkUpdateFormstate)) {
            this.props.handleSubmit({ concessionData: this.props.checkBulkUpdateId,concession: this.state.bulkUpdateFormstate, type: "bulkUpdate" });
        }
    };
}