import React from "react";
// Customizable Area Start
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { withStyles, Theme } from '@material-ui/core/styles';
import { formSubmitBtn,formCancelBtn } from "../../Layout/src/color";
import { Styles } from '@material-ui/styles';
import CommonStepper from '../../../../components/src/CommonStepper.web'

import { defaultBtn } from "../../Layout/src/color";
import CustomStepModalController, {
  Props
} from "./CustomStepModalController";

import {
  StyleSheet,
  Platform
} from "react-native";
import { ThemeContext } from "react-native-elements";


const useStyles: Styles<Theme, {}, string> = (theme: Theme) => ({
  dialogPaper: {
    maxWidth: "65vw",
  },
  modalTitle: {
    "backgroundColor": "#fcf3d4",
    "fontWeight": 600
  },
  headerButton: {
    "borderRadius": "8px",
    "boxShadow": "none",
    "textTransform": "none",
    "fontSize": "16px",
    "padding": "4px 24px",
    "border": "1px solid #ea5c6b",
    "lineHeight": "1.5",
    "background": "rgb(234, 80, 79)",
    "color": "white",
    '&:hover, &:focus': {
      backgroundColor: "#F15B5B",
      borderColor: "#F15B5B",
      color: '#FFFFFF',
    }
  }
});
// Customizable Area End
class CustomStepModal extends CustomStepModalController {
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { 
      open, 
      handleClose, 
      submitLoading, 
      cancelButtonTitle, 
      errormsg, 
      title, 
      children, 
      handleSubmit, 
      stepLabel,
      activeStep,
      isHeaderButton,
      headerButtonTitle, 
      headerButtonIcon,
      handleButtonClick,
      isHideButton,
      viewForm,
      currentForm
     } = this.props;
    // Customizable Area End
    return (
      // Customizable Area Start
      //Merge Engine DefaultContainer
      <>
        <Dialog
          open={open}
          onClose={handleClose}
          fullWidth={true}
          // maxWidth={"65%"}
          classes={{ paper: this.props.classes.dialogPaper }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <CommonStepper stepLabel={stepLabel} activeStep={activeStep} currentForm={currentForm}/>
          {/* <Stepper alternativeLabel nonLinear activeStep={1}>
            {stepLabel && stepLabel.map((label, index) => {
              return (
                <Step>
                  <StepButton>
                  {label}
                  </StepButton>
                </Step>
              );
            })}
          </Stepper> */}
          
          {title &&
            <DialogTitle style={{ backgroundColor: "#fef4d1" }} id="alert-dialog-title">
              <Grid container justify={"space-between"}>
              <Grid item>

              <Typography style={{ fontWeight: 600 }}>{title}</Typography>
              </Grid>
              <Grid item>

              {isHeaderButton && 
                    <Button
                      className={this.props.classes.headerButton}
                      onClick={handleButtonClick}
                      endIcon={headerButtonIcon}
                    >
                      {headerButtonTitle}
                    </Button>
                
              }
              </Grid>
              </Grid>
            </DialogTitle>
          }
          <DialogContent>
            {children}
          </DialogContent>
          {!isHideButton &&
            <DialogActions style={{
              justifyContent: "flex-start",
              marginLeft: "18px",
              marginBottom: "26px",
            }}>
              {
                (viewForm === true && currentForm === 'installmentPlanForm') ?
                <Button style={{
                  boxShadow: 'none',
                  textTransform: 'none',
                  fontSize: 16,
                  padding: '8px 22px',
                  border: '1px solid',
                  lineHeight: 1.5,
                  background: formSubmitBtn,//"linear-gradient(to right, #fc4a1a, #f7b733)",
                  color: "white",
                  marginRight: "20px",
                  borderRadius: "8px",
                }}
                  onClick={handleClose}>
                  Back To Listing Page
                </Button>
                :
                !this.props.hideCancelButton &&
                <Button style={this.props.cancelButtonTitle ? {
                  boxShadow: 'none',
                  textTransform: 'none',
                  fontSize: 16,
                  padding: '8px 22px',
                  border: '1px solid',
                  lineHeight: 1.5,
                  background: formSubmitBtn,//"linear-gradient(to right, #fc4a1a, #f7b733)",
                  color: "white",
                  marginRight: "20px",
                  borderRadius: "8px",
                } :
                  {
                    boxShadow: 'none',
                    textTransform: 'none',
                    fontSize: 16,
                    padding: '8px 40px',
                    border: '1px solid',
                    lineHeight: 1.5,
                    backgroundColor: handleSubmit ? formCancelBtn : '#ea586b',
                    borderColor: '#ea5c6b',
                    color: handleSubmit ? "#44556c" : 'white',
                    marginRight: "14px",
                    borderRadius: "8px",
                  }}
                  onClick={handleClose}>
                  {this.props.cancelButtonTitle ? this.props.cancelButtonTitle : 'Cancel'}
                </Button>
              }
              {handleSubmit && !this.props.isError  && !(viewForm === true && currentForm === 'installmentPlanForm') &&
                <Button
                  disabled={submitLoading ? true : false}
                  type="submit"
                  onClick={handleSubmit}
                  style={{
                    borderRadius: "8px",
                    boxShadow: 'none',
                    textTransform: 'none',
                    fontSize: 16,
                    padding: '8px 40px',
                    border: '1px solid',
                    lineHeight: 1.5,
                    background: formSubmitBtn,
                    color: "white",
                  }}>
                  {this.props.buttonTitle}
                </Button>
              }
              
            </DialogActions>
          }

        </Dialog>

      </>
      //Merge Engine End DefaultContainer
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 16,
    marginLeft: "auto",
    marginRight: "auto",
    width: Platform.OS === "web" ? "75%" : "100%",
    maxWidth: 650,
    backgroundColor: "#ffffffff"
  }
});

// Customizable Area End

export default withStyles(useStyles)(CustomStepModal);