import React from "react";
import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";

// Customizable Area Start
import { IActionMenuItem } from "../../CommonLayout/HRMSCommons/src/actionMenu";
import { downloadCSV } from "framework/src/Utilities";
import { toast } from "react-toastify";
import { IPaymentTerms, ISourceOfSupply, ITDS, IGSTTreatment, ITDSSection } from "./VendorFormController";

// Customizable Area End

import { FormMode, Props as IVendorFormProps, IVendorForm } from "./VendorFormController";
import { debounce } from "lodash";
export const configJSON = require("./config");

// Customizable Area Start
enum Method {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
  PATCH = 'PATCH',
}

interface IVendor {
  id: number;
  type: string;
  selected: boolean;
  attributes: {
    salutation: string;
    first_name: string;
    last_name: string;
    company_name: string;
    vendor_email: string;
    phone_no_mob: string;
    phone_no_work: string;
    phone_no_work_code: string;
    phone_no_mob_code: string;
    website_url: string;
    currency: string;
    state_source_of_supply: string;
    billing_address: string;
    shipping_address: string;
  };
}

interface IVendorName {
  id: number,
  type: string,
  attributes: {
    name: string;
  }
}

interface IPageMeta {
  message: string;
  total_pages: number;
}

interface IFilterForm {
  first_name: string;
  last_name: string;
  vendor_email: string;
  phone_no_mob: string;
  gst_treatment_id: string;
}
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  anchorEl: HTMLElement | null;
  actionedTableRow: IVendor | null;
  actionMenuItems: Array<IActionMenuItem>;
  vendors: Array<IVendor>;
  pageMeta: IPageMeta;
  selectAllCheckboxChecked: false | 'indeterminate' | true;
  isLoading: boolean;
  deleteModalOpen: boolean;
  filterForm: IFilterForm;
  isVendorFormModalOpened: boolean;
  vendorForm: IVendorForm | null;
  vendorFormMode: FormMode;
  currentPage: number;
  closeImportModal: boolean,
  searchText: string,
  currency: Array<[string, string]>;
  paymentTerms: Array<IPaymentTerms>;
  sourceOfSupply: ISourceOfSupply;
  tds: Array<ITDS>;
  gstTreatment: Array<IGSTTreatment>;
  tdSection: Array<ITDSSection>;
  deleteMessage: "single" | "multiple"
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class VendorsController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  public tableActionMenuItems: Array<IActionMenuItem> = [
    { label: "Edit", action: this.editVendor.bind(this) },
    { label: "Delete", action: this.deleteVendor.bind(this) },
    { label: "View", action: this.viewVendor.bind(this) },
    { label: "Copy", action: this.copyVendor.bind(this) }
  ];

  public RequestMessage = {
    GetVendors: this.buildRequestMessage(Method.GET),
    GetSourceOfSupply: this.buildRequestMessage(Method.GET),
    GetCurrency: this.buildRequestMessage(Method.GET),
    GetPaymentTerms: this.buildRequestMessage(Method.GET),
    GetTDS: this.buildRequestMessage(Method.GET),
    GetTDSSection: this.buildRequestMessage(Method.GET),
    GetGSTTreatment: this.buildRequestMessage(Method.GET),
    FilterVendors: this.buildRequestMessage(Method.GET),
    DeleteVendor: this.buildRequestMessage(Method.DELETE),
    CreateVendor: this.buildRequestMessage(Method.POST),
    EditVendor: this.buildRequestMessage(Method.PUT),
    ImportVendors: this.buildRequestMessage(Method.POST),
    ExportVendors: this.buildRequestMessage(Method.GET),
    SearchVendors: this.buildRequestMessage(Method.GET),
    GetVendorNames: this.buildRequestMessage(Method.GET),
    CreatePaymentTerm: this.buildRequestMessage(Method.POST),
    UpdatePaymentTerm: this.buildRequestMessage(Method.PUT),
    CreateTDS: this.buildRequestMessage(Method.POST),
    CreateSection: this.buildRequestMessage(Method.POST),
    Null: undefined as any
  }

  public vendorFormProps: { [key: number]: IVendorFormProps } = {
    [FormMode.Create]: {
      title: "Create Vendor Rate",
      submitLabel: "Create",
      initialValues: null,
      formMode: FormMode.Create,
      isOpen: false,
      onClose: this.onCloseVendorFormModal.bind(this),
      onSubmit: this.onSubmitCreateVendorModal.bind(this),
      requestMessage: this.RequestMessage.CreateVendor
    } as IVendorFormProps,
    [FormMode.Edit]: {
      title: "Edit Vendor Rate",
      submitLabel: "Update",
      initialValues: null,
      formMode: FormMode.Edit,
      isOpen: false,
      onClose: this.onCloseVendorFormModal.bind(this),
      onSubmit: this.onSubmitEditVendorModal.bind(this),
      requestMessage: this.RequestMessage.EditVendor
    } as IVendorFormProps,
    [FormMode.View]: {
      title: "View Vendor Rate",
      submitLabel: "Back to Listing",
      initialValues: null,
      formMode: FormMode.View,
      isOpen: false,
      onClose: this.onCloseVendorFormModal.bind(this),
      onSubmit: this.onCloseVendorFormModal.bind(this),
      requestMessage: this.RequestMessage.Null
    } as any as IVendorFormProps,
    [FormMode.Copy]: {
      title: "Copy Vendor Rate",
      submitLabel: "Copy",
      initialValues: null,
      formMode: FormMode.Copy,
      isOpen: false,
      onClose: this.onCloseVendorFormModal.bind(this),
      onSubmit: this.onSubmitCreateVendorModal.bind(this),
      requestMessage: this.RequestMessage.CreateVendor
    } as IVendorFormProps,
  }

  public printReferance: any = React.createRef();
  public tableRef: any = React.createRef();;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      anchorEl: null,
      actionedTableRow: null,
      vendors: [],
      actionMenuItems: [],
      pageMeta: {
        message: "",
        total_pages: 0
      },
      selectAllCheckboxChecked: false,
      isLoading: true,
      deleteModalOpen: false,
      filterForm: {
        first_name: '',
        last_name: '',
        phone_no_mob: '',
        vendor_email: '',
        gst_treatment_id: '',
      },
      isVendorFormModalOpened: false,
      vendorForm: null,
      vendorFormMode: FormMode.Create,
      currentPage: 1,
      closeImportModal: false,
      searchText: '',
      sourceOfSupply: {},
      currency: [],
      paymentTerms: [],
      tds: [],
      gstTreatment: [],
      tdSection: [],
      deleteMessage: 'single',
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start

    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const callID = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const response = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      switch (callID) {
        case this.RequestMessage.GetVendors.messageId:
        case this.RequestMessage.FilterVendors.messageId:
          if (response !== null) {
            this.setState({
              vendors: response?.data || [],
              pageMeta: response?.meta || {},
              isLoading: false
            });
          }
          break;

        case this.RequestMessage.GetSourceOfSupply.messageId:
          if (response !== null) {
            this.setState({
              sourceOfSupply: response?.data || []
            });
          }
          break;

        case this.RequestMessage.GetCurrency.messageId:
          if (response !== null) {
            this.setState({
              currency: response?.data || []
            });
          }
          break;

        case this.RequestMessage.GetPaymentTerms.messageId:
          if (response !== null) {
            this.setState({
              paymentTerms: response?.data || []
            });
          }
          break;

        case this.RequestMessage.GetTDS.messageId:
          if (response !== null) {
            this.setState({
              tds: response?.data || []
            });
          }
          break;

        case this.RequestMessage.GetGSTTreatment.messageId:
          if (response !== null) {
            this.setState({
              gstTreatment: response?.data || []
            });
          }
          break;

        case this.RequestMessage.GetTDSSection.messageId:
          if (response !== null) {
            this.setState({
              tdSection: response?.data || []
            });
          }
          break;

        case this.RequestMessage.DeleteVendor.messageId:
          if (response !== null) {
            this.setState({
              deleteModalOpen: false,
              actionedTableRow: null
            });

            this.getVendorsCall();
          }
          break;

        case this.RequestMessage.CreateVendor.messageId:
          if (response !== null && response.errors === undefined) {
            this.setState({ isVendorFormModalOpened: false })
            this.getVendorsCall();
          }

          break;

        case this.RequestMessage.EditVendor.messageId:
          if (response !== null) {
            this.setState({ isVendorFormModalOpened: false })
            this.getVendorsCall();
          }

          break;

        case this.RequestMessage.ImportVendors.messageId:
          if (response !== null) {
            this.setState({ closeImportModal: true }, () => {
              this.setState({ closeImportModal: false });
            });
            this.getVendorsCall();
          }

          break;

        case this.RequestMessage.SearchVendors.messageId:
          if (response !== null) {
            this.setState({
              vendors: response?.data || [],
              pageMeta: response?.meta || {},
              isLoading: false
            });
          }
          break;

        case this.RequestMessage.CreatePaymentTerm.messageId:
          if (response !== null) {
            this.getPaymentTermsCall();
          }
          break;

        case this.RequestMessage.UpdatePaymentTerm.messageId:
          if (response !== null) {
            this.getPaymentTermsCall();
          }
          break;

        case this.RequestMessage.CreateSection.messageId:
          if (response !== null) {
            this.getTDSSectionCall();
          }
          break;

        case this.RequestMessage.CreateTDS.messageId:
          if (response !== null) {
            this.getTDSCall();
          }
          break;
      }
    }
    // Customizable Area End 
  }

  // Customizable Area Start
  public async componentDidMount() {
    super.componentDidMount();

    this.getVendorsCall();
    this.getVendorNamesCall();
    this.getSourceOfSupplyCall();
    this.getCurrencyCall();
    this.getPaymentTermsCall();
    this.getTDSCall();
    this.getGSTTreatmentCall();
    this.getTDSSectionCall();
  }

  public onClickCreateVendor() {
    this.setState({
      isVendorFormModalOpened: true,
      vendorFormMode: FormMode.Create
    });
  }

  public onSelectAllVendors(event: React.ChangeEvent<HTMLInputElement>) {
    const { vendors } = this.state;
    const { checked } = event.target;

    const updatedVendors = vendors.map((vendor) => {
      return {
        ...vendor,
        selected: checked
      }
    });

    this.setState({
      vendors: updatedVendors,
      selectAllCheckboxChecked: this.isSelectAllCheckboxChecked(updatedVendors)
    });
  }

  public onSelectVendor(event: React.ChangeEvent<HTMLInputElement>, row: IVendor) {
    const { vendors } = this.state;
    const { checked } = event.target;

    const updatedVendors = vendors.map((vendor) => {
      return {
        ...vendor,
        selected: row.id === vendor.id ? checked : vendor.selected
      }
    });

    this.setState({
      vendors: updatedVendors,
      selectAllCheckboxChecked: this.isSelectAllCheckboxChecked(updatedVendors)
    });
  }

  public onClickActionButton(event: any, item: IVendor) {
    this.setState({
      anchorEl: event.currentTarget,
      actionedTableRow: item,
      actionMenuItems: this.tableActionMenuItems
    });
  }

  public onCloseActionMenu() {
    this.setState({
      anchorEl: null,
      actionMenuItems: []
    });
  }

  onClickPagination = (page: any) => {
    if (this.tableRef.current !== null) {
      this.tableRef.current.scrollIntoView();
    }

    this.setState({ currentPage: page.selected + 1 }, () => {
      if (this.state.searchText !== '') {

        this.getSearchVendorCall();
      } else {
        this.getVendorsCall();
      }
    });
  }

  public onCloseDeleteModal() {
    this.setState({
      deleteModalOpen: false,
      actionedTableRow: null
    });
  }

  public onSubmitDeleteModal() {
    this.deleteVendorCall(this.state.actionedTableRow);
  }

  public onCloseVendorFormModal() {
    this.setState({ isVendorFormModalOpened: false });
  }

  public onSubmitCreateVendorModal(form: IVendorForm) {
    this.createVendorCall(form);
  }

  public onSubmitEditVendorModal(form: IVendorForm) {
    this.editVendorCall(form);
  }

  public onUploadCSVForImport(file: File) {
    this.importVendorsCall(file);
  }

  public onClickExportCSVButton(isSample: boolean) {
    this.exportVendorsCall(isSample);
  }

  public onCreatePaymentTerms(paymentTerms: Array<IPaymentTerms>) {
    const createPaymentTerms = paymentTerms.filter((paymentTerm) => paymentTerm.id === undefined);
    const updatePaymentTerms = paymentTerms.filter((paymentTerm) => paymentTerm.id !== undefined);

    if (createPaymentTerms.length > 0) {
      this.createPaymentTermCall(createPaymentTerms);
    }

    if (updatePaymentTerms.length > 0) {
      this.updatePaymentTermCall(updatePaymentTerms);
    }
  }

  public onCreateTDS(tds: any) {
    this.createTDSCall(tds);
  }

  public onCreateSection(section: any) {
    this.createSectionCall(section);
  }

  //#region Filter Methods
  public onFilterFormChange({ target: { name, value } }: any) {
    const state: Record<string, any> = {};
    state.filterForm = {
      ...this.state.filterForm,
      [name]: value
    };

    this.setState(state as any);
  }

  public onFilterFormSubmit() {
    this.filterVendorsCall(this.state.filterForm);
  }

  public onChangeSearchInput({ target: { value } }: any) {
    const state: Record<string, any> = {};
    state.searchText = value;

    const debounced = debounce((value) => {
      this.setState({ searchText: value });

      if (value !== "") {
        state.currentPage = 1;

        this.getSearchVendorCall(value);
      } else {
        this.getVendorsCall();
      }
    }, 100);

    this.setState(state as any);

    debounced(value);
  }

  //#endregion Filter Methods

  private editVendor() {
    this.openVendorModalWithInitialData(this.state.actionedTableRow, FormMode.Edit);
  }

  private viewVendor() {
    this.openVendorModalWithInitialData(this.state.actionedTableRow, FormMode.View);
  }

  private deleteVendor() {
    const { vendors } = this.state;

    this.setState({
      deleteMessage: vendors.filter(el => el.selected).length > 1 ? "multiple" : "single",
      deleteModalOpen: true
    });
  }

  private copyVendor() {
    this.openVendorModalWithInitialData(this.state.actionedTableRow, FormMode.Copy);
  }

  private openVendorModalWithInitialData(vendor: IVendor | null, mode: FormMode) {
    if (vendor !== null) {
      const { id, attributes } = vendor as IVendor;
      const { phone_no_mob, phone_no_mob_code, phone_no_work, phone_no_work_code } = attributes;

      this.setState({
        isVendorFormModalOpened: true,
        vendorFormMode: mode,
        vendorForm: {
          ...attributes as any,
          phone_no_mob: phone_no_mob.replace(phone_no_mob_code, ''),
          phone_no_work: phone_no_work.replace(phone_no_work_code, ''),
        }
      });
    }
  }

  private selectedVendors(vendors?: Array<IVendor>): Array<IVendor> {
    if (vendors === undefined) {
      vendors = this.state.vendors;
    }

    return vendors.filter((vendor) => vendor.selected);
  }


  private isSelectAllCheckboxChecked(vendors: Array<IVendor>): false | "indeterminate" | true {
    const selectedVendors = this.selectedVendors(vendors);
    const { length } = selectedVendors;

    return length !== 0 && (length === vendors.length ? true : 'indeterminate');
  }

  //#region Service Calls 
  private getVendorsCall(page: number = 1) {
    this.setState({ isLoading: true });
    page = this.state.currentPage;

    this.RequestMessage.GetVendors.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.VendoresEndPoint}?page=${page}`
    );

    runEngine.sendMessage(this.RequestMessage.GetVendors.id, this.RequestMessage.GetVendors);
  }

  private getVendorNamesCall() {
    this.RequestMessage.GetVendorNames.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.VendoresEndPoint}/vendor_name_list`
    );

    runEngine.sendMessage(this.RequestMessage.GetVendorNames.id, this.RequestMessage.GetVendorNames);
  }

  private getSourceOfSupplyCall() {
    this.RequestMessage.GetSourceOfSupply.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.ExpenseTracking}/get_indian_states`
    );

    runEngine.sendMessage(this.RequestMessage.GetSourceOfSupply.id, this.RequestMessage.GetSourceOfSupply);
  }

  private getCurrencyCall() {
    this.RequestMessage.GetCurrency.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.ExpenseTracking}/get_currency_list`
    );

    runEngine.sendMessage(this.RequestMessage.GetCurrency.id, this.RequestMessage.GetCurrency);
  }

  private getPaymentTermsCall() {
    this.RequestMessage.GetPaymentTerms.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.CustomeresEndPoint}/business_customers/payment_terms`
    );

    runEngine.sendMessage(this.RequestMessage.GetPaymentTerms.id, this.RequestMessage.GetPaymentTerms);
  }

  private getTDSCall() {
    this.RequestMessage.GetTDS.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.VendoresEndPoint}/tds_list`
    );

    runEngine.sendMessage(this.RequestMessage.GetTDS.id, this.RequestMessage.GetTDS);
  }

  private getGSTTreatmentCall() {
    this.RequestMessage.GetGSTTreatment.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.GSTTreatment}/gst_treatments`
    );

    runEngine.sendMessage(this.RequestMessage.GetGSTTreatment.id, this.RequestMessage.GetGSTTreatment);
  }

  private getTDSSectionCall() {
    this.RequestMessage.GetTDSSection.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.VendoresEndPoint}/get_all_section`
    );

    runEngine.sendMessage(this.RequestMessage.GetTDSSection.id, this.RequestMessage.GetTDSSection);
  }

  private deleteVendorCall(vendor: IVendor | null) {
    if (vendor !== null) {
      const selectedFees = this.state.vendors.filter(el => el.selected);
      if (selectedFees.length === 1) {
        this.RequestMessage.DeleteVendor.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `${configJSON.VendoresEndPoint}/${selectedFees[0].id}`
        );
      }
      else if (selectedFees.length > 1) {
        const ids = selectedFees.reduce((p, c) => p + (p ? "," : "") + c.id, "");
        this.RequestMessage.DeleteVendor.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `${configJSON.VendoresEndPoint}/bulk_destroy?ids=${ids}`
        );
      }
      else return;

      runEngine.sendMessage(this.RequestMessage.DeleteVendor.id, this.RequestMessage.DeleteVendor);
    }
  }

  private createVendorCall(form: IVendorForm) {
    const newForm = { ...form };
    const { phone_no_mob_code, phone_no_mob, phone_no_work_code, phone_no_work, ...rest } = newForm;

    newForm.phone_no_mob = `${phone_no_mob_code}${phone_no_mob}`.replace('+', '');
    newForm.phone_no_work = `${phone_no_work_code}${phone_no_work}`.replace('+', '');

    this.RequestMessage.CreateVendor.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({ data: newForm })
    );

    runEngine.sendMessage(this.RequestMessage.CreateVendor.id, this.RequestMessage.CreateVendor);
  }

  private editVendorCall(form: IVendorForm) {
    const { id } = this.state.actionedTableRow as any;
    const newForm = { ...form };
    const { phone_no_mob_code, phone_no_mob, phone_no_work_code, phone_no_work, ...rest } = newForm;

    newForm.phone_no_mob = `${phone_no_mob_code}${phone_no_mob}`.replace('+', '');
    newForm.phone_no_work = `${phone_no_work_code}${phone_no_work}`.replace('+', '');

    this.RequestMessage.EditVendor.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({ data: newForm })
    );

    this.RequestMessage.EditVendor.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.VendoresEndPoint}/${id}`
    );

    runEngine.sendMessage(this.RequestMessage.EditVendor.id, this.RequestMessage.EditVendor);
  }

  private filterVendorsCall(form: IFilterForm) {
    this.RequestMessage.FilterVendors.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.VendoresEndPoint}/filter?${this.getFilterQuery(form)}`
    );

    runEngine.sendMessage(this.RequestMessage.FilterVendors.id, this.RequestMessage.FilterVendors);
  }

  private exportVendorsCall(isSample: boolean = false) {
    let url = `${configJSON.APIBaseURL}/${configJSON.VendoresEndPoint}/export`;
    const requestOptions = {
      method: "GET",
      headers: JSON.parse(this.getHeaderMessage()),
    };

    if (isSample) {
      url = `${configJSON.APIBaseURL}/${configJSON.VendoresEndPoint}/tax_csv_sample_file`;
    }

    fetch(url, requestOptions)
      .then((response) => {
        const now = new Date().getTime();
        let fileName = `vendors_csv_${now}.csv`;

        if (isSample) {
          fileName = `vendors_csv_sample_file.csv`;
        }

        response.blob().then(blob => downloadCSV(blob, fileName))
      })
      .catch((error) => {
        toast.success(error.message);
      });
  }

  private importVendorsCall(file: File) {
    const formData = new FormData();

    formData.append("file", file);

    this.RequestMessage.ImportVendors.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.VendoresEndPoint}/import`
    );

    this.RequestMessage.ImportVendors.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    runEngine.sendMessage(this.RequestMessage.ImportVendors.id, this.RequestMessage.ImportVendors);
  }

  private getSearchVendorCall(searchText: string = "") {
    if (searchText !== "") { searchText = this.state.searchText; }

    this.RequestMessage.SearchVendors.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.VendoresEndPoint}/search?search=${searchText}&page=${this.state.currentPage}`
    );

    runEngine.sendMessage(this.RequestMessage.SearchVendors.id, this.RequestMessage.SearchVendors);
  }

  private createPaymentTermCall(paymentTerms: Array<IPaymentTerms>) {
    this.RequestMessage.CreatePaymentTerm.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.VendoresEndPoint}/create_payment_term`
    );

    this.RequestMessage.CreatePaymentTerm.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({ data: paymentTerms })
    );

    runEngine.sendMessage(this.RequestMessage.CreatePaymentTerm.id, this.RequestMessage.CreatePaymentTerm);
  }

  private updatePaymentTermCall(paymentTerms: Array<IPaymentTerms>) {
    this.RequestMessage.UpdatePaymentTerm.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.CustomeresEndPoint}/business_customers/update_payment_term`
    );

    this.RequestMessage.UpdatePaymentTerm.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({ data: { attributes: { payment_terms: paymentTerms } } })
    );

    runEngine.sendMessage(this.RequestMessage.UpdatePaymentTerm.id, this.RequestMessage.UpdatePaymentTerm);
  }

  private createTDSCall(tds: any) {
    this.RequestMessage.CreateTDS.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.VendoresEndPoint}/create_tds`
    );

    this.RequestMessage.CreateTDS.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({ data: { ...tds, status: true } })
    );

    runEngine.sendMessage(this.RequestMessage.CreateTDS.id, this.RequestMessage.CreateTDS);
  }

  private createSectionCall(section: any) {
    this.RequestMessage.CreateSection.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.VendoresEndPoint}/section_creation?name=${section}`
    );

    runEngine.sendMessage(this.RequestMessage.CreateSection.id, this.RequestMessage.CreateSection);
  }

  //#endregion Service Calls 

  private buildRequestMessage(method: Method): Message {
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.VendoresEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.APIBaseURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      this.getHeaderMessage()
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method.toString()
    );

    return requestMessage;
  }

  private getHeaderMessage() {
    const header = {
      "Content-Type": configJSON.APIContentType,
      token: localStorage.getItem("token"),
    };

    return JSON.stringify(header);
  }

  private getFilterQuery(form: IFilterForm) {
    const clone = { ...form };

    return Object.entries(clone)
      .filter(([key, value]) => value !== "" && value !== null && value !== "null")
      .map(([key, value]) => `${key}=${value}`)
      .join("&");
  }
  // Customizable Area End
}
