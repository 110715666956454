import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { deleteIcon, concessionTypeEditIcon, dropdownIcon } from "./assets";
import { toast } from "react-toastify";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  classes: any;
  open: boolean;
  handleClose: any;
  handleSubmit:(formData: object) => void;
  handleEditConcessionTypeSubmit:(formData: object) => void;
  handleDeleteConcessionTypeSubmit:() => void;
  handleDownloadCSV?: any;
  importerrorMessage: string;
  ConcessionTypeList: any;
  selectedConcessionTypeItem:any;
  handleConcessionTypeEditmodal:any;
  handleConcessionTypeEditmodalClose:any;
  handleConcessionTypeDeletemodalClose:any
  handleConcessionTypeDeletemodal:any
  handleConcessionTypemodalOpen:any
  handleConcessionTypemodal:any
  handleConcessionTypemodalClose:any
  handleConcessionTypeEditmodalOpen:any
  handleConcessionTypeDeletemodalOpen:any
  concessionTypeCreateerrorMessage:string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  concessionTypeForm: InitialField;
  concessionType: any;
  concessionDescription: string;
  concessionTypeError: any;
  concessionDescriptionError: any;
  showList: boolean;
  metaMsg:any;
  deleteIcon: any;
  concessionTypeEditIcon: any;
  dropdownIcon: any;
  handalConcessionTypeEditmodal: boolean;
  selectedConcessionTypeItem:any;
  deleteModalOpen:boolean;
  //   downloadIcon: any;
  // Customizable Area End
}
interface concessionTypeData {
  id: number;
  attributes:concessionTypeAttribute
}
interface concessionTypeAttribute {
  id:any;
  name: string;
  description:string;
}
interface InitialField {
  fee_concession_type: string;
  description: string;
}
interface SS {
  id: any;
}
const concessionInitialField: InitialField = {
  fee_concession_type: "",
  description: ""
};

export default class CreateConcessionTypeController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [getName(MessageEnum.AccoutLoginSuccess)];

    this.state = {
      txtInputValue: "",
      showList: false,
      txtSavedValue: "A",
      selectedConcessionTypeItem:'',
      enableField: false,
      concessionType: "",
      concessionTypeError: "",
      metaMsg:'',
      deleteModalOpen:false,
      concessionDescriptionError: "",
      concessionDescription: "",
      concessionTypeForm: concessionInitialField,
      deleteIcon: deleteIcon,
      concessionTypeEditIcon: concessionTypeEditIcon,
      dropdownIcon: dropdownIcon,
      handalConcessionTypeEditmodal: false
      //   downloadIcon: downloadIcon,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start

  handleChange = (e: any) => {
    let value = e.target.value;

    this.setState({
      concessionTypeForm: {
        ...this.state.concessionTypeForm,
        [e.target.name]: value
      }
    });
  };
  // handleAnchorClick = (event: any, row: concessionTypeData) => {
  //   this.setState({selectedConcessionTypeItem: row });
  //   console.log("event.currentTarget",row)
  // };

  // handleAnchorClose = () => {
  //   this.setState({ anchorEl: null });
  // };
  handleListShow = () => {
    this.setState({ showList: !this.state.showList });
  };
  async componentDidMount() {
    super.componentDidMount();
  }
 
  componentDidUpdate(prevProps: Props) {
    if (prevProps.open !== this.props.open) {
      this.resetFormState();
    }
  }
 

  resetFormState() {
    this.setState({
      concessionTypeForm: concessionInitialField,
      concessionTypeError:''
    });
   
  }
  // handalConcessionTypeEditmodal = (row: concessionTypeData) => {
  //   this.setState({ handalConcessionTypeEditmodal: true ,selectedConcessionTypeItem:row});
  //   console.log("event.currentTarget",row)
  // };

  // handleConcessionTypeEditmodalClose = () => {
  //   this.setState({ handalConcessionTypeEditmodal: false });
  // };
  // handleConcessionTypeDeletemodalClose = () => {
  //   this.setState({ deleteModalOpen: false });
  // };
  // handalConcessionTypeDeletemodal = (row: concessionTypeData) => {
  //   this.setState({ deleteModalOpen: true ,selectedConcessionTypeItem:row});
  //   console.log("event.currentTarget",row)
  // };
  handleValidation = (formState: InitialField) => {
    let isValid = true;
    // const regex = /^[a-zA-Z0-9_]{2,40}$/i;
    if (formState.fee_concession_type === "") {
      this.setState({ concessionTypeError: "Concession type is required" });
      isValid = false;
    } else {
      this.setState({ concessionTypeError: "" });
    }

    return isValid;
  };
  // handleEditSubmit=(FormData:any)=>{
  //   var raw = JSON.stringify({
  //     "name": FormData.concessionTypeData.fee_concession_type,
  //     "description": FormData.concessionTypeData.name
  //   });
  //   console.log("FormData",FormData)
  //   var requestOptions = {
  //     method: 'PUT',
  //     headers: {
  //       "Content-Type":"application/json",
  //       token: "eyJhbGciOiJIUzUxMiJ9.eyJpZCI6OCwiZXhwIjoxNjY3NTYyNTQ0fQ.4F8uPAZH54d5h_TWe5zM9u1JQ2OtR9ZPkUo_4XUtm6Afb7OYc4SUN4JoFNArr066I8XwCit6pTMXAjO0DS1l5Q"
  //     },
  //     body: raw,
  //   };
    
  //     fetch("https://hrms-finance.b91002.dev.us-east-1.aws.svc.builder.cafe/bx_block_fee/fee_concession_types/2", requestOptions)
  //     .then(response => response.json())
  //     .then((result) => {
  //       // toast.success(result.meta.message);
  //       this.props.ConcessionTypeList
  //       this.setState({metaMsg:result.meta.message})
  //       console.log("result",result)
  //     this.handleConcessionTypeEditmodalClose()
  //     })
  //     .catch(error => console.log('error', error));
  // }
  handleSubmit = (e: any) => {
    e.preventDefault();
    if (this.handleValidation(this.state.concessionTypeForm)) {
      this.props.handleSubmit({
        concessionData: this.state.concessionTypeForm
      });
    }
  };
  // Customizable Area End
}
