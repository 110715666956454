Object.defineProperty(exports, "__esModule", {
  value: true
});

const urlConfig = require("../../../framework/src/config");

// Customizable Area Start
exports.APIBaseURL = urlConfig.baseURL;
exports.APIContentType = "application/json";
exports.GET = "GET";
exports.POST = "POST";
exports.PUT = "PUT";
exports.DELETE = "DELETE";
exports.PATCH = "PATCH";
exports.EndPoint = "bx_block_expensetracking/expense";
exports.TaxEndPoint = "bx_block_tax/taxes";
exports.SourceEndPoint = "bx_block_expensetracking/recurring_expense/get_indian_states";
exports.GstTreatmentsEndPoint = "bx_block_expensetracking/expense/gst_treatment_list";
exports.CurrencyEndPoint = "bx_block_expensetracking/recurring_expense/get_currency_list";
exports.VendorsEndPoint = "vendors";
exports.userManagementURL = urlConfig.baseURL;
