import React from "react";
// Customizable Area Start
import { withStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Styles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import TextField from '@material-ui/core/TextField';
import OutlinedSelect from "../../../components/src/OutlinedSelect";
import { capitalizeFirst } from "../../../framework/src/Utilities";
import CustomModal from "../../CommonLayout/CustomModal/src/CustomModal.web";
// Customizable Area End

import UserFormController, { FormMode, Props } from "./UserFormController";

interface roleAttribute {
  id: number;
  name: string;
  status: string;
  created_by: string;
  custom_id: string;
  created: Date;
}
interface roleData {
  id: string;
  attributes: roleAttribute
}
// Customizable Area Start
const useStyles: Styles<Theme, {}, string> = (theme: Theme) => ({
  formLabel: {
    marginBottom: "8px",
    fontWeight: 600,
  },
  inputGrid: {
    marginBottom: "20px",
    paddingRight: "10px",

    "&:nth-child(even)": {
      paddingLeft: "10px",
    },
  },
  inputBorder: {
    border: '1px solid rgba(0, 0, 0, 0.23)',
    borderRadius: 4
  },
 
     
  
});
// Customizable Area End

class UserForm extends UserFormController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
//  async componentDidMount(): Promise<void> {
//    if(this.props.formMode === FormMode.Create)
//    {
//     this.setState({form})
//    }
//  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes, isOpen, formMode, title, submitLabel,errormsg,roleList, } = this.props;
    const { form } = this.state;

    return (
      <CustomModal
        title={title}
        handleSubmit={this.handleSubmit.bind(this)}
        buttonTitle={submitLabel}
        open={isOpen}
        handleClose={this.handleFormClose.bind(this)}
        submitLoading={this.state.isSubmitting}
        hideCancelButton={formMode === FormMode.View}
        // errormsg={!errormsg?.email && !errormsg?.full_phone_number  && errormsg ? errormsg : ''}

      >
        <Grid container direction="column">
          <Grid container direction="row" style={{ marginTop: 20 }}>

          {
            formMode === FormMode.View ? (
              <>
              <Grid style={{ color: "#465872", fontWeight: "bold", marginBottom: "36px" }} xs={6} item>User ID:
                <Typography style={{color: "#7e7d7d", marginTop: "16px"}}>{form.id}</Typography>
              </Grid>
              <Grid style={{ color: "#465872", fontWeight: "bold", marginBottom: "36px" }} xs={6} item>User Name*:
                <Typography style={{color: "#7e7d7d", marginTop: "16px"}}>{capitalizeFirst(form.first_name)}</Typography>
              </Grid>
              <Grid style={{ color: "#465872", fontWeight: "bold", marginBottom: "36px" }} xs={6} item>Email*:
                <Typography style={{color: "#7e7d7d", marginTop: "16px"}}>{form.email}</Typography>
              </Grid>
              <Grid style={{ color: "#465872", fontWeight: "bold", marginBottom: "36px" }} xs={6} item>Mobile:
              <Typography style={{color: "#7e7d7d", marginTop: "16px"}}>{'+'+form.country_code+' '+form.phone_number}</Typography>
              </Grid>
              <Grid style={{ color: "#465872", fontWeight: "bold", marginBottom: "36px" }} xs={6} item>Role:
                  <Typography style={{color: "#7e7d7d", marginTop: "16px"}}>{capitalizeFirst(form.role)}</Typography>
              </Grid>
              <Grid style={{ color: "#465872", fontWeight: "bold", marginBottom: "36px" }} xs={6} item>Status:
                  <Typography style={{color: "#7e7d7d", marginTop: "16px"}}>{form.activated ? 'Active' : 'Inactive' }</Typography>
              </Grid>
            </>
            ) 
            :
            <>
            <Grid item xs={12} sm={6} className={classes.inputGrid}>
              <FormControl style={{ width: '100%' }}>
                <FormLabel className={classes.formLabel}>
                  User ID
                </FormLabel>
                <TextField
                  value={formMode=== FormMode.Create ? null :form.id}
                  style={
                     { color: "black",borderRadius:"5px",backgroundColor:"rgb(234 234 234)"}
                  }
                  className={classes.textField}
                  color={'primary'}
                  variant="outlined"
                  type={'number'}
                  name={'id'}
                  placeholder={ formMode === FormMode.Create ? "User ID will be auto generated" :'000'}
                  // disabled={true}
                  disabled             
                   />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} className={classes.inputGrid}>
              <FormControl style={{ width: '100%' }}>
                <FormLabel className={classes.formLabel}>
                  Name *
                </FormLabel>
                <TextField
                className={this.props.editUserNameError ||this.state.createUserNameError ?"errorInput":"withoutError" }
                  value={form.first_name}
                  color={'primary'}
                  variant="outlined"
                  type={'text'}
                  name={'first_name'}
                  placeholder={'Enter Name'}
                  onChange={this.onChangeFirstName.bind(this)}
                />
              </FormControl>
              {this.props.editUserNameError && <p className="errorMsg2">{this.props.editUserNameError}</p>}
            {this.state.createUserNameError? <p className="errorMsg2">{this.state.createUserNameError}</p> :null}
            </Grid>
            
            <Grid item xs={12} sm={6} className={classes.inputGrid}>
              <FormControl style={{ width: '100%' }}>
                <FormLabel className={classes.formLabel}>
                  Email ID *
                </FormLabel>
                <TextField
                className={this.props.editUseremailError ||this.state.createUseremailError ?"errorInput":"withoutError" }
                  value={form.email}
                  color={'primary'}
                  variant="outlined"
                  type={'email'}
                  name={'email'}
                  placeholder={'Enter Email ID'}
                  onChange={this.onChangeEmail.bind(this)}
                />
              </FormControl>
              {this.props.editUseremailError && <p className="errorMsg2">{this.props.editUseremailError}</p>}
              {this.state.createUseremailError ? <p className="errorMsg2">{this.state.createUseremailError}</p> :null}
            </Grid>

            <Grid item xs={2} sm={2} className={classes.inputGrid}>
                   <OutlinedSelect
                      styleOverrides={{ width: "60%" }}
                      className={this.state.createUsercountry_codeError ?"errorSelect":"withoutErrorSelect" }
                      val={form.country_code}
                      options={this.country_codes}
                      label="Mobile"
                      // placeholder="Select Code"
                      name={'country_code'}
                      handleChange={this.onChangeCountryCode.bind(this)}
                      formControlStyle={{ minWidth: '0' }}
                      placeholderTitle={null}            
                     />
              {this.state.createUsercountry_codeError ? <p className="errorMsg2">{this.state.createUsercountry_codeError}</p> :null}
            </Grid>
            <Grid item xs={12} sm={4} className={classes.inputGrid}>
              <FormControl style={{ width: '100%' }}>
                <FormLabel className={classes.formLabel}>&nbsp;</FormLabel>
                <TextField
                className={this.props.editUsermobileError ||this.state.createUsermobileError ?"errorInput":"withoutError" }
                style={
                   {  width: "346px",
                    marginLeft: "-54px"}
                }
                  value={form.phone_number}
                  color={'primary'}
                  variant="outlined"
                  type={'number'}
                  name={'phone_number'}
                  placeholder={'Enter Phone Number'}
                  onChange={this.onChangePhoneNumber.bind(this)}
                />
              </FormControl>
              {this.props.editUsermobileError && <p className="errorMsg2"style={{marginLeft: "-50px"}}>{this.props.editUsermobileError}</p>}
              {this.state.createUsermobileError ? <p className="errorMsg2" style={{marginLeft: "-50px"}}>{this.state.createUsermobileError}</p> :null}
            </Grid>

            <Grid>{/* Important! Leave this for css rule. */}</Grid>

            <Grid item xs={12} sm={6} className={classes.inputGrid}>
              <OutlinedSelect
                      placeholderTitle="Select Role"
                      className={this.state.createUserroleError  || this.props.editUserroleError ?"errorSelect":"withoutErrorSelect" }
                      val={form.role_id}
                      options={roleList}
                      label="Role"
                      placeholder="Select Type"
                      name={'role_id'}
                      handleChange={this.onChangeRoleID.bind(this)}          
                     />
               {this.props.editUserroleError && <p className="errorMsg2">{this.props.editUserroleError}</p>}
              {this.state.createUserroleError ? <p className="errorMsg2">{this.state.createUserroleError}</p> :null}
            </Grid>

            <Grid item xs={12} sm={6} className={classes.inputGrid}>
              <OutlinedSelect
                      placeholderTitle="Select Status"
                      className={this.state.createUserstatusError  || this.props.editUserstatusError ?"errorSelect":"withoutErrorSelect" }
                      val={form.activated === false ? "false" : form.activated}
                      options={[
                        { id: "true", name: "Active" },
                        { id: "false", name: "Inactive" },
                      ]}
                      label="Status"
                      placeholder="Select Type"
                      name={'activated'}
                      handleChange={this.onChangeActivated.bind(this)} 
                     />
              {this.props.editUserstatusError && <p className="errorMsg2">{this.props.editUserstatusError}</p>}
              {this.state.createUserstatusError ? <p className="errorMsg2">{this.state.createUserstatusError}</p> :null}
            </Grid>
            {
            formMode === FormMode.Edit ? 
            <>
            <Grid item xs={12} sm={6} className={classes.inputGrid}>
              <FormControl style={{ width: '100%' }}>
                <FormLabel className={classes.formLabel}>
                Current password
                </FormLabel>
                <TextField
                 className={this.props.editUserCurrentPasswordError ?"errorInput":"withoutError" }
                  color={'primary'}
                  variant="outlined"
                  value={form.current_password}
                  onChange={this.onChangeCurrentPassword.bind(this)} 
                  type={this.state.passwordType}
                  name={'current_password'}
                  style={{
                          color: "black",
                          borderRadius:"5px",
                          outline: "0px",
                        }}
                 
                  placeholder={'Current password'}
                />
                <div onClick={this.togglePassword} className="usereyeToggle">
                        <img
                          src={
                            this.state.passwordType === "password"
                              ? this.state.imgPasswordInVisible
                              : this.state.imgPasswordVisible
                          }
                          alt="mainImage"
                          className=" iconStyle"
                        />
                      </div>
              </FormControl>
              {this.props.editUserCurrentPasswordError && <p className="errorMsg2">{this.props.editUserCurrentPasswordError}</p>}
            </Grid>
            <Grid item xs={12} sm={6} className={classes.inputGrid}>
              <FormControl style={{ width: '100%' }}>
                <FormLabel className={classes.formLabel}>
                New password
                </FormLabel>
                <TextField
                className={this.props.editUserPasswordError ?"errorInput":"withoutError" }
                  color={'primary'}
                  variant="outlined"
                  onChange={this.onChangeNewPassword.bind(this)} 
                  value={form.new_password}
                  type={this.state.newPasswordType}
                  name={'new_password'}
                  style={{
                          color: "black",
                          borderRadius:"5px",
                          outline: "0px",
                        }}
                  placeholder={'New password'}
                />
                <div onClick={this.toggleNewPassword} className="usereyeToggle">
                        <img
                          src={
                            this.state.newPasswordType === "password"
                            ? this.state.imgPasswordInVisible
                            : this.state.imgPasswordVisible
                          }
                          alt="mainImage"
                          className=" iconStyle"
                        />
                      </div>
              </FormControl>
              {this.props.editUserPasswordError && <p className="errorMsg2">{this.props.editUserPasswordError}</p>}
            </Grid>
            </>
          : 
          <Grid item xs={12} sm={6} className={classes.inputGrid}>
                      <FormControl style={{ width: '100%' }}>
                        <FormLabel className={classes.formLabel}>
                          Password
                        </FormLabel>
                        <TextField
                          color={'primary'}
                          variant="outlined"
                          type={'text'}
                          name={'password'}
                          style={{
                                  color: "black",
                                  borderRadius:"5px",
                                  outline: "0px",
                                  backgroundColor:"rgb(234 234 234)"
                                }}
                          placeholder={'********'}
                          disabled 
                        />
                      </FormControl>
                      </Grid> }
            </>
          }
          </Grid>
        </Grid>
        {errormsg ? <p className="errorMsg">{errormsg}</p> :null}
      </CustomModal>
    );
    // Customizable Area End
  }
}

export default withStyles(useStyles)(UserForm);