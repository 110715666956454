import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
export const configJSON = require("./config.js");

export interface Props {
    open: boolean;
    handleClose: (type: string, action: boolean) => void;
    classes: any;
    menuList: [];
    handleSubmit: (formData: object) => void;
    handleChange: () => void;
    checkBulkUpdateId:{};
    rolebulkUpdateerrorMessage: string;
}

interface S {
    closeModal: boolean;
    data: any;
    noteHeader: string;
    disabledbutton: boolean;
    loadingSpinner: boolean;
    statusError: string;
    status: string;
}

interface SS {
    id: any;
}
// Customizable Area End
export default class BulkUpdateRolesController extends BlockComponent<Props, S, SS> {

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [];

        this.state = {
            closeModal: false,
            data: [],
            noteHeader: '',
            disabledbutton: true,
            loadingSpinner: false,
            statusError: '',
            status: '',
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }
    // Customizable Area Start
    async componentDidMount() {
        super.componentDidMount();
    }

    componentDidUpdate(prevProps: Props) {
        if (prevProps.open !== this.props.open) {
            this.setState({ status: '', statusError: ""});
        }
    }

    handleChange = (e: any) => {
        this.setState({ status: (e.target.value === "null") ? "" : e.target.value} );
       // this.setState({ status: e.target.value} );
        this.handleValidation();
    };

    handleValidation: any = () => {
        let isValid = true;

        // status validation
        if (this.state.status === "") {
            this.setState({
                statusError: (
                    'Status is required'
                )
            });
            isValid = false;
        } else {
            this.setState({ statusError: '' });
        }
        return isValid;
    };

    handleSubmit = (e: any) => {
        e.preventDefault();
        if (this.handleValidation()) {
            this.props.handleSubmit({ roleData: this.props.checkBulkUpdateId,status: this.state.status,type: "bulkUpdate" });
        }
    };
    // Customizable Area End
}
