import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible, actionIcon } from "./assets";
import moment from "moment";
import { toast } from "react-toastify";
import React from "react";
import { IActionMenuItem } from "../../CommonLayout/HRMSCommons/src/actionMenu";
import { downloadCSV } from "../../../framework/src/Utilities";
import CustomImportModalController from "../../CommonLayout/CustomImportModal/src/CustomImportModalController";
import { debounce } from 'lodash';
import { number, string } from "yup";

const { baseURL } = require("../../../framework/src/config");
let token: any;
export const configJSON = require("./config");

// Customizable Area End



export interface Props {
  // Customizable Area Start
  navigation: any;
  classes: any;
  // Customizable Area End
}

// Customizable Area Start

interface accountAttribute {
  custom_id: number;
  name: string;
  account_type: string;
  code: string;
  description: string;
}

interface accountTypeAttribute {
  name: string;
}

interface parentAccountAttribute {
  name: string;
}

interface accountData {
  id: number;
  attributes: accountAttribute
}

interface accountTypeData {
  id: number;
  attributes: accountTypeAttribute
}

interface parentAccountData {
  id: number;
  attributes: parentAccountAttribute
}

interface bulkUpdate {
  status: string,
  ids: [],
}

interface checkBulkRow {
  [key: number]: boolean;
}
// Customizable Area End

interface S {
  // Customizable Area Start
  submitLoading: boolean;
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  filterAnchorEl: null,
  actionIcon: any;
  anchorEl: null;
  importExportanchorEl: null;
  deleteModalOpen: boolean;
  createModalOpen: boolean;
  editModalOpen: boolean;
  viewModalOpen: boolean;
  copyModalOpen: boolean;
  bulkUpdateAccountModalOpen: boolean;
  importRoleModalOpen: boolean;
  accountData: accountData | null;
  accountList: any;
  accountListMeta: any;
  page: number;
  roleId: number;
  accountTypeList: [];
  parentAccountList: [];
  showLoader: boolean;
  accountListerrorMessage: string;
  roleExporterrorMessage: string;
  accountImporterrorMessage: string;
  accountDetails: object | null;
  accountDetailserrorMessage: string;
  accountSearcherrorMessage: string;
  accountCreateerrorMessage: string;
  accountCreateNameerrorMessage: string;
  accountUpdateNameerrorMessage: string;
  selectedAccountItem: accountData | undefined;
  accountUpdateerrorMessage: string;
  bulkUpdateData: bulkUpdate | null;
  checkBulkUpdateRow: checkBulkRow | null;
  disableBukUpload: boolean;
  allRowsChecked: boolean | string;
  accountbulkUpdateerrorMessage: string;
  exportRoleData: any;
  searchKeyword: string;
  closeImportModal: boolean;
  deleteMessage: string;

  // Customizable Area End
}

interface SS {
  id: any;
}

export default class AccountController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  apiImportAccount: string = "";
  apiUpdateAccount: string = "";
  apiGetAccountList: string = "";
  apiGetAccountTypeList: string = "";
  apiGetParentAccountList: string = "";
  apiAddAccount: string = "";
  apiDeleteAccount: string = "";
  apiGetAccountDetails: string = "";
  tableRef: any = null;
  selectorRef: any = null;
  apiBulkUpdateAccount: string = "";
  apiGetAccountSearchList: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    console.disableYellowBox = true;
    this.subScribedMessages = [
        getName(MessageEnum.RestAPIResponceMessage)
    ];
    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      actionIcon: actionIcon,
      anchorEl: null,
      importExportanchorEl: null,
      filterAnchorEl: null,
      deleteModalOpen: false,
      createModalOpen: false,
      editModalOpen: false,
      viewModalOpen: false,
      copyModalOpen: false,
      bulkUpdateAccountModalOpen: false,
      importRoleModalOpen: false,
      submitLoading: false,
      accountData: null,
      accountList: [],
      accountListMeta: {},
      accountTypeList: [],
      parentAccountList: [],
      accountDetails: null,
      page: 0,
      showLoader: false,
      accountListerrorMessage: '',
      accountDetailserrorMessage: '',
      roleExporterrorMessage: '',
      accountImporterrorMessage: '',
      accountSearcherrorMessage: '',
      accountCreateNameerrorMessage: '',
      accountUpdateNameerrorMessage: '',
      roleId: 0,
      selectedAccountItem: undefined,
      accountCreateerrorMessage: '',
      accountUpdateerrorMessage: '',
      bulkUpdateData: null,
      checkBulkUpdateRow: null,
      disableBukUpload: true,
      allRowsChecked: false,
      accountbulkUpdateerrorMessage: '',
      exportRoleData: '',
      searchKeyword: '',
      closeImportModal: false,
      deleteMessage: 'multiple',

    };
    this.selectorRef = React.createRef();
    this.tableRef = React.createRef();

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
      
      if (apiRequestCallId === this.apiGetAccountList) {
        if (responseJson != null) {
            if (!responseJson.error && !responseJson.errors) {
              console.log("success");
              this.setState({ accountList: responseJson?.data, accountListMeta: responseJson?.meta , searchKeyword: "",checkBulkUpdateRow: null,
              disableBukUpload: true,
              allRowsChecked: false});
            } else {
              if(responseJson?.errors && responseJson?.errors[0]?.token == "Token has Expired, Please login again."){
                localStorage.removeItem("token");
                this.props.navigation.navigate("EmailAccountLoginBlock");
              }
              this.setState({ accountListerrorMessage: responseJson.error});
            }
        }
        setTimeout(() => this.setState({ showLoader: false }),500);
      // this.setState({ roleListerrorMessage: errorReponse});
      }  else if (apiRequestCallId === this.apiGetAccountTypeList) {
        if (responseJson != null) {
         
            if (!responseJson.error && !responseJson.errors) {
              let accTypedata;
              const accountTypeRes = responseJson?.data && responseJson?.data.map((item: accountTypeData,index:number) => {
                return  accTypedata ={
                  "id": item.id,
                  "name": item.attributes.name,
                }
              })
              this.setState({
                accountTypeList: accountTypeRes,
              });

            } else {
              if(responseJson?.errors && responseJson?.errors[0]?.token == "Token has Expired, Please login again."){
                localStorage.removeItem("token");
                this.props.navigation.navigate("EmailAccountLoginBlock");
              }
            }
        }
        //this.setState({ roleMenuListerrorMessage: errorReponse});
      } else if (apiRequestCallId === this.apiGetParentAccountList) {
        if (responseJson != null) {
            if (!responseJson.error && !responseJson.errors) {
              let parentAccdata;
              const parentAccountRes = responseJson?.data && responseJson?.data.map((item: parentAccountData,index:number) => {
                return  parentAccdata ={
                  "id": item.id,
                  "name": item.attributes.name,
                }
              })
              this.setState({
                parentAccountList: parentAccountRes,
              });
              console.log('parentAccountRes',parentAccountRes);
            } else {
              if(responseJson?.errors && responseJson?.errors[0]?.token == "Token has Expired, Please login again."){
                localStorage.removeItem("token");
                this.props.navigation.navigate("EmailAccountLoginBlock");
              }
            }
        }
        //this.setState({ roleMenuListerrorMessage: errorReponse});
      } else if (apiRequestCallId === this.apiAddAccount) {
        if (responseJson != null) {
            if (!responseJson.name_error && !responseJson.other_error && !responseJson.error && !responseJson.errors) {
              toast.success("Account created successfully");
              this.setState({ createModalOpen: false });
              this.setState({ copyModalOpen: false });
              this.getAccountList(this.state.page);
            } else {
              if(responseJson?.errors && responseJson?.errors[0]?.token == "Token has Expired, Please login again."){
                localStorage.removeItem("token");
                this.props.navigation.navigate("EmailAccountLoginBlock");
              }else if(responseJson.name_error){
                this.setState({ accountCreateNameerrorMessage: responseJson.name_error});

              }else{
                this.setState({ accountCreateerrorMessage: responseJson.other_error});

              }
            }
        }
        this.setState({ showLoader: false })
      } else if (apiRequestCallId === this.apiDeleteAccount) {
        if (responseJson != null) {
            if (!responseJson.error && !responseJson.errors) {
              toast.success("Account deleted successfully");
              this.setState({ deleteModalOpen: false });
              this.getAccountList(this.state.page);
            
            } else {
              if(responseJson?.errors && responseJson?.errors[0]?.token == "Token has Expired, Please login again."){
                localStorage.removeItem("token");
                this.props.navigation.navigate("EmailAccountLoginBlock");
              }
            }
        }
        this.setState({ showLoader: false })
        //this.setState({ errorMessage: errorReponse});
       
      } else if (apiRequestCallId === this.apiGetAccountDetails) {
        if (responseJson != null) {
            if (!responseJson.error && !responseJson.errors) {
              this.setState({ accountDetails: responseJson?.data, showLoader: false });
            } else {
              if(responseJson?.errors && responseJson?.errors[0]?.token == "Token has Expired, Please login again."){
                localStorage.removeItem("token");
                this.props.navigation.navigate("EmailAccountLoginBlock");
              }
              this.setState({ accountDetailserrorMessage: responseJson.error});
            }
        }
        this.setState({ showLoader: false })
        //this.setState({ roleDetailserrorMessage: errorReponse});
      
      } else if (apiRequestCallId === this.apiUpdateAccount) {
        if (responseJson != null) {
          if (!responseJson.name_error && !responseJson.other_error && !responseJson.error && !responseJson.errors) {
            toast.success("Account updated successfully");
              this.setState({ editModalOpen: false });
              this.getAccountList(this.state.page);
            } else {
              if(responseJson?.errors && responseJson?.errors[0]?.token == "Token has Expired, Please login again."){
                localStorage.removeItem("token");
                this.props.navigation.navigate("EmailAccountLoginBlock");
              }else if(responseJson.name_error){
                this.setState({ accountUpdateNameerrorMessage: responseJson.name_error});
              }else{
                this.setState({ accountUpdateerrorMessage: responseJson.other_error});

              }
            }
        }
        this.setState({ showLoader: false })
      } else if (apiRequestCallId === this.apiBulkUpdateAccount) {
        if (responseJson != null ) {
            if (!responseJson.error && !responseJson.errors) {
              toast.success("Account updated successfully");
              this.setState({ bulkUpdateAccountModalOpen: false,checkBulkUpdateRow: null, disableBukUpload: true, allRowsChecked: false });
              this.getAccountList(this.state.page);
            } else {
              if(responseJson?.errors && responseJson?.errors[0]?.token == "Token has Expired, Please login again."){
                localStorage.removeItem("token");
                this.props.navigation.navigate("EmailAccountLoginBlock");
              }
              this.setState({ accountbulkUpdateerrorMessage: responseJson.error.name[0]});
            }
        }
        this.setState({ showLoader: false })
      } else if (apiRequestCallId === this.apiGetAccountSearchList) {
        if (responseJson != null) {
            if (!responseJson.error && !responseJson.errors) {
              this.setState({ accountList: responseJson.data ? responseJson.data : [], accountListMeta: responseJson?.meta });
            } else {
              if(responseJson?.errors && responseJson?.errors[0]?.token == "Token has Expired, Please login again."){
                localStorage.removeItem("token");
                this.props.navigation.navigate("EmailAccountLoginBlock");
              }
              this.setState({ accountListerrorMessage: responseJson.error});
            }
        }
        setTimeout(() => this.setState({ showLoader: false }),300);
        // this.setState({ roleListerrorMessage: errorReponse});
      }
    }
    // Customizable Area End

  }

  // Customizable Area Start
  onClickCreateAccount = () => {
    this.handleModalAction("create", true);
  }

  handleSearchChange = (evt: any) => {
    const { value } = evt.target;
    this.setState({searchKeyword: value});
    const debouncedSave = debounce((value) => {
      if(value !== ""){
        this.setState({page: 0});
        this.getSearchAccountList(0,value);
      }else{
        this.getAccountList(0);
      }
    }, 100);
		debouncedSave(value);
  }

 
  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address"
  };

  txtInputProps = this.isPlatformWeb() ? this.txtInputWebProps : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed()
  };

  async componentDidMount() {
    super.componentDidMount();
    this.getAccountList(this.state.page);
    this.getAccountTypeList();
  }

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  handleAnchorClick = (event: any, row: accountData) => {
    this.setState({ anchorEl: event.currentTarget, selectedAccountItem: row });
  };

  handleAnchorClose = () => {
    this.setState({ anchorEl: null });
  };

  handleExportImportAnchorClick = (event: any) => {
    this.setState({ importExportanchorEl: event.currentTarget });
  };

  handleFilterAnchorClick = (event: any) => {
    this.setState({ filterAnchorEl: event.currentTarget });
  };

  handleFilterAnchorClose = () => {
    this.setState({ filterAnchorEl: null });
  };

  handleModalAction = (type: string, action: boolean) => {
    this.setState({ accountCreateerrorMessage: '' });
    this.setState({ accountCreateNameerrorMessage: '' });
    this.setState({ accountUpdateerrorMessage: '' });
    this.setState({ accountUpdateNameerrorMessage: '' });

    const typeArr = ["view","edit","copy"];
    if(typeArr.includes(type)){
      if(!action){
        this.setState({ accountDetails: null, selectedAccountItem: undefined});
      }else{
       
        //For handle checkbox if perform one action
        if(this.state.selectedAccountItem){
          const newState = {[this.state.selectedAccountItem?.id as number]: true};
          const disableBukUpload = this.bulkUpdateDisable(newState);
          this.setState({checkBulkUpdateRow: newState, disableBukUpload, allRowsChecked: "indeterminate" });
        }
        //For handle checkbox if perform one action
        this.getAccountDetails(this.state.selectedAccountItem ? this.state.selectedAccountItem?.id : 0);
      }
    }
    this.handleAnchorClose();
    if (type === "create") {
      this.setState({ createModalOpen: action,parentAccountList:[] });
    }else if (type === "delete") {
      /* Handle checbox before delete modal open start*/
      const ids = Object.keys(this.state.checkBulkUpdateRow!== null && this.state.checkBulkUpdateRow).filter((item: any) => this.state.checkBulkUpdateRow && this.state.checkBulkUpdateRow[item])
      if(this.state.selectedAccountItem?.id && !ids.includes(this.state.selectedAccountItem.id.toString())){
        const newState = {[this.state.selectedAccountItem?.id as number]: true};
        const disableBukUpload = this.bulkUpdateDisable(newState);
        this.setState({deleteMessage: 'single',checkBulkUpdateRow: newState, disableBukUpload, allRowsChecked: "indeterminate" });  
      }else{
        this.setState({deleteMessage: ids.length>1 ?'multiple':'single'});
      }
      /* Handle checbox before delete modal open end */
      this.setState({ deleteModalOpen: action, accountData: this.state.selectedAccountItem ?this.state.selectedAccountItem : null });
    }
     else if (type === "edit") {
      this.setState({ editModalOpen: action, accountData: this.state.selectedAccountItem ?this.state.selectedAccountItem : null });
    } 
    else if (type === "copy") {
      this.setState({ copyModalOpen: action, accountData: this.state.selectedAccountItem ?this.state.selectedAccountItem : null });
    }else if (type === "bulkUpdate") {
      this.setState({ bulkUpdateAccountModalOpen: action });
    }else {
      this.setState({ viewModalOpen: action, accountData: this.state.selectedAccountItem ?this.state.selectedAccountItem : null });
    }
    return null;
  }

  handleModalFormSubmit = (formData: any) => {

    if(!formData.accountData) return null;
    if(formData.type && formData.type == "bulkUpdate"){
      const ids = Object.keys(formData.accountData).filter((item: string) => formData.accountData[item])
      console.log('bilkids', ids);
      console.log('formData', formData);
      this.bulkUpdateAccount(ids,formData.parent_academic_account_id,formData.account_type_id);

    }else{
      if(formData.accountData){
        this.setState({ submitLoading: true });
        
        if(formData.type == "update"){
          if(this.state.selectedAccountItem?.id){
            const acc_id = this.state.selectedAccountItem?.id ;
            this.updateAccount(formData.accountData,acc_id);
          }
          else{
            return null;
          }
        }else{
          this.addAccount(formData.accountData);
        }
      }
    }  
  }

  handleDeleteModalClose = () => {
    this.setState({ deleteModalOpen: false });
  }

  handlePageClick = (event: any) => {
    this.setState({page: event.selected});
    this.tableRef.current.scrollIntoView()
    if(this.state.searchKeyword !== ""){
    this.getSearchAccountList(event.selected, this.state.searchKeyword);
    }else{
      this.getAccountList(event.selected);
    }
  };
  handleModalFormDelete = () => {
    this.setState({ deleteModalOpen: false });
    const ids = Object.keys(this.state.checkBulkUpdateRow!== null && this.state.checkBulkUpdateRow).filter((item: any) => this.state.checkBulkUpdateRow && this.state.checkBulkUpdateRow[item])
    const newIds = ids.join(",");
    if(this.state.selectedAccountItem?.id && ids.includes(this.state.selectedAccountItem.id.toString())){
      if(ids.length){
        this.deleteAccount(newIds);
      }
    }else{
      if(this.state.selectedAccountItem?.id){
        const selectedIds = this.state.selectedAccountItem.id;
        this.deleteAccount(selectedIds.toString());
      }
    }
    // if(this.state.accountData && this.state.accountData.id ){
    //   const account_id = this.state.accountData.id;
    //   this.deleteAccount(account_id);
    // }
    return null;
  }

  bulkUpdateAccount = (roleIds: string[],parent_academic_account_id: string,account_type_id: string) => {
    let token = localStorage.getItem("token");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token,
    };
    //this.bulkUpdateAccount(ids,formData.parent_academic_account_id,formData.account_type_id,formData.is_sub_account);
    const data = {
      parent_academic_account_id:parent_academic_account_id,
      account_type_id: account_type_id,
      is_sub_account: parent_academic_account_id == "" ? false : true,
      ids: roleIds,
    };
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    console.log('msgid',requestMessage.messageId);

    this.apiBulkUpdateAccount = requestMessage.messageId;
    let apiEndPoint = configJSON.getAccountEndPoint+ `/bulk_update`
    this.setState({ showLoader: true });
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.accountManagementURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PUT
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getAccountList = (page: number) => {
    this.setState({ showLoader: true });
    let token = localStorage.getItem("token");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetAccountList = requestMessage.messageId
    let apiEndPoint = configJSON.getAccountEndPoint + `?page=${page + 1}`
      
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.accountManagementURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getAccountDetails = (id: number) => {
    this.setState({ showLoader: true });
    let token = localStorage.getItem("token");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetAccountDetails = requestMessage.messageId
    let apiEndPoint = configJSON.getAccountEndPoint + `/${id}`
    this.setState({ showLoader: true });
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.accountManagementURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  updateAccount = (accountData: object,accId: number) => {
    let token = localStorage.getItem("token");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token,
    };
    const data = {
      academic_account: {...accountData}
    };
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiUpdateAccount = requestMessage.messageId;
    let apiEndPoint = configJSON.getAccountEndPoint+ `/${accId}`
    this.setState({ showLoader: true });
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.accountManagementURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PUT
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  deleteAccount = (accId: string) => {
    let token = localStorage.getItem("token");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token,
    };
   
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiDeleteAccount = requestMessage.messageId;
    let apiEndPoint = configJSON.getAccountEndPoint+ `/bulk_destroy?ids=${accId}`
    this.setState({ showLoader: true });
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.accountManagementURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );
    console.log("requestMessage: ", requestMessage);
    
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getAccountTypeList = () => {
    let token = localStorage.getItem("token");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetAccountTypeList = requestMessage.messageId;
    let apiEndPoint = configJSON.getAccountBaseEndPoint + `/account_types`;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.accountManagementURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getParentAccountList = (id: string) => {
    let token = localStorage.getItem("token");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetParentAccountList = requestMessage.messageId;
    let apiEndPoint = configJSON.getAccountBaseEndPoint + `/parent_accounts?account_type_id=${id}`;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.accountManagementURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleParentList = (id: string) => {
    if(id === "" || id === "null"){
      this.setState({
        parentAccountList: []
      });
    }else{
      this.getParentAccountList(id);
    }
  }
  addAccount = (accData: object) => {
    let token = localStorage.getItem("token");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token,
    };
    const data = {
      academic_account: {...accData}
    };
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiAddAccount = requestMessage.messageId;
    let apiEndPoint = configJSON.getAccountEndPoint
    this.setState({ showLoader: true });
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.accountManagementURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.POST
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  
  handleCheckAccountItem = (evt: React.FormEvent<HTMLInputElement>, id: number) => {
    const { checkBulkUpdateRow } = this.state;
    const newState = {...checkBulkUpdateRow, [id]: evt.currentTarget.checked};
    const disableBukUpload = this.bulkUpdateDisable(newState);
    let allRowsChecked = false;
    let checkAnyTrue = false;
    const trueCount = Object.keys(newState).filter((itm: string) => newState[parseInt(itm)]).length;
    if(trueCount === this.state.accountList.length){
      allRowsChecked = true;
    }else{
      checkAnyTrue = (trueCount > 0)?true:false;
    }

    this.setState({checkBulkUpdateRow: newState, disableBukUpload, allRowsChecked: (checkAnyTrue) ? "indeterminate" : allRowsChecked });
  };

  handleCheckAllAccount = (evt: React.FormEvent<HTMLInputElement>) => {
    if(this.state.accountList){
      const checkAccount: checkBulkRow = {};
      this.state.accountList.map((item: accountData) => {
        checkAccount[item.id] = evt.currentTarget.checked;
      });
   
      this.setState({checkBulkUpdateRow: checkAccount, allRowsChecked: evt.currentTarget.checked, disableBukUpload: !evt.currentTarget.checked });
    }
  };

  bulkUpdateDisable = (rows: checkBulkRow) => {
    let hasTrueKeys = true;
    if(rows && Object.keys(rows).length){
      hasTrueKeys = Object.keys(rows).some((key: string) => rows[parseInt(key)]);
    }
    return !hasTrueKeys;
  };

  handleExportAccount = () => {
    
      token = localStorage.getItem("token");
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          token,
        },
      };
      fetch(`${configJSON.accountManagementURL}/${configJSON.getAccountEndPoint}/export`, requestOptions)
        .then((response) => {
          const file_sub_name = new Date().getTime()

          response.blob().then(blob => downloadCSV(blob, "account_csv_"+file_sub_name))
        })
        .catch((error) => {
          toast.success(error.message);
          });
  }

  importAccountData = (file: File) => {

    const formData = new FormData();
    formData.append("file", file);
    token = localStorage.getItem("token");

    const header = {
      "Content-Type": configJSON.APIContentType,
      token: localStorage.getItem("token"),
    };

    const headerData =  JSON.stringify(header);
     const requestOptions = {
        method: "POST",
        headers: JSON.parse(headerData),
        body: formData
      };
    fetch(`${configJSON.accountManagementURL}/${configJSON.getAccountEndPoint}/import`, requestOptions)
    .then((response) => {
      const contentType = response.headers.get("content-type");
      if (contentType && contentType.indexOf("application/json") !== -1) {
        return response.json();
      } else {
        return response.blob();
      }
    })
    .then((response) => {
      console.log('reesponse', response)
      if(response instanceof Blob){
        const file_sub_name = new Date().getTime();
        downloadCSV(response, "account_invalid_csv_"+file_sub_name);
        this.setState({ closeImportModal: true }, () => {
          this.setState({ closeImportModal: false });
        });
      }else{
        if(response.message){
          toast.success(response.message);
          this.setState({ closeImportModal: true }, () => {
            this.setState({ closeImportModal: false });
          });
          this.getAccountList(this.state.page);
        }else if(response?.errors && response?.errors[0]?.token == "Invalid token"){
            localStorage.removeItem("token");
            this.props.navigation.navigate("EmailAccountLoginBlock");
        }else{
          this.setState({ accountImporterrorMessage: response.common_error});
        }
      }
    })
    .catch((error) => {
      toast.success(error?.message ? error.message : error);
    });
  }

  getSearchAccountList = (page: number,searchKey: string) => {
    this.setState({ showLoader: true });
    let token = localStorage.getItem("token");
    const role_Data = JSON.parse('{}');
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetAccountSearchList = requestMessage.messageId    
    let apiEndPoint = configJSON.getAccountEndPoint + `/search?page=${page + 1}&name=${searchKey}`
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.accountManagementURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  
  handleDownloadCSVFile = () => {
    token = localStorage.getItem("token");
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          token,
        },
      };
      fetch(`${configJSON.accountManagementURL}/${configJSON.getAccountEndPoint}/academic_account_csv_sample_file`, requestOptions)
        .then((response) => {
          const file_sub_name = new Date().getTime()
          response.blob().then(blob => downloadCSV(blob, "account_csv_sample_"+file_sub_name))
        })
        .catch((error) => {
          toast.success(error.message);
          });
  }
  // Customizable Area End

}