import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible, actionIcon } from "./assets";
import moment from "moment";
import { toast } from "react-toastify";
import React from "react";
import { downloadCSV } from "../../../framework/src/Utilities";
import { debounce } from 'lodash';

const { baseURL } = require("../../../framework/src/config");
let token: any;
export const configJSON = require("./config");

// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation: any;
  classes: any;
  // Customizable Area End
}

// Customizable Area Start

interface roleAttribute {
  id: number;
  name: string;
  status: string;
  created_by: string;
  custom_id: string;
  created: Date;
}

interface roleData {
  id: string;
  attributes: roleAttribute
}

interface bulkUpdate {
  status: string,
  ids: [],
}

interface checkBulkRow {
  [key: number]: boolean;
}

interface permissionFormData {
  menu_id: number,
  actions: Array<string>,
}
// Customizable Area End

interface S {
  // Customizable Area Start
  submitLoading: boolean;
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  filterAnchorEl: null,
  actionIcon: any;
  anchorEl: null;
  importExportanchorEl: null;
  deleteModalOpen: boolean;
  createModalOpen: boolean;
  editModalOpen: boolean;
  viewModalOpen: boolean;
  copyModalOpen: boolean;
  bulkUpdateRoleModalOpen: boolean;
  importRoleModalOpen: boolean;
  roleData: roleData | null;
  roleList: any;
  roleListMeta: any;
  page: number;
  roleId: number;
  roleMenuList: [];
  showLoader: boolean;
  roleListerrorMessage: string;
  roleExporterrorMessage: string;
  roleMenuListerrorMessage: string;
  roleImporterrorMessage: string;
  roleDetails: object | null;
  roleDetailserrorMessage: string;
  roleSearcherrorMessage: string;
  roleCreateerrorMessage: string;
  roleCreateNameerrorMessage: string;
  roleUpdateNameerrorMessage: string;
  selectedRoleItem: roleData | undefined;
  roleUpdateerrorMessage: string;
  bulkUpdateData: bulkUpdate | null;
  checkBulkUpdateRow: checkBulkRow | null;
  disableBukUpload: boolean;
  allRowsChecked: boolean | string;
  rolebulkUpdateerrorMessage: string;
  exportRoleData: any;
  searchKeyword: string;
  closeImportModal: boolean;
  deleteMessage: string;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class RolesPermissionsController extends BlockComponent<
  Props,
  S,
  SS
> {

   // Customizable Area Start

  tableRef: any = null;
  roleSelectorRef: any = null;
  apiGetRoleList: string = "";
  apiAddRole: string = "";
  apiGetRoleMenuList: string = "";
  apiDeleteRole: string = "";
  apiGetRoleDetails: string = "";
  apiUpdateRole: string = "";
  apiBulkUpdateRole: string = "";
  apiGetRoleExport: string = "";
  apiImportRole: string = "";
  apiGetRoleSearchList: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    console.disableYellowBox = true;
    this.subScribedMessages = [
        getName(MessageEnum.RestAPIResponceMessage)
    ];
    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      actionIcon: actionIcon,
      anchorEl: null,
      importExportanchorEl: null,
      filterAnchorEl: null,
      deleteModalOpen: false,
      createModalOpen: false,
      editModalOpen: false,
      viewModalOpen: false,
      copyModalOpen: false,
      bulkUpdateRoleModalOpen: false,
      importRoleModalOpen: false,
      submitLoading: false,
      roleData: null,
      roleList: [],
      roleListMeta: {},
      roleMenuList: [],
      roleDetails: null,
      page: 0,
      showLoader: false,
      roleListerrorMessage: '',

      roleMenuListerrorMessage: '',
      roleDetailserrorMessage: '',
      roleExporterrorMessage: '',
      roleImporterrorMessage: '',
      roleSearcherrorMessage: '',
      roleCreateNameerrorMessage: '',
      roleUpdateNameerrorMessage: '',
      roleId: 0,
      selectedRoleItem: undefined,
      roleCreateerrorMessage: '',
      roleUpdateerrorMessage: '',
      bulkUpdateData: null,
      checkBulkUpdateRow: null,
      disableBukUpload: true,
      allRowsChecked: false,
      rolebulkUpdateerrorMessage: '',
      exportRoleData: '',
      searchKeyword: '',
      closeImportModal:false,
      deleteMessage: 'multiple',
    };
    this.roleSelectorRef = React.createRef();
    this.tableRef = React.createRef()

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
      
      if (apiRequestCallId === this.apiGetRoleList) {
        if (responseJson != null) {
            if (!responseJson.error && !responseJson.errors) {
              console.log("success");
             
              this.setState({ roleList: responseJson?.data, roleListMeta: responseJson?.meta, searchKeyword: "", checkBulkUpdateRow: null,
              disableBukUpload: true,
              allRowsChecked: false });
            } else {
              if(responseJson?.errors && responseJson?.errors[0]?.token == "Token has Expired, Please login again."){
                localStorage.removeItem("token");
                this.props.navigation.navigate("EmailAccountLoginBlock");
              }
              this.setState({ roleListerrorMessage: responseJson.error});
            }
        }
        setTimeout(() => this.setState({ showLoader: false }),500);
      } else if (apiRequestCallId === this.apiGetRoleMenuList) {
        if (responseJson != null) {
            if (!responseJson.error && !responseJson.errors) {
              this.setState({ roleMenuList: responseJson?.data});
            } else {
              if(responseJson?.errors && responseJson?.errors[0]?.token == "Token has Expired, Please login again."){
                localStorage.removeItem("token");
                this.props.navigation.navigate("EmailAccountLoginBlock");
              }
              this.setState({ roleMenuListerrorMessage: responseJson.error});
            }
        }
        //this.setState({ roleMenuListerrorMessage: errorReponse});
      } else if (apiRequestCallId === this.apiAddRole) {
        if (responseJson != null) {
            if (!responseJson.name_error && !responseJson.other_error && !responseJson.error && !responseJson.errors) {
              toast.success("Role created successfully");
              this.setState({ createModalOpen: false });
              this.setState({ copyModalOpen: false });
              this.getRoleList(this.state.page);
            } else {
              if(responseJson?.errors && responseJson?.errors[0]?.token == "Token has Expired, Please login again."){
                localStorage.removeItem("token");
                this.props.navigation.navigate("EmailAccountLoginBlock");
              }else if(responseJson.name_error){
                this.setState({ roleCreateNameerrorMessage: responseJson.name_error});

              }else{
                this.setState({ roleCreateerrorMessage: responseJson.other_error});

              }
            }
        }
        this.setState({ showLoader: false })
      }else if (apiRequestCallId === this.apiUpdateRole) {
        if (responseJson != null) {
          if (!responseJson.name_error && !responseJson.other_error && !responseJson.error && !responseJson.errors) {
            toast.success("Role updated successfully");
              this.setState({ editModalOpen: false });
              this.getRoleList(this.state.page);
            } else {
              if(responseJson?.errors && responseJson?.errors[0]?.token == "Token has Expired, Please login again."){
                localStorage.removeItem("token");
                this.props.navigation.navigate("EmailAccountLoginBlock");
              }else if(responseJson.name_error){
                this.setState({ roleUpdateNameerrorMessage: responseJson.name_error});

              }else{
                this.setState({ roleUpdateerrorMessage: responseJson.other_error});

              }
            }
        }
        this.setState({ showLoader: false })
      } else if (apiRequestCallId === this.apiBulkUpdateRole) {
        if (responseJson != null ) {
            if (!responseJson.error && !responseJson.errors) {
              toast.success("Roles updated successfully");
              this.setState({ bulkUpdateRoleModalOpen: false,checkBulkUpdateRow: null, disableBukUpload: true, allRowsChecked: false });
              this.getRoleList(this.state.page);
            } else {
              if(responseJson?.errors && responseJson?.errors[0]?.token == "Token has Expired, Please login again."){
                localStorage.removeItem("token");
                this.props.navigation.navigate("EmailAccountLoginBlock");
              }
              this.setState({ rolebulkUpdateerrorMessage: responseJson.error.name[0]});
            }
        }
        this.setState({ showLoader: false })
      } else if (apiRequestCallId === this.apiDeleteRole) {
        if (responseJson != null) {
            if (!responseJson.error && !responseJson.errors) {
              toast.success("Role deleted successfully");
              this.setState({ deleteModalOpen: false });
              this.getRoleList(this.state.page);
            
            } else {
              if(responseJson?.errors && responseJson?.errors[0]?.token == "Token has Expired, Please login again."){
                localStorage.removeItem("token");
                this.props.navigation.navigate("EmailAccountLoginBlock");
              }
              //this.setState({ errorMessage: responseJson.errors});
            }
        }
        this.setState({ showLoader: false })
        //this.setState({ errorMessage: errorReponse});
        
      } else if (apiRequestCallId === this.apiGetRoleDetails) {
        if (responseJson != null) {
            if (!responseJson.error && !responseJson.errors) {
              this.setState({ roleDetails: responseJson?.data, showLoader: false });
            } else {
              if(responseJson?.errors && responseJson?.errors[0]?.token == "Token has Expired, Please login again."){
                localStorage.removeItem("token");
                this.props.navigation.navigate("EmailAccountLoginBlock");
              }
              this.setState({ roleDetailserrorMessage: responseJson.error});
            }
        }
        this.setState({ showLoader: false })
        //this.setState({ roleDetailserrorMessage: errorReponse});
      
      } else if (apiRequestCallId === this.apiGetRoleSearchList) {
        if (responseJson != null) {
            if (!responseJson.error && !responseJson.errors) {
              this.setState({ roleList: responseJson.data ? responseJson.data : [], roleListMeta: responseJson?.meta });
            } else {
              if(responseJson?.errors && responseJson?.errors[0]?.token == "Token has Expired, Please login again."){
                localStorage.removeItem("token");
                this.props.navigation.navigate("EmailAccountLoginBlock");
              }
              this.setState({ roleListerrorMessage: responseJson.error});
            }
        }
        setTimeout(() => this.setState({ showLoader: false }),300);
        // this.setState({ roleListerrorMessage: errorReponse});
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  handleSearchChange = (evt: any) => {
    const { value } = evt.target;
    this.setState({searchKeyword: value});
    const debouncedSave = debounce((value) => {
      if(value !== ""){
        this.setState({page: 0});
        this.getSearchRoleList(0,value);
      }else{
        this.getRoleList(0);
      }
    }, 100);
		debouncedSave(value);
    
  
  }

  // Customizable Area Start
  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address"
  };

  txtInputProps = this.isPlatformWeb() ? this.txtInputWebProps : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed()
  };

  async componentDidMount() {
    super.componentDidMount();
    this.getRoleList(this.state.page);
    this.getRoleMenuList();
  }

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  handleAnchorClick = (event: any, row: roleData) => {
    this.setState({ anchorEl: event.currentTarget, selectedRoleItem: row });
  };

  handleAnchorClose = () => {
    this.setState({ anchorEl: null });
  };

  handleFilterAnchorClick = (event: any) => {
    this.setState({ filterAnchorEl: event.currentTarget });
  };

  handleFilterAnchorClose = () => {
    this.setState({ filterAnchorEl: null });
  };

  handleModalAction = (type: string, action: boolean) => {
    this.setState({ roleCreateerrorMessage: '' });
    this.setState({ roleCreateNameerrorMessage: '' });
    this.setState({ roleUpdateerrorMessage: '' });
    this.setState({ roleUpdateNameerrorMessage: '' });
    
    const typeArr = ["view","edit","copy"];
    if(typeArr.includes(type)){
      if(!action){
        this.setState({ roleDetails: null, selectedRoleItem: undefined});
      }else{
          //For handle checkbox if perform one action
          if(this.state.selectedRoleItem){
            const newState = {[this.state.selectedRoleItem?.id as string]: true};
            const disableBukUpload = this.bulkUpdateDisable(newState);
            this.setState({checkBulkUpdateRow: newState, disableBukUpload, allRowsChecked: "indeterminate" });
          }
          //For handle checkbox if perform one action
        this.getRoleDetails(this.state.selectedRoleItem ? this.state.selectedRoleItem?.id : '');
      }
    }
    this.handleAnchorClose();
    if (type === "create") {
      this.setState({ createModalOpen: action });
    } else if (type === "edit") {
      this.setState({ editModalOpen: action, roleData: this.state.selectedRoleItem ?this.state.selectedRoleItem : null });
    } else if (type === "copy") {
      this.setState({ copyModalOpen: action, roleData: this.state.selectedRoleItem ?this.state.selectedRoleItem : null });
    } else if (type === "delete") {
      /* Handle checbox before delete modal open start*/
      const ids = Object.keys(this.state.checkBulkUpdateRow!== null && this.state.checkBulkUpdateRow).filter((item: any) => this.state.checkBulkUpdateRow && this.state.checkBulkUpdateRow[item])
      if(!ids.includes(this.state.selectedRoleItem?.id as string)){
        const newState = {[this.state.selectedRoleItem?.id as string]: true};
        const disableBukUpload = this.bulkUpdateDisable(newState);
        this.setState({deleteMessage: 'single',checkBulkUpdateRow: newState, disableBukUpload, allRowsChecked: "indeterminate" });  
      }else{
        this.setState({deleteMessage: ids.length>1 ?'multiple':'single'});
      }
      /* Handle checbox before delete modal open end */
      this.setState({ deleteModalOpen: action, roleData: this.state.selectedRoleItem ?this.state.selectedRoleItem : null });
    } else if (type === "bulkUpdate") {
      this.setState({ bulkUpdateRoleModalOpen: action });
    } else {
      this.setState({ viewModalOpen: action, roleData: this.state.selectedRoleItem ?this.state.selectedRoleItem : null });
    }
    return null;
  }

  handleDeleteModalClose = () => {
    this.setState({ deleteModalOpen: false });
  }

  handleModalFormDelete = () => {
    this.setState({ deleteModalOpen: false });
    const ids = Object.keys(this.state.checkBulkUpdateRow!== null && this.state.checkBulkUpdateRow).filter((item: any) => this.state.checkBulkUpdateRow && this.state.checkBulkUpdateRow[item])
    const newIds = ids.join(",");
    if(ids.includes(this.state.selectedRoleItem?.id as string)){
      if(ids.length){
        this.deleteRole(newIds);
      }
    }else{
     this.deleteRole(this.state.selectedRoleItem?.id as string);
    }
  
    return null;
  }

  handleModalFormSubmit = (formData: any) => {

    if(!formData.roleData) return null;
    if(formData.type && formData.type == "bulkUpdate"){
      const ids = Object.keys(formData.roleData).filter((item: string) => formData.roleData[item])
      this.bulkUpdateRole(ids,formData.status);

    }else{
      if(formData.roleData){
        this.setState({ submitLoading: true });
        const newdate = moment(formData.roleData.created_date).format("DD-MM-YYYY"); 
        const rolePostData = { 
          name: formData.roleData.name.trim(),
          status: formData.roleData.status,
          created: formData.roleData.created_date
        }
        //role_permissions
        const permissionPostData: permissionFormData[] = [];
        
        if(formData.permissionData){

          for (const iterator in formData.permissionData) {
            const {fullAccessStatus, viewStatus, deleteStatus, editStatus, createStatus, approveStatus} = formData.permissionData[iterator];
            if((fullAccessStatus || viewStatus || deleteStatus || editStatus || createStatus || approveStatus)){
              const actions =[];
              if(createStatus){
                actions.push("create");
              }
              if(editStatus){
                actions.push("edit");
              }
              if(approveStatus){
                actions.push("approve");
              }
              if(fullAccessStatus){
                actions.push("full_access");
              }
              if(deleteStatus){
                actions.push("delete");
              }
              if(viewStatus){
                actions.push("view");
              }
              permissionPostData.push({
                menu_id: parseInt(iterator),
                actions: actions
              });
            }
          }
        }
        const rolePermissionData = {...rolePostData, role_permissions: permissionPostData };
        if(formData.type == "update"){
          if(this.state.selectedRoleItem?.id){
            const role_id = this.state.selectedRoleItem?.id ;
            this.updateRole(rolePermissionData,role_id);
          }
          else{
            return null;
          }
        }else{
          this.addRole(rolePermissionData);
        }
      }
    }  
  }

  addRole = (roleData: object) => {
    let token = localStorage.getItem("token");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token,
    };
    const data = {
      role: {...roleData}
    };
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiAddRole = requestMessage.messageId;
    let apiEndPoint = configJSON.getRoleEndPoint
    this.setState({ showLoader: true });
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.roleManagementURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.POST
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  updateRole = (roleData: object,roleId: string) => {
    let token = localStorage.getItem("token");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token,
    };
    const data = {
      role: {...roleData}
    };
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiUpdateRole = requestMessage.messageId;
    let apiEndPoint = configJSON.getRoleEndPoint+ `/${roleId}`
    this.setState({ showLoader: true });
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.roleManagementURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PUT
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  
  bulkUpdateRole = (roleIds: string[],status: string) => {
    let token = localStorage.getItem("token");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token,
    };
    const data = {
      status: status,
      ids: roleIds,
    };
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    console.log('msgid',requestMessage.messageId);

    this.apiBulkUpdateRole = requestMessage.messageId;
    let apiEndPoint = configJSON.getRoleEndPoint+ `/bulk_update`
    this.setState({ showLoader: true });
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.roleManagementURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PUT
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getRoleList = (page: number) => {
    this.setState({ showLoader: true });
    let token = localStorage.getItem("token");
    const role_Data = JSON.parse('{}');
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetRoleList = requestMessage.messageId
    let apiEndPoint = configJSON.getRoleEndPoint + `?page=${page + 1}`
      
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.roleManagementURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getRoleMenuList = () => {
    let token = localStorage.getItem("token");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetRoleMenuList = requestMessage.messageId;
    let apiEndPoint = configJSON.getRoleEndPoint + `/module_menu_list`;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.roleManagementURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  deleteRole = (roleId: string) => {
    let token = localStorage.getItem("token");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token,
    };
   
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiDeleteRole = requestMessage.messageId;
   // let apiEndPoint = configJSON.getRoleEndPoint+ `/${roleId}`
    let apiEndPoint = configJSON.getRoleEndPoint+ `/bulk_destroy?ids=${roleId}`
    this.setState({ showLoader: true });
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.roleManagementURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );
    console.log("requestMessage: ", requestMessage);
    
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getRoleDetails = (id: string) => {
    this.setState({ showLoader: true });
    let token = localStorage.getItem("token");
    const role_Data = JSON.parse('{}');
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetRoleDetails = requestMessage.messageId
    let apiEndPoint = configJSON.getRoleEndPoint + `/${id}`
    this.setState({ showLoader: true });
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.roleManagementURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handlePageClick = (event: any) => {
    this.setState({page: event.selected});
    this.tableRef.current.scrollIntoView()
    if(this.state.searchKeyword !== ""){
    this.getSearchRoleList(event.selected, this.state.searchKeyword);
    }else{
      this.getRoleList(event.selected);
    }
  };

  DateFormateConverter = (date: Date) => {
    const newdate =  moment(date).format("DD-MM-YYYY");
    return newdate;
  }
  
  handleCheckRoleItem = (evt: React.FormEvent<HTMLInputElement>, id: number) => {
    const { checkBulkUpdateRow } = this.state;
    const newState = {...checkBulkUpdateRow, [id]: evt.currentTarget.checked};
    const disableBukUpload = this.bulkUpdateDisable(newState);
    let allRowsChecked = false;
    let checkAnyTrue = false;
    const trueCount = Object.keys(newState).filter((itm: string) => newState[parseInt(itm)]).length;
    if(trueCount === this.state.roleList.length){
      allRowsChecked = true;
    }else{
      checkAnyTrue = (trueCount > 0)?true:false;
    }

    this.setState({checkBulkUpdateRow: newState, disableBukUpload, allRowsChecked: (checkAnyTrue) ? "indeterminate" : allRowsChecked });
  };

  handleCheckAllRole = (evt: React.FormEvent<HTMLInputElement>) => {
    if(this.state.roleList){
      const checkRoles: checkBulkRow = {};
      this.state.roleList.map((item: roleData) => {
        checkRoles[item.attributes.id] = evt.currentTarget.checked;
      });
   
      this.setState({checkBulkUpdateRow: checkRoles, allRowsChecked: evt.currentTarget.checked, disableBukUpload: !evt.currentTarget.checked });
    }
  };

  bulkUpdateDisable = (rows: checkBulkRow) => {
    let hasTrueKeys = true;
    if(rows && Object.keys(rows).length){
      hasTrueKeys = Object.keys(rows).some((key: string) => rows[parseInt(key)]);
    }
    return !hasTrueKeys;
  };

  onClickCreateRole = () => {
    this.handleModalAction("create", true);
  }

  handleExportRole = () => {
    
      token = localStorage.getItem("token");
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          token,
        },
      };
      fetch(`${configJSON.roleManagementURL}/${configJSON.getRoleEndPoint}/export`, requestOptions)
        .then((response) => {
          const file_sub_name = new Date().getTime()

          response.blob().then(blob => downloadCSV(blob, "role_csv_"+file_sub_name))
        })
        .catch((error) => {
          toast.success(error.message);
          });
   
  }

  handleDownloadCSVFile = () => {
    token = localStorage.getItem("token");
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          token,
        },
      };
      fetch(`${configJSON.roleManagementURL}/${configJSON.getRoleEndPoint}/role_csv_sample_file`, requestOptions)
        .then((response) => {
          const file_sub_name = new Date().getTime()
          response.blob().then(blob => downloadCSV(blob, "role_csv_sample_"+file_sub_name))
        })
        .catch((error) => {
          toast.success(error.message);
          });
  }


  importRoleData = (file: File) => {

    const formData = new FormData();
    formData.append("file", file);
    token = localStorage.getItem("token");

    const header = {
      "Content-Type": configJSON.APIContentType,
      token: localStorage.getItem("token"),
    };

    const headerData =  JSON.stringify(header);
     const requestOptions = {
        method: "POST",
        headers: JSON.parse(headerData),
        body: formData
      };
    fetch(`${configJSON.roleManagementURL}/${configJSON.getRoleEndPoint}/import`, requestOptions)
    .then((response) => {
      console.log("status",response.status);
      const contentType = response.headers.get("content-type");
      if (contentType && contentType.indexOf("application/json") !== -1) {
        return response.json();
      } else {
        return response.blob();
      }
    })
    .then((response) => {
      console.log('reesponse', response)
      if(response instanceof Blob){
        const file_sub_name = new Date().getTime();
        downloadCSV(response, "role_invalid_csv_"+file_sub_name);
        this.setState({ closeImportModal: true }, () => {
          this.setState({ closeImportModal: false });
        });
      }else{
        if(response.message){
          toast.success(response.message);
          this.setState({ closeImportModal: true }, () => {
            this.setState({ closeImportModal: false });
          });
          this.setState({ importRoleModalOpen: false });
          this.getRoleList(this.state.page);
        }else if(response?.errors && response?.errors[0]?.token == "Token has Expired, Please login again."){
          localStorage.removeItem("token");
          this.props.navigation.navigate("EmailAccountLoginBlock");
        }else{
          this.setState({ roleImporterrorMessage: response.common_error});
        }
      }
    })
    .catch((error) => {
      toast.success(error?.message ? error.message : error);
    });
  }

  getSearchRoleList = (page: number,searchKey: string) => {
    this.setState({ showLoader: true });
    let token = localStorage.getItem("token");
    const role_Data = JSON.parse('{}');
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetRoleSearchList = requestMessage.messageId    
    let apiEndPoint = configJSON.getRoleEndPoint + `/search?page=${page + 1}&name=${searchKey}`
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.roleManagementURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  

  // Customizable Area End

}