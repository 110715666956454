import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";

// Customizable Area Start
import { IVendor } from "../../CommonLayout/VendorSelectionModal/src/VendorSelectionModalController";
// Customizable Area End

export const configJSON = require("./config");

// Customizable Area Start
export enum FormMode {
  Create,
  Edit,
  View,
  Copy
}

enum Method {
  GET = "GET",
  POST = "POST",
  PUT = "PUT",
  DELETE = "DELETE",
  PATCH = "PATCH"
}

export interface IRecurringExpenseForm {
  id?: number;
  expense_type: string;
  sac: string | null;
  hsn: string | null;
  expense_name: string;
  exchange_rate: string;
  exchange_amount: string;
  paid_through: string;
  currency: string;
  ammount: string;
  repeat_every: string;
  start_date: any;
  end_date: any;
  expense_id: string | number | null;
  vendor_id: string | number;
  vendor?: string;
  customer_id: string | number | null | number;
  tax: string;
  source_of_supply: string;
  destination_of_supply: string;
  gst_treatment: string;
  reverse_charge_account: string;
  reverse_charge: string;
  reverse_charge_ammount: string;
  reverse_charge_type: string;
  comment: string;
  ammount_is: string | null;
  gst_in: string | null;
  bill_every_count: string;
  bill_every_option: string;
}

export interface ICustomer {
  id: string;
  type: string;
  attributes: {
    id: number;
    customer_name: string;
    company_name: string;
    phone: string;
  };
}
// Customizable Area End

export interface Props {
  // Customizable Area Start
  classes?: any;
  isOpen: boolean;
  requestMessage: Message;
  initialValues: IRecurringExpenseForm | null;
  formMode: FormMode;
  title: string;
  submitLabel: string;
  onClose: () => void;
  onSubmit: (form: IRecurringExpenseForm) => void;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  form: IRecurringExpenseForm;
  formErrors: { [key: string]: string };
  isSubmitting: boolean;
  showCustomRepeat: boolean;
  isLoading: boolean;
  states: [];
  gstTreatments: {
    id: string;
    name: string;
    description: string;
    created_at: string;
    updated_at: string;
  }[];
  currencies: [];
  vendors: IVendor[];
  customers: ICustomer[];
  showNoTaxReason: boolean;
  showReverseCharge: boolean;
  vendorModalOpen: boolean;
  customerModalOpen: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class RecurringExpenseFormController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  private initialValues: IRecurringExpenseForm = {
    expense_type: "services",
    sac: "",
    hsn: "",
    expense_name: "",
    exchange_rate: '',
    exchange_amount: '',
    paid_through: "",
    currency: "INR",
    ammount: "",
    repeat_every: "",
    start_date: "",
    end_date: "",
    expense_id: '',
    vendor_id: "",
    customer_id: "",
    tax: "",
    source_of_supply: "",
    destination_of_supply: "",
    gst_treatment: "",
    reverse_charge_account: "",
    reverse_charge: "",
    reverse_charge_ammount: "",
    reverse_charge_type: "%",
    comment: "",
    ammount_is: "",
    gst_in: "",
    bill_every_count: '',
    bill_every_option: '',
  };

  public RequestMessage = {
    GetCurrencies: this.buildRequestMessage(Method.GET),
    GetCustomers: this.buildRequestMessage(Method.GET),
    GetVendors: this.buildRequestMessage(Method.GET),
    GetGstTreatment: this.buildRequestMessage(Method.GET),
    GetStates: this.buildRequestMessage(Method.GET),
    Null: undefined as any
  };
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      form: { ...(props.initialValues || this.initialValues) },
      formErrors: {},
      isSubmitting: false,
      showCustomRepeat: false,
      isLoading: false,
      states: [],
      gstTreatments: [],
      currencies: [],
      customers: [],
      vendors: [],
      showNoTaxReason: false,
      showReverseCharge: false,
      vendorModalOpen: false,
      customerModalOpen: false
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const callID = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const response = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      switch (callID) {
        case this.RequestMessage.GetStates.messageId:
          if (response !== null) {
            const stateArray = response?.data?.map((el: any) => {
              return { id: el.name, name: el.name }
            });

            this.setState({
              states: stateArray || [],
              isLoading: false
            });
          }
          break;
        
        case this.RequestMessage.GetGstTreatment.messageId:
          const gstArray = response?.data?.map((el: any) => {
            return { ...el, id: `${el.id}` }
          });

          if (response !== null) {
            this.setState({
              gstTreatments: gstArray || [],
              isLoading: false
            });
          }
          break;

        case this.RequestMessage.GetCurrencies.messageId:
          if (response !== null) {
            const currencyArray = response?.data?.map((el: any) => {
              return { id: el[1], name: `${el[1]} - ${el[0]}` };
            });

            this.setState({
              currencies: currencyArray || [],
              isLoading: false
            });
          }
          break;

        case this.RequestMessage.GetCustomers.messageId:
          if (response !== null) {
            this.setState({
              customers: response?.data || [],
              isLoading: false
            });
          }
          break;
        
        case this.RequestMessage.GetVendors.messageId:
          if (response !== null) {
            // runEngine.debugLog("vendors", response.data);
            this.setState({
              vendors: response?.data
              // isLoading: false
            });
          }

          break;
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  public async componentDidMount() {
    super.componentDidMount();

    this.getCurrenciesCall();
    this.getCustomersCall();
    this.getVendorsCall();
    this.getStatesCall();
    this.getGstTreatmentCall();
  }

  public componentDidUpdate(prevProps: Props) {
    const { isOpen, initialValues, formMode } = this.props;
    if (prevProps.isOpen !== isOpen) {
      if (isOpen) {
        let form: IRecurringExpenseForm = { ...this.initialValues };

        if ([FormMode.Edit, FormMode.View, FormMode.Copy].includes(formMode)) {
          form = { ...(initialValues || this.initialValues) };
        }

        this.setState({
          form,
          isSubmitting: false
        });
      }
    }
  }

  private getCurrenciesCall() {
    this.setState({ isLoading: true });

    this.RequestMessage.GetCurrencies.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.RecurringExpensesEndPoint}/get_currency_list`
    );

    runEngine.sendMessage(
      this.RequestMessage.GetCurrencies.id,
      this.RequestMessage.GetCurrencies
    );
  }

  private getCustomersCall() {
    this.setState({ isLoading: true });

    this.RequestMessage.GetCustomers.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "/bx_block_profile/business_customers/customer_name_list"
    );

    runEngine.sendMessage(
      this.RequestMessage.GetCustomers.id,
      this.RequestMessage.GetCustomers
    );
  }

  private getVendorsCall() {
    this.setState({ isLoading: true });

    this.RequestMessage.GetVendors.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "/vendors"
    );

    runEngine.sendMessage(
      this.RequestMessage.GetVendors.id,
      this.RequestMessage.GetVendors
    );
  }

  private getStatesCall() {
    this.setState({ isLoading: true });

    this.RequestMessage.GetStates.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      '/bx_block_profile/individual_customers/get_states_name'
    );

    runEngine.sendMessage(
      this.RequestMessage.GetStates.id,
      this.RequestMessage.GetStates
    );
  }

  private getGstTreatmentCall() {
    this.setState({ isLoading: true });

    this.RequestMessage.GetGstTreatment.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'bx_block_profile/business_customers/gst_treatments'
    );

    runEngine.sendMessage(this.RequestMessage.GetGstTreatment.id, this.RequestMessage.GetGstTreatment);
  }

  private buildRequestMessage(method: Method): Message {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.RecurringExpensesEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.APIBaseURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      this.getHeaderMessage()
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method.toString()
    );

    return requestMessage;
  }

  private getHeaderMessage() {
    const header = {
      "Content-Type": configJSON.APIContentType,
      token: localStorage.getItem("token")
    };

    return JSON.stringify(header);
  }

  public onFormElementChange(
    element: React.ChangeEvent<{ value: string }>,
    property: string
  ) {
    this.setState({
      form: {
        ...this.state.form,
        [property]: element.target.value
      }
    });
  }

  onSubmitVendorModal = (id: string) => {
    this.setState({
      vendorModalOpen: false,
      form: { ...this.state.form, vendor_id: id }
    });
  };

  onSubmitCustomerModal = (id: string) => {
    this.setState({
      customerModalOpen: false,
      form: { ...this.state.form, customer_id: id }
    });
  };

  handleFormValueChange = (value: any, property: string) => {
    this.setState({
      form: {
        ...this.state.form,
        [property]: value
      }
    });
  };

  handleExchangeRate = (e: any) => {
    let expense_amount: any =
      parseFloat(e.target.value) *
      parseFloat(this.state.form.exchange_amount || "0.00");
    expense_amount = expense_amount ? expense_amount.toFixed(2) : "0.00";
    this.setState({
      form: {
        ...this.state.form,
        exchange_rate: e.target.value || "0.00",
        ammount: expense_amount
      }
    });
  };

  handleExchangeAmount = (e: any) => {
    let expense_amount: any =
      parseFloat(e.target.value) *
      parseFloat(this.state.form.exchange_rate || "0.00");
    expense_amount = expense_amount ? expense_amount.toFixed(2) : "0.00";
    this.setState({
      form: {
        ...this.state.form,
        exchange_amount: e.target.value,
        ammount: expense_amount
      }
    });
  };

  onGstTreatmentChange = (e: React.ChangeEvent<{ value: string }>) => {
    if (e.target.value === 'unRegisteredBusiness') {
      this.setState({
        form: {
          ...this.state.form,
          gst_in: null,
          gst_treatment: e.target.value,
        },
      });
    } else {
      this.setState({
        form: {
          ...this.state.form,
          gst_treatment: e.target.value,
        },
      });
    }
  }

  handleTaxElementChange = (e: React.ChangeEvent<{ value: string }>) => {
    if (e.target.value === 'Non-Taxable') {
      this.setState({
        form: {
          ...this.state.form,
          ammount_is: '',
          tax: e.target.value,
        },
        showNoTaxReason: true
      })
    } else {
      if (this.state.form.ammount_is === '') {
        this.setState({
          form: {
            ...this.state.form,
            tax: e.target.value,
            ammount_is: 'taxInclusive'
          }
        })
      } else {
        this.setState({
          form: {
            ...this.state.form,
            tax: e.target.value,
          }
        })
      }
    }
  }

  handleReverChargeShow = () => {
    this.setState({
      showReverseCharge: !this.state.showReverseCharge
    })
  }

  handleCustomRepeatClick = () => {
    this.setState({
      showCustomRepeat: true
    });
  };

  public onSubmit() {
    this.setState({ isSubmitting: this.props.formMode !== FormMode.View });

    // const formErrors: { [key: string]: string } = {};
    // Object.entries(this.state.form).forEach(([key, value]) => {
    //   if (!value || (Array.isArray(value) && value.length === 0)) {
    //     formErrors[key] = "Please enter a value";
    //   }
    // });

    // if (Object.keys(formErrors).length > 0) {
    //   this.setState({ formErrors, isSubmitting: false });

    //   return;
    // }

    this.props.onSubmit(this.state.form);
  }

  onCloseVendorModal = () => {
    this.setState({
      vendorModalOpen: false
    });
  };

  onCloseCustomerModal = () => {
    this.setState({
      customerModalOpen: false
    });
  };

  public handleFormClose() {
    this.setState({ formErrors: {} });

    this.props.onClose();
  }
  // Customizable Area End
}
