import React from "react";
// Customizable Area Start
import {
    TextField,
    Typography
} from "@material-ui/core";
import { withStyles, } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CustomBulkUpdateModal from '../../CommonLayout/CustomBulkUpdateModal/src/CustomBulkUpdateModal.web';
import OutlinedSelect from "../../../components/src/OutlinedSelect";

import BulkUpdateAccountController, {
    Props,
} from "./BulkUpdateAccountController";
// Customizable Area End

// Customizable Area Start
interface roleMenuSubmenu {
    id: number;
    title: string;
    permission_category_id: number,
    fullAccessStatus: boolean,
    viewStatus: boolean,
    editStatus: boolean,
    createStatus: boolean,
    deleteStatus: boolean,
    approveStatus: boolean
}

interface roleMenuAttributes {
    id: number;
    title: string;
    menus: roleMenuSubmenu[];
}

interface roleMenuData {
    attributes: roleMenuAttributes;
}
interface parentAcc {
    id: string,
    name: string,
}
// Customizable Area End

class BulkUpdateAccount extends BulkUpdateAccountController {

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End

    }

    render() {
        // Customizable Area Start

        const { open, handleClose, accountbulkUpdateerrorMessage,accountTypeList, parentAccountList,checkBulkUpdateId } = this.props;
        const { account_type_id, commonErrorMsg,accTypeError,parent_academic_account_id,parentAccError } = this.state;
        // Customizable Area End

        return (
            // Customizable Area Start

            <CustomBulkUpdateModal
                title={"Bulk Update"}
                handleSubmit={this.handleSubmit}
                buttonTitle={"Update"}
                open={open}
                handleClose={(evt: object) => handleClose("bulkUpdate", false)}
                errormsg={accountbulkUpdateerrorMessage ? accountbulkUpdateerrorMessage : ''}
            >
                {commonErrorMsg && <p style={{color: "red", textAlign:"center"}}>{commonErrorMsg}</p>}
                <Grid spacing={3} container>
                    <Grid item xs={6}>
                        <OutlinedSelect
                            placeholderTitle="Select Type"
                            val={account_type_id}
                            options={accountTypeList}
                            label="Account Type*"
                            name={'account_type_id'}
                            handleChange={this.handleChange}
                            className={accTypeError && !account_type_id ?"errorSelect":"withoutErrorSelect" }                      
                        />
                        {accTypeError && !account_type_id &&
                            <p className="errorMsg2">{accTypeError}</p>
                        }
                    </Grid>
                    <Grid item xs={6}>
                        <OutlinedSelect
                            placeholderTitle="Select Account"
                            val={parent_academic_account_id}
                            options={parentAccountList}
                            label="Parent Account*"
                            name={'parent_academic_account_id'}
                            handleChange={this.handleChange}
                            className={parentAccError && parent_academic_account_id ?"errorSelect":"withoutErrorSelect" }                      
                        />
                        {parentAccError && !parent_academic_account_id &&
                            <p className="errorMsg2">{parentAccError}</p>
                        }
                    </Grid>
                </Grid>
            </CustomBulkUpdateModal>
            // Customizable Area End

        );
    }
}

export const themeCreatStyle = ((theme: any) => ({

    background_color: {
        backgroundColor: theme.palette.primary.main
    },
    title_font: {
        color: theme.props.titleColor,
        fontFamily: theme.props.titleFont
    },
    subTitle_font: {
        color: theme.props.subtitleColor,
        fontFamily: theme.props.subtitleFont
    },
    heading_font: {
        color: theme.props.headingColor,
        fontFamily: theme.props.headingFont
    },
    subHeading_font: {
        color: theme.props.subheadingColor,
        fontFamily: theme.props.subheadingFont
    },
    bodyText_font: {
        color: theme.props.textColor,
        fontFamily: theme.props.textFont
    },
    button_color: {
        backgroundColor: theme.props.buttonColor,
        color: "white",
    },
    icon_color: {
        color: theme.props.iconColor
    },
    button_color_disabled: {
        color: theme.props.buttonColor,
        backgroundColor: "white",
        border: `1px solid ${theme.props.buttonColor}`,
        '&:hover': {
            background: "#fff",
        }
    }
}));

export default withStyles(themeCreatStyle)(
    BulkUpdateAccount as React.ComponentType<any>
)