import React from "react";
// Customizable Area Start
import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  TextInput,
  Button,
  Image,
  ScrollView,
  TouchableWithoutFeedback,
  Platform,
} from "react-native";
import { Link, Redirect } from "react-router-dom";
const { baseURL } = require("../../../framework/src/config");
import Loader from "../../../components/src/Loader.web";
var Email: any;
var Password: any;
var jsonPayload: any;
// Customizable Area End

import EmailAccountLoginController, {
  Props,
} from "./EmailAccountLoginController";

export default class EmailAccountLoginBlock extends EmailAccountLoginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    Email = React.createRef();
    Password = React.createRef();
    let token = localStorage.getItem("token");
    if (token) {
      // this.props.navigation.navigate(1);
      this.props.navigation.navigate("RolesPermissions");
    }
    // localStorage.clear();
    // Customizable Area End
  }
  // Customizable Area Start
  handleChange = (e: any) => {
    if (e.target.name === "email") {
      this.setState({ email: e.target.value });
    } else if (e.target.name === "password") {
      this.setState({ password: e.target.value });
    }
  };

  handleValidation: any = () => {
    let isValid = true;
    const regex = /^(\+|\d)(?:.*[1-9]{1,2})(?:.*[0-9]{10,11})|(?:[A-Za-z0-9.+-]+@[a-z.+-]+\.[a-z]{2,3})$/i;
    if (Email.current.value === "") {
      this.setState({
        phoneError: (
          <p className="errorMsg">Email ID / Phone number is required</p>
        ),
      });
      isValid = false;
    } else if (regex.test(Email.current.value) === false) {
      // console.log(Email.current.value)

      this.setState({
        phoneError: (
          <p className="errorMsg">Email ID / Phone number is invalid</p>
        ),
      });
      isValid = false;
    } else {
      this.setState({ phoneError: null });
    }

    if (Password.current.value === "") {
      this.setState({
        passwordError: <p className="errorMsg">Password is required</p>,
      });
      isValid = false;
    } else {
      this.setState({ passwordError: null });
    }

    return isValid;
  };
  handleSubmit = (e: any) => {
    e.preventDefault();
    if (this.handleValidation()) {
      const emailRegex = /^[A-Za-z0-9.+-]+@[a-z.+-]+\.[a-z]{2,3}$/i;
      this.setState({ isLoading: true });
      if (emailRegex.test(this.state.email)) {
        jsonPayload = {
          data: {
            // type: "email_account",
            attributes: {
              email: this.state.email,
              password: this.state.password,
            },
          },
        };
      } else {
        jsonPayload = {
          data: {
            // type: "sms_account",
            attributes: {
              full_phone_number: this.state.email,
              password: this.state.password,
            },
          },
        };
      }
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(jsonPayload),
      };

      fetch(baseURL + "/bx_block_login/login/login_with_lms", requestOptions)
        .then((response) => {
          // if (response.status === 401) {
          //   this.setState({ isLoading: false });
          //   this.setState({
          //     passwordError: (
          //       <p className="errorMsg">Your password is incorrect</p>
          //     ),
          //   });
          //   throw new Error("Your password is incorrect");
          // } else if (response.status === 422) {
          //   this.setState({ isLoading: false });
          //   if (emailRegex.test(this.state.email)) {
          //     this.setState({
          //       phoneError: (
          //         <p className="errorMsg">Your Email ID is incorrect</p>
          //       ),
          //     });
          //   } else {
          //     this.setState({
          //       phoneError: (
          //         <p className="errorMsg">Your Mobile Number is incorrect</p>
          //       ),
          //     });
          //   }
          //   throw new Error("Your Email ID is incorrect");
          // }
          return response.json();
        })
        .then((data) => {
          if(data.errors){
            this.setState({ isLoading: false });
            if(data.errors[0].account === "Invalid Email"){
              this.setState({
                      phoneError: (
                        <p className="errorMsg">Your Email ID is incorrect</p>
                      ),
                    });
              }else if(data.errors[0].account === "Invalid Password"){
              this.setState({
                    passwordError: (
                      <p className="errorMsg">Your password is incorrect</p>
                    ),
                  });
              }else{
              this.setState({
                loginError: data.errors[0].account
                  });
                  }
          }else {
          localStorage.setItem(
            "login_token",
            data.meta.token
          );
          localStorage.setItem(
            "login_type",
            data.data.type
          );
          localStorage.setItem(
            "phoneNumber",
            this.state.email
          );
          localStorage.setItem(
            "is_otp_from_lms",
            data.is_otp_from_lms
          );
          this.setState({ isLoading: false });
          this.props.navigation.navigate("OTPInputAuth");
        }
       })
        
        .catch((error) => {
          this.setState({ isLoading: false });
          this.setState({ loginError: error.message });
        });}
  };
  togglePassword = () => {
    if (this.state.passwordType === "text") {
      this.setState({
        passwordType: "password",
      });

      return;
    }
    this.setState({
      passwordType: "text",
    });
  };
  // Customizable Area End

  render() {
    return (
      // Required for all blocks
      <ScrollView keyboardShouldPersistTaps="always">
        {/* Customizable Area Start */}
        <>
          {this.state.isLoading && (
            <Loader loading={true} positionType={"left"} />
          )}
          <div className="container">
            <div className="leftSide">
              <div className="leftContainer">
                <div>
                  <img
                    src={this.state.logoImage}
                    alt="logo"
                    className="logoImgStyle"
                  />
                  <p className="headingStyle">
                    Manage your <span className="d-block">Finance</span>
                  </p>
                </div>
                <div className="mainImg">
                  <img
                    src={this.state.mainImage}
                    alt="mainImage"
                    className="mainImageStyle"
                  />
                </div>
              </div>
            </div>
            <div className="rightSide">
              <div className="rightContainer">
                <h1>Welcome to 21K School</h1>
                <form onSubmit={this.handleSubmit}>
                  <div className="inputParent">
                    <div className="inputContainer">
                      <input
                        onInput={this.handleValidation}
                        type="text"
                        ref={Email}
                        value={this.state.email}
                        style={
                          this.state.phoneError
                            ? {
                                color: "#f15b5b",
                                border: "1px solid #ff0000",
                                outline: "0px",
                              }
                            : { color: "black", border: "none" }
                        }
                        name="email"
                        onChange={this.handleChange}
                        placeholder="Enter Email Id / Phone Number"
                      />
                      <span>
                        <img
                          src={
                            this.state.phoneError
                              ? this.state.errorUserIconImg
                              : this.state.userIconImg
                          }
                          alt="mainImage"
                          className="iconStyle"
                        />{" "}
                      </span>
                      <p>{this.state.phoneError}</p>
                    </div>
                    <div className="inputContainer">
                      <input
                        onInput={this.handleValidation}
                        type={this.state.passwordType}
                        style={
                          this.state.passwordError
                            ? {
                                color: "#f15b5b",
                                border: "1px solid #ff0000",
                                outline: "0px",
                              }
                            : { color: "black", border: "none" }
                        }
                        value={this.state.password}
                        placeholder="Password"
                        name="password"
                        ref={Password}
                        onChange={this.handleChange}
                      />
                      <span>
                        <img
                          src={
                            this.state.passwordError
                              ? this.state.errorPasswordIconImg
                              : this.state.passwordIconImg
                          }
                          alt="mainImage"
                          className=" iconStyle"
                        />
                      </span>
                      <div onClick={this.togglePassword} className="eyeToggle">
                        <img
                          src={
                            this.state.passwordType === "password"
                              ? this.state.imgPasswordVisible
                              : this.state.imgPasswordInVisible
                          }
                          alt="mainImage"
                          className=" iconStyle"
                        />
                      </div>
                      <p>{this.state.passwordError}</p>
                      {this.state.loginError ?<p className="loginErrorMsg">{this.state.loginError}</p> :null}
                    </div>
                    <div className="forgotPasswordStyle">
                      <Link to={"ForgotPassword"}>Forgot Password?</Link>
                    </div>
                    
                    <button type="submit" className="LoginButton">
                      Log In{" "}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </>
        {/* Customizable Area End */}
      </ScrollView>
    );
  }
}

// Customizable Area Start
// Customizable Area End
