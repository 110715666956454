Object.defineProperty(exports, "__esModule", {
  value: true
});

const urlConfig = require("../../../framework/src/config");

// Customizable Area Start
exports.APIBaseURL = urlConfig.baseURL;
exports.APIContentType = "application/json";

exports.FeesEndPoint = "bx_block_fee/fees";
exports.TaxesEndPoint = "bx_block_tax/taxes";
exports.AcademicAccountEndPoint = "bx_block_academic_account/academic_account";
// Customizable Area End