Object.defineProperty(exports, "__esModule", {
  value: true
});

const urlConfig = require("../../../framework/src/config");

// Customizable Area Start
exports.APIBaseURL = urlConfig.baseURL;
exports.APIContentType = "application/json";
exports.GET = "GET";
exports.POST = "POST";
exports.PUT = "PUT";
exports.DELETE = "DELETE";
exports.PATCH = "PATCH";
//exports.UsersEndPoint = "bx_block_user/users";
exports.RolesEndPoint = "bx_block_roles_permissions/roles";

exports.userManagementURL = urlConfig.baseURL;
exports.UsersEndPoint = "accounts";
// Customizable Area End