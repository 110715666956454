import React from "react";

//Customizable Area Start
import {
  View,
  Button,
  StyleSheet,
  ScrollView,
  Text,
  KeyboardAvoidingView,
  TouchableWithoutFeedback,
} from "react-native";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "../../../components/src/Loader.web";
import ForgotPasswordController, { Props } from "./ForgotPasswordController";
const { baseURL } = require("../../../framework/src/config");
var Email: any;
//Customizable Area End

export default class ForgotPassword extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
    //Customizable Area Start
    Email = React.createRef();
    //Customizable Area End
  }
  //Customizable Area Start
  handleValidation: any = () => {
    let isValid = true;
    const regex =/^(\+|\d)(?:.*[1-9]{1,2})(?:.*[0-9]{10})$/i;
   // /^\+(?:[0-9]●?){7,15}[0-9]$/i;
    if (Email.current.value === "") {
      this.setState({
        emailError: <p className="errorMsg">Mobile Number is required</p>,
      });
      isValid = false;
    } else if (regex.test(Email.current.value) === false) {
      this.setState({
        emailError: <p className="errorMsg">Your Mobile Number is invalid</p>,
        isLoading: false,
      });
      isValid = false;
    } else {
      this.setState({ emailError: null });
    }
    return isValid;
  };
  handleSubmit = (e: any) => {
    e.preventDefault();
    if (this.handleValidation()) {
      this.setState({ isLoading: true });

      const jsonPayload = {
        data: {
          type: "sms_account",
          attributes: {
            full_phone_number: this.state.emailValue,
          },
        },
      };

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(jsonPayload),
      };

      fetch(baseURL + "/otps/send_otp_forgot_password_lms", requestOptions)
        .then((response) => response.json())
        .then((data) => {
          if (data.errors) {
            this.setState({ isLoading: false });
            this.setState({
              emailError: <p className="errorMsg">{data.errors[0].full_phone_number|| data.errors[0].otp}</p>,
            });
          } else {
            localStorage.setItem("forgot_token", data.meta.token);
            localStorage.setItem(
              "Phone_number",
              data.data.attributes.full_phone_number
            );
            localStorage.setItem(
              "is_otp_from_lms",
              data.is_otp_from_lms
            );
            toast.success("OTP send successfully");
            this.setState({ isLoading: false });
            this.props.navigation.navigate("ForgotPasswordOTP");
          }
        })
        .catch((error) => {
          this.setState({ isLoading: false });
        });
    }
  };
  handleChange = (e: any) => {
    this.setState({ emailValue: e.target.value });
    let length = this.state.emailValue.length;
  };
  //Customizable Area End

  render() {
    return (
      <ScrollView keyboardShouldPersistTaps="always">
        {/* Customizable Area Start */}
        {this.state.isLoading && <Loader positionType={'left'} loading={true} /> }
        <div className="container">
          <div className="leftSide">
            <div className="leftContainer">
              <img
                src={this.state.logoImage}
                alt="logo"
                className="logoImgStyle"
              />
              <p className="headingStyle">
                Manage your <span className="d-block">Finance</span>
              </p>

              <div className="mainImg">
                <img
                  src={this.state.mainImage}
                  alt="mainImage"
                  className="mainImageStyle"
                />
              </div>
            </div>
          </div>
          <div className="rightSide">
            <div className="commonRightContainer">
              <h1>Forgot Password?</h1>
              <p className="forgotParaStyle">
                Enter your registered mobile number below to receive the
                password <span>reset instructions.</span>
              </p>
              <form onSubmit={this.handleSubmit}>
                <div className="forgotInputParent">
                  <div className="inputContainer">
                    <input
                      onInput={this.handleValidation}
                      ref={Email}
                      type="number"
                      placeholder="Mobile Number"
                      value={this.state.emailValue}
                      onChange={this.handleChange}
                      style={
                        this.state.emailError
                          ? {
                              color: "#f15b5b",
                              border: "1px solid #ff0000",
                              outline: "0px",
                            }
                          : { color: "black", border: "none" }
                      }
                    />
                    <span>
                      <img
                        src={
                          this.state.emailError
                            ? this.state.errorEmailIconImg
                            : this.state.emailIconImg
                        }
                        alt="mainImage"
                        className="iconStyle"
                      />{" "}
                    </span>
                    <p>{this.state.emailError}</p>
                  </div>
                  <button type="submit" className="LoginButton">
                    Send
                  </button>
                </div>
                <div className="backToLoginStyle">
                  <Link to={"/"}>Back to Login</Link>
                </div>
              </form>
            </div>
          </div>
        </div>
        {/* Customizable Area End */}
      </ScrollView>
    );
  }
}

// Customizable Area Start
const styles = StyleSheet.create({});
// Customizable Area End
