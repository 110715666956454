import React, { Component } from 'react';
// Customizable Area Start

import {
  makeStyles,
  Theme,
  createStyles,
  withStyles,

} from "@material-ui/core/styles";
import { Styles } from "@material-ui/styles";
import {
  FormLabel,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from "@material-ui/core";
import DateFnsUtils from '@date-io/date-fns';
// import MuiPickersUtilsProvider from "@material-ui/pickers/MuiPickersUtilsProvider";
import 'date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import FormControl from '@material-ui/core/FormControl';
import OutlinedSelect from "../../../components/src/OutlinedSelect";
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {feeAssignmentData,installmentMultiFieldAttributes,installmentsDetailsAttributes} from './FeeAssignmentController';
import { dateFormateToDDMMYY } from "../../../framework/src/Utilities";
import CommonDatePicker from '../../CommonLayout/HRMSCommons/src/CommonDatePicker'
interface feeStructureInitialField {
  id: number,
  amount: string,
  tax_ids: string[],
}

interface installmentCycleField {
  name: string,
  due_date: any,
  fine_id: number
}

interface installmentAttributes {
  name: string,
  installment_code: string,
  total_amount: number,
  concession: any,
  concession_type: string,
  discounted_amount: number,
  bill_duration_no: any,
  bill_duration: string,
  billing_cycle: number|null,
  payment_collection: string,
  invoice_template_id: number,
  email_template_id: number,
  payment_option: string,
  installment_details_attributes: installmentCycleField[],
}

interface feeAssignmentFormstate {
  feeAssignmentType: string;
  academic_year_id: number;
  grade_id: number;
  division_id: number;
  name: string;
  is_class_and_division: boolean,
  is_group: boolean,
  is_class: boolean,
  grade: string,
  division: string,
  fee_type: string,
  sub_fee_structures_attributes: feeStructureInitialField[],
  installments: installmentAttributes,
  sum_total_amount: number,

}

type MyProps = {
  handleInstallmentForm: (e: any) => void, 
  feeAssignmentFormstate: feeAssignmentFormstate,
  invoiceTemplateList: [],
  emailTemplateList: [],
  fineList: [],
  maxDueDate:any,
  minDueDate:any,
  handleInstallmentCycleField: any,
  feeInstallmentNameError:string,
  feeInstallmentDurationError:string,
  feeInstallmentBillDurationError:string,
  feeInstallmentBillingCycleError:string,
  feeInstallmentError:any,
  viewForm: boolean,
  feeAssignmentData:feeAssignmentData,
  installmentPlanDetailsData:installmentMultiFieldAttributes|null,
  installPlanAction: string,
  updatePlanIndex: string,
  feeInstallmentConcessionError:string,
  feeInstallmentConcessionTypeError:string
  handleDateChange:any,
  calculateWeek : (billDurationNo:number,i:number) => any
  calculateMonth :(billDurationNo:number,i:number) => any
  calculateYear :(billDurationNo:number,i:number) => any
};

// Customizable Area End

export default class InstallmentForm extends Component<MyProps> {

  render() {
    // Customizable Area Start

    const { feeAssignmentFormstate, fineList,handleInstallmentForm,handleInstallmentCycleField,invoiceTemplateList,emailTemplateList,feeInstallmentNameError,feeInstallmentDurationError,feeInstallmentBillDurationError, feeInstallmentBillingCycleError,feeInstallmentError,
    viewForm,feeAssignmentData,installmentPlanDetailsData,installPlanAction,
    updatePlanIndex,feeInstallmentConcessionError,feeInstallmentConcessionTypeError} = this.props;
    console.log('installPlanAction',installPlanAction);
    console.log('updatePlanIndex',updatePlanIndex);

    // Customizable Area End

    return (
      // Customizable Area Start

      viewForm === true && installmentPlanDetailsData ? 
      <>
      <Grid
          container
          spacing={4}
          style={{
            marginTop: "10px",
            marginBottom: "10px",
            borderRadius: 10,
          }}
        >
          <Grid style={{ color: "#465872", fontWeight: "bold", marginBottom: "8px" }} xs={4} item>Installment Name*
            <Typography style={{color: "#7e7d7d", marginTop: "16px"}}>{installmentPlanDetailsData?.attributes.name}</Typography>
          </Grid>
          <Grid style={{ color: "#465872", fontWeight: "bold", marginBottom: "8px" }} xs={4} item>Installment Code*
            <Typography style={{color: "#7e7d7d", marginTop: "16px"}}>{installmentPlanDetailsData?.attributes.installment_code}</Typography>
          </Grid>
          <Grid style={{ color: "#465872", fontWeight: "bold", marginBottom: "8px" }} xs={4} item>Total Amount (Without Tax)
            <Typography style={{color: "#7e7d7d", marginTop: "16px"}}>{installmentPlanDetailsData?.attributes.total_amount}</Typography>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={4}
          style={{
            marginTop: "10px",
            marginBottom: "10px",
            borderRadius: 10,
          }}
        >
          <Grid style={{ color: "#465872", fontWeight: "bold", marginBottom: "8px"}} item xs={4}>Concession
            <Typography style={{color: "#7e7d7d", marginTop: "16px"}}>{installmentPlanDetailsData?.attributes.concession}<span style={{paddingLeft: "36px"}}>{installmentPlanDetailsData?.attributes.concession_type}</span></Typography>
          </Grid>
          <Grid style={{ color: "#465872", fontWeight: "bold", marginBottom: "8px" }} item xs={4}>Total Amount (After Concession)
            <Typography style={{color: "#7e7d7d", marginTop: "16px"}}>{installmentPlanDetailsData?.attributes.discounted_amount}</Typography>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={4}
          style={{
            marginTop: "10px",
            marginBottom: "8px"
          }}
        >
          <Grid style={{ color: "#465872", fontWeight: "bold", marginBottom: "8px" }} item xs={4}>Bill Every*
            <Typography style={{color: "#7e7d7d", marginTop: "16px"}}>{installmentPlanDetailsData?.attributes.bill_duration_no}<span style={{paddingLeft: "36px"}}>{installmentPlanDetailsData?.attributes.bill_duration}</span></Typography>
          </Grid>
          <Grid style={{ color: "#465872", fontWeight: "bold", marginBottom: "8px" }} item xs={4}>Billing Cycles*
            <Typography style={{color: "#7e7d7d", marginTop: "16px"}}>{installmentPlanDetailsData?.attributes.billing_cycle}</Typography>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={3}
          style={{
            marginTop: "10px",
            marginBottom: "8px"
          }}
        >
          <Grid style={{ color: "#ea5157", fontSize: "18px",fontWeight: "bold", marginBottom: "8px"}} item xs={6}>Payment Collection
            <RadioGroup style={{marginTop: "24px"}} row aria-label="gender" value={installmentPlanDetailsData.attributes.payment_collection}>
                <FormControlLabel
                  disabled
                  style={{
                    color: "#3f5071"
                  }}
                  value="manual_due_dates"
                  control={<Radio />}
                  label="Manual Due dates"
                />
                <FormControlLabel
                  disabled
                  style={{
                    color: "#3f5071"
                  }}
                  value="equally_distributes"
                  control={<Radio />}
                  label="Equally distributes"
                />
            </RadioGroup>
          </Grid>
        </Grid>
        {installmentPlanDetailsData.attributes.installment_details.length > 0 &&
          <Grid
            component={Paper}
            style={{
              // position: "relative",
              // flexGrow: 1,

              marginTop: "14px",
              boxShadow: "0px 5px 10px 0px #d9d9d9",
              // height: "300px !important",
              borderRadius: 10
            }}
          >
            <Grid
              style={{
                margin: "0px",
                display: "flex",
                alignItems: "center",
                borderRadius: 5,
                backgroundColor: '#e2e2e2',//"#FCF3D4",
                padding: "14px"
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <Typography variant="subtitle1" style={{ fontWeight: "bold", color: "#44556c" }}>
                    Installment Name
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="subtitle1" style={{ fontWeight: "bold", color: "#44556c" }}>
                    Due Date
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="subtitle1" style={{ fontWeight: "bold", color: "#44556c" }}>
                    Fine
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            {installmentPlanDetailsData.attributes.installment_details.map((fieldData: installmentsDetailsAttributes, indx: number) => (
               <Grid key={indx}
               component={Paper}
                style={{
                  marginBottom: "2px",
                  display: "flex",
                  padding: "14px"
                }}
              >
                 <Grid container spacing={2}>
                  <Grid item xs={4} >
                    <FormControl style={{ minWidth: "100%" }}>
                      <TextField
                        disabled
                        style={{ width: "100%", backgroundColor: "#f1f1f1" }}
                        value={fieldData.name}
                        type={'text'}
                        placeholder={`Installment Name`}
                        variant="outlined"
                        name={'name'}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl style={{ minWidth: "100%" }}>
                    <TextField
                        disabled
                        value={dateFormateToDDMMYY(new Date(fieldData.due_date))}
                        style={{ width: "100%", backgroundColor: "#f1f1f1" }}
                        color={'primary'}
                        type={'text'}
                        placeholder={`Due Date`}
                        variant="outlined"
                        name={'due_date'}
                    />
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                  <TextField
                        disabled
                        style={{ width: "100%", backgroundColor: "#f1f1f1" }}
                        value={fieldData.fine_name}
                        type={'text'}
                        placeholder={`Fine Name`}
                        variant="outlined"
                        name={'name'}
                      />
                  </Grid>
                </Grid>
              </Grid>
            ))
            }
          </Grid>
        }
        <Grid
          container
          spacing={2}
          style={{
            marginTop: "10px",
            marginBottom: "8px"
          }}
        >
          <Grid item xs={12}><Typography style={{ color: "#465872", fontSize: "18px", fontWeight: "bold", marginBottom: "8px" }} variant="subtitle1" > Templates </Typography>
          </Grid>
          <Grid style={{ color: "#465872", fontWeight: "bold", marginBottom: "8px" }} xs={4} item>Invoice
            <Typography style={{color: "#7e7d7d", marginTop: "16px"}}>{installmentPlanDetailsData?.attributes.invoice_template_name}</Typography>
          </Grid>
          <Grid style={{ color: "#465872", fontWeight: "bold", marginBottom: "8px" }} xs={4} item>Email
            <Typography style={{color: "#7e7d7d", marginTop: "16px"}}>{installmentPlanDetailsData?.attributes.email_template_name}</Typography>
          </Grid>
        </Grid>
      </>
      :
      <>
        <Grid
          container
          spacing={4}
          style={{
            marginTop: "10px",
            marginBottom: "10px",
            borderRadius: 10,
          }}
        >
          <Grid item xs={4}>
            <FormControl style={{ minWidth: "100%" }}>
              <FormLabel style={{ marginBottom: "8px" }}>
                Installment Name*
              </FormLabel>
              <TextField
                value={feeAssignmentFormstate.installments.name}
                type={'text'}
                placeholder={`Fee Structure Name`}
                variant="outlined"
                name={'name'}
                onChange={handleInstallmentForm}
              />
              {feeInstallmentNameError && (
              <p className="errorMsg2">{feeInstallmentNameError}</p>
            )}
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl style={{ minWidth: "100%" }}>
              <FormLabel style={{ marginBottom: "8px" }}>
                Installment Code
              </FormLabel>
              <TextField
                value={feeAssignmentFormstate.installments.installment_code}
                type={'text'}
                placeholder={`Installment Code`}
                variant="outlined"
                name={'installment_code'}
                onChange={handleInstallmentForm}
              />
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl style={{ minWidth: "100%" }}>
              <FormLabel style={{ marginBottom: "8px" }}>
                Total Amount (Without Tax)
              </FormLabel>
              <TextField
                disabled
                style={{ width: "100%", backgroundColor: "#f1f1f1" }}
                value={feeAssignmentFormstate.sum_total_amount}
                type={'text'}
                variant="outlined"
                name={'total_amount'}
                onChange={handleInstallmentForm}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={4}
          style={{
            marginTop: "10px",
            marginBottom: "10px",
            borderRadius: 10,
          }}
        >
          <Grid style={{
            padding: "12px 0px 12px 12px",
          }} item xs={2}>
            <FormControl>
              <FormLabel style={{ marginBottom: "8px" }}>
                Concession
              </FormLabel>
              <TextField
                value={feeAssignmentFormstate.installments.concession}
                type={'number'}
                placeholder={`Concession`}
                variant="outlined"
                name={'concession'}
                onChange={handleInstallmentForm}
              />
            </FormControl>
            {feeInstallmentConcessionError && (
              <p className="errorMsg2">{feeInstallmentConcessionError}</p>
            )}
          </Grid>
          <Grid style={{
            padding: "12px 12px 12px 0px",
          }} item xs={2}>
            <FormControl>
              <FormLabel style={{ marginBottom: "24px" }}>
              </FormLabel>
              <OutlinedSelect
                placeholderTitle="Select Type"
                val={feeAssignmentFormstate.installments.concession_type}
                options={[{ id: "percentage", name: "Percentage" }, { id: "amount", name: "Amount" }]}
                label=""
                name={'concession_type'}
                handleChange={handleInstallmentForm}
                styleOverrides={{ border: "none", width: '100%', marginBottom: "0px" }}
                labelStyle={{ marginBottom: "0px" }} 
                className={undefined}        
                />
            </FormControl>
            {feeInstallmentConcessionTypeError && (
              <p className="errorMsg2">{feeInstallmentConcessionTypeError}</p>
            )}
          </Grid>
          <Grid item xs={4}>
            <FormControl style={{ minWidth: "100%" }}>
              <FormLabel style={{ marginBottom: "8px" }}>
                Total Amount (After Concession)
              </FormLabel>
              <TextField
                disabled
                style={{ width: "100%", backgroundColor: "#f1f1f1" }}
                value={feeAssignmentFormstate.installments.concession  ? feeAssignmentFormstate.installments.discounted_amount: feeAssignmentFormstate.sum_total_amount}
                type={'text'}
                variant="outlined"
                name={'discounted_amount'}
                onChange={handleInstallmentForm}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={4}
          style={{
            marginTop: "10px",
            marginBottom: "8px"
          }}
        >

          <Grid item xs={2}>
            <Typography style={{ fontWeight: 600, color: "#3f5371" }} variant="subtitle1" >  Bill Every* </Typography>
            <TextField
              value={feeAssignmentFormstate.installments.bill_duration_no}
              type={'number'}
              placeholder={`Bill Duration No`}
              variant="outlined"
              name={'bill_duration_no'}
              onChange={handleInstallmentForm}
            />
            {feeInstallmentDurationError && (
              <p className="errorMsg2">{feeInstallmentDurationError}</p>
            )}
          </Grid>
          <Grid item xs={2}>
            <Typography style={{ fontWeight: 600, color: "#3f5371", marginTop: "27px" }} variant="subtitle1" > </Typography>
            <OutlinedSelect
              placeholderTitle="Select Duration"
              val={feeAssignmentFormstate.installments.bill_duration}
              options={[{ id: "weeks", name: "Weeks (S)" }, { id: "months", name: "Months (S)" }, { id: "year", name: "Year (S)" }]}
              label=""
              name={'bill_duration'}
              handleChange={handleInstallmentForm}
              styleOverrides={{ border: "none", width: '100%' }}
              labelStyle={{ marginBottom: "0px" }} className={undefined}            />
              {feeInstallmentBillDurationError && (
              <p className="errorMsg2">{feeInstallmentBillDurationError}</p>
            )}
          </Grid>

          <Grid item xs={4}>
            <Typography style={{ fontWeight: 600, color: "#3f5371" }} variant="subtitle1" >Billing Cycles*</Typography>
            <TextField
              style={{ width: "100%" }}
              value={feeAssignmentFormstate.installments.billing_cycle}
              type={'number'}
              variant="outlined"
              name={'billing_cycle'}
              onChange={handleInstallmentForm}
            />
            {feeInstallmentBillingCycleError && (
              <p className="errorMsg2">{feeInstallmentBillingCycleError}</p>
            )}
          </Grid>
        </Grid>
        <Grid
          container
          spacing={3}
          style={{
            marginTop: "10px",
            marginBottom: "8px"
          }}
        >
          <Grid item xs={6}>
            <FormControl component="fieldset">
              <FormLabel style={{ marginBottom: "8px" }}>
                Payment Collection
              </FormLabel>
              <RadioGroup row aria-label="gender" value={feeAssignmentFormstate.installments.payment_collection} onChange={handleInstallmentForm} name="payment_collection">
                <FormControlLabel
                  style={{
                    color: "#3f5071"
                  }}
                  value="manual_due_dates"
                  control={<Radio />}
                  label="Manual Due dates"
                />
                <FormControlLabel
                  style={{
                    color: "#3f5071"
                  }}
                  value="equally_distributes"
                  control={<Radio />}
                  label="Equally distributes"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
        {feeAssignmentFormstate.installments.installment_details_attributes.length > 0 &&
          <Grid
            component={Paper}
            style={{
              // position: "relative",
              // flexGrow: 1,

              marginTop: "14px",
              boxShadow: "0px 5px 10px 0px #d9d9d9",
              // height: "300px !important",
              borderRadius: 10
            }}
          >
            <Grid
              style={{
                margin: "0px",
                display: "flex",
                alignItems: "center",
                borderRadius: 5,
                backgroundColor: "#FCF3D4",
                padding: "14px"
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <Typography variant="subtitle1" style={{ fontWeight: "bold", color: "#44556c" }}>
                    Installment Name
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="subtitle1" style={{ fontWeight: "bold", color: "#44556c" }}>
                    Due Date
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="subtitle1" style={{ fontWeight: "bold", color: "#44556c" }}>
                    Fine
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            {feeAssignmentFormstate.installments.installment_details_attributes.map((fieldData: installmentCycleField, indx: any) => {
            console.log("indexxxxx",indx)
            const {payment_collection,installment_details_attributes,bill_duration,bill_duration_no}=feeAssignmentFormstate.installments;
            const {installments}=feeAssignmentFormstate;
            let maxDate:any;
            let minDate:any;
            if(installment_details_attributes[0].due_date && indx > 0){
            if(installments.bill_duration == "weeks"){
              const curremtIndx = indx-1
              maxDate=  this.props.calculateWeek(bill_duration_no,indx)
              minDate = new Date(installment_details_attributes[0].due_date.getTime() + indx*7*bill_duration_no * 24 * 60 * 60 * 1000);
             }
             else if(installments.bill_duration == "months"){
              const initialMonth = new Date(installment_details_attributes[indx].due_date);
              const nextMonth  = initialMonth.setMonth(initialMonth.getMonth()+indx*bill_duration_no);
              maxDate = new Date(nextMonth);
              let min = new Date(installment_details_attributes[0].due_date.getFullYear(), installment_details_attributes[0].due_date.getMonth() + indx*bill_duration_no, 1);
              // minDate=new Date(min);
            }else{
              maxDate= this.props.calculateYear(bill_duration_no,indx)
              // minDate = new Date(installment_details_attributes[0].due_date.setFullYear(installment_details_attributes[0].due_date.getFullYear()+indx*bill_duration_no,1));
             }
            }
            
              return (
               <Grid key={indx}
                style={{
                  margin: "0px",
                  display: "flex",
                  padding: "14px"
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={4} >
                    <FormControl style={{ minWidth: "100%" }}>
                      <TextField
                        disabled
                        style={{ backgroundColor: "#f1f1f1" }}
                        value={fieldData.name}
                        type={'text'}
                        placeholder={`Installment Name`}
                        variant="outlined"
                        name={'name'}
                       onChange={(evt: React.ChangeEvent<HTMLInputElement>) => handleInstallmentCycleField(evt, indx)}
                      />
                      {feeInstallmentError[indx].installmentNameError && (
              <p style={{ color: "red",    marginTop: "2px",
              fontSize: "14px",
              marginLeft: "3px" }}>{feeInstallmentError[indx].installmentNameError}</p>
            )}
                    </FormControl>
                  </Grid>
                  
                  <Grid item xs={4}>
                    <FormControl style={{ minWidth: "100%" }}>
                      <CommonDatePicker 
                      disabled={indx === 0 ?false: payment_collection== "manual_due_dates" &&installment_details_attributes[0].due_date !==null && indx > 0 ?false:true}
                      name="due_date"
                      placeholder={"Due Date"}
                      // maxDate={payment_collection== "manual_due_dates" && maxDate &&indx!==0? new Date(maxDate):new Date("01/01/8000")}
                      // minDate={payment_collection== "manual_due_dates" && minDate &&indx!==0? new Date(minDate):new Date()}
                      handleChange={this.props.handleDateChange(indx)}
                      value={fieldData.due_date}
                      />
                    {feeInstallmentError[indx].installmentNameDueDateError && (
                        <p style={{ color: "red",    marginTop: "2px",
                        fontSize: "14px",
                        marginLeft: "3px" }}>{feeInstallmentError[indx].installmentNameDueDateError}</p>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <OutlinedSelect
                      placeholderTitle="Select Fine"
                      val={fieldData.fine_id}
                      options={fineList}
                      label=""
                      labelStyle={{ marginBottom: "0px" }}
                      name={'fine_id'}
                      handleChange={(evt: React.ChangeEvent<HTMLInputElement>) => handleInstallmentCycleField(evt, indx)} 
                      className={undefined}                    
                      />
                      {feeInstallmentError[indx].installmentFineError && (
                        <p style={{ color: "red",    marginTop: "2px",
                        fontSize: "14px",
                        marginLeft: "3px" }}>{feeInstallmentError[indx].installmentFineError}</p>
                      )}
                  </Grid>
                </Grid>
              </Grid>
           )})
            }
          </Grid>
        }
        <Grid
          container
          spacing={2}
          style={{
            marginTop: "10px",
            marginBottom: "8px"
          }}
        >
          <Grid item xs={12}><Typography style={{ fontWeight: 600, color: "#3f5371" }} variant="subtitle1" > Templates </Typography></Grid>
          
          <Grid item xs={4}>
            <OutlinedSelect
              placeholderTitle="Select Fee Name"
              val={feeAssignmentFormstate.installments.invoice_template_id}
              options={invoiceTemplateList}
              label="Invoice"
              name={'invoice_template_id'}
              handleChange={handleInstallmentForm} className={undefined}            />
          </Grid>
          <Grid item xs={4}>
            <OutlinedSelect
              placeholderTitle="Select Fee Name"
              val={feeAssignmentFormstate.installments.email_template_id}
              options={emailTemplateList}
              label="Email"
              name={'email_template_id'}
              handleChange={handleInstallmentForm} className={undefined}            />
          </Grid>

        </Grid>
        <Grid
          container
          spacing={3}
          style={{
            marginTop: "10px",
            marginBottom: "8px"
          }}
        >
          <Grid item xs={6}>
            <FormControl component="fieldset">
              <FormLabel style={{ marginBottom: "8px" }}>
                Select an online payment option
              </FormLabel>
              <RadioGroup row aria-label="gender" value={feeAssignmentFormstate.installments.payment_option} onChange={handleInstallmentForm} name="payment_option">
                <FormControlLabel
                  style={{
                    color: "#3f5071"
                  }}
                  value="razorpay"
                  control={<Radio />}
                  label="Razorpay"
                />
                <FormControlLabel
                  style={{
                    color: "#3f5071"
                  }}
                  value="payu"
                  control={<Radio />}
                  label="Payu"
                />
                <FormControlLabel
                  style={{
                    color: "#3f5071"
                  }}
                  value="21k_payment_service"
                  control={<Radio />}
                  label="21K payment service"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
      </>
      // Customizable Area End
    );
  }
}
