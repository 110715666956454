import { IBlock } from '../../../../framework/src/IBlock';
import { Message } from '../../../../framework/src/Message';
import { BlockComponent } from '../../../../framework/src/BlockComponent';
import MessageEnum, {
  getName
} from '../../../../framework/src/Messages/MessageEnum';
import { runEngine } from '../../../../framework/src/RunEngine';

// Customizable Area Start
import { downloadIcon } from "./assets";
// Customizable Area End

export const configJSON = require('./config');

export interface Props {
  // Customizable Area Start
  classes: any;
  visible: boolean;
  handleClose: any;
  handleSubmit: (file: any, fileUrl: string) => void;
  title: string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  enableField: boolean;
  importFile: any;
  fileUrl: string;
  fileError: string;
  downloadIcon: any;

  // Customizable Area End
}

interface SS {
  id: any;
}

export default class CustomUploadModalController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [getName(MessageEnum.AccoutLoginSuccess)];

    this.state = {
      enableField: false,
      importFile: null,
      fileError: "",
      downloadIcon: downloadIcon,
      fileUrl: ""
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start
  fileInputRef: HTMLInputElement;
  handleFileChange = (files: any[]) => {
    var reader = new FileReader();
    reader.addEventListener(
      "load",
      () => {
        this.setState({ fileUrl: reader.result as string });
      },
      false 
    );
    if (files[0]) {
      reader.readAsDataURL(files[0]);
    }
    this.setState({ importFile: files[0] });
    this.handleValidation(files[0]);
  };

  handleDrop = (event: any) => {
    event.preventDefault();
    if (event.dataTransfer.items) {
      [...event.dataTransfer.items].forEach((item, i) => {
        if (item.kind === "file") {
          const file = item.getAsFile();
          this.handleFileChange([file]);
        }
      });
    } else {
      [...event.dataTransfer.files].forEach((file, i) => {
        this.handleFileChange([file]);
      });
    }
  };

  handleValidation = (fileState: any) => {
    let isValid = true;

    if (fileState.length < 1) {
      this.setState({
        fileError: "File is required"
      });
      isValid = false;
    } else if (
      fileState.type !== "image/png" &&
      fileState.type !== "image/jpg" &&
      fileState.type !== "image/jpeg"
    ) {
      this.setState({
        fileError: "Only an image file can be upload"
      });
      isValid = false;
    } else {
      this.setState({ fileError: "" });
    }

    return isValid;
  };
  // Customizable Area End
}
