import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";

// Customizable Area Start
import {
  imgPasswordInVisible,
  imgPasswordVisible,
  imgDummyUser,
  countryIcon,
  refreshIcon,
  supportIcon,
  notificationIcon,
  fontIcon,
  leftArrowIcon
} from "./assets";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  hideSearchBar?: boolean;
  classes: any;
  navigation: any;
  open: boolean;
  drawerWidth: number;
  handleSearchChange?: (evt: any) => void | undefined;
  searchKeyword?: string | undefined;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  dummyUserIconImg: any;
  countryIconImg: any;
  refreshIconImg: any;
  notificationIconImg: any;
  supportIconImg: any;
  fontIconImg: any;
  leftArrowIcon: any;
  anchorEl: null;
  showCollabsibleIcons: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class HeaderController extends BlockComponent<Props, S, SS> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [getName(MessageEnum.AccoutLoginSuccess)];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      dummyUserIconImg: imgDummyUser,
      countryIconImg: countryIcon,
      refreshIconImg: refreshIcon,
      notificationIconImg: notificationIcon,
      supportIconImg: supportIcon,
      fontIconImg: fontIcon,
      leftArrowIcon: leftArrowIcon,
      anchorEl: null,
      showCollabsibleIcons: false
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }
    // Customizable Area End
  }

  // Customizable Area Start
  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address"
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed()
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  handleAnchorClick = (event: any) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleArrowButtonClick = () => {
    this.setState({ showCollabsibleIcons: !this.state.showCollabsibleIcons });
  };

  handleAnchorClose = () => {
    this.setState({ anchorEl: null });
  };

  handleLogout = () => {
    localStorage.clear();
    this.props.navigation.navigate("EmailAccountLoginBlock");
  };

  // Customizable Area End
}
