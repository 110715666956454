import React from "react";
// Customizable Area Start
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { withStyles, Theme } from "@material-ui/core/styles";
import { formSubmitBtn, formCancelBtn } from "../../Layout/src/color";

import { Styles } from "@material-ui/styles";
// Customizable Area End
// Customizable Area Start
import CustomDeleteModalController, {
  Props
} from "./CustomDeleteModalController";

import { StyleSheet, Platform } from "react-native";

const useStyles: Styles<Theme, {}, string> = (theme: Theme) => ({
  dialogPaper: {
    maxWidth: "55vh",
    borderRadius: "26px"
  }
});
// Customizable Area End
class CustomDeleteModal extends CustomDeleteModalController {
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const {
      open,
      DialogueDeletetitle,
      handleClose,
      handleSubmit,
      submitActionText,
      modalIcon,
      dialogText
    } = this.props;
    // Customizable Area End
    return (
      // Customizable Area Start
      //Merge Engine DefaultContainer
      <>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="simple-dialog-title"
          classes={{ paper: this.props.classes.dialogPaper }}
        >
          <DialogContent style={{ textAlign: "center" }}>
            <div
              style={{
                fontWeight: "bold",
                fontSize: "18px",
                borderBottom: "2px solid #f1f1f1",
                paddingBottom: "14px"
              }}
            >
              {DialogueDeletetitle}
            </div>
            <img
              style={{ paddingTop: "14px" }}
              height="117px"
              src={modalIcon ? modalIcon : this.state.deleteBoxIcon}
            />
            <DialogTitle style={{ color: "#44556c" }}>
              {this.props.msgHandle
                ? this.props.msgHandle === "multiple"
                  ? "Are you sure you want to delete the records?"
                  : "Are you sure you want to delete the record?"
                : dialogText
                ? dialogText
                : "Are you sure you want to delete the record?"}
            </DialogTitle>
          </DialogContent>
          <DialogActions
            style={{
              justifyContent: "flex-start",
              margin: "0px 48px 48px 48px",
              padding: "22px"
            }}
          >
            <Button
              style={{
                boxShadow: "none",
                textTransform: "none",
                fontSize: 16,
                padding: "8px 40px",
                border: "1px solid",
                lineHeight: 1.5,
                backgroundColor: formCancelBtn,
                borderColor: "#ea5c6b",
                color: "#44556c",
                marginRight: "14px",
                borderRadius: "8px"
              }}
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              style={{
                boxShadow: "none",
                textTransform: "none",
                fontSize: 16,
                padding: "8px 40px",
                border: "1px solid",
                lineHeight: 1.5,
                background: formSubmitBtn,
                //borderColor: '#ea5c6b',
                color: "white",
                borderRadius: "8px"
              }}
              onClick={handleSubmit}
            >
              {submitActionText ? submitActionText : "Delete"}
            </Button>
          </DialogActions>
        </Dialog>
      </>
      //Merge Engine End DefaultContainer
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 16,
    marginLeft: "auto",
    marginRight: "auto",
    width: Platform.OS === "web" ? "75%" : "100%",
    maxWidth: 650,
    backgroundColor: "#ffffffff"
  }
});

// Customizable Area End

export default withStyles(useStyles)(CustomDeleteModal);