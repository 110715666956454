import React from "react";
// Customizable Area Start
import {
    TextField,
    Typography
} from "@material-ui/core";
import { withStyles, } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CustomBulkUpdateModal from '../../CommonLayout/CustomBulkUpdateModal/src/CustomBulkUpdateModal.web';
import OutlinedSelect from "../../../components/src/OutlinedSelect";

import BulkUpdateRolesController, {
    Props,
} from "./BulkUpdateRolesController";

interface roleMenuSubmenu {
    id: number;
    title: string;
    permission_category_id: number,
    fullAccessStatus: boolean,
    viewStatus: boolean,
    editStatus: boolean,
    createStatus: boolean,
    deleteStatus: boolean,
    approveStatus: boolean
}

interface roleMenuAttributes {
    id: number;
    title: string;
    menus: roleMenuSubmenu[];
}

interface roleMenuData {
    attributes: roleMenuAttributes;
}
// Customizable Area End
class BulkUpdateRoles extends BulkUpdateRolesController {

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    render() {
        // Customizable Area Start
        const { open, handleClose, rolebulkUpdateerrorMessage } = this.props;
        const { status, statusError } = this.state;
        // Customizable Area End
        return (
            // Customizable Area Start
            <CustomBulkUpdateModal
                title={"Bulk Update"}
                handleSubmit={this.handleSubmit}
                buttonTitle={"Update"}
                open={open}
                handleClose={(evt: object) => handleClose("bulkUpdate", false)}
                errormsg={rolebulkUpdateerrorMessage ? rolebulkUpdateerrorMessage : ''}
            >
                <Grid spacing={3} container>
                    <Grid item xs={6}>
                        <OutlinedSelect
                            placeholderTitle="Please Select"
                            val={status}
                            options={[{ id: "active", name: "Active" }, { id: "inactive", name: "Inactive" }]}
                            label="Status"
                            name={'status'}
                            handleChange={this.handleChange}
                            className={statusError && !status ? "errorSelect":"withoutErrorSelect" }
                        />
                            {statusError && !status &&
                            <p className="errorMsg2">{statusError}</p>
                            }
                    </Grid>
                </Grid>
            </CustomBulkUpdateModal>
            // Customizable Area End
        );
    }
}

export const themeCreatStyle = ((theme: any) => ({

    background_color: {
        backgroundColor: theme.palette.primary.main
    },
    title_font: {
        color: theme.props.titleColor,
        fontFamily: theme.props.titleFont
    },
    subTitle_font: {
        color: theme.props.subtitleColor,
        fontFamily: theme.props.subtitleFont
    },
    heading_font: {
        color: theme.props.headingColor,
        fontFamily: theme.props.headingFont
    },
    subHeading_font: {
        color: theme.props.subheadingColor,
        fontFamily: theme.props.subheadingFont
    },
    bodyText_font: {
        color: theme.props.textColor,
        fontFamily: theme.props.textFont
    },
    button_color: {
        backgroundColor: theme.props.buttonColor,
        color: "white",
    },
    icon_color: {
        color: theme.props.iconColor
    },
    button_color_disabled: {
        color: theme.props.buttonColor,
        backgroundColor: "white",
        border: `1px solid ${theme.props.buttonColor}`,
        '&:hover': {
            background: "#fff",
        }
    }
}));

export default withStyles(themeCreatStyle)(
    BulkUpdateRoles as React.ComponentType<any>
)
