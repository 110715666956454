import React from "react";
import { Linking, ScrollView } from "react-native";

// Customizable Area Start
import { Link } from "react-router-dom";
import OtpInput from "react-otp-input";
import { ToastContainer, toast, cssTransition } from "react-toastify";
var phoneNumber: any;
let myInterval: any;
const { baseURL } = require("../../../framework/src/config");
let firstNumber: any;
let secondNumber: any;
let thirdNumber: any;
let Otptoken:any;
let login_type:any;
let is_otp_from_lms:any;
import OTPInputAuthController, { Props } from "./OTPInputAuthController";
import Loader from "../../../components/src/Loader.web";

// Customizable Area End
export default class OTPInputAuth extends OTPInputAuthController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    let token = localStorage.getItem("token")
    phoneNumber = localStorage.getItem("phoneNumber");
    is_otp_from_lms = localStorage.getItem("is_otp_from_lms");
    if(token){
      // this.props.navigation.navigate(1);
      this.props.navigation.navigate("RolesPermissions");
    }
    if(phoneNumber){
      let plusSign = phoneNumber.slice(0,1)
      if(plusSign === "+"){
        firstNumber=  phoneNumber.slice(1,3);
        secondNumber=  phoneNumber.slice(3,5)
        thirdNumber=  phoneNumber.slice(-2)
      }else{
        firstNumber=  phoneNumber.slice(0,2);
        secondNumber=  phoneNumber.slice(2,4)
        thirdNumber=  phoneNumber.slice(-2)
      }
    
    }
    if(phoneNumber === null){
      this.props.navigation.navigate("EmailAccountLoginBlock");
    }
    
    // Customizable Area End
  }
  // Customizable Area Start
  async componentDidMount() {
    Otptoken = localStorage.getItem("login_token")
    this.setState({otpAuthToken:Otptoken})
    login_type = localStorage.getItem("login_type")
    const emailRegex = /^[A-Za-z0-9.+-]+@[a-z.+-]+\.[a-z]{2,3}$/i;
    if(emailRegex.test(phoneNumber)){
      this.setState({showEmail:true})
    }
  // if(!token){
  //   this.getOtp(null  );
  // }
    myInterval = setInterval(() => {
      const { seconds, minutes } = this.state;

      if (seconds > 0) {
        this.setState(({ seconds }) => ({
          seconds: seconds - 1,
        }));
      }
    }, 1000);
  }

  async componentWillUnmount() {
    clearInterval(myInterval);
  }
   
  getOtp=(e:any)=>{
  {this.state.otp ? e.preventDefault()  : null }
    this.setState({ isLoading: true });

    const jsonPayload = {
      
        "is_otp_from_lms": is_otp_from_lms
    
    };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        token:Otptoken
      },
      body: JSON.stringify(jsonPayload),
    };

    fetch(baseURL + "/bx_block_login/login/resend_otp_with_lms", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data.errors) {
          this.setState({ isLoading: false });
          this.setState({
            otpError: <p className="errorMsg">{data.errors[0].otp}</p>,
          });
        } else {
          toast.success("OTP send successfully");
          // localStorage.setItem("forgot_token", data.meta.token);
          this.setState({
            seconds: 59,
            isLoading: false,
            otpAuthToken: data.meta.token,
          });
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false });
      });
  }


  handleValidation: any = () => {
    let isValid = true;
    if (this.state.otp === "" ) {
      this.setState({
        otpError: (
          <p className="errorMsg" style={{ marginTop: "0px" }}>
            Invalid OTP provided
          </p>
        ),
        isLoading: false,
      });
      isValid = false;
    } else {
      this.setState({ otpError: "" });
    }
    return isValid;
  };
  handleSubmit = (e: any) => {
    e.preventDefault();
    if (this.handleValidation()) {
      this.setState({ isLoading: true });

      const jsonPayload = {
        data: {
          "type": login_type,
          otp_code: this.state.otp,
          token: this.state.otpAuthToken,
          is_otp_from_lms: JSON.parse(is_otp_from_lms)
        },
      };

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(jsonPayload),
      };

      fetch(baseURL + "/otp_confirmations/otp_confirmation_with_lms", requestOptions)
        .then((response) => response.json())
        .then((data) => {
          if (data.errors) {
            this.setState({ isLoading: false });
            this.setState({
              otpError: <p className="errorMsg">{data.errors[0].otp ? data.errors[0].otp : data.errors[0].pin === null ? "Invalid OTP":data.errors[0].pin }</p>,
            });
          } else {
            localStorage.setItem("token", data.meta.token);
            if(data.meta.role){
              localStorage.setItem(
                "role",
                data.meta.role
              )
            }
            if(data.data.attributes.type){
              localStorage.setItem(
                "account_type",
                data.data.attributes.type
              );
            }
           if(!data.meta.role)
            {
              localStorage.setItem(
                "role",
                data.data.attributes.role
              );
            }
            localStorage.removeItem("login_token")
            localStorage.removeItem("login_type")
            localStorage.removeItem("is_otp_from_lms")
            this.props.navigation.navigate("RolesPermissions");
            this.setState({ isLoading: false });
          }
        });
    }
  };
  handleChange = (event: any) => {
    this.setState({ otp: event });
  };
 

  // Customizable Area End

  render() {
    return (
      //Merge Engine DefaultContainer
      <ScrollView keyboardShouldPersistTaps="always">
        {/* Customizable Area Start */}
        <>
        {this.state.isLoading && <Loader positionType={'left'} loading={true} /> }
          <div className="container">
            <div className="leftSide">
              <div className="leftContainer">
                <img
                  src={this.state.logoImage}
                  alt="logo"
                  className="logoImgStyle"
                />
                <p className="headingStyle">
                  Manage your <span className="d-block">Finance</span>
                </p>

                <div className="mainImg">
                  <img
                    src={this.state.mainImage}
                    alt="mainImage"
                    className="mainImageStyle"
                  />
                </div>
              </div>
            </div>
            {/* right side */}
            <div className="rightSide">
              <div className="commonRightContainer">
                <h1>Enter OTP</h1>
                <p className="otpParaStyle">
                  We have sent the code for verification to your email or mobile number 
                  {this.state.showEmail? <span>{phoneNumber}</span> :<span>{ "+"+firstNumber +" "+ secondNumber +"######"+thirdNumber}</span>}
                </p>
             
                  <div className="otpInputContainer">
                    <OtpInput
                   isInputNum={true}
                      value={this.state.otp}
                      numInputs={4}
                      className={
                        this.state.otpError ? "otpInput2 otpInput" : "otpInput"
                      }
                      onChange={this.handleChange}
                    />
                    <p className="otp-error">{this.state.otpError}</p>
                  </div>
                  <p className="otpParaStyle1">
                    Didn't receive the OTP?{" "}
                    <button
                      onClick={this.getOtp}
                      className="resendOtpStyle"
                      style={{
                        opacity: this.state.seconds === 0 ? "1" : "0.5",
                      }}
                      disabled={this.state.seconds === 0 ? false : true}
                    >
                      Resend OTP
                    </button>
                    {this.state.seconds === 0 ? null : (
                      <h1 className="resendOtpTimer">
                        {" "}
                        {this.state.minutes}:
                        {this.state.seconds < 10
                          ? `0${this.state.seconds}`
                          : this.state.seconds}
                      </h1>
                    )}
                  </p>
                  <button  onClick={this.handleSubmit} className="sendButton">
                    Confirm
                  </button>
                  <div className="backToLoginStyle">
                    <Link to={"/"}>Back to Login</Link>
                  </div>
               
              </div>
            </div>
          </div>
        </>
        {/* Customizable Area End */}
      </ScrollView>
    );
  }
}

// Customizable Area Start
// Customizable Area End
