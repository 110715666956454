import React from "react";
// Customizable Area Start
import { withStyles, Theme } from "@material-ui/core/styles";
import { Styles } from "@material-ui/styles";
import Grid from "@material-ui/core/Grid";
import Layout from "../../CommonLayout/Layout/src/Layout.web";

import { IconButton } from "../../CommonLayout/HRMSCommons/src/button/iconButton";
import PageHeader from "../../CommonLayout/HRMSCommons/src/layout/PageHeader";
import { FormControl, FormLabel, TextField } from "@material-ui/core";
import OutlinedSelect from "../../../components/src/OutlinedSelect";
import CreateFineController, { Props } from "./CreateFineController";
import { FormMode, IFineForm, ISubFineForm } from "./FineFormController";
import { deleteIcon } from "./assets";
import {
  Button,
  ButtonType
} from "../../CommonLayout/HRMSCommons/src/button/default";
// Customizable Area End

// Customizable Area Start
const useStyles: Styles<Theme, {}, string> = () => ({
  container: {
    minHeight: "calc(100vh - 120px)",
    maxHeight: "calc(100vh - 120px)",
    flexDirection: "column",
    flexWrap: "nowrap"
  },
  mt20: {
    marginTop: 20
  },
  actionColumn: {
    maxWidth: "100px",
    width: "100px"
  },
  tableContainer: {
    position: "relative",
    flexGrow: 1,
    boxShadow: "0px 5px 10px 0px #d9d9d9",
    borderRadius: 10
  },
  table: {
    borderCollapse: "separate",
    borderSpacing: "0 10px",
    padding: "0 25px",

    "& td": {
      backgroundColor: "#F9F9F9",
      border: "none"
    }
  },
  actionColumnButton: {
    margin: 0
  },
  checkbox: {
    padding: 0
  },
  tableHead: {
    backgroundColor: "#FCF3D4"
  },
  fineContainer: {
    marginTop: "15px",
    boxShadow: "0px 1px 9px 0px #888888",
    borderRadius: "11px"
  },
  firstFineContainer: {
    marginTop: "15px"
  },
  headerContainer: {
    boxShadow: "0px 1px 9px 0px #888888",
    borderRadius: "11px"
  },
  deleteButton: {
    backgroundColor: "#FF414A",
    width: "10px",
    height: "10px",
    margin: "0px",
    borderRadius: "15px"
  }
});
// Customizable Area End

class CreateFine extends CreateFineController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderFine = (
    fineForm: IFineForm | ISubFineForm,
    index?: number,
    isFirstFine: Boolean = false
  ) => {
    const { formMode } = this.state;
    return (
      <div
        style={{ display: "flex" }}
        className={
          isFirstFine
            ? this.props.classes.firstFineContainer
            : this.props.classes.fineContainer
        }
      >
        <Grid
          style={{ marginTop: 20, padding: "15px" }}
          container
          direction="row"
        >
          <Grid item xs={12} sm={4} style={{ paddingRight: 12 }}>
            <OutlinedSelect
              val={fineForm.fine_category}
              options={[
                { id: "late_fine", name: "Late Fine" },
                { id: "miscellaneous", name: "Miscellaneous" }
              ]}
              label="Fine Category*"
              placeholder="Select Type"
              name={"late_fine"}
              disabled={formMode === FormMode.View || !isFirstFine}
              handleChange={(e: any) => this.onChangeFineCategory.bind(this)(e, index)} className={undefined}            />
          </Grid>
          <Grid item xs={12} sm={4} style={{ paddingRight: 12 }}>
            <FormControl style={{ width: "100%" }}>
              <FormLabel style={{ marginBottom: "8px" }}>Fine Name*</FormLabel>
              <TextField
                value={fineForm.name}
                color={"primary"}
                variant="outlined"
                type={"text"}
                name={"name"}
                placeholder={"Enter Name"}
                disabled={formMode === FormMode.View}
                onChange={e => this.onChangeFineName.bind(this)(e, index)}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4} style={{ paddingRight: 12 }}>
            <FormControl style={{ width: "100%" }}>
              <FormLabel style={{ marginBottom: "8px" }}>
                Days After Due Date*
              </FormLabel>
              <div style={{ display: "flex" }}>
                <TextField
                  value={fineForm.day}
                  color={"primary"}
                  variant="outlined"
                  type={"number"}
                  name={"tax_percentage"}
                  placeholder={"Enter Tax %"}
                  disabled={formMode === FormMode.View}
                  onChange={e => this.onChangeFineDay.bind(this)(e, index)}
                />
                <OutlinedSelect
                  val={fineForm.duration}
                  options={[
                    { id: "week", name: "Week" },
                    { id: "days", name: "Days" },
                    { id: "month", name: "Month" }
                  ]}
                  label=""
                  placeholder="Select Type"
                  name={"duration"}
                  styleOverrides={{ marginTop: "-8px" }}
                  disabled={formMode === FormMode.View}
                  handleChange={(e: any) => this.onChangeFineDuration.bind(this)(e, index)} className={undefined}                />
              </div>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4} style={{ marginTop: 30, paddingRight: 12 }}>
            <FormControl style={{ width: "100%" }}>
              <FormLabel style={{ marginBottom: "8px" }}>
                Fine Amount*
              </FormLabel>
              <TextField
                value={fineForm.fine_amount}
                color={"primary"}
                variant="outlined"
                type={"text"}
                name={"amount"}
                placeholder={"Enter Amount"}
                disabled={formMode === FormMode.View}
                onChange={e => this.onChangeFineAmount.bind(this)(e, index)}
                // onClick={e => e.target.select()}
                onBlur={e => this.onBlurFineAmount.bind(this)(e, index)}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4} style={{ marginTop: 30, paddingRight: 12 }}>
            <OutlinedSelect
              val={fineForm.mode}
              options={[
                { id: "amount", name: "Amount" },
                { id: "percentage", name: "Percentage" }
              ]}
              label="Fine Mode*"
              placeholder="Select Type"
              name={"mode"}
              disabled={formMode === FormMode.View}
              handleChange={(e: any) => this.onChangeFineMode.bind(this)(e, index)} className={undefined}            />
          </Grid>
        </Grid>
        {!isFirstFine && (
          <div
            style={{
              backgroundColor: "#FFF4CE",
              width: "30px",
              minHeight: "100%",
              borderTopRightRadius: "10px",
              borderBottomRightRadius: "11px"
            }}
          >
            <div
              style={{
                minHeight: "100%",
                display: "flex",
                alignItems: "center"
              }}
            >
              <IconButton
                icon={deleteIcon}
                className={this.props.classes.deleteButton}
                onClick={() => this.onClickDelete.bind(this)(index || 0)}
              />
            </div>
          </div>
        )}
      </div>
    );
  };

  renderRemainFines(fineForms: ISubFineForm[]) {
    return fineForms.map((el, i) => this.renderFine(el, i));
  }

  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes, navigation } = this.props;
    const { fineForm, formMode } = this.state;

    return (
      <Layout navigation={navigation}>
        {/* <Grid container className={classes.container}> */}
        <div style={{ maxHeight: "80vh", overflow: "auto", padding: "5px" }}>
          <Grid
            className={classes.headerContainer}
            container
            direction="column"
          >
            <PageHeader
              title="Create Fine"
              importModalTitle="Import fine by CSV file"
              printReference={this.printReferance}
              onClickAddButton={this.onClickAdd.bind(this)}
              haveFilterButton={false}
              haveActionBar={false}
              haveCSVButton={false}
              haveCreateButton={false}
              haveAddButton={
                fineForm.fine_category === "late_fine" &&
                formMode === FormMode.Create
              }
            />
            {this.renderFine(fineForm, undefined, true)}
          </Grid>
          <Grid container direction="column">
            {this.renderRemainFines(fineForm.sub_fines_attributes)}
          </Grid>
        </div>
        {formMode === FormMode.Create && (
          <div style={{ marginTop: 20 }}>
            <Button
              title="Cancel"
              buttonType={ButtonType.Cancel}
              onClick={() => this.props.navigation.navigate("Fine")}
            />

            <Button
              title="Create"
              buttonType={ButtonType.Primary}
              style={{ marginLeft: 20 }}
              onClick={() => this.onCreateFine.bind(this)(fineForm)}
            />
          </div>
        )}

        {formMode === FormMode.Edit && (
          <div style={{ marginTop: 20 }}>
            <Button
              title="Cancel"
              buttonType={ButtonType.Cancel}
              onClick={() => this.props.navigation.navigate("Fine")}
            />

            <Button
              title="Update"
              buttonType={ButtonType.Primary}
              style={{ marginLeft: 20 }}
              onClick={() => this.onEditFine.bind(this)(fineForm)}
            />
          </div>
        )}

        {formMode === FormMode.View && (
          <div style={{ marginTop: 20 }}>
            <Button
              title="Back To Listing"
              buttonType={ButtonType.Primary}
              style={{ marginLeft: 20 }}
              onClick={() => this.props.navigation.navigate("Fine")}
            />
          </div>
        )}
      </Layout>
    );
    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End
}

export default withStyles(useStyles)(CreateFine);
