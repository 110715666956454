import React from "react";
import { TextField, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import CustomModal from "../../CommonLayout/CustomModal/src/CustomModal.web";
import { capitalizeFirst } from "../../../framework/src/Utilities";

import ViewConcessionController, { Props } from "./ViewConcessionController";

interface roleMenuAttributes {
  id: number;
  title: string;
}

interface roleMenuData {
  id: number;
  attributes: roleMenuAttributes;
}

class ViewConcession extends ViewConcessionController {
  constructor(props: Props) {
    super(props);
  }
  render() {
    const {
      open,
      handleClose,
      concessionDetails,
      concessionDetailserrorMessage
    } = this.props;
    return (
      <CustomModal
        cancelButtonTitle={"Back to Listing"}
        open={open}
        title={"View Concession"}
        handleClose={() => handleClose("view", false)}
      >
        {concessionDetailserrorMessage ? (
          <p style={{ color: "red" }}>{concessionDetailserrorMessage}</p>
        ) : (
          concessionDetails && (
            <>
              <Grid
                container
                style={{
                  marginTop: "10px"
                }}
              >
                <Grid
                  style={{
                    color: "#465872",
                    fontWeight: "bold",
                    marginBottom: "36px"
                  }}
                  xs={4}
                  item
                >
                  Concession Type*
                  <Typography style={{ color: "#7e7d7d", marginTop: "16px" }}>
                    {concessionDetails
                      ? capitalizeFirst(
                          concessionDetails.attributes.fee_concession_type.data
                            .attributes.name
                        )
                      : ""}
                  </Typography>
                </Grid>
                <Grid
                  style={{
                    color: "#465872",
                    fontWeight: "bold",
                    marginBottom: "36px"
                  }}
                  xs={4}
                  item
                >
                  Concession Name*
                  <Typography style={{ color: "#7e7d7d", marginTop: "16px" }}>
                    {concessionDetails
                      ? capitalizeFirst(concessionDetails.attributes.name)
                      : ""}
                  </Typography>
                </Grid>
                <Grid
                  style={{
                    color: "#465872",
                    fontWeight: "bold",
                    marginBottom: "36px"
                  }}
                  xs={4}
                  item
                >
                  Valid Until*
                  <Typography style={{ color: "#7e7d7d", marginTop: "16px" }}>
                    {concessionDetails
                      ? concessionDetails.attributes.valid_until
                      : ""}
                  </Typography>
                </Grid>
                <Grid
                  style={{
                    color: "#465872",
                    fontWeight: "bold",
                    marginBottom: "36px"
                  }}
                  xs={4}
                  item
                >
                  Concession Amount*
                  <Typography style={{ color: "#7e7d7d", marginTop: "16px" }}>
                    {concessionDetails
                      ? concessionDetails.attributes.amount + ".00"
                      : ""}
                  </Typography>
                </Grid>

                <Grid
                  style={{
                    color: "#465872",
                    fontWeight: "bold",
                    marginBottom: "36px"
                  }}
                  xs={4}
                  item
                >
                  Mode*
                  <Typography style={{ color: "#7e7d7d", marginTop: "16px" }}>
                    {concessionDetails
                      ? capitalizeFirst(concessionDetails.attributes.mode)
                      : ""}
                  </Typography>
                </Grid>
              </Grid>
            </>
          )
        )}
      </CustomModal>
    );
  }
}
export const themeCreatStyle = (theme: any) => ({
  background_color: {
    backgroundColor: theme.palette.primary.main
  },
  title_font: {
    color: theme.props.titleColor,
    fontFamily: theme.props.titleFont
  },
  subTitle_font: {
    color: theme.props.subtitleColor,
    fontFamily: theme.props.subtitleFont
  },
  heading_font: {
    color: theme.props.headingColor,
    fontFamily: theme.props.headingFont
  },
  subHeading_font: {
    color: theme.props.subheadingColor,
    fontFamily: theme.props.subheadingFont
  },
  bodyText_font: {
    color: theme.props.textColor,
    fontFamily: theme.props.textFont
  },
  button_color: {
    backgroundColor: theme.props.buttonColor,
    color: "white"
  },
  icon_color: {
    color: theme.props.iconColor
  },
  button_color_disabled: {
    color: theme.props.buttonColor,
    backgroundColor: "white",
    border: `1px solid ${theme.props.buttonColor}`,
    "&:hover": {
      background: "#fff"
    }
  }
});

export default withStyles(themeCreatStyle)(
  ViewConcession as React.ComponentType<any>
);
