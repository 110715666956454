import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { number } from "yup";

// Customizable Area Start
enum Method {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
  PATCH = 'PATCH',
}
// Customizable Area End

export const configJSON = require("./config");

// Customizable Area Start
export interface IGSTSettingForm {
  id: number;
  is_gst: boolean;
  gst_number: string;
  legal_name: string;
  trade_name: string;
  regesterd_on: string;
}
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes?: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  form: IGSTSettingForm;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class TaxRatesController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  public RequestMessage = {
    GetGSTSettings: this.buildRequestMessage(Method.GET),
    GSTSettingsPatch: this.buildRequestMessage(Method.PATCH),

    Null: undefined as any
  }
  // Customizable Area End

  constructor(props: Props) {
    super(props);

    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      form: {
        id: 0,
        is_gst: true,
        gst_number: "",
        legal_name: "",
        trade_name: "",
        regesterd_on: ""
      }
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const callID = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const response = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      switch (callID) {
        case this.RequestMessage.GetGSTSettings.messageId:
          if (response !== null) {
            const { id, attributes } = response.data;

            const date = new Date(attributes.regesterd_on);
            const month = (date.getMonth() + 1).toString().padStart(2, "0");
            const regesterd_on = `${date.getFullYear()}-${month}-${date.getDate()}`;

            this.setState({ form: { ...attributes, regesterd_on, id } });
          }

          break;
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  public async componentDidMount() {
    super.componentDidMount();

    this.getGSTSettingsCall();
  }

  public onChangeGST(is_gst: boolean) {
    this.setState({ form: { ...this.state.form, is_gst } });
  }

  public onChangeGSTIN({ target }: React.ChangeEvent<HTMLInputElement>) {
    this.setState({ form: { ...this.state.form, gst_number: target.value.toUpperCase() } });
  }

  public onChangeLegalName({ target }: React.ChangeEvent<HTMLInputElement>) {
    this.setState({ form: { ...this.state.form, legal_name: target.value } });
  }

  public onChangeTradeName({ target }: React.ChangeEvent<HTMLInputElement>) {
    this.setState({ form: { ...this.state.form, trade_name: target.value } });
  }

  public onChangeRegesterdOn({ target }: React.ChangeEvent<HTMLInputElement>) {
    console.log(target.value);
    this.setState({ form: { ...this.state.form, regesterd_on: target.value } });
  }

  public onClickGetTaxpayerDetails() {
    // todo
  }

  public onCancel() {
    this.props.navigation.goBack();
  }

  public onSave() {
    this.patchGSTSettingsCall(this.state.form);
  }

  //#region Service Calls
  private getGSTSettingsCall() {
    const message = this.RequestMessage.GetGSTSettings;

    runEngine.sendMessage(message.id, message);
  }

  private patchGSTSettingsCall(form: IGSTSettingForm) {
    const message = this.RequestMessage.GSTSettingsPatch;

    message.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.GSTSettingsEndPoint}/${form.id}`
    );

    message.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({ gst_setting: form })
    );

    runEngine.sendMessage(message.id, message);
  }

  //#endregion Service Calls

  private buildRequestMessage(method: Method): Message {
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.GSTSettingsEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.APIBaseURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      this.getHeaderMessage()
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method.toString()
    );

    return requestMessage;
  }

  private getHeaderMessage() {
    const header = {
      "Content-Type": configJSON.APIContentType,
      token: localStorage.getItem("token"),
    };

    return JSON.stringify(header);
  }
  // Customizable Area End
}
