import React from "react";
import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";

// Customizable Area Start
import { IActionMenuItem } from "../../CommonLayout/HRMSCommons/src/actionMenu";
import { downloadCSV } from "framework/src/Utilities";
import { toast } from "react-toastify";
import { debounce } from "lodash";

// Customizable Area End

import {
  FormMode,
  Props as IExpenseFormProps,
  IExpenseForm
} from "./ExpenseFormController";
export const configJSON = require("./config");

// Customizable Area Start
enum Method {
  GET = "GET",
  POST = "POST",
  PUT = "PUT",
  DELETE = "DELETE",
  PATCH = "PATCH"
}

export interface IExpense {
  id: string;
  type: string;
  selected?: boolean;
  attributes: {
    hns_code: string;
    date: string;
    currency: string;
    expence_account: string;
    ammount: string;
    tax: string;
    vendor_id: string;
    vendor?: string;
    invoice: string;
    paid_trough: string;
    source_of_supply: string;
    destination_of_supply: string;
    gst_treatment: string;
    reverse_charge: boolean;
    reverse_charge_account: string;
    reverse_charge_ammount: string;
    comment: string;
    expense_recipt: string;
    duration: string;
    exchange_amount: string;
    exchange_rate: string;
    expense_amount: string;
    expense_category: string;
    expense_type: string;
    is_tax_inclusive: boolean;
    mode: string;
    name: string;
    reverse_charge_type: string;
    sac: string;
  };
}



interface IPageMeta {
  message: string;
  total_pages: number;
}

interface IFilterForm {
  expense_account: string;
  paid_through: string;
  invoice: string;
}
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  anchorEl: HTMLElement | null;
  actionedTableRow: IExpense | null;
  actionMenuItems: Array<IActionMenuItem>;
  expenses: Array<IExpense>;
  expenseNames: Array<string>;
  pageMeta: IPageMeta;
  selectAllCheckboxChecked: false | "indeterminate" | true;
  isLoading: boolean;
  deleteModalOpen: boolean;
  filterForm: IFilterForm;
  isFormModalOpened: boolean;
  form: IExpenseForm | null;
  searchKeyword: string;
  formMode: FormMode;
  openedRow: number | undefined;
  currentPage: number;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ExpenseListController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  public tableActionMenuItems: Array<IActionMenuItem> = [
    { label: "Edit", action: this.editExpense.bind(this) },
    { label: "Delete", action: this.deleteExpense.bind(this) },
    { label: "View", action: this.viewExpense.bind(this) },
    { label: "Copy", action: this.copyExpense.bind(this) }
  ];

  public RequestMessage = {
    GetExpenses: this.buildRequestMessage(Method.GET),
    GetAllExpenses: this.buildRequestMessage(Method.GET),
    FilterExpenses: this.buildRequestMessage(Method.GET),
    DeleteExpense: this.buildRequestMessage(Method.DELETE),
    CreateExpense: this.buildRequestMessage(Method.POST),
    EditExpense: this.buildRequestMessage(Method.PUT),
    ExportExpenses: this.buildRequestMessage(Method.GET),
    Null: undefined as any
  };

  public formProps: { [key: number]: IExpenseFormProps } = {
    [FormMode.Create]: {
      title: "Create Expense",
      submitLabel: "Create",
      initialValues: null,
      formMode: FormMode.Create,
      isOpen: false,
      onClose: this.onCloseExpenseFormModal.bind(this),
      onSubmit: this.onSubmitCreateExpenseFormModal.bind(this),
      requestMessage: this.RequestMessage.CreateExpense
    },
    [FormMode.Edit]: {
      title: "Edit Expense",
      submitLabel: "Update",
      initialValues: null,
      formMode: FormMode.Edit,
      isOpen: false,
      onClose: this.onCloseExpenseFormModal.bind(this),
      onSubmit: this.onSubmitEditExpenseFormModal.bind(this),
      requestMessage: this.RequestMessage.EditExpense
    },
    [FormMode.View]: {
      title: "View Expense",
      submitLabel: "Back to Listing",
      initialValues: null,
      formMode: FormMode.View,
      isOpen: false,
      onClose: this.onCloseExpenseFormModal.bind(this),
      onSubmit: this.onCloseExpenseFormModal.bind(this),
      requestMessage: this.RequestMessage.Null
    },
    [FormMode.Copy]: {
      title: "Copy Expense",
      submitLabel: "Update",
      initialValues: null,
      formMode: FormMode.Copy,
      isOpen: false,
      onClose: this.onCloseExpenseFormModal.bind(this),
      onSubmit: this.onSubmitCreateExpenseFormModal.bind(this),
      requestMessage: this.RequestMessage.Null
    }
  };

  public printReferance = React.createRef();
  tableRef: any = null;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      currentPage: 0,
      searchKeyword: "",
      anchorEl: null,
      actionedTableRow: null,
      expenses: [],
      actionMenuItems: [],
      pageMeta: {
        message: "",
        total_pages: 0
      },
      selectAllCheckboxChecked: false,
      isLoading: true,
      deleteModalOpen: false,
      filterForm: {
        expense_account: "",
        invoice: "",
        paid_through: ""
      },
      isFormModalOpened: false,
      form: null,
      formMode: FormMode.Create,
      expenseNames: [],
      openedRow: undefined
      // Customizable Area End
    };
    this.tableRef = React.createRef();

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const callID = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const response = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      switch (callID) {
        case this.RequestMessage.GetExpenses.messageId:
        case this.RequestMessage.FilterExpenses.messageId:
          if (response) {
            this.setState({
              expenses: ((response.data && response.data.data) || []).map(
                (el: any) => {
                  el.attributes.expense_amount = el.attributes.ammount;
                  return el;
                }
              ),
              pageMeta: response.meta || {},
              isLoading: false
            });
          }
          break;
        case this.RequestMessage.GetAllExpenses.messageId:
          if (response) {
            let expenseNames = (response.data || [])
              .map((el: any) =>
                el.attributes ? el.attributes.name : undefined
              )
              .filter((el: any) => el);
            expenseNames = [...new Set(expenseNames)];
            this.setState({
              expenseNames,
              isLoading: false
            });
          }
          break;

        case this.RequestMessage.DeleteExpense.messageId:
          if (response !== null) {
            this.setState({
              deleteModalOpen: false,
              actionedTableRow: null
            });

            this.getExpensesCall();
          }
          break;
        case this.RequestMessage.EditExpense.messageId:
        case this.RequestMessage.CreateExpense.messageId:
          if (response !== null) {
            this.getExpensesCall();
            this.setState({ isFormModalOpened: false });
          }
          break;

        case this.RequestMessage.ExportExpenses.messageId:
          if (response !== null) {
            const file = new Blob(response, { type: "text/csv" });
            const fileURL = URL.createObjectURL(file);
            window.open(fileURL);
          }

          break;
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  public async componentDidMount() {
    super.componentDidMount();

    this.getExpensesCall();
  }

  handleSearchChange = (evt: any) => {
    const { value } = evt.target;
    const debouncedSave = debounce(value => {
      this.setState({ searchKeyword: value });
      if (value !== "") {
        this.setState({ currentPage: 0 });
        this.searchExpenseCall(value);
      } else {
        this.getExpensesCall(this.state.currentPage + 1);
      }
    }, 300);
    debouncedSave(value);
  };

  public onClickCreate() {
    //this.props.navigation.navigate("CreateExpense");
    this.setState({ isFormModalOpened: true, formMode: FormMode.Create });
  }

  public onSelectAllExpenses(event: React.ChangeEvent<HTMLInputElement>) {
    const { expenses: Expense } = this.state;
    const { checked } = event.target;

    const updatedExpenses = Expense.map(Expense => {
      return {
        ...Expense,
        selected: checked
      };
    });

    this.setState({
      expenses: updatedExpenses,
      selectAllCheckboxChecked: this.isSelectAllCheckboxChecked(updatedExpenses)
    });
  }

  public onSelectExpense(
    event: React.ChangeEvent<HTMLInputElement>,
    row: IExpense
  ) {
    const { expenses: Expense } = this.state;
    const { checked } = event.target;

    const updatedExpenses = Expense.map(Expense => {
      return {
        ...Expense,
        selected: row.id === Expense.id ? checked : Expense.selected
      };
    });

    this.setState({
      expenses: updatedExpenses,
      selectAllCheckboxChecked: this.isSelectAllCheckboxChecked(updatedExpenses)
    });
  }

  public onClickActionButton(event: any, item: IExpense) {
    this.setState({
      anchorEl: event.currentTarget,
      actionedTableRow: item,
      actionMenuItems: this.tableActionMenuItems
    });
  }

  public onCloseActionMenu() {
    this.setState({
      anchorEl: null,
      actionMenuItems: []
    });
  }

  public onClickPagination(page: any) {
    this.setState({ currentPage: page.selected });
    this.tableRef.current.scrollIntoView();
    this.getExpensesCall(page.selected + 1);
  }

  public onCloseDeleteModal() {
    this.setState({
      deleteModalOpen: false,
      actionedTableRow: null
    });
  }

  public onSubmitDeleteModal() {
    this.setState({ selectAllCheckboxChecked: false });
    this.deleteExpenseCall();
  }

  public onCloseExpenseFormModal() {
    this.setState({ isFormModalOpened: false });
  }

  public onSubmitCreateExpenseFormModal(form: IExpenseForm) {
    this.createExpenseCall(form);
  }
  public onSubmitEditExpenseFormModal(form: IExpenseForm) {
    this.editExpenseCall(form);
  }

  // public onSubmitEditExpenseFormModal(form: ITaxRateForm) {
  //   this.edi(form);
  // }

  public onUploadCSVForImport(file: File) {
    this.importExpensesCall(file);
  }

  public onClickExportCSVButton() {
    this.exportExpensesCall();
  }

  //#region Filter Methods

  onChangeFilterExpenseAccount = (event: any) => {
    this.setState({
      filterForm: {
        ...this.state.filterForm,
        expense_account: event.target.value
      }
    });
  };

  onChangeFilterPaidThrough = (event: any) => {
    this.setState({
      filterForm: {
        ...this.state.filterForm,
        paid_through: event.target.value
      }
    });
  };

  onChangeFilterInvoice = (event: any) => {
    this.setState({
      filterForm: {
        ...this.state.filterForm,
        invoice: event.target.value
      }
    });
  };

  openExpenseModalWithInitialData = (
    expense: IExpense | null,
    formMode: FormMode
  ) => {
    if (expense !== null) {
      const {
        id,
        attributes: {
          ammount,
          comment,
          currency,
          date,
          destination_of_supply,
          expence_account,
          expense_recipt,
          gst_treatment,
          hns_code,
          invoice,
          paid_trough,
          reverse_charge,
          reverse_charge_account,
          reverse_charge_ammount,
          source_of_supply,
          tax,
          vendor_id,
          vendor,
          duration,
          exchange_amount,
          exchange_rate,
          expense_amount,
          expense_category,
          expense_type,
          is_tax_inclusive,
          mode,
          name,
          reverse_charge_type,
          sac
        }
      } = expense as IExpense;

      this.setState({
        isFormModalOpened: true,
        formMode: formMode,
        form: {
          id,
          comment,
          currency,
          vendor,
          date,
          destination_of_supply,
          expence_account,
          expense_recipt,
          gst_treatment,
          hns_code,
          invoice,
          paid_trough,
          reverse_charge,
          reverse_charge_account,
          reverse_charge_ammount,
          source_of_supply,
          tax,
          vendor_id,
          duration,
          exchange_amount,
          exchange_rate,
          expense_amount,
          expense_category,
          expense_type,
          is_tax_inclusive,
          mode,
          name,
          reverse_charge_type,
          sac
        }
      });
    }
  };

  public onFilterFormSubmit() {
    this.filterExpensesCall(this.state.filterForm);
  }
  //#endregion Filter Methods

  private editExpense() {
    const expense = this.state.actionedTableRow;
    if (!expense) return;
    // this.props.navigation.navigate("EditExpense");
    // window.location.href = "EditExpense?id=" + expense.attributes.id;
    this.openExpenseModalWithInitialData(expense, FormMode.Edit);
  }

  private viewExpense() {
    const expense = this.state.actionedTableRow;
    if (!expense) return;
    // this.props.navigation.navigate("ViewExpense");
    // this.props.navigation.navigate("ViewExpense?id=5");
    // window.location.href = "ViewExpense?id=" + expense.attributes.id;
    this.openExpenseModalWithInitialData(expense, FormMode.View);
  }

  private deleteExpense() {
    this.setState({ deleteModalOpen: true });
  }

  private copyExpense() {
    const expense = this.state.actionedTableRow;
    if (!expense) return;
    // window.location.href = "CopyExpense?copyId=" + expense.id;
    this.openExpenseModalWithInitialData(expense, FormMode.Copy);
  }

  private selectedExpenses(expenses?: Array<IExpense>): Array<IExpense> {
    if (expenses === undefined) {
      expenses = this.state.expenses;
    }

    return expenses.filter(expense => expense.selected);
  }

  private isSelectAllCheckboxChecked(
    Expense: Array<IExpense>
  ): false | "indeterminate" | true {
    const selectedExpenses = this.selectedExpenses(Expense);
    const { length } = selectedExpenses;

    return length !== 0 && (length === Expense.length ? true : "indeterminate");
  }

  //#region Service Calls
  private searchExpenseCall(vendorName: string) {
    this.RequestMessage.FilterExpenses.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.EndPoint}/search?page=${this.state.currentPage ||
        1}&vendor=${vendorName}`
    );

    runEngine.sendMessage(
      this.RequestMessage.FilterExpenses.id,
      this.RequestMessage.FilterExpenses
    );
  }

  private getExpensesCall(page: number = 1) {
    this.setState({ isLoading: true });

    this.RequestMessage.GetExpenses.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.EndPoint}?page=${page}`
    );

    runEngine.sendMessage(
      this.RequestMessage.GetExpenses.id,
      this.RequestMessage.GetExpenses
    );
  }

  private deleteExpenseCall() {
    const selectedFines = this.state.expenses.filter(el => el.selected);
    if (selectedFines.length === 1) {
      this.RequestMessage.DeleteExpense.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.EndPoint}/${selectedFines[0].id}`
      );
    } else if (selectedFines.length > 1) {
      const ids = selectedFines.reduce((p, c) => p + (p ? "," : "") + c.id, "");
      this.RequestMessage.DeleteExpense.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.EndPoint}/bulk_destroy?ids=${ids}`
      );
    } else return;

    runEngine.sendMessage(
      this.RequestMessage.DeleteExpense.id,
      this.RequestMessage.DeleteExpense
    );
  }

  private filterExpensesCall(form: IFilterForm) {
    this.RequestMessage.FilterExpenses.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.EndPoint}/filter?${this.getFilterQuery(form)}`
    );

    runEngine.sendMessage(
      this.RequestMessage.FilterExpenses.id,
      this.RequestMessage.FilterExpenses
    );
  }

  private exportExpensesCall() {
    const requestOptions = {
      method: "GET",
      headers: JSON.parse(this.getHeaderMessage())
    };

    fetch(
      `${configJSON.APIBaseURL}/${configJSON.EndPoint}/export`,
      requestOptions
    )
      .then(response => {
        runEngine.debugLog("response Recived", JSON.stringify(response));
        if (JSON.stringify(response) === "{}")
          return toast.error("export error");
        const file_sub_name = new Date().getTime();
        response
          .blob()
          .then(blob => downloadCSV(blob, "expense_csv_" + file_sub_name));
      })
      .catch(error => {
        toast.error(error.message);
      });
  }

  private importExpensesCall(file: File) {
    const formData = new FormData();
    formData.append("file", file);

    const requestOptions = {
      method: "POST",
      headers: JSON.parse(this.getHeaderMessage()),
      body: formData
    };

    fetch(
      `${configJSON.APIBaseURL}/${configJSON.EndPoint}/import`,
      requestOptions
    )
      .then(response => response.json())
      .then(response => toast.success(response.message))
      .catch(response => toast.error(response.error));
  }

  private createExpenseCall(form: IExpenseForm) {
    let token = window.localStorage.getItem("token");
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token
    };

    var FormData = require("form-data");
    var bodyFormData = new FormData();
    runEngine.debugLog("formformformform", form);
    if (form.expense_recipt)
      bodyFormData.append("expense_recipt", form.expense_recipt);
    if (form.expense_amount)
      bodyFormData.append("ammount", form.expense_amount);
    Object.entries(form).forEach(([key, val]) => {
      if (val && key !== "expense_recipt")
        bodyFormData.append("data[" + key + "]", val);
    });

    this.RequestMessage.CreateExpense.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.EndPoint
    );
    this.RequestMessage.CreateExpense.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.APIBaseURL
    );
    this.RequestMessage.CreateExpense.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.RequestMessage.CreateExpense.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      bodyFormData
    );
    this.RequestMessage.CreateExpense.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.POST
    );
    runEngine.sendMessage(
      this.RequestMessage.CreateExpense.id,
      this.RequestMessage.CreateExpense
    );
  }

  private editExpenseCall(form: IExpenseForm) {
    const { id, expense_recipt, ...rest } = form;

    if (id !== undefined) {
      this.RequestMessage.EditExpense.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify({
          data: { ...rest, ammount: parseFloat(rest.expense_amount) }
        })
      );

      this.RequestMessage.EditExpense.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.EndPoint}/${id}`
      );

      runEngine.sendMessage(
        this.RequestMessage.EditExpense.id,
        this.RequestMessage.EditExpense
      );
    }
  }
  //#endregion Service Calls

  private buildRequestMessage(method: Method): Message {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.EndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.APIBaseURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      this.getHeaderMessage()
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method.toString()
    );

    return requestMessage;
  }

  private getHeaderMessage() {
    const header = {
      "Content-Type": configJSON.APIContentType,
      token: window.localStorage.getItem("token")
    };

    return JSON.stringify(header);
  }

  private getFilterQuery(form: IFilterForm) {
    return Object.entries(form)
      .filter(
        ([key, value]) => value !== "" && value !== null && value !== undefined
      )
      .map(([key, value]) => `${key}=${value}`)
      .join("&");
  }
  // Customizable Area End
}
