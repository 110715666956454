import React from "react";
// Customizable Area Start
import {
    TextField,
    Typography
} from "@material-ui/core";
import { withStyles, } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CustomModal from '../../CommonLayout/CustomModal/src/CustomModal.web';
import { capitalizeFirst } from "../../../framework/src/Utilities";

import ViewAccountController, {
    Props,
} from "./ViewAccountController";


interface roleMenuAttributes {
    id: number;
    title: string;
}

interface roleMenuData {
    id: number,
    attributes: roleMenuAttributes;
}
// Customizable Area End
class ViewAccount extends ViewAccountController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    render() {
        // Customizable Area Start
        const { open, handleClose,accountDetails,accountDetailserrorMessage  } = this.props;
        console.log('accountDetails details', accountDetails);
        // Customizable Area End
        return (
            // Customizable Area Start
            <CustomModal cancelButtonTitle={'Back to Listing'}
                open={open}
                title={"View Account"}
                handleClose={() => handleClose("view", false)}>
                {
                    accountDetailserrorMessage  ? <p className="errorMsg2">{accountDetailserrorMessage}</p> :
                    (
                        accountDetails && <>
                        <Grid
                            container
                            style={{
                                marginTop: "10px"
                                
                            }}
                        >
                            <Grid style={{ color: "#465872", fontWeight: "bold", marginBottom: "36px" }} xs={4} item>Account Type*:
                                <Typography style={{color: "#7e7d7d", marginTop: "16px"}}>{accountDetails ? accountDetails.attributes?.account_type :''}</Typography>
                            </Grid>
                            <Grid style={{ color: "#465872", fontWeight: "bold", marginBottom: "36px" }} xs={4} item>Account Name*:
                                <Typography style={{color: "#7e7d7d", marginTop: "16px"}}>{accountDetails ? capitalizeFirst(accountDetails.attributes?.name): ''}</Typography>
                            </Grid>
                            <Grid style={{ color: "#465872", fontWeight: "bold", marginBottom: "36px" }} xs={4} item>Account Code:
                                <Typography style={{color: "#7e7d7d", marginTop: "16px"}}>{accountDetails ? accountDetails.attributes?.code : ''}</Typography>
                            </Grid>
                            <Grid style={{ color: "#465872", fontWeight: "bold", marginBottom: "36px" }} xs={8} item>Description:
                                <Typography style={{color: "#7e7d7d", marginTop: "16px"}}>{accountDetails ? capitalizeFirst(accountDetails.attributes?.description): ''}</Typography>
                            </Grid>
                            {
                                accountDetails.attributes.is_sub_account === true &&
                                <Grid style={{ color: "#465872", fontWeight: "bold", marginBottom: "36px" }} xs={4} item>Parent Account*:
                                    <Typography style={{color: "#7e7d7d", marginTop: "16px"}}>{accountDetails ? accountDetails.attributes?.parent_account_name: ''}</Typography>
                                </Grid>
                            }
                        </Grid>
                        <Grid
                            container
                            style={{
                                marginTop: "10px"
                                
                            }}
                        >
                            <Grid style={{ color: "#465872", fontWeight: "bold", marginBottom: "36px" }} xs={4} item> 
                            <FormControlLabel
                                control={<Checkbox disabled checked={accountDetails.attributes.is_sub_account} 
                                name="is_sub_account" />}
                                label="Make this a sub-account"
                            />
                            </Grid>
                        </Grid>
                        
                    </>)
                }
            </CustomModal >
            // Customizable Area End
        );
    }
}
export const themeCreatStyle = ((theme: any) => ({

    background_color: {
        backgroundColor: theme.palette.primary.main
    },
    title_font: {
        color: theme.props.titleColor,
        fontFamily: theme.props.titleFont
    },
    subTitle_font: {
        color: theme.props.subtitleColor,
        fontFamily: theme.props.subtitleFont
    },
    heading_font: {
        color: theme.props.headingColor,
        fontFamily: theme.props.headingFont
    },
    subHeading_font: {
        color: theme.props.subheadingColor,
        fontFamily: theme.props.subheadingFont
    },
    bodyText_font: {
        color: theme.props.textColor,
        fontFamily: theme.props.textFont
    },
    button_color: {
        backgroundColor: theme.props.buttonColor,
        color: "white",
    },
    icon_color: {
        color: theme.props.iconColor
    },
    button_color_disabled: {
        color: theme.props.buttonColor,
        backgroundColor: "white",
        border: `1px solid ${theme.props.buttonColor}`,
        '&:hover': {
            background: "#fff",
        }
    }
}))

export default withStyles(themeCreatStyle)(
    ViewAccount as React.ComponentType<any>
)
