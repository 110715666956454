import React from 'react';
// Customizable Area Start
import { withStyles, Theme } from "@material-ui/core/styles";
import { Styles } from "@material-ui/styles";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import TextField from "@material-ui/core/TextField";
import OutlinedSelect from "../../../components/src/OutlinedSelect";
import { IconButton } from "../../CommonLayout/HRMSCommons/src/button/iconButton";
import { deleteIcon, plusIcon } from "./assets";

import CustomModal from "../../CommonLayout/CustomModal/src/CustomModal.web";

import FineModalFormController, {
  FormMode,
  Props
} from "./FineModalFormController";
import { classNames } from "../../CommonLayout/HRMSCommons/src/classNames";
import { IFineForm, ISubFineForm } from "./FineFormController";
import {
  Button,
  ButtonType
} from "../../CommonLayout/HRMSCommons/src/button/default";
import { Typography } from "@material-ui/core";
// Customizable Area End

// Customizable Area Start
const useStyles: Styles<Theme, {}, string> = (theme: Theme) => ({
  question: {
    backgroundColor: "#FFF9EE",
    marginTop: 20,
    marginLeft: -24,
    width: "calc(100% + 48px)",

    "& h6, & label": {
      color: "#7E766B",
      fontSize: 14,
      fontWeight: 600
    }
  },
  viewMode: {
    "& .question": {
      "& h6, & label": {
        color: "#1E1E1E"
      }
    },
    "& .MuiFormLabel-root.Mui-disabled": {
      color: "#44556C"
    },
    "& .MuiInputBase-root > *": {
      opacity: 0,

      "&.MuiSelect-root.Mui-disabled, &.MuiInputBase-input.Mui-disabled": {
        opacity: 1,
        color: "#7E7E7E",
        paddingLeft: 0,
        fontWeight: 500
      }
    }
  },
  container: {
    minHeight: "calc(100vh - 120px)",
    maxHeight: "calc(100vh - 120px)",
    flexDirection: "column",
    flexWrap: "nowrap"
  },
  mt20: {
    marginTop: 20
  },
  actionColumn: {
    maxWidth: "100px",
    width: "100px"
  },
  tableContainer: {
    position: "relative",
    flexGrow: 1,
    boxShadow: "0px 5px 10px 0px #d9d9d9",
    borderRadius: 10
  },
  table: {
    borderCollapse: "separate",
    borderSpacing: "0 10px",
    padding: "0 25px",

    "& td": {
      backgroundColor: "#F9F9F9",
      border: "none"
    }
  },
  actionColumnButton: {
    margin: 0
  },
  checkbox: {
    padding: 0
  },
  tableHead: {
    backgroundColor: "#FCF3D4"
  },
  fineContainer: {
    marginTop: "15px",
    boxShadow: "0px 1px 9px 0px #888888",
    borderRadius: "11px"
  },
  firstFineContainer: {
    marginTop: "15px"
  },
  headerContainer: {
    boxShadow: "0px 1px 9px 0px #888888",
    borderRadius: "11px"
  },
  deleteButton: {
    backgroundColor: "#FF414A",
    width: "10px",
    height: "10px",
    margin: "0px",
    borderRadius: "15px"
  },
  addButton: {
    marginRight: 0,
    marginLeft: 30,
    height: 40,
    width: 130
  },
  icon: { width: "15px", marginRight: "20px" }
});
// Customizable Area End

class FineModalForm extends FineModalFormController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderFine = (
    fineForm: IFineForm | ISubFineForm,
    index?: number,
    isFirstFine: Boolean = false
  ) => {
    const { formMode } = this.props;
    const { formErrors } = this.state;
    const errorPrefix = index === undefined ? "" : index;
    return (
      <div
        style={{ display: "flex" }}
        className={
          isFirstFine
            ? this.props.classes.firstFineContainer
            : this.props.classes.fineContainer
        }
      >
        <Grid
          style={{ marginTop: 20, padding: "15px" }}
          container
          direction="row"
        >
          <Grid item xs={12} sm={4} style={{ paddingRight: 12 }}>
            <OutlinedSelect
              val={fineForm.fine_category}
              options={[
                { id: "late_fine", name: "Late Fine" },
                { id: "miscellaneous", name: "Miscellaneous" }
              ]}
              label="Fine Category*"
              placeholder="Select Type"
              name={"late_fine"}
              disabled={formMode === FormMode.View || !isFirstFine}
              handleChange={(e: any) =>
                this.onChangeFineCategory.bind(this)(e, index)
              }
              className={undefined}
            />
            {formErrors["fine_category" + errorPrefix] && (
              <Typography variant="caption" style={{ color: "red" }}>
                {formErrors["fine_category" + errorPrefix]}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} sm={4} style={{ paddingRight: 12 }}>
            <FormControl style={{ width: "100%" }}>
              <FormLabel style={{ marginBottom: "8px" }}>Fine Name*</FormLabel>
              <TextField
                value={fineForm.name}
                color={"primary"}
                variant="outlined"
                type={"text"}
                name={"name"}
                placeholder={"Enter Name"}
                disabled={formMode === FormMode.View}
                onChange={e => this.onChangeFineName.bind(this)(e, index)}
              />
              {formErrors["name" + errorPrefix] && (
                <Typography variant="caption" style={{ color: "red" }}>
                  {formErrors["name" + errorPrefix]}
                </Typography>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4} style={{ paddingRight: 12 }}>
            <FormControl style={{ width: "100%" }}>
              <FormLabel style={{ marginBottom: "8px" }}>
                Days After Due Date*
              </FormLabel>
              <div style={{ display: "flex" }}>
                <TextField
                  value={
                    (fineForm.day as string) === ""
                      ? fineForm.day
                      : parseInt(fineForm.day as string) >= 0
                      ? parseInt(fineForm.day as string)
                      : ""
                  }
                  color={"primary"}
                  variant="outlined"
                  name={"tax_percentage"}
                  placeholder={"Enter Days"}
                  disabled={
                    formMode === FormMode.View ||
                    fineForm.fine_category === "miscellaneous"
                  }
                  onChange={e => this.onChangeFineDay.bind(this)(e, index)}
                />
                <OutlinedSelect
                  val={fineForm.duration}
                  options={[
                    { id: "week", name: "Week" },
                    { id: "days", name: "Days" },
                    { id: "month", name: "Month" }
                  ]}
                  label=""
                  placeholder="Select Type"
                  name={"duration"}
                  styleOverrides={{ marginTop: "-8px" }}
                  disabled={
                    formMode === FormMode.View ||
                    fineForm.fine_category === "miscellaneous"
                  }
                  handleChange={(e: any) =>
                    this.onChangeFineDuration.bind(this)(e, index)
                  }
                  className={undefined}
                />
              </div>
              {formErrors["day" + errorPrefix] && (
                <Typography variant="caption" style={{ color: "red" }}>
                  {formErrors["day" + errorPrefix]}
                </Typography>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4} style={{ marginTop: 30, paddingRight: 12 }}>
            <FormControl style={{ width: "100%" }}>
              <FormLabel style={{ marginBottom: "8px" }}>
                Fine Amount*
              </FormLabel>
              <TextField
                value={fineForm.fine_amount}
                color={"primary"}
                variant="outlined"
                type={"text"}
                name={"amount"}
                placeholder={"Enter Amount"}
                disabled={formMode === FormMode.View}
                onChange={e => this.onChangeFineAmount.bind(this)(e, index)}
                // onClick={e => e.target.select()}
                onBlur={e => this.onBlurFineAmount.bind(this)(e, index)}
              />
              {formErrors["fine_amount" + errorPrefix] && (
                <Typography variant="caption" style={{ color: "red" }}>
                  {formErrors["fine_amount" + errorPrefix]}
                </Typography>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4} style={{ marginTop: 30, paddingRight: 12 }}>
            <OutlinedSelect
              val={fineForm.mode}
              options={[
                { id: "amount", name: "Amount" },
                { id: "percentage", name: "Percentage" }
              ]}
              label="Fine Mode*"
              placeholder="Select Type"
              name={"mode"}
              disabled={formMode === FormMode.View}
              handleChange={(e: any) =>
                this.onChangeFineMode.bind(this)(e, index)
              }
              className={undefined}
            />
            {formErrors["mode" + errorPrefix] && (
              <Typography variant="caption" style={{ color: "red" }}>
                {formErrors["mode" + errorPrefix]}
              </Typography>
            )}
          </Grid>
        </Grid>
        {!isFirstFine && formMode !== FormMode.View && (
          <div
            style={{
              backgroundColor: "#FFF4CE",
              width: "30px",
              minHeight: "100%",
              borderTopRightRadius: "10px",
              borderBottomRightRadius: "11px"
            }}
          >
            <div
              style={{
                minHeight: "100%",
                display: "flex",
                alignItems: "center"
              }}
            >
              <IconButton
                icon={deleteIcon}
                className={this.props.classes.deleteButton}
                onClick={() => this.onClickDelete.bind(this)(index || 0)}
              />
            </div>
          </div>
        )}
      </div>
    );
  };

  renderRemainFines(fineForms: ISubFineForm[]) {
    return fineForms.map((el, i) => this.renderFine(el, i));
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes, isOpen, formMode, title, submitLabel } = this.props;
    const { form, formErrors } = this.state;
    console.log("props", this.props);

    return (
      <CustomModal
        title={
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center"
            }}
          >
            <div>{title}</div>
            {form.fine_category === "late_fine" &&
              (formMode === FormMode.Create ||
                formMode === FormMode.Edit ||
                formMode === FormMode.Copy) && (
                <div>
                  <Button
                    title="ADD"
                    startIcon={
                      <img
                        className={classes.icon}
                        src={(plusIcon as unknown) as string}
                      />
                    }
                    buttonType={ButtonType.Primary}
                    className={classes.addButton}
                    onClick={this.onClickAdd.bind(this)}
                  />
                </div>
              )}
          </div>
        }
        handleSubmit={this.onSubmit.bind(this)}
        buttonTitle={submitLabel}
        open={isOpen}
        handleClose={this.handleFormClose.bind(this)}
        submitLoading={this.state.isSubmitting}
        hideCancelButton={formMode === FormMode.View}
      >
        <div style={{ maxHeight: "80vh", padding: "5px" }}>
          <Grid
            className={classes.headerContainer}
            container
            direction="column"
          >
            {this.renderFine(this.state.form, undefined, true)}
          </Grid>
          <Grid container direction="column">
            {this.renderRemainFines(this.state.form.sub_fines_attributes)}
          </Grid>
        </div>
      </CustomModal>
    );
    // Customizable Area End
  }
}

export default withStyles(useStyles)(FineModalForm);
