import React from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { IActionMenuItem } from "../../CommonLayout/HRMSCommons/src/actionMenu";
import { downloadCSV } from "../../../framework/src/Utilities";
import { toast } from "react-toastify";
import { debounce } from 'lodash';
// Customizable Area End

import { FormMode, Props as IRecurringExpenseFormProps, IRecurringExpenseForm } from "./RecurringExpenseFormController";
export const configJSON = require("./config");

// Customizable Area Start
enum Method {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
  PATCH = 'PATCH',
}

interface IRecurringExpense {
  id: number;
  type: string;
  selected: boolean;
  attributes: {
    expense_type: string,
    sac: string,
    hsn: string,
    expense_name: string,
    exchange_rate: string,
    exchange_amount: string,
    paid_through: string,
    currency: string,
    ammount: string,
    repeat_every: string,
    last_expense_date: string,
    next_expense_date: string,
    start_date: string,
    end_date: string,
    expense_id: number,
    vendor_id: number | string,
    customer_id: number,
    tax: string,
    source_of_supply: string,
    destination_of_supply: string,
    gst_treatment: string,
    reverse_charge_account: string,
    reverse_charge: string,
    reverse_charge_ammount: string;
    reverse_charge_type: string;
    comment: string,
    ammount_is: string,
    gst_in: null,
    expense_recipt: string,
    ref_no: string,
    status: string,
    customer: string,
    vendor: string,
    bill_every_count: string;
    bill_every_option: string;
  };
}

interface IPageMeta {
  message: string;
  total_pages: number;
}

interface IFilterForm {
  expense_type: string;
  vendor_id: string;
  status: string;
}
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  anchorEl: HTMLElement | null;
  actionedTableRow: IRecurringExpense | null;
  actionMenuItems: Array<IActionMenuItem>;
  recurringExpenses: Array<IRecurringExpense>;
  pageMeta: number;
  selectAllCheckboxChecked: false | 'indeterminate' | true;
  isLoading: boolean;
  deleteModalOpen: boolean;
  deleteMessage: "single" | "multiple";
  stopModalOpen: boolean;
  filterForm: IFilterForm;
  isRecurringExpenseFormModalOpened: boolean;
  recurringExpenseForm: IRecurringExpenseForm | null;
  recurringExpenseFormMode: FormMode;
  currentPage: number;
  closeImportModal: boolean,
  expenseAccounts: string[],
  searchKeyword: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class RecurringExpensesController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  public tableActionMenuItems: Array<IActionMenuItem> = [
    { label: "Edit", action: this.editRecurringExpense.bind(this) },
    { label: "Stop", action: this.stopRecurringExpense.bind(this) },
    { label: "View", action: this.viewRecurringExpense.bind(this) },
    { label: "Delete", action: this.deleteRecurringExpense.bind(this) },
  ];

  public RequestMessage = {
    GetRecurringExpenses: this.buildRequestMessage(Method.GET),
    FilterRecurringExpenses: this.buildRequestMessage(Method.GET),
    DeleteRecurringExpense: this.buildRequestMessage(Method.DELETE),
    StopRecurringExpense: this.buildRequestMessage(Method.PUT),
    CreateRecurringExpense: this.buildRequestMessage(Method.POST),
    EditRecurringExpense: this.buildRequestMessage(Method.PUT),
    ImportRecurringExpense: this.buildRequestMessage(Method.POST),
    ExportRecurringExpense: this.buildRequestMessage(Method.GET),
    SearchRecurringExpenses: this.buildRequestMessage(Method.GET),
    Null: undefined as any
  }

  public recurringExpenseFormProps: { [key: number]: IRecurringExpenseFormProps } = {
    [FormMode.Create]: {
      title: "Create Recurring Expense",
      submitLabel: "Create",
      initialValues: null,
      
      formMode: FormMode.Create,
      isOpen: false,
      onClose: this.onCloseRecurringExpenseFormModal.bind(this),
      onSubmit: this.onSubmitCreateRecurringExpenseModal.bind(this),
      requestMessage: this.RequestMessage.CreateRecurringExpense
    },
    [FormMode.Edit]: {
      title: "Edit Recurring Expense",
      submitLabel: "Update",
      initialValues: null,
      formMode: FormMode.Edit,
      isOpen: false,
      onClose: this.onCloseRecurringExpenseFormModal.bind(this),
      onSubmit: this.onSubmitEditRecurringExpenseModal.bind(this),
      requestMessage: this.RequestMessage.EditRecurringExpense
    },
    [FormMode.View]: {
      title: "View Recurring Expense",
      submitLabel: "Back to Listing",
      initialValues: null,
      formMode: FormMode.View,
      isOpen: false,
      onClose: this.onCloseRecurringExpenseFormModal.bind(this),
      onSubmit: this.onCloseRecurringExpenseFormModal.bind(this),
      requestMessage: this.RequestMessage.Null
    },
    [FormMode.Copy]: {
      title: "Copy Tax Rate",
      submitLabel: "Copy",
      initialValues: null,
      formMode: FormMode.Copy,
      isOpen: false,
      onClose: this.onCloseRecurringExpenseFormModal.bind(this),
      onSubmit: this.onSubmitCreateRecurringExpenseModal.bind(this),
      requestMessage: this.RequestMessage.Null
    }
  }

  public printReferance: any = React.createRef();
  public tableRef: any = React.createRef();;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      anchorEl: null,
      searchKeyword: "",
      actionedTableRow: null,
      recurringExpenses: [],
      actionMenuItems: [],
      pageMeta: 0,
      selectAllCheckboxChecked: false,
      isLoading: true,
      deleteModalOpen: false,
      stopModalOpen: false,
      filterForm: {
        expense_type: '',
        vendor_id: '',
        status: ''
      },
      deleteMessage: "single",
      isRecurringExpenseFormModalOpened: false,
      recurringExpenseForm: null,
      recurringExpenseFormMode: FormMode.Create,
      currentPage: 1,
      closeImportModal: false,
      expenseAccounts: [
        "Job Costing",
        "Labour",
        "Materials"
      ]
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start

    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const callID = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const response = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      switch (callID) {
        case this.RequestMessage.FilterRecurringExpenses.messageId:
          if (response !== null) {
            this.setState({
              recurringExpenses: response?.data || [],
              pageMeta: response?.meta || 0,
              isLoading: false,
              searchKeyword: ""
            });
          }
          break;
        case this.RequestMessage.GetRecurringExpenses.messageId:
          if (response !== null) {
            this.setState({
              recurringExpenses: response?.data?.data || [],
              pageMeta: response?.meta || 0,
              isLoading: false,
              searchKeyword: ""
            });
          }
          break;

        case this.RequestMessage.DeleteRecurringExpense.messageId:
          if (response !== null) {
            this.setState({
              deleteModalOpen: false,
              actionedTableRow: null
            });

            this.getRecurringExpensesCall();
          }
          break;
        
        case this.RequestMessage.StopRecurringExpense.messageId:
          if (response !== null) {
            this.setState({
              stopModalOpen: false,
              actionedTableRow: null
            });

            this.getRecurringExpensesCall();
          }
          break;

        case this.RequestMessage.CreateRecurringExpense.messageId:
          if (response !== null) {
            this.setState({ isRecurringExpenseFormModalOpened: false })
            this.getRecurringExpensesCall();
          }

          break;

        case this.RequestMessage.EditRecurringExpense.messageId:
          if (response !== null) {
            this.setState({ isRecurringExpenseFormModalOpened: false })
            this.getRecurringExpensesCall();
          }

          break;

        case this.RequestMessage.ImportRecurringExpense.messageId:
          if (response !== null) {
            this.setState({ closeImportModal: true }, () => {
              this.setState({ closeImportModal: false });
            });
            this.getRecurringExpensesCall();
          }

          break;
        
        case this.RequestMessage.SearchRecurringExpenses.messageId:
          if (response !== null) {
            this.setState({
              recurringExpenses: response?.data || [],
              pageMeta: response?.meta || {},
              isLoading: false
            });
          }
          break;
      }
    }
    // Customizable Area End 
  }

  // Customizable Area Start
  public async componentDidMount() {
    super.componentDidMount();

    this.getRecurringExpensesCall();
  }

  public onClickCreateRecurringExpense() {
    this.setState({
      isRecurringExpenseFormModalOpened: true,
      recurringExpenseFormMode: FormMode.Create
    });
  }

  handleSearchChange = (evt: any) => {
    const { value } = evt.target;
    this.setState({ searchKeyword: value });
    const debouncedSave = debounce((value) => {
      if (value !== "") {
        this.setState({ currentPage: 1 });
        this.getSearchRecurringExpensesCall(1, value);
      } else {
        this.getRecurringExpensesCall();
      }
    }, 100);
    debouncedSave(value);
  }

  public onSelectAllRecurringExpenses(event: React.ChangeEvent<HTMLInputElement>) {
    const { recurringExpenses: taxRates } = this.state;
    const { checked } = event.target;

    const updatedTaxRates = taxRates.map((taxRate) => {
      return {
        ...taxRate,
        selected: checked
      }
    });

    this.setState({
      recurringExpenses: updatedTaxRates,
      selectAllCheckboxChecked: this.isSelectAllCheckboxChecked(updatedTaxRates)
    });
  }

  public onSelectRecurringExpense(event: React.ChangeEvent<HTMLInputElement>, row: IRecurringExpense) {
    const { recurringExpenses: taxRates } = this.state;
    const { checked } = event.target;

    const updatedTaxRates = taxRates.map((taxRate) => {
      return {
        ...taxRate,
        selected: row.id === taxRate.id ? checked : taxRate.selected
      }
    });

    this.setState({
      recurringExpenses: updatedTaxRates,
      selectAllCheckboxChecked: this.isSelectAllCheckboxChecked(updatedTaxRates)
    });
  }

  public onClickActionButton(event: any, item: IRecurringExpense) {
    if (item.attributes.status) {
      this.setState({
        anchorEl: event.currentTarget,
        actionedTableRow: item,
        actionMenuItems: this.tableActionMenuItems
      });  
    } else {
      this.setState({
        anchorEl: event.currentTarget,
        actionedTableRow: item,
        actionMenuItems: [{ label: "View", action: this.viewRecurringExpense.bind(this) }]
      });
    } 
  }

  public onCloseActionMenu() {
    this.setState({
      anchorEl: null,
      actionMenuItems: []
    });
  }

  onClickPagination = (page: any) => {
    this.setState({currentPage: page.selected});
    if (this.tableRef.current !== null) {
      this.tableRef.current.scrollIntoView();
    }
    if(this.state.searchKeyword !== ""){
    this.getSearchRecurringExpensesCall(page.selected + 1, this.state.searchKeyword);
    }else{
      this.getRecurringExpensesCall(page.selected + 1);
    }
  }

  public onCloseDeleteModal() {
    this.setState({
      deleteModalOpen: false,
      actionedTableRow: null
    });
  }

  public onCloseStopModal() {
    this.setState({
      stopModalOpen: false,
      actionedTableRow: null
    });
  }

  public onSubmitDeleteModal() {
    this.setState({ selectAllCheckboxChecked: false });
    this.deleteRecurringExpenseCall();
  }

  public onSubmitStopModal() {
    this.stopRecurringExpenseCall(this.state.actionedTableRow);
  }

  public onCloseRecurringExpenseFormModal() {
    this.setState({ isRecurringExpenseFormModalOpened: false });
  }

  public onSubmitCreateRecurringExpenseModal(form: IRecurringExpenseForm) {
    this.createRecurringExpenseCall(form);
  }

  public onSubmitEditRecurringExpenseModal(form: IRecurringExpenseForm) {
    this.editRecurringExpenseCall(form);
  }

  public onUploadCSVForImport(file: File) {
    this.importRecurringExpensesCall(file);
  }

  public onClickExportCSVButton() {
    this.exportRecurringExpensesCall();
  }

  //#region Filter Methods
  public onFilterElementChange(element: any, property: string) {
    this.setState({
      filterForm: {
        ...this.state.filterForm,
        [property]: element.target.value
      }
    });
  }

  public onFilterFormSubmit() {
    this.filterRecurringExpensesCall(this.state.filterForm);
  }
  //#endregion Filter Methods

  private getSearchRecurringExpensesCall(page: number = 1, searchKey: string) {
    this.setState({ isLoading: true });

    this.RequestMessage.SearchRecurringExpenses.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.RecurringExpensesEndPoint}/search?search_keyword=${searchKey}&page=${page? page: 1}`
    );
    
    runEngine.sendMessage(this.RequestMessage.SearchRecurringExpenses.id, this.RequestMessage.SearchRecurringExpenses);
  }

  private editRecurringExpense() {
    this.openRecurringExpenseModalWithInitialData(this.state.actionedTableRow, FormMode.Edit);
  }

  private viewRecurringExpense() {
    this.openRecurringExpenseModalWithInitialData(this.state.actionedTableRow, FormMode.View);
  }

  private deleteRecurringExpense() {
    const currentRecurringExpense = this.state.actionedTableRow;
    if (!currentRecurringExpense) return;
    const { recurringExpenses } = this.state;
    if (!currentRecurringExpense.selected) {
      const updatedRecurringExpenses = recurringExpenses.map(el => {
        return { ...el, selected: (el.id === currentRecurringExpense.id) ? true : false };
      });

      this.setState({
        recurringExpenses: updatedRecurringExpenses,
        selectAllCheckboxChecked: false,
        deleteMessage: "single"
      });
    } else {
      if (recurringExpenses.filter(el => el.selected).length > 1)
        this.setState({ deleteMessage: "multiple" });
      else this.setState({ deleteMessage: "single" });
    }

    this.setState({ deleteModalOpen: true });
  }

  private stopRecurringExpense() {
    this.setState({ stopModalOpen: true });
  }

  // private copyTaxRate() {
  //   this.openRecurringExpenseModalWithInitialData(this.state.actionedTableRow, FormMode.Copy);
  // }

  private openRecurringExpenseModalWithInitialData(recurringExpense: IRecurringExpense | null, mode: FormMode) {
    if (recurringExpense !== null) {
      const { id, attributes } = recurringExpense as IRecurringExpense;

      this.setState({
        isRecurringExpenseFormModalOpened: true,
        recurringExpenseFormMode: mode,
        recurringExpenseForm: {
          id,
          ...recurringExpense.attributes
        }
      });
    }
  }

  private selectedRecurringExpenses(recurringExpenses?: Array<IRecurringExpense>): Array<IRecurringExpense> {
    if (recurringExpenses === undefined) {
      recurringExpenses = this.state.recurringExpenses;
    }

    return recurringExpenses.filter((recurringExpense) => recurringExpense.selected);
  }


  private isSelectAllCheckboxChecked(recurringExpenses: Array<IRecurringExpense>): false | "indeterminate" | true {
    const selectedRecurringExpensess = this.selectedRecurringExpenses(recurringExpenses);
    const { length } = selectedRecurringExpensess;

    return length !== 0 && (length === recurringExpenses.length ? true : 'indeterminate');
  }

  //#region Service Calls 
  private getRecurringExpensesCall(page: number = 1) {
    this.setState({ isLoading: true });
    page = this.state.currentPage;

    this.RequestMessage.GetRecurringExpenses.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.RecurringExpensesEndPoint}?page=${page}`
    );

    runEngine.sendMessage(this.RequestMessage.GetRecurringExpenses.id, this.RequestMessage.GetRecurringExpenses);
  }

  private deleteRecurringExpenseCall() {
    const selectedRecurringExpenses = this.state.recurringExpenses.filter(el => el.selected)
    if (selectedRecurringExpenses.length > 0) {
      if (selectedRecurringExpenses.length === 1) {
        this.RequestMessage.DeleteRecurringExpense.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `${configJSON.RecurringExpensesEndPoint}/${selectedRecurringExpenses[0].id}`
        );
      } else {
        const ids = selectedRecurringExpenses.reduce((p, c) => p + (p ? "," : "") + c.id, "");
      this.RequestMessage.DeleteRecurringExpense.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.RecurringExpensesEndPoint}/bulk_destroy?ids=${ids}`
      );

      }
    }
      
    runEngine.sendMessage(this.RequestMessage.DeleteRecurringExpense.id, this.RequestMessage.DeleteRecurringExpense);
  }

  private stopRecurringExpenseCall(recurringExpense: IRecurringExpense | null) {
    if (recurringExpense !== null) {
      this.RequestMessage.StopRecurringExpense.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.RecurringExpensesEndPoint}/stop_recurring_expense?id=${recurringExpense.id}`
      );

      runEngine.sendMessage(this.RequestMessage.StopRecurringExpense.id, this.RequestMessage.StopRecurringExpense);
    }
  }

  private createRecurringExpenseCall(form: IRecurringExpenseForm) {
    this.RequestMessage.CreateRecurringExpense.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({ data: form })
    );

    runEngine.sendMessage(this.RequestMessage.CreateRecurringExpense.id, this.RequestMessage.CreateRecurringExpense);
  }

  private editRecurringExpenseCall(form: IRecurringExpenseForm) {
    const { id, ...rest } = form;

    if (id !== undefined) {
      this.RequestMessage.EditRecurringExpense.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify({ data: rest })
      );

      this.RequestMessage.EditRecurringExpense.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.RecurringExpensesEndPoint}/${id}`
      );

      runEngine.sendMessage(this.RequestMessage.EditRecurringExpense.id, this.RequestMessage.EditRecurringExpense);
    }
  }

  private filterRecurringExpensesCall(form: IFilterForm) {
    this.RequestMessage.FilterRecurringExpenses.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.RecurringExpensesEndPoint}/filter?${this.getFilterQuery(form)}`
    );

    runEngine.sendMessage(this.RequestMessage.FilterRecurringExpenses.id, this.RequestMessage.FilterRecurringExpenses);
  }

  private exportRecurringExpensesCall() {
    const requestOptions = {
      method: "GET",
      headers: JSON.parse(this.getHeaderMessage()),
    };

    fetch(`${configJSON.APIBaseURL}/${configJSON.RecurringExpensesEndPoint}/export`, requestOptions)
      .then((response) => {
        const file_sub_name = new Date().getTime()
        response.blob().then(blob => downloadCSV(blob, "recurring_expenses_csv_" + file_sub_name))
      })
      .catch((error) => {
        toast.success(error.message);
      });
  }

  private importRecurringExpensesCall(file: File) {
    const formData = new FormData();

    formData.append("file", file);

    this.RequestMessage.ImportRecurringExpense.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.RecurringExpensesEndPoint}/import`
    );

    this.RequestMessage.ImportRecurringExpense.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    runEngine.sendMessage(this.RequestMessage.ImportRecurringExpense.id, this.RequestMessage.ImportRecurringExpense);
  }
  //#endregion Service Calls 

  private buildRequestMessage(method: Method): Message {
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.RecurringExpensesEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.APIBaseURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      this.getHeaderMessage()
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method.toString()
    );

    return requestMessage;
  }

  private getHeaderMessage() {
    const header = {
      "Content-Type": configJSON.APIContentType,
      token: localStorage.getItem("token"),
    };

    return JSON.stringify(header);
  }

  private getFilterQuery(form: IFilterForm) {
    return Object.entries(form)
      .filter(([key, value]) => value !== "" && value !== null && value !== "null")
      .map(([key, value]) => `${key}=${value}`)
      .join("&");
  }
  // Customizable Area End
}