import React from "react";
// Customizable Area Start
import { runEngine } from "framework/src/RunEngine";
import { withStyles, Theme } from "@material-ui/core/styles";
import { Styles } from "@material-ui/styles";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import OutlinedSelect from "../../../components/src/OutlinedSelect";
import { IconButton } from "../../CommonLayout/HRMSCommons/src/button/iconButton";
import { calenderIcon, uploadIcon } from "./assets";
import SearchIcon from "@material-ui/icons/Search";

import CustomModal from "../../CommonLayout/CustomModal/src/CustomModal.web";

import ExpenseModalFormController, {
  FormMode,
  Props
} from "./ExpenseModalFormController";

import {
  Checkbox,
  FormControlLabel,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Radio,
  Select,
  Typography
} from "@material-ui/core";
import DatePicker from "react-date-picker";
import VendorSelectionModal from "../../CommonLayout/VendorSelectionModal/src/VendorSelectionModal.web";
import { IVendor } from "../../CommonLayout/VendorSelectionModal/src/VendorSelectionModalController";
// Customizable Area End

// Customizable Area Start
const useStyles: Styles<Theme, {}, string> = (theme: Theme) => ({
  viewMode: {
    "& .question": {
      "& h6, & label": {
        color: "#1E1E1E"
      }
    },
    "& .MuiFormLabel-root.Mui-disabled": {
      color: "#44556C"
    },
    "& .MuiInputBase-root > *": {
      opacity: 0,

      "&.MuiSelect-root.Mui-disabled, &.MuiInputBase-input.Mui-disabled": {
        opacity: 1,
        color: "#7E7E7E",
        paddingLeft: 0,
        fontWeight: 500
      }
    }
  },
  tableContainer: {
    position: "relative",
    flexGrow: 1,
    boxShadow: "0px 5px 10px 0px #d9d9d9",
    borderRadius: 10
  },
  table: {
    borderCollapse: "separate",
    borderSpacing: "0 10px",
    padding: "0 25px",

    "& td": {
      backgroundColor: "#F9F9F9",
      border: "none"
    }
  },
  fineContainer: {
    marginTop: "15px",
    boxShadow: "0px 1px 9px 0px #888888",
    borderRadius: "11px"
  },
  firstFineContainer: {
    marginTop: "15px"
  },

  deleteButton: {
    backgroundColor: "#FF414A",
    width: "10px",
    height: "10px",
    margin: "0px",
    borderRadius: "15px"
  },
  addButton: {
    marginRight: 0,
    marginLeft: 30,
    height: 40,
    width: 130
  },
  icon: { width: "15px", marginRight: "20px" },
  container: {
    minHeight: "calc(100vh - 120px)",
    maxHeight: "calc(100vh - 120px)",
    flexDirection: "column",
    flexWrap: "nowrap"
  },
  mt20: {
    marginTop: 20
  },
  actionColumn: {
    maxWidth: "100px",
    width: "100px"
  },

  actionColumnButton: {
    margin: 0
  },
  checkbox: {
    padding: 0
  },
  tableHead: {
    backgroundColor: "#FCF3D4"
  },
  firstExpenseContainer: {
    marginTop: "15px"
  },
  headerContainer: {
    boxShadow: "0px 1px 9px 0px #888888",
    borderRadius: "11px"
  },
  question: {
    backgroundColor: "#FFF9EE",
    marginTop: 20
  },
  searchIconContainer: {
    background: "#eb514f",
    height: "56px",
    display: "flex",
    borderRadius: "4px",
    width: "70px",
    justifyContent: "center",
    alignItems: "center"
  },

  uploadIconContainer: {
    // background: "#eb514f",
    height: "25px",
    display: "flex",
    borderRadius: "10px",
    width: "95%",
    justifyContent: "space-between",
    alignItems: "center",
    border: "dashed",
    padding: "8px",
    cursor: "pointer"
  },
  reverseTypeSelect: {
    backgroundColor: "#F7F7F7",
    width: "60px",
    marginRight: "-13px",
    height: "55px",
    paddingLeft: "10px",
    borderTopRightRadius: "3px",
    borderBottomRightRadius: "4px",
    borderLeft: "solid #D1D1D1"
  }
});
// Customizable Area End

class ExpenseModalForm extends ExpenseModalFormController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderView = (value: string, color: string = "#838383") => (
    <Typography variant="h6" style={{ color: color }}>
      {value}
    </Typography>
  );

  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes, isOpen, formMode, title, submitLabel } = this.props;
    const { form, vendors, formErrors, vendorName } = this.state;

    return (
      <CustomModal
        title={
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center"
            }}
          >
            <div>{title}</div>
          </div>
        }
        handleSubmit={this.onSubmit.bind(this)}
        buttonTitle={submitLabel}
        open={isOpen}
        handleClose={this.handleFormClose.bind(this)}
        submitLoading={this.state.isSubmitting}
        hideCancelButton={formMode === FormMode.View}
      >
        <div
          style={{ maxHeight: "80vh", padding: "5px", paddingBottom: "50px" }}
        >
          <Grid
            container
            direction="row"
            wrap="nowrap"
            justify="center"
            alignItems="center"
            className={classes.question}
          >
            <Typography variant="h6" style={{ color: "#494E5C" }}>
              {"Expense Type" + (formMode !== FormMode.View ? "*" : "")}
            </Typography>
            {formMode === FormMode.View ? (
              <div style={{ marginLeft: "10px" }}>
                {this.renderView(form.expense_type, "#47546C")}
              </div>
            ) : (
              <>
                <Radio
                  checked={form.expense_type === "goods"}
                  value={form.expense_type === "goods"}
                  onClick={() => this.onChangeExpenseType.bind(this)("goods")}
                  name="goods"
                />
                <InputLabel htmlFor="is_gst">Goods</InputLabel>
                <Radio
                  checked={form.expense_type === "services"}
                  value={form.expense_type === "services"}
                  onClick={() =>
                    this.onChangeExpenseType.bind(this)("services")
                  }
                  name="services"
                />
                <InputLabel htmlFor="is_gst">Services</InputLabel>
              </>
            )}
          </Grid>
          <div style={{ padding: "25px" }}>
            <Grid container direction="row" style={{ marginTop: 10 }}>
              <Grid item xs={12} sm={4} style={{ paddingRight: 12 }}>
                <FormControl style={{ width: "100%" }}>
                  <FormLabel style={{ marginBottom: "8px" }}>
                    {form.expense_type === "goods" ? "HSN code" : "SAC"}
                  </FormLabel>
                  {formMode === FormMode.View ? (
                    this.renderView(
                      form.expense_type === "goods" ? form.hns_code : form.sac
                    )
                  ) : (
                    <TextField
                      value={
                        form.expense_type === "goods" ? form.hns_code : form.sac
                      }
                      color={"primary"}
                      variant="outlined"
                      type={"text"}
                      name={"hsnSac"}
                      placeholder={
                        form.expense_type === "goods"
                          ? "Enter HSN code"
                          : "Enter SAC"
                      }
                      onChange={
                        form.expense_type === "goods"
                          ? this.onChangeHsnCode.bind(this)
                          : this.onChangeSac.bind(this)
                      }
                    />
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4} style={{ paddingRight: 12 }}>
                <FormControl style={{ width: "100%" }}>
                  <FormLabel style={{ marginBottom: "8px" }}>
                    {"Date" + (formMode === FormMode.View ? "" : " *")}
                  </FormLabel>
                  {formMode === FormMode.View ? (
                    this.renderView(form.date)
                  ) : (
                    <>
                      <DatePicker
                        dayPlaceholder="DD"
                        monthPlaceholder="MM"
                        yearPlaceholder="YYYY"
                        onChange={this.handleDateChange}
                        // style={ { border: "none", width: "100%"}}
                        value={form.date}
                        minDate={new Date()}
                        calendarIcon={
                          <img style={{ height: "27px" }} src={calenderIcon} />
                        }
                      />
                      {formErrors["date"] && (
                        <Typography variant="caption" style={{ color: "red" }}>
                          {formErrors["date"]}
                        </Typography>
                      )}
                    </>
                  )}
                </FormControl>
              </Grid>
            </Grid>
            <Grid container direction="row" style={{ marginTop: 10 }}>
              <Grid
                item
                xs={12}
                sm={4}
                style={{ marginTop: 30, paddingRight: 12 }}
              >
                {formMode === FormMode.View ? (
                  <>
                    <FormLabel style={{ marginBottom: "8px" }}>
                      Currency
                    </FormLabel>
                    {this.renderView(form.currency)}
                  </>
                ) : (
                  <>
                    <OutlinedSelect
                      val={form.currency}
                      options={this.state.currencies.map(el => ({
                        id: el[1],
                        name: el[1]
                      }))}
                      label="Currency*"
                      placeholder="Select Currency"
                      name={"Currency"}
                      handleChange={(e: any) => this.handleCurrencyChange(e)}
                      className={undefined}
                    />
                    {formErrors["currency"] && (
                      <Typography variant="caption" style={{ color: "red" }}>
                        {formErrors["currency"]}
                      </Typography>
                    )}
                  </>
                )}
              </Grid>
              {form.currency && form.currency !== "INR" && (
                <Grid
                  item
                  xs={12}
                  sm={4}
                  style={{ marginTop: 30, paddingRight: 12 }}
                >
                  <FormControl style={{ width: "100%" }}>
                    <FormLabel style={{ marginBottom: "8px" }}>
                      {"Exchange rate in INR for 1 " +
                        form.currency +
                        (formMode !== FormMode.View ? " *" : "")}
                    </FormLabel>
                    {formMode === FormMode.View ? (
                      this.renderView(form.exchange_rate)
                    ) : (
                      <>
                        <TextField
                          value={form.exchange_rate}
                          color={"primary"}
                          variant="outlined"
                          type={"text"}
                          name={"exchangeRate"}
                          placeholder={"Enter Exchange Rate"}
                          onChange={this.handleExchangeRate}
                        />
                        {formErrors["exchange_rate"] && (
                          <Typography
                            variant="caption"
                            style={{ color: "red" }}
                          >
                            {formErrors["exchange_rate"]}
                          </Typography>
                        )}
                      </>
                    )}
                  </FormControl>
                </Grid>
              )}
              {form.currency && form.currency !== "INR" && (
                <Grid
                  item
                  xs={12}
                  sm={4}
                  style={{ marginTop: 30, paddingRight: 12 }}
                >
                  <FormControl style={{ width: "100%" }}>
                    <FormLabel style={{ marginBottom: "8px" }}>
                      {"Amount" + (formMode === FormMode.View ? "" : " *")}
                    </FormLabel>
                    {formMode === FormMode.View ? (
                      this.renderView(form.exchange_amount)
                    ) : (
                      <>
                        <TextField
                          value={form.exchange_amount}
                          color={"primary"}
                          variant="outlined"
                          type={"text"}
                          name={"exchangeRate"}
                          placeholder={"Enter Exchange Rate"}
                          onChange={this.handleExchangeAmount}
                        />
                        {formErrors["exchange_amount"] && (
                          <Typography
                            variant="caption"
                            style={{ color: "red" }}
                          >
                            {formErrors["exchange_amount"]}
                          </Typography>
                        )}
                      </>
                    )}
                  </FormControl>
                </Grid>
              )}
            </Grid>
            <Grid container direction="row" style={{ marginTop: 10 }}>
              <Grid
                item
                xs={12}
                sm={4}
                style={{ marginTop: 30, paddingRight: 12 }}
              >
                {formMode === FormMode.View ? (
                  <>
                    <FormLabel style={{ marginBottom: "8px" }}>
                      Expense Account
                    </FormLabel>
                    {this.renderView(form.expence_account)}
                  </>
                ) : (
                  <>
                    {" "}
                    <OutlinedSelect
                      val={form.expence_account}
                      options={[
                        { id: "ACCOUNT1", name: "ACCOUNT1" },
                        { id: "ACCOUNT2", name: "ACCOUNT2" },
                        { id: "ACCOUNT3", name: "ACCOUNT3" }
                      ]}
                      label="Expense Account*"
                      placeholder="Select Expense Account"
                      name={"mode"}
                      handleChange={(e: any) =>
                        this.handleExpenseAccountChange(e)
                      }
                      className={undefined}
                    />
                    {formErrors["expence_account"] && (
                      <Typography variant="caption" style={{ color: "red" }}>
                        {formErrors["expence_account"]}
                      </Typography>
                    )}
                  </>
                )}
              </Grid>
              <Grid
                item
                xs={12}
                sm={4}
                style={{ marginTop: 30, paddingRight: 12 }}
              >
                <FormControl style={{ width: "100%" }}>
                  <FormLabel style={{ marginBottom: "8px" }}>
                    {"Amount (in INR)" +
                      (formMode === FormMode.View ? "" : " *")}
                  </FormLabel>
                  {formMode === FormMode.View ? (
                    this.renderView(form.expense_amount)
                  ) : (
                    <>
                      <TextField
                        value={form.expense_amount}
                        color={"primary"}
                        variant="outlined"
                        type={"text"}
                        name={"hsnSac"}
                        placeholder={"Enter Amount"}
                        onChange={
                          form.currency && form.currency !== "INR"
                            ? undefined
                            : this.handleAmountChange
                        }
                      />
                      {formErrors["expense_amount"] && (
                        <Typography variant="caption" style={{ color: "red" }}>
                          {formErrors["expense_amount"]}
                        </Typography>
                      )}
                    </>
                  )}
                </FormControl>
              </Grid>
              <Grid
                item
                xs={12}
                sm={4}
                style={{ marginTop: 30, paddingRight: 12 }}
              >
                {formMode === FormMode.View ? (
                  <>
                    <FormLabel style={{ marginBottom: "8px" }}>Tax</FormLabel>
                    {this.renderView(form.tax)}
                  </>
                ) : (
                  <>
                    <OutlinedSelect
                      val={form.tax}
                      options={this.state.taxes.map(el => ({
                        id: el.id,
                        name: el.attributes ? el.attributes.name : ""
                      }))}
                      label="Tax*"
                      placeholder="Select Tax"
                      name={"Tax"}
                      handleChange={this.handleTaxChange}
                      className={undefined}
                    />
                    {formErrors["tax"] && (
                      <Typography variant="caption" style={{ color: "red" }}>
                        {formErrors["tax"]}
                      </Typography>
                    )}
                  </>
                )}
              </Grid>
            </Grid>
            {form.tax && (
              <Grid
                container
                direction="row"
                wrap="nowrap"
                alignItems="center"
                style={{ marginTop: 30, paddingRight: 12 }}
              >
                <Typography
                  variant="h6"
                  style={{ color: "#3A4D72", marginRight: "5px" }}
                >
                  Amount is
                </Typography>
                {formMode === FormMode.View ? (
                  this.renderView(
                    form.is_tax_inclusive ? " Tax Inclusive" : " Tax Exclusive"
                  )
                ) : (
                  <>
                    <Radio
                      checked={form.is_tax_inclusive}
                      value={form.is_tax_inclusive}
                      onClick={() => this.onChangeTaxExclusive(true)}
                      name="Tax Inclusive"
                    />
                    <InputLabel style={{ color: "#3A4D72" }}>
                      Tax Inclusive
                    </InputLabel>
                    <Radio
                      checked={!form.is_tax_inclusive}
                      value={!form.is_tax_inclusive}
                      onClick={() => this.onChangeTaxExclusive(false)}
                      name="Tax Exclusive"
                    />
                    <InputLabel style={{ color: "#3A4D72" }}>
                      Tax Exclusive
                    </InputLabel>
                  </>
                )}
              </Grid>
            )}

            <Grid
              item
              xs={12}
              sm={4}
              style={{ marginTop: 30, paddingRight: 12 }}
            >
              <FormControl style={{ width: "100%" }}>
                <FormLabel style={{ marginBottom: "8px" }}>Vendor</FormLabel>
                {formMode === FormMode.View ? (
                  this.renderView(form.vendor || "")
                ) : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between"
                    }}
                  >
                    <Autocomplete
                      // freeSolo
                      forcePopupIcon={false}
                      disableClearable
                      filterOptions={options =>
                        options.filter(
                          el =>
                            el
                              .toLowerCase()
                              .indexOf(vendorName.toLowerCase()) >= 0
                        )
                      }
                      style={{ width: "100%", marginRight: "15px" }}
                      options={vendors.map(this.prepareVendorName)}
                      value={vendorName}
                      noOptionsText={"No Record Found"}
                      onChange={(e, val) => this.setState({ vendorName: val })}
                      renderInput={params => (
                        <TextField
                          {...params}
                          color={"primary"}
                          variant="outlined"
                          type={"text"}
                          name={"hsnSac"}
                          style={{ width: "100%", marginRight: "15px" }}
                          placeholder={""}
                        />
                      )}
                    />
                    {/* <TextField
                      value={vendorName}
                      color={"primary"}
                      variant="outlined"
                      type={"text"}
                      name={"hsnSac"}
                      style={{ width: "100%", marginRight: "15px" }}
                      placeholder={""}
                      onChange={e =>
                        this.setState({ vendorName: e.target.value })
                      }
                    /> */}
                    <div
                      className={classes.searchIconContainer}
                      onClick={() => this.setState({ vendorModalOpen: true })}
                    >
                      <SearchIcon
                        style={{
                          color: "white",
                          fontSize: "33px",
                          cursor: "pointer"
                        }}
                      />
                    </div>
                  </div>
                )}
              </FormControl>
            </Grid>

            <Grid container direction="row" style={{ marginTop: 10 }}>
              <Grid
                item
                xs={12}
                sm={4}
                style={{ marginTop: 30, paddingRight: 12 }}
              >
                <FormControl style={{ width: "100%" }}>
                  <FormLabel style={{ marginBottom: "8px" }}>
                    Invoice #
                  </FormLabel>
                  {formMode === FormMode.View ? (
                    this.renderView(form.invoice)
                  ) : (
                    <TextField
                      value={form.invoice}
                      color={"primary"}
                      variant="outlined"
                      type={"text"}
                      name={"hsnSac"}
                      placeholder={"Enter Invoice"}
                      onChange={this.handleInvoiceChange}
                    />
                  )}
                </FormControl>
              </Grid>
              <Grid
                item
                xs={12}
                sm={4}
                style={{ marginTop: 30, paddingRight: 12 }}
              >
                {formMode === FormMode.View ? (
                  <>
                    <FormLabel style={{ marginBottom: "8px" }}>
                      Paid Through
                    </FormLabel>
                    {this.renderView(form.paid_trough)}
                  </>
                ) : (
                  <>
                    <OutlinedSelect
                      val={form.paid_trough}
                      options={[
                        { id: "Advance Tax", name: "Advance Tax" },
                        { id: "Prepaid Expenses", name: "Prepaid Expenses" }
                      ]}
                      label="Paid Through*"
                      placeholder="Paid Through"
                      name={"Tax"}
                      handleChange={this.handlePaidThroughChange}
                    />
                    {formErrors["paid_trough"] && (
                      <Typography variant="caption" style={{ color: "red" }}>
                        {formErrors["paid_trough"]}
                      </Typography>
                    )}
                  </>
                )}
              </Grid>
            </Grid>
            <Grid container direction="row" style={{ marginTop: 10 }}>
              <Grid
                item
                xs={12}
                sm={4}
                style={{ marginTop: 30, paddingRight: 12 }}
              >
                {formMode === FormMode.View ? (
                  <>
                    <FormLabel style={{ marginBottom: "8px" }}>
                      Source Of Supply
                    </FormLabel>
                    {this.renderView(form.source_of_supply)}
                  </>
                ) : (
                  <>
                    <OutlinedSelect
                      val={form.source_of_supply}
                      options={[
                        { id: "TAX1", name: "TAX1" },
                        { id: "TAX2", name: "TAX2" },
                        { id: "TAX3", name: "TAX3" }
                      ]}
                      label="Source Of Supply*"
                      placeholder="Select"
                      name={"Source Of Supply"}
                      handleChange={this.handleSourceSupplyChange}
                    />
                    {formErrors["source_of_supply"] && (
                      <Typography variant="caption" style={{ color: "red" }}>
                        {formErrors["source_of_supply"]}
                      </Typography>
                    )}
                  </>
                )}
              </Grid>
              <Grid
                item
                xs={12}
                sm={4}
                style={{ marginTop: 30, paddingRight: 12 }}
              >
                {formMode === FormMode.View ? (
                  <>
                    <FormLabel style={{ marginBottom: "8px" }}>
                      Destination Of Supply
                    </FormLabel>
                    {this.renderView(form.destination_of_supply)}
                  </>
                ) : (
                  <>
                    <OutlinedSelect
                      val={form.destination_of_supply}
                      options={[
                        { id: "TAX1", name: "TAX1" },
                        { id: "TAX2", name: "TAX2" },
                        { id: "TAX3", name: "TAX3" }
                      ]}
                      label="Destination Of Supply*"
                      placeholder="Select"
                      name={"Destination Of Supply"}
                      handleChange={this.handleDestinationSupplyChange}
                    />
                    {formErrors["destination_of_supply"] && (
                      <Typography variant="caption" style={{ color: "red" }}>
                        {formErrors["destination_of_supply"]}
                      </Typography>
                    )}
                  </>
                )}
              </Grid>
            </Grid>
            <Grid container direction="row" style={{ marginTop: 10 }}>
              <Grid
                item
                xs={12}
                sm={4}
                style={{ marginTop: 30, paddingRight: 12 }}
              >
                {formMode === FormMode.View ? (
                  <>
                    <FormLabel style={{ marginBottom: "8px" }}>
                      GST Treatment
                    </FormLabel>
                    {this.renderView(form.gst_treatment)}
                  </>
                ) : (
                  <>
                    <OutlinedSelect
                      val={form.gst_treatment}
                      options={this.state.gstTreatments.map(el => ({
                        id: el.name,
                        name: el.name
                      }))}
                      label="GST Treatment*"
                      placeholder="Select"
                      name={"GST Treatment"}
                      handleChange={this.handleGstTreatmentChange}
                    />
                    {formErrors["gst_treatment"] && (
                      <Typography variant="caption" style={{ color: "red" }}>
                        {formErrors["gst_treatment"]}
                      </Typography>
                    )}
                  </>
                )}
              </Grid>
            </Grid>
            <Grid container direction="row" style={{ marginTop: 10 }}>
              <Grid
                item
                xs={12}
                sm={4}
                style={{ marginTop: 30, paddingRight: 12 }}
              >
                <FormControl style={{ width: "100%" }}>
                  <FormLabel style={{ marginBottom: "8px" }}>
                    Attach Expense Receipt
                  </FormLabel>
                  {formMode === FormMode.View ? (
                    this.renderView(form.expense_recipt, "#FF4162")
                  ) : (
                    <div
                      style={{
                        borderColor: form.expense_recipt ? "#FD4553" : "#D5D9DF"
                      }}
                      className={classes.uploadIconContainer}
                      onClick={() => this.fileInputRef.click()}
                    >
                      <input
                        ref={ref =>
                          (this.fileInputRef = ref as HTMLInputElement)
                        }
                        type={"file"}
                        // accept={".jpeg, .jpg, .png, .JPEG, .PNG, .JPG"}
                        style={{ display: "none" }}
                        name={"file"}
                        onChange={(e: any) =>
                          this.handleFileChange(e.target.files)
                        }
                      />

                      <div
                        style={{
                          color: form.expense_recipt ? "#FD4553" : "#556479"
                        }}
                      >
                        {form.expense_recipt
                          ? form.expense_recipt.name
                          : "Upload File"}
                      </div>
                      <img
                        style={{ width: "33px", color: "#D07A85" }}
                        src={uploadIcon}
                      />
                    </div>
                  )}
                </FormControl>
              </Grid>
            </Grid>

            <Grid
              item
              xs={12}
              sm={8}
              style={{ marginTop: 30, paddingRight: 12 }}
            >
              <FormControl style={{ width: "100%" }}>
                <FormLabel style={{ marginBottom: "8px" }}>
                  Reverse Charge
                </FormLabel>
                {formMode === FormMode.View ? (
                  this.renderView(
                    form.reverse_charge
                      ? "This transaction is acceptable for reverse charge"
                      : "This transaction is not acceptable for reverse charge"
                  )
                ) : (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={form.reverse_charge}
                        name="is_sub_account"
                      />
                    }
                    label="This transaction is acceptable for reverse charge"
                    onChange={this.handleReverseChargeChange}
                  />
                )}
              </FormControl>
            </Grid>

            {form.reverse_charge && (
              <Grid container direction="row" style={{ marginTop: 10 }}>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  style={{ marginTop: 30, paddingRight: 12 }}
                >
                  {formMode === FormMode.View ? (
                    <>
                      <FormLabel style={{ marginBottom: "8px" }}>
                        Reverse Charge Account
                      </FormLabel>
                      {this.renderView(form.reverse_charge_account)}
                    </>
                  ) : (
                    <OutlinedSelect
                      val={form.reverse_charge_account}
                      options={[
                        { id: "ACCOUNT1", name: "ACCOUNT1" },
                        { id: "ACCOUNT2", name: "ACCOUNT2" },
                        { id: "ACCOUNT3", name: "ACCOUNT3" }
                      ]}
                      label="Reverse Charge Account"
                      placeholder="Select Reverse Charge Account"
                      handleChange={this.handleReverseChargeAccountChange}
                      className={undefined}
                    />
                  )}
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  style={{ marginTop: 30, paddingRight: 12 }}
                >
                  <FormControl style={{ width: "100%" }}>
                    <FormLabel style={{ marginBottom: "8px" }}>
                      Reverse Charge
                    </FormLabel>

                    {formMode === FormMode.View ? (
                      this.renderView(
                        form.reverse_charge_ammount +
                          " " +
                          form.reverse_charge_type
                      )
                    ) : (
                      <OutlinedInput
                        type={"text"}
                        value={form.reverse_charge_ammount}
                        onChange={this.handleReverseChargeAmountChange}
                        endAdornment={
                          <InputAdornment position="end">
                            <div>
                              <Select
                                value={form.reverse_charge_type}
                                onChange={this.handleReverseChargeTypeChange}
                                className={classes.reverseTypeSelect}
                              >
                                <MenuItem value={"₹"}>₹</MenuItem>
                                <MenuItem value={"%"}>%</MenuItem>
                              </Select>
                            </div>
                          </InputAdornment>
                        }
                      />
                    )}
                  </FormControl>
                </Grid>
              </Grid>
            )}

            <Grid container direction="row" style={{ marginTop: 10 }}>
              <Grid
                item
                xs={12}
                sm={9}
                style={{ marginTop: 30, paddingRight: 12 }}
              >
                <FormControl style={{ width: "100%" }}>
                  <FormLabel style={{ marginBottom: "8px" }}>
                    Comments
                  </FormLabel>
                  {formMode === FormMode.View ? (
                    this.renderView(form.comment)
                  ) : (
                    <TextField
                      className="inputContainerExpense"
                      rows={3}
                      style={{ width: "100%", borderRadius: "6px" }}
                      name={"Comments"}
                      placeholder={"Add Comment"}
                      onChange={this.handleCommentChange}
                      multiline
                      value={form.comment}
                      color={"primary"}
                      variant="outlined"
                      type={"text"}
                    />
                  )}
                </FormControl>
              </Grid>
            </Grid>
          </div>

          <VendorSelectionModal
            title={"Advanced vendor search"}
            open={this.state.vendorModalOpen}
            handleClose={this.onCloseVendorModal}
            vendors={this.state.vendors}
            handleSubmit={(id: string) => this.onSubmitVendorModal(id)}
          />
        </div>
      </CustomModal>
    );
    // Customizable Area End
  }
}

export default withStyles(useStyles)(ExpenseModalForm);
