import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

// Customizable Area Start
export enum FormMode {
  Create,
  Edit,
  View,
  Copy
}

export interface IExpenseForm {
  expense_recipt: any;
  expense_type: string;
  is_tax_inclusive: boolean;
  reverse_charge: boolean;
  reverse_charge_type: string;
  reverse_charge_account: string;
  reverse_charge_ammount: string;
  id?: string;
  name: string;
  hns_code: string;
  sac: string;
  date: any;
  duration: string;
  currency: string;
  comment: string;
  tax: string;
  exchange_rate: string;
  exchange_amount: string;
  paid_trough: string;
  source_of_supply: string;
  destination_of_supply: string;
  gst_treatment: string;
  expence_account: string;
  expense_category: string | null;
  expense_amount: string;
  vendor_id: string;
  vendor?: string;
  invoice: string;
  mode: string;
  created_at?: string;
  updated_at?: string;
  custom_id?: string;
}
// Customizable Area End

export interface Props {
  // Customizable Area Start
  classes?: any;
  isOpen: boolean;
  requestMessage: Message;
  initialValues: IExpenseForm | null;
  formMode: FormMode;
  title: string;
  submitLabel: string;
  navigation?: any;
  onClose: () => void;
  onSubmit: (form: IExpenseForm) => void;

  // Customizable Area End
}

interface S {
  // Customizable Area Start
  form: IExpenseForm;
  isSubmitting: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ExpenseFormController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  private initialValues: IExpenseForm = {
    expense_recipt: undefined,
    reverse_charge: false,
    reverse_charge_type: "",
    reverse_charge_account: "",
    reverse_charge_ammount: "",
    expense_type: "goods",
    is_tax_inclusive: false,
    created_at: new Date().toString(),
    custom_id: "",
    date: "",
    paid_trough: "",
    gst_treatment: "",
    vendor_id: "",
    invoice: "",
    comment: "",
    duration: "",
    source_of_supply: "",
    destination_of_supply: "",
    expense_amount: "0",
    expense_category: null,
    mode: "amount",
    name: "",
    sac: "",
    tax: "",
    currency: "",
    exchange_rate: "",
    exchange_amount: "",
    expence_account: "",
    hns_code: "",
    updated_at: new Date().toString()
  };
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    console.log("props", props);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      form: { ...(props.initialValues || this.initialValues) },
      isSubmitting: false
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const callID = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      if (
        this.props.requestMessage &&
        callID === this.props.requestMessage.messageId
      ) {
        //this.handleFormClose();
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  public componentDidUpdate(prevProps: Props) {
    const { isOpen, initialValues, formMode } = this.props;
    if (prevProps.isOpen !== isOpen) {
      if (isOpen) {
        let form: IExpenseForm = { ...this.initialValues };

        if ([FormMode.Edit, FormMode.View].includes(formMode)) {
          form = { ...(initialValues || this.initialValues) };
        }

        this.setState({
          form,
          isSubmitting: false
        });
      }
    }
  }

  public onChangeTaxName(element: React.ChangeEvent<{ value: string }>) {
    this.setState({
      form: {
        ...this.state.form,
        name: element.target.value
      }
    });
  }

  public onSubmit() {
    this.setState({ isSubmitting: this.props.formMode !== FormMode.View });

    // TODO: Validate form

    this.props.onSubmit(this.state.form);
  }

  public handleFormClose() {
    this.props.onClose();
  }
  // Customizable Area End
}
