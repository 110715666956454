export enum Icon {
  Action = require("../assets/icons/action.png"),
  CSV = require("../assets/icons/cvs.png"),
  CSVActivated = require("../assets/icons/csv-activated.png"),
  Filter = require("../assets/icons/filter.png"),
  FilterActivated = require("../assets/icons/filter-activated.png"),
  Printer = require("../assets/icons/printer.png"),
  PrinterActivated = require("../assets/icons/printer-activated.png"),
  BulkUpdate = require("../assets/icons/bulkUpdate.png"),
  BulkUpdateActive = require("../assets/icons/bulkUpdateActive.png"),
  plus = require("../assets/icons/plus.png"),
  Trash = require("../assets/icons/trashIcon.png"),
}
