import React from "react";
import {
    TextField,
    Typography
} from "@material-ui/core";
import { withStyles, } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CustomBulkUpdateModal from '../../CommonLayout/CustomBulkUpdateModal/src/CustomBulkUpdateModal.web';
import OutlinedSelect from "../../../components/src/OutlinedSelect";

import BulkUpdateConcessionController,{
    Props
  } from "./BulkUpdateConcessionController";
import DatePicker from "react-date-picker";

interface roleMenuSubmenu {
    id: number;
    title: string;
    permission_category_id: number,
    fullAccessStatus: boolean,
    viewStatus: boolean,
    editStatus: boolean,
    createStatus: boolean,
    deleteStatus: boolean,
    approveStatus: boolean
}

interface roleMenuAttributes {
    id: number;
    title: string;
    menus: roleMenuSubmenu[];
}

interface roleMenuData {
    attributes: roleMenuAttributes;
}
interface parentAcc {
    id: string,
    name: string,
}
class BulkUpdateConcession extends BulkUpdateConcessionController {

    constructor(props: Props) {
        super(props);
    }

    render() {

        const { open, handleClose, concessionbulkUpdateerrorMessage,concessionTypeList,checkBulkUpdateId } = this.props;
        const { concession_type_id, commonErrorMsg,concessionTypeError,concessionModeError,bulkUpdateFormstate,concessionValidUntilError } = this.state;

        return (
            <CustomBulkUpdateModal
                title={"Bulk Update"}
                handleSubmit={this.handleSubmit}
                buttonTitle={"Update"}
                open={open}
                handleClose={(evt: object) => handleClose("bulkUpdate", false)}
                errormsg={concessionbulkUpdateerrorMessage ? concessionbulkUpdateerrorMessage : ''}
            >
                {commonErrorMsg && <p className="errorMsg2" style={{ textAlign:"center"}}>{commonErrorMsg}</p>}
                <Grid spacing={2} container>
                    <Grid item xs={6}>
                        <OutlinedSelect
                            placeholderTitle="Select Type"
                            val={bulkUpdateFormstate.fee_concession_type_id}
                            options={concessionTypeList}
                            label="Concession Type"
                            name={'fee_concession_type_id'}
                            handleChange={this.handleChange}
                            className={undefined}
                        />
                       
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl style={{ minWidth: "100%" }}>
                        <FormLabel style={{ marginBottom: "8px" }}>
                            valid Until
                        </FormLabel>
                        <DatePicker
                         dayPlaceholder="DD"
                         monthPlaceholder="MM"
                         yearPlaceholder="YYYY"
                            // selected={concessionFormstate.valid_until}
                            className={concessionValidUntilError ?"errorDate":"withoutErrorDate"}
                            onChange={this.handleDateChange}
                                // style={ { border: "none", width: "100%"}}
                                name={"valid_until"}
                                value={bulkUpdateFormstate.valid_until}
                                minDate={new Date()}
                                calendarClassName="Calender"
                                calendarIcon={<img style={{height:"27px"}
                            }src={this.state.calenderIcon} />               }
                            />
                        {/* <TextField
                            placeholder={`Select date`}
                            value={bulkUpdateFormstate.valid_until}
                            style={
                                concessionValidUntilError
                                  ? {
                                      border: "1px solid #d74a3b",
                                      outline: "0px",
                                      width: "100%"
                                    }
                                  : {
                                      border: "none",
                                      width: "100%"
                                    }
                              }
                            color={"primary"}
                            variant="outlined"
                            type={"date"}
                            InputLabelProps={{ shrink: true }}
                            name={"valid_until"}
                            onChange={this.handleChange}
                        /> */}
                        </FormControl>
                     {concessionValidUntilError && <p className="errorMsg2">{concessionValidUntilError}</p>}

                   {/* {concessionErrorMessage.valid_until &&(
                        <p style={{ color: "red" }}>{concessionErrorMessage.valid_until}</p>
                        )} */}
                    </Grid>
                   
                </Grid>
                {concessionTypeError &&
                            <p className="errorMsg2" style={{  display: "flex",
                            justifyContent: "center", }}>{concessionTypeError}</p>
                        }
            </CustomBulkUpdateModal>
        );
    }
}

export const themeCreatStyle = ((theme: any) => ({

    background_color: {
        backgroundColor: theme.palette.primary.main
    },
    title_font: {
        color: theme.props.titleColor,
        fontFamily: theme.props.titleFont
    },
    subTitle_font: {
        color: theme.props.subtitleColor,
        fontFamily: theme.props.subtitleFont
    },
    heading_font: {
        color: theme.props.headingColor,
        fontFamily: theme.props.headingFont
    },
    subHeading_font: {
        color: theme.props.subheadingColor,
        fontFamily: theme.props.subheadingFont
    },
    bodyText_font: {
        color: theme.props.textColor,
        fontFamily: theme.props.textFont
    },
    button_color: {
        backgroundColor: theme.props.buttonColor,
        color: "white",
    },
    icon_color: {
        color: theme.props.iconColor
    },
    button_color_disabled: {
        color: theme.props.buttonColor,
        backgroundColor: "white",
        border: `1px solid ${theme.props.buttonColor}`,
        '&:hover': {
            background: "#fff",
        }
    }
}));

export default withStyles(themeCreatStyle)(
    BulkUpdateConcession as React.ComponentType<any>
)