import React, { FunctionComponent, MouseEventHandler } from "react";
// Customizable Area Start
import { IconButton as MUIIConButton, Theme } from "@material-ui/core";
import withStyles, { Styles } from "@material-ui/core/styles/withStyles";
import { Icon } from "../assets";
import { classNames } from "../classNames";
// Customizable Area End

// Customizable Area Start
// Customizable Area End

interface Props {
  // Customizable Area Start
  onClick?: MouseEventHandler;
  icon: Icon;
  className?: string;
  classes?: any;
  borderless?: boolean;
  ref?: any;
  height?: any;
  // Customizable Area End
}

const IconButtonWithOutStyles: FunctionComponent<Props> = props => {
  // Customizable Area Start

  const defaults: Props = {
    icon: Icon.Action,
    className: "",
    onClick: (event: any) => {}
  };

  props = { ...defaults, ...props };
  const { classes, className, onClick, icon, ref, height } = props;

  return (
    <MUIIConButton
      ref={ref}
      className={classNames(
        classes.button,
        className,
        props.borderless && classes.borderless
      )}
      onClick={onClick}
    >
      <img
        height={height ? height : "18px"}
        width="auto"
        src={icon.toString()}
      />
    </MUIIConButton>
  );
  // Customizable Area End
};

const styles: Styles<Theme, {}, string> = {
  // Customizable Area Start
  button: {
    borderRadius: "8px",
    border: "1px solid #C7C9C4",
    backgroundColor: "transparent",
    margin: "10px"
  },
  borderless: {
    border: "none"
  }
  // Customizable Area End
};

export const IconButton = withStyles(styles)(IconButtonWithOutStyles);
