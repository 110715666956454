import React, { Component } from 'react';
// Customizable Area Start
import {
  makeStyles,
  Theme,
  createStyles,
  withStyles,
  
} from "@material-ui/core/styles";
import {
    TextField,
    Typography
} from "@material-ui/core";
import Grid from '@material-ui/core/Grid';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import OutlinedSelect from "../../../components/src/OutlinedSelect";
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Icon from '@material-ui/core/Icon';
import Button from '@material-ui/core/Button';
import {installmentIcon} from './assets';
import { classNames } from 'react-select/src/utils';
import StudentList from './StudentList';
import { Styles } from '@material-ui/styles';

interface feeAssignmentFormstate {
  feeAssignmentType: string;
  academic_year_id: number;
  grade_id: number;
  division_id: number;
  name: string;
  is_class_and_division: boolean,
  is_group: boolean,
  is_class: boolean,
  grade: string,
  division: string
}

const useStyles: Styles<Theme, {}, string> = (theme: Theme) => ({
 
  addButton: {
    "borderRadius": "8px",
    "boxShadow": "none",
    "textTransform": "none",
    "fontSize": "16px",
    "padding": "8px 30px",
    "border": "1px solid #ea5c6b",
    "lineHeight": "1.5",
    "background": "rgb(234, 80, 79)",
    "color": "white",
    '&:hover, &:focus': {
      backgroundColor: "#F15B5B",
      borderColor: "#F15B5B",
      color: '#FFFFFF',
    }
  }
});

type MyProps = { 
  handleChange: (e: any) => void,
  feeAssignmentFormstate: feeAssignmentFormstate,
  handleNext: (e: any) => void,
  classes: any
};
// Customizable Area End

class InstallmentAddForm extends Component<MyProps> {
  render() {
    // Customizable Area Start
    const { feeAssignmentFormstate, handleChange,handleNext,classes } = this.props;
    // Customizable Area End
  return (
    // Customizable Area Start
    <>
      <Grid
        container
        spacing={3}
        style={{
            marginTop: "70px",
            justifyContent: "center"
        }}
      >
        <Grid item>
          <img height={180} width={215} src={installmentIcon}/>
          <Typography style={{marginTop: "8px",fontSize: "17px",color: "#5d6b7f"}}>Add an Installment Plan</Typography>
        </Grid>
      </Grid>
      <Grid
       container
       spacing={3}
       style={{
           marginTop: "6px",
           marginBottom: "70px",
           justifyContent: "center"
       }}
      >
        <Grid item>
          <Button
            className={this.props.classes.addButton}
            onClick={handleNext}
            variant="contained"
            color="primary"
          >
            ADD
          </Button>
        </Grid>
    </Grid>
    </>
    // Customizable Area End
  );
  }
}
export default withStyles(useStyles)(InstallmentAddForm);