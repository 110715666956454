import React from "react";
import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";

// Customizable Area Start
import { downloadCSV } from "framework/src/Utilities";
import { toast } from "react-toastify";
// Customizable Area End

import { FormMode, IExpenseForm } from "./ExpenseFormController";
export const configJSON = require("./config");

// Customizable Area Start
enum Method {
  GET = "GET",
  POST = "POST",
  PUT = "PUT",
  DELETE = "DELETE",
  PATCH = "PATCH"
}

interface IPageMeta {
  message: string;
  total_pages: number;
}

interface IFilterForm {
  name: string;
  start_range: number;
  end_range: number;
  tax_type: string;
}
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  anchorEl: HTMLElement | null;
  pageMeta: IPageMeta;
  selectAllCheckboxChecked: false | "indeterminate" | true;
  isLoading: boolean;
  vendorModalOpen: boolean;
  expenseForm: IExpenseForm;
  formMode: FormMode;
  taxes: any[];
  sources: any[];
  gstTreatments: any[];
  currencies: any[];
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CreateUpdateExpenseController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start

  public RequestMessage = {
    GetExpense: this.buildRequestMessage(Method.GET),
    GetTaxes: this.buildRequestMessage(Method.GET),
    GetSources: this.buildRequestMessage(Method.GET),
    GetGstTreatments: this.buildRequestMessage(Method.GET),
    GetCurrencies: this.buildRequestMessage(Method.GET),
    CreateExpense: this.buildRequestMessage(Method.POST),
    EditExpense: this.buildRequestMessage(Method.PUT),
    ExportTaxRates: this.buildRequestMessage(Method.GET),
    Null: undefined as any
  };

  fileInputRef: HTMLInputElement;
  printReferance = React.createRef();
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      gstTreatments: [],
      taxes: [],
      sources: [],
      currencies: [],
      anchorEl: null,
      pageMeta: {
        message: "",
        total_pages: 0
      },
      selectAllCheckboxChecked: false,
      isLoading: true,
      vendorModalOpen: false,
      expenseForm: {
        reverse_charge: false,
        reverse_charge_account: "",
        reverse_charge_ammount: "",
        reverse_charge_type: "%",
        exchange_amount: "",
        exchange_rate: "",
        expense_recipt: undefined,
        is_tax_inclusive: false,
        expense_type: "goods",
        created_at: new Date().toString(),
        custom_id: "",
        date: "",
        gst_treatment: "",
        comment: "",
        vendor_id: "2",
        invoice: "",
        currency: "",
        duration: "",
        expense_amount: "0",
        source_of_supply: "",
        destination_of_supply: "",
        expense_category: null,
        mode: "amount",
        name: "",
        paid_trough: "",
        sac: "",
        hns_code: "",
        tax: "",
        expence_account: "",
        updated_at: new Date().toString()
      },
      formMode: FormMode.View
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const callID = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const response = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      switch (callID) {
        case this.RequestMessage.GetExpense.messageId:
          if (response && response.data && response.data.attributes) {
            const expenseForm: IExpenseForm = {
              ...response.data.attributes
            };
            this.setState({
              expenseForm,
              pageMeta: response.meta || {},
              isLoading: false
            });
          }
          break;

        case this.RequestMessage.CreateExpense.messageId:
          if (response !== null) {
            this.props.navigation.navigate("Expense");
          }

          break;
        case this.RequestMessage.GetTaxes.messageId:
          if (response !== null) {
            runEngine.debugLog("taxestaxes", response.data);
            this.setState({
              taxes: response.data,
              isLoading: false
            });
          }

          break;
        case this.RequestMessage.GetSources.messageId:
          if (response !== null) {
            runEngine.debugLog("taxestaxes", response.data);
            this.setState({
              sources: response.data,
              isLoading: false
            });
          }

          break;
        case this.RequestMessage.GetGstTreatments.messageId:
          if (response !== null) {
            runEngine.debugLog("GetGstTreatments", response.data);
            this.setState({
              gstTreatments: response.data,
              isLoading: false
            });
          }

          break;
        case this.RequestMessage.GetCurrencies.messageId:
          if (response !== null) {
            runEngine.debugLog("currencies", response.data);
            this.setState({
              currencies: response.data,
              isLoading: false
            });
          }

          break;

        case this.RequestMessage.EditExpense.messageId:
          if (response !== null) {
            this.props.navigation.navigate("Expense");
          }

          break;
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  public async componentDidMount() {
    super.componentDidMount();
    let formMode = FormMode.View;
    if (window.location.pathname === "/CreateExpense")
      formMode = FormMode.Create;
    else if (window.location.pathname === "/ViewExpense")
      formMode = FormMode.View;
    else if (window.location.pathname === "/EditExpense")
      formMode = FormMode.Edit;

    this.setState({ formMode });
    if (formMode !== FormMode.Create) {
      const query = window.location.search.replace("?", "");
      const searchText = "id=";
      if (query.indexOf(searchText) >= 0) {
        const id = query.substring(
          query.indexOf(searchText) + searchText.length
        );

        this.getExpenseCall(id);
      }
    }
    this.getTaxesCall();
    this.getSourcesCall();
    this.getGstTreatmentsCall();
    this.getCurrenciesCall();
  }

  handleFileChange = (files: any[]) => {
    runEngine.debugLog("filesfilesfiles", files);

    this.setState({
      expenseForm: { ...this.state.expenseForm, expense_recipt: files[0] }
    });
  };

  onCloseVendorModal = () => {
    this.setState({
      vendorModalOpen: false
    });
  };

  onCreateExpense = (form: IExpenseForm) => {
    this.createExpenseCall(form);
  };

  onEditExpense = (form: IExpenseForm) => {
    this.createExpenseCall(form);
  };

  handleReverseChargeChange = (e: any) => {
    this.setState({
      expenseForm: {
        ...this.state.expenseForm,
        reverse_charge: e.target.checked
      }
    });
  };

  handleDateChange = (value: any) => {
    runEngine.debugLog("datedatedated", value);

    this.setState({
      expenseForm: { ...this.state.expenseForm, date: value }
    });
  };

  handleCurrencyChange = (e: any) => {
    this.setState({
      expenseForm: { ...this.state.expenseForm, currency: e.target.value }
    });
  };

  handleExpenseAccountChange = (e: any) => {
    this.setState({
      expenseForm: {
        ...this.state.expenseForm,
        expence_account: e.target.value
      }
    });
  };

  handleReverseChargeAccountChange = (e: any) => {
    this.setState({
      expenseForm: {
        ...this.state.expenseForm,
        reverse_charge_account: e.target.value
      }
    });
  };

  handleTaxChange = (e: any) => {
    this.setState({
      expenseForm: {
        ...this.state.expenseForm,
        tax: e.target.value
      }
    });
  };

  handleGstTreatmentChange = (e: any) => {
    this.setState({
      expenseForm: {
        ...this.state.expenseForm,
        gst_treatment: e.target.value
      }
    });
  };

  handleSourceSupplyChange = (e: any) => {
    this.setState({
      expenseForm: {
        ...this.state.expenseForm,
        source_of_supply: e.target.value
      }
    });
  };

  handleDestinationSupplyChange = (e: any) => {
    this.setState({
      expenseForm: {
        ...this.state.expenseForm,
        destination_of_supply: e.target.value
      }
    });
  };

  handlePaidThroughChange = (e: any) => {
    this.setState({
      expenseForm: {
        ...this.state.expenseForm,
        paid_trough: e.target.value
      }
    });
  };

  handleAmountChange = (e: any) => {
    this.setState({
      expenseForm: {
        ...this.state.expenseForm,
        expense_amount: e.target.value
      }
    });
  };

  handleReverseChargeTypeChange = (e: any) => {
    this.setState({
      expenseForm: {
        ...this.state.expenseForm,
        reverse_charge_type: e.target.value
      }
    });
  };

  handleReverseChargeAmountChange = (e: any) => {
    this.setState({
      expenseForm: {
        ...this.state.expenseForm,
        reverse_charge_ammount: e.target.value
      }
    });
  };

  handleExchangeRate = (e: any) => {
    let expense_amount: any =
      parseFloat(e.target.value) *
      parseFloat(this.state.expenseForm.exchange_amount);
    expense_amount = expense_amount ? expense_amount.toFixed(2) : "0.00";
    this.setState({
      expenseForm: {
        ...this.state.expenseForm,
        exchange_rate: parseFloat(e.target.value).toFixed(2) || "0.00",
        expense_amount
      }
    });
  };

  handleExchangeAmount = (e: any) => {
    let expense_amount: any =
      parseFloat(e.target.value) *
      parseFloat(this.state.expenseForm.exchange_rate);
    expense_amount = expense_amount ? expense_amount.toFixed(2) : "0.00";
    this.setState({
      expenseForm: {
        ...this.state.expenseForm,
        exchange_amount: parseFloat(e.target.value).toFixed(2) || "0.00",
        expense_amount
      }
    });
  };

  handleCommentChange = (e: any) => {
    this.setState({
      expenseForm: {
        ...this.state.expenseForm,
        comment: e.target.value
      }
    });
  };

  handleInvoiceChange = (e: any) => {
    this.setState({
      expenseForm: {
        ...this.state.expenseForm,
        invoice: e.target.value
      }
    });
  };

  handleVendorIdChange = (e: any) => {
    this.setState({
      expenseForm: {
        ...this.state.expenseForm,
        vendor_id: e.target.value
      }
    });
  };

  onChangeTaxExclusive = (is_tax_inclusive: boolean) => {
    this.state.expenseForm.is_tax_inclusive = is_tax_inclusive;
    this.setState({ expenseForm: { ...this.state.expenseForm } });
  };

  onChangeSac = (event: any) => {
    this.state.expenseForm.sac = event.target.value;
    this.setState({ expenseForm: { ...this.state.expenseForm } });
  };
  onChangeHsnCode = (event: any) => {
    this.state.expenseForm.hns_code = event.target.value;
    this.setState({ expenseForm: { ...this.state.expenseForm } });
  };

  onChangeExpenseType = (expenseType: string) => {
    this.state.expenseForm.expense_type = expenseType;
    this.setState({ expenseForm: { ...this.state.expenseForm } });
  };

  onChangeExpenseCategory = (event: any) => {
    const expenseForm = this.state.expenseForm;
    if (expenseForm) expenseForm.expense_category = event.target.value;
    this.setState({ expenseForm: { ...this.state.expenseForm } });
  };
  onChangeExpenseName = (event: any) => {
    const expenseForm = this.state.expenseForm;
    if (expenseForm) expenseForm.name = event.target.value;
    this.setState({ expenseForm: { ...this.state.expenseForm } });
  };

  onChangeExpenseDay = (event: any) => {
    const expenseForm = this.state.expenseForm;
    // if (expenseForm) expenseForm.day = event.target.value;
    this.setState({ expenseForm: { ...this.state.expenseForm } });
  };

  onChangeExpenseDuration = (event: any) => {
    const expenseForm = this.state.expenseForm;
    if (expenseForm) expenseForm.duration = event.target.value;
    this.setState({ expenseForm: { ...this.state.expenseForm } });
  };

  onChangeExpenseAmount = (event: any) => {
    const expenseForm = this.state.expenseForm;
    if (expenseForm) expenseForm.expense_amount = event.target.value;
    this.setState({ expenseForm: { ...this.state.expenseForm } });
  };

  onBlurExpenseAmount = (event: any) => {
    const expenseForm = this.state.expenseForm;
    if (expenseForm)
      expenseForm.expense_amount =
        parseFloat(expenseForm.expense_amount).toFixed(2) || "0";

    this.setState({ expenseForm: { ...this.state.expenseForm } });
  };

  onChangeExpenseMode = (event: any) => {
    const expenseForm = this.state.expenseForm;
    if (expenseForm) expenseForm.mode = event.target.value;
    this.setState({ expenseForm: { ...this.state.expenseForm } });
  };

  //#region Service Calls
  private getExpenseCall(id: string) {
    this.setState({ isLoading: true });

    this.RequestMessage.GetExpense.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.EndPoint}/${id}`
    );

    runEngine.sendMessage(
      this.RequestMessage.GetExpense.id,
      this.RequestMessage.GetExpense
    );
  }

  private getTaxesCall() {
    this.setState({ isLoading: true });

    this.RequestMessage.GetTaxes.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.TaxEndPoint}/`
    );

    runEngine.sendMessage(
      this.RequestMessage.GetTaxes.id,
      this.RequestMessage.GetTaxes
    );
  }

  private getSourcesCall() {
    this.setState({ isLoading: true });

    this.RequestMessage.GetSources.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.SourceEndPoint}/`
    );

    runEngine.sendMessage(
      this.RequestMessage.GetSources.id,
      this.RequestMessage.GetSources
    );
  }

  private getGstTreatmentsCall() {
    this.setState({ isLoading: true });

    this.RequestMessage.GetGstTreatments.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.GstTreatmentsEndPoint}/`
    );

    runEngine.sendMessage(
      this.RequestMessage.GetGstTreatments.id,
      this.RequestMessage.GetGstTreatments
    );
  }

  private getCurrenciesCall() {
    this.setState({ isLoading: true });

    this.RequestMessage.GetCurrencies.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.CurrencyEndPoint}`
    );

    runEngine.sendMessage(
      this.RequestMessage.GetCurrencies.id,
      this.RequestMessage.GetCurrencies
    );
  }

  private createExpenseCall(form: IExpenseForm) {
    let token = window.localStorage.getItem("token");
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token
    };

    var FormData = require("form-data");
    var bodyFormData = new FormData();

    bodyFormData.append("expense_recipt", form.expense_recipt);
    bodyFormData.append("data[hns_code]", form.hns_code);
    bodyFormData.append("data[date]", form.date);
    bodyFormData.append("data[currency]", form.currency);
    bodyFormData.append("data[expence_account]", form.expence_account);
    bodyFormData.append("data[ammount]", form.expense_amount);
    bodyFormData.append("data[tax]", form.tax);
    bodyFormData.append("data[is_tax_inclusive]", form.is_tax_inclusive);
    bodyFormData.append("data[vendor_id]", form.vendor_id);
    bodyFormData.append("data[invoice]", form.invoice);
    bodyFormData.append("data[paid_trough]", form.paid_trough);
    bodyFormData.append("data[source_of_supply]", form.source_of_supply);
    bodyFormData.append(
      "data[destination_of_supply]",
      form.destination_of_supply
    );
    bodyFormData.append("data[gst_treatment]", form.gst_treatment);
    bodyFormData.append("data[comment]", form.comment);
    bodyFormData.append("data[exchange_rate]", form.exchange_rate);
    bodyFormData.append("data[exchange_amount]", form.exchange_amount);
    bodyFormData.append("data[expense_type]", form.expense_type);

    this.RequestMessage.CreateExpense.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.EndPoint
    );
    this.RequestMessage.CreateExpense.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.APIBaseURL
    );
    this.RequestMessage.CreateExpense.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.RequestMessage.CreateExpense.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      bodyFormData
    );
    this.RequestMessage.CreateExpense.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.POST
    );
    runEngine.sendMessage(
      this.RequestMessage.CreateExpense.id,
      this.RequestMessage.CreateExpense
    );
  }

  private editExpenseCall(form: IExpenseForm) {
    const { id, ...rest } = form;

    if (id !== undefined) {
      this.RequestMessage.EditExpense.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify({ tax: rest })
      );

      this.RequestMessage.EditExpense.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.ExpensesEndPoint}/${id}`
      );

      runEngine.sendMessage(
        this.RequestMessage.EditExpense.id,
        this.RequestMessage.EditExpense
      );
    }
  }

  //#endregion Service Calls

  private buildRequestMessage(method: Method): Message {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.TaxesEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestbaseURLMessage),
      configJSON.APIBaseURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      this.getHeaderMessage()
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method.toString()
    );

    return requestMessage;
  }

  private getHeaderMessage() {
    const header = {
      "Content-Type": configJSON.APIContentType,
      token: localStorage.getItem("token")
    };

    return JSON.stringify(header);
  }

  private getFilterQuery(form: IFilterForm) {
    return Object.entries(form)
      .filter(([key, value]) => value !== "" && value !== null)
      .map(([key, value]) => `${key}=${value}`)
      .join("&");
  }
  // Customizable Area End
}
