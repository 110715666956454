import React from "react";
// Customizable Area Start
import { withStyles, Theme } from '@material-ui/core/styles';
import { Styles } from '@material-ui/styles';
import Layout from "../../CommonLayout/Layout/src/Layout.web";
import { Grid, InputLabel, Radio, TextField, Typography } from "@material-ui/core";
import PageHeader from "../../CommonLayout/HRMSCommons/src/layout/PageHeader";
// Customizable Area End

import GSTSettingsController, { Props } from "./GSTSettingsController";
import { Button, ButtonType } from "../../CommonLayout/HRMSCommons/src/button/default";
import { classNames } from "../../CommonLayout/HRMSCommons/src/classNames";

// Customizable Area Start
const useStyles: Styles<Theme, {}, string> = (theme: Theme) => ({
  container: {
    boxShadow: "0px 5px 10px 0px #d9d9d9",
    borderRadius: 10,
    overflow: "hidden",
  },
  question: {
    backgroundColor: "#FFF9EE",
    marginTop: 20,
  },
  formRow: {
    marginTop: 20,

    "& .MuiFormControl-root": {
      width: "100%",
    }
  },
  labelContainer: {
    display: "flex",
    alignItems: "center",
    paddingLeft: 20,

    "& > label": {
      fontSize: 14,
      fontWeight: 600,
      color: "#3C516B"
    },

    "& > span": {
      fontSize: 10,
      fontWeight: 500,
      color: "#777777",
    }
  },
  actionContainer: {
    margin: '40px 0 20px 0'
  }
});
// Customizable Area End

class GSTSettings extends GSTSettingsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes, navigation } = this.props;
    const { form } = this.state;

    console.log('state', this.state.form)

    return (
      <Layout navigation={navigation}>
        <Grid container className={classes.container}>
          <PageHeader
            title="Tax Rates"
            haveActionBar={false}
            haveCreateButton={false}
          />

          <Grid container direction="row" wrap="nowrap" justify="center" alignItems="center" className={classes.question}>
            <Typography variant="h6">
              Is this GST?
            </Typography>
            <Radio
              checked={form.is_gst}
              value={!form.is_gst}
              onClick={this.onChangeGST.bind(this, true)}
              name="is_gst"
            />
            <InputLabel htmlFor="is_gst">Yes</InputLabel>
            <Radio
              checked={!form.is_gst}
              value={!form.is_gst}
              onClick={this.onChangeGST.bind(this, false)}
              name="is_gst"
            />
            <InputLabel htmlFor="is_gst">No</InputLabel>
          </Grid>

          <Grid container direction="column">
            <Grid container direction="row" wrap="nowrap" className={classes.formRow}>
              <Grid item xs={5} direction="column"
                className={classes.labelContainer}
                style={{ justifyContent: "center", alignItems: "flex-start" }}>
                <InputLabel htmlFor="gst_number">GSTIN*</InputLabel>
                <span>( Maximum 16 Digits )</span>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  value={form.gst_number}
                  id="gst_number"
                  name="gst_number"
                  color={'primary'}
                  variant="outlined"
                  type={'text'}
                  placeholder={'Enter Name'}
                  onChange={this.onChangeGSTIN.bind(this)}
                />
              </Grid>
              <Grid item xs={3}>
                <span style={{ fontSize: 10 }}>
                  Get Taxpayer Details // TODO
                </span>
              </Grid>
            </Grid>

            <Grid container direction="row" wrap="nowrap" className={classes.formRow}>
              <Grid item xs={5} className={classes.labelContainer}>
                <InputLabel htmlFor="legal_name">Business Legal Name</InputLabel>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  value={form.legal_name}
                  id="legal_name"
                  name="legal_name"
                  color={'primary'}
                  variant="outlined"
                  type={'text'}
                  placeholder={'Enter Business Legal Name'}
                  onChange={this.onChangeLegalName.bind(this)}
                />
              </Grid>
              <Grid item xs={3}>
              </Grid>
            </Grid>

            <Grid container direction="row" wrap="nowrap" className={classes.formRow}>
              <Grid item xs={5} className={classes.labelContainer}>
                <InputLabel htmlFor="trade_name">Business Trade Name</InputLabel>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  value={form.trade_name}
                  id="trade_name"
                  name="trade_name"
                  color={'primary'}
                  variant="outlined"
                  type={'text'}
                  placeholder={'Enter Business Trade Name'}
                  onChange={this.onChangeTradeName.bind(this)}
                />
              </Grid>
              <Grid item xs={3}>
              </Grid>
            </Grid>

            <Grid container direction="row" wrap="nowrap" className={classes.formRow}>
              <Grid item xs={5} className={classes.labelContainer}>
                <InputLabel htmlFor="regesterd_on">GST Registered On</InputLabel>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  value={form.regesterd_on}
                  id="regesterd_on"
                  name="regesterd_on"
                  color={'primary'}
                  variant="outlined"
                  type={'date'}
                  placeholder={'Enter Date'}
                  onChange={this.onChangeRegesterdOn.bind(this)}
                />
              </Grid>
              <Grid item xs={3}>
              </Grid>
            </Grid>

            <Grid container direction="row" wrap="nowrap" className={classNames(classes.formRow, classes.actionContainer)}>
              <Grid item xs={5}></Grid>
              <Grid item xs={4} justify="space-between" style={{ display: "flex" }}>
                <Button
                  title="Cancel"
                  buttonType={ButtonType.Cancel}
                  onClick={this.onCancel.bind(this)}
                />

                <Button
                  title="Save"
                  buttonType={ButtonType.Primary}
                  onClick={this.onSave.bind(this)}
                />
              </Grid>
              <Grid item xs={3}>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Layout>
    );
    // Customizable Area End
  }
}

export default withStyles(useStyles)(GSTSettings);
