import React from "react";
// Customizable Area Start
import { withStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Styles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import TextField from '@material-ui/core/TextField';
import OutlinedSelect from "../../../components/src/OutlinedSelect";

import CustomModal from "../../CommonLayout/CustomModal/src/CustomModal.web";
import { calenderIcon } from "./assets";
import DatePicker from "react-date-picker";
// Customizable Area End

import FeeFormController, { FormMode, Props } from "./FeeFormController";

// Customizable Area Start
const useStyles: Styles<Theme, {}, string> = (theme: Theme) => ({
  question: {
    backgroundColor: "#FFF9EE",
    marginTop: 20,
    marginLeft: -24,
    width: "calc(100% + 48px)",

    '& h6, & label': {
      color: "#7E766B",
      fontSize: 14,
      fontWeight: 600,
    }
  },
  viewMode: {
    '& .question': {
      '& h6, & label': {
        color: "#1E1E1E",
      }
    },
    '& .MuiFormLabel-root.Mui-disabled': {
      color: "#44556C",
    },
    '& .MuiInputBase-root > *': {
      opacity: 0,

      '&.MuiSelect-root.Mui-disabled, &.MuiInputBase-input.Mui-disabled': {
        opacity: 1,
        color: "#7E7E7E",
        paddingLeft: 0,
        fontWeight: 500
      }
    },
    '& .react-date-picker--disabled': {
      backgroundColor: "transparent",
      border: "none",

      '& img': {
        display: "none"
      },

      '& .react-date-picker__inputGroup': {
        padding: '0 !important'
      }
    }
  }
});
// Customizable Area End

class FeeForm extends FeeFormController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes, isOpen, formMode, title, submitLabel, academicAccounts, taxes } = this.props;
    const { form, formErrors } = this.state;

    return (
      <CustomModal
        title={title}
        handleSubmit={this.onSubmit.bind(this)}
        buttonTitle={submitLabel}
        open={isOpen}
        handleClose={this.handleFormClose.bind(this)}
        submitLoading={this.state.isSubmitting}
        hideCancelButton={formMode === FormMode.View}
      >
        <Grid container direction="column" className={FormMode.View === formMode ? classes.viewMode : ''}>
          <Grid container direction="row" style={{ marginTop: 20 }}>
            <Grid item xs={12} sm={4} style={{ marginTop: 16, paddingRight: 12 }}>
              <FormControl style={{ width: '100%' }}>
                <FormLabel style={{ marginBottom: "8px" }}>
                  Fee Name*
                </FormLabel>
                <TextField
                  value={form.name}
                  color={'primary'}
                  variant="outlined"
                  type={'text'}
                  name={'name'}
                  placeholder={'Enter Name'}
                  disabled={formMode === FormMode.View}
                  onChange={this.onChangeFeeName.bind(this)}
                />
              </FormControl>
              {
                formErrors.name && (
                  <Typography variant="caption" style={{ color: 'red' }}>
                    {formErrors.name}
                  </Typography>
                )
              }
            </Grid>

            <Grid item xs={12} sm={4} style={{ marginTop: 16, paddingRight: 12 }}>
              <FormControl style={{ width: '100%' }}>
                <FormLabel style={{ marginBottom: "8px" }}>
                  Valid Until*
                </FormLabel>
                <DatePicker
                  className={''}
                  dayPlaceholder="DD"
                  monthPlaceholder="MM"
                  yearPlaceholder="YYYY"
                  onChange={this.onChangeValidUntil.bind(this)}
                  calendarClassName="Calender"
                  name={"valid_until"}
                  value={form.valid_until}
                  minDate={new Date()}
                  calendarIcon={<img style={{ height: "27px" }} src={calenderIcon} />}
                  disabled={formMode === FormMode.View}
                />
              </FormControl>
              {
                formErrors.valid_until && (
                  <Typography variant="caption" style={{ color: 'red' }}>
                    {formErrors.valid_until}
                  </Typography>
                )
              }
            </Grid>

            <Grid item xs={12} sm={4} style={{ marginTop: 16, paddingRight: 12 }}>
              <OutlinedSelect
                val={form.academic_account_id?.toString()}
                options={academicAccounts.map(({ id, attributes: { name } }) => ({ id: id.toString(), name }))}
                label="Fee Account"
                placeholder="Select Account"
                name={'academic_account_id'}
                disabled={formMode === FormMode.View}
                handleChange={this.onChangeFeeAccount.bind(this)} className={undefined} />
              {
                formErrors.academic_account_id && (
                  <Typography variant="caption" style={{ color: 'red' }}>
                    {formErrors.academic_account_id}
                  </Typography>
                )
              }
            </Grid>

            <Grid item xs={12} sm={4} style={{ marginTop: 16, paddingRight: 12 }}>
              <FormControl style={{ width: '100%' }}>
                <FormLabel style={{ marginBottom: "8px" }}>
                  Fee Amount*
                </FormLabel>
                <TextField
                  value={form.amount}
                  color={'primary'}
                  variant="outlined"
                  type={'number'}
                  name={'fee_amount'}
                  placeholder={'Enter Amount'}
                  disabled={formMode === FormMode.View}
                  onChange={this.onChangeFeeAmount.bind(this)}
                />
              </FormControl>
              {
                formErrors.amount && (
                  <Typography variant="caption" style={{ color: 'red' }}>
                    {formErrors.amount}
                  </Typography>
                )
              }
            </Grid>

            <Grid item xs={12} sm={4} style={{ marginTop: 16, paddingRight: 12 }}>
              <OutlinedSelect
                val={form.tax_ids.map(id => id.toString())}
                multiple
                options={taxes.map(({ id, attributes: { name, tax_percentage } }) => ({ id: id.toString(), name: `${name} ${tax_percentage}%` }))}
                label="Tax"
                placeholder="Select Type"
                name={'tax_ids'}
                disabled={formMode === FormMode.View}
                handleChange={this.onChangeTaxType.bind(this)} className={undefined} />
              {
                formErrors.tax_ids && (
                  <Typography variant="caption" style={{ color: 'red' }}>
                    {formErrors.tax_ids}
                  </Typography>
                )
              }
            </Grid>
          </Grid>
        </Grid>
      </CustomModal>
    );
    // Customizable Area End
  }
}

export default withStyles(useStyles)(FeeForm);
