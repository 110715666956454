export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const rightIcon = require("../assets/rightIcon.png");
export const leftIcon = require("../assets/leftIcon.png");
export const disableRightIcon = require("../assets/disableRightIcon.png");
export const disableLeftIcon = require("../assets/disableLeftIcon.png");




